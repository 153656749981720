import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf6b96d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "infoText" }
const _hoisted_2 = { href: "https://www.auroral.eu/" }
const _hoisted_3 = { href: "https://www.auroral.eu/" }
const _hoisted_4 = { href: "https://www.digipolis.fi/" }
const _hoisted_5 = { href: "https://www.oulu.fi/" }
const _hoisted_6 = { href: "https://www.centria.fi/" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_GDPRModal = _resolveComponent("GDPRModal")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { class: "content-1" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_accordion_group, { ref: "accInfoGroup" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_accordion, { value: "new" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { slot: "header" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.newTitle")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { slot: "content" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.updates, (update) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: update.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createElementVNode("h2", null, _toDisplayString(update.title), 1),
                                _createElementVNode("h3", null, _toDisplayString(_ctx.moment(update.timestamp).format("DD.MM.yyyy HH:mm")), 1),
                                _createElementVNode("p", _hoisted_1, _toDisplayString(update.description), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion, { value: "feedback" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { slot: "header" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.feedbackTitle")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { slot: "content" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('info.feedbackHelpText')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select, {
                            interface: "popover",
                            modelValue: _ctx.feedback.type,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.feedback.type) = $event)),
                            "ok-text": _ctx.$t('assistant.choose'),
                            "cancel-text": _ctx.$t('assistant.cancel'),
                            value: "1",
                            placeholder: _ctx.$t('info.feedback1')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_select_option, { value: "1" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('info.feedback1')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_select_option, { value: "2" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('info.feedback2')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue", "ok-text", "cancel-text", "placeholder"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            "label-placement": "floating",
                            label: _ctx.$t('info.feedbackFormTitle'),
                            modelValue: _ctx.feedback.topic,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.feedback.topic) = $event))
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_textarea, {
                            "label-placement": "floating",
                            label: _ctx.$t('info.feedbackFormDesc'),
                            modelValue: _ctx.feedback.desc,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.feedback.desc) = $event)),
                            "auto-grow": true,
                            counter: true,
                            maxlength: 2000
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, { onClick: _ctx.submitForm }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('info.submit')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion, { value: "what" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { slot: "header" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.whatTitle")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { slot: "content" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.what")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion, { value: "why" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { slot: "header" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.whyTitle")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { slot: "content" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.why")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion, { value: "who" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { slot: "header" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.whoTitle")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { slot: "content" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.who")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion, { value: "developers" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { slot: "header" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.developersTitle")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { slot: "content" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.developers")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion, { value: "contact" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { slot: "header" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.contactTitle")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                class: "cont",
                slot: "content"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("info.contact.transportCompany")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("info.contact.owner")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("info.contact.devs")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion, { value: "gdpr" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { slot: "header" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.gdprTitle")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { slot: "content" }, {
                default: _withCtx(() => [
                  _createVNode(_component_GDPRModal)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion, { value: "support" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { slot: "header" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.supportTitle")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { slot: "content" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("info.support")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode("v" + _toDisplayString(_ctx.info.version), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_grid, { fixed: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { size: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { class: "items" }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", _hoisted_2, [
                        _createVNode(_component_ion_img, { src: "assets/icon/AURORAL-Vertical.png" })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, { size: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { class: "items" }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", _hoisted_3, [
                        _createVNode(_component_ion_icon, { src: "assets/icon/EU-Auroral.svg" })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, { size: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { class: "items" }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", _hoisted_4, [
                        _createVNode(_component_ion_img, { src: "assets/icon/Digipolis.png" })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, { size: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { class: "items" }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", _hoisted_5, [
                        _createVNode(_component_ion_img, {
                          size: "large",
                          src: "assets/icon/UniOulu.png"
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, { size: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { class: "items" }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", _hoisted_6, [
                        _createVNode(_component_ion_img, { src: "assets/icon/Centria.jpg" })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}