<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-item>
					<h3>{{$t('operator.editUser')}}</h3>
				</ion-item>
				<form novalidate>
					<ion-row>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-input :label="$t('general.firstName')" labelPlacement="stacked" v-model="editUserForm.firstname"></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-input :label="$t('general.lastName')" labelPlacement="stacked" v-model="editUserForm.lastname"></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-input :label="$t('general.email')" labelPlacement="stacked" v-model="editUserForm.email"></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-input :label="$t('general.defaultAddress')" labelPlacement="stacked" v-model="editUserForm.addressdefault"></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-input :label="$t('general.phone')" labelPlacement="stacked" v-model="editUserForm.phone"></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-select :label="$t('general.mainaccount')" labelPlacement="stacked" :placeholder="$t('general.mainaccount')" name="childparent" v-model="editUserForm.mainaccountid" id="childParent">
									<ion-select-option :value="null" key="null">{{$t('operator.noMainAccount')}}</ion-select-option>
									<ion-select-option v-for="(parent, index) in parents" :value="parent.id" :key="index">{{parent.name}}</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-item>
								<ion-textarea  :label="$t('general.addresses')" labelPlacement="stacked" rows="5" cols="5" v-model="editUserForm.addresses"></ion-textarea>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="editUser(editUserForm)">{{$t('general.save')}}</ion-button>
							<ion-button color="danger" @click="modalController.dismiss()">{{$t('general.cancel')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonInput,
	IonSelect,
	IonSelectOption,
	IonTextarea,
	modalController,
  IonCol,
  IonButton
} from "@ionic/vue";
import { defineComponent, toRaw } from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "EditUser",
		props: ['userdata', 'parentdata'],
		emits: ['responsedata'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonTextarea,
			IonInput,
			IonCol,
			IonButton,
			IonSelect,
			IonSelectOption
		},
    methods:{
			editUser(form){
				// TODO: Needs API call
				const rawObject = toRaw(form);
				const addressesSplit = rawObject['addresses'].split('\n');

				rawObject['address1'] = addressesSplit[0];
				rawObject['address2'] = addressesSplit[1];
				rawObject['address3'] = addressesSplit[2];

				axios.post(this.$api_add + localStorage.getItem("org") +'users/operatorupdate', rawObject).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.userResponses.editSuccess')),
							showMessage: true,
							editStatus: true
						});
					}
					else{
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.userResponses.editFailure') + res.statusText),
							showMessage: true,
							editStatus: false
						});
					}
				}).catch((e)=>{
					this.$emit('responsedata', {
						statusMessage: String(this.$t('operator.userResponses.editFailure') + e.message),
						showMessage: true,
						editStatus: false
					});
				});
			}
    },
    data(){
			const userdt = toRaw(this.userdata);
			const parentdt = toRaw(this.parentdata)
			const uAddresses = String((userdt.address1 || "") + "\n" + (userdt.address2 || "") + "\n" + (userdt.address3 || "")).trim();
      return{
				editUserForm: {
					id:userdt.id,
					firstname: userdt.firstname,
					lastname: userdt.lastname,
          username: userdt.username,
          email: userdt.email,
          phone: userdt.phone,
					role: userdt.role,
					mainaccountid: userdt.mainaccountid,
					addressdefault: userdt.addressdefault,
					address1: userdt.address1,
					address2: userdt.address2,
					address3: userdt.address3,
					addresses: uAddresses
				},
				parents: parentdt,
				isdesktop: this.$isdesktop
      }
    },
		setup(){
			return{
				modalController
			}
		}
});
</script>
<style>
	.bigarea{
		height:50%;
	}
</style>
