<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-header>
					<ion-toolbar>
						<ion-title>{{currentPage == 'table' ? $t('operator.savedAddresses') : $t('operator.addAddress')}}</ion-title>
						<ion-buttons slot="secondary">
							<ion-button size="large" @click="currentPage == 'table' ? changeView('AddAddress') : changeView('table')">
								<ion-icon :icon="changeIcon()" slot="start" size="large"></ion-icon>
								{{currentPage == 'AddAddress' ? $t('operator.back') : $t('operator.addAddress')}}
							</ion-button>
						</ion-buttons>
					</ion-toolbar>
				</ion-header>
				<ion-content class="ion-padding ion-margin-top schoolsTable" v-if="currentPage == 'table'">
          <ion-grid>
						<ion-row>
							<ion-col size="12">
								<ion-searchbar id="userSearch" :placeholder="$t('operator.searchAddresses')" @input="searchCoordinates($event.target.value)"></ion-searchbar>
							</ion-col>
						</ion-row>
						<ion-row class="tableHeader">
							<ion-col size="4">
								<ion-item lines="none" class="smallertext">
									<b>{{$t('general.address')}}</b>
								</ion-item>
							</ion-col>
							<ion-col size="4">
								<ion-item lines="none" class="smallertext">
									<b>{{$t('general.coordinates') + " ("+$t('general.latitude')+", "+$t('general.longitude')+")"}}</b>
								</ion-item>
							</ion-col>
							<ion-col size="4">
							</ion-col>
						</ion-row>
              <ion-row>
                <ion-col size="12" class="btmborder"  v-for="(school,index) in filteredCoordinates" :key="index">
                  <ion-row>
                    <ion-col size="4">
                      <ion-item lines="none">
                        {{school.address}}
                      </ion-item>
                    </ion-col>
										<ion-col size="4">
											<ion-item lines="none">
												{{school.latitude+", "+school.longitude}}
											</ion-item>
										</ion-col>
                    <ion-col size="4" v-if="isdesktop">
                      <ion-item lines="none">
												<ion-row>
													<ion-col size="12">
														<ion-button class="smallertext" @click="getEditData(index), setOpen(true)" size="full">{{$t('operator.modify')}}</ion-button>
													</ion-col>
													<ion-col size="12">
														<ion-button class="smallertext" @click="showDeleteAlert(index)" size="full" color="danger">{{$t('operator.delete')}}</ion-button>
													</ion-col>
												</ion-row>
                      </ion-item>
                    </ion-col>
										<ion-col size="4" v-else>
											<ion-item lines="none">
												<ion-row>
													<ion-col size="12">
														<ion-button  @click="getEditData(index), setOpen(true)" size="large">
															<ion-icon :icon="pencilOutline"></ion-icon>
														</ion-button>
													</ion-col>
													<ion-col size="12">
														<ion-button @click="showDeleteAlert(index)" size="large" color="danger">
															<ion-icon :icon="trashOutline"></ion-icon>
														</ion-button>
													</ion-col>
												</ion-row>
											</ion-item>
										</ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
          </ion-grid>
				</ion-content>
        <ion-content class="ion-padding ion-margin-top schoolsTable" v-if="currentPage == 'AddAddress'">
					<AddAddress @goback="parseAddEmited"/>
				</ion-content>
				<ion-modal :is-open="isOpenRef"  @didDismiss="setOpen(false), getAddresses()" v-on:openmodal="setOpen(openmodal)">
					<ion-content class="ion-padding ion-margin-top schoolsTable">
						<EditAddress :addressdata="editData" @responsedata="parseModalEmitted"/>
					</ion-content>
				</ion-modal>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonContent,
  IonGrid,
  IonRow,
	IonButton,
	IonIcon,
	IonButtons,
	IonModal,
  IonItem,
  IonHeader,
  IonCol,
  IonTitle,
  IonToolbar,
	modalController,
	alertController,
	IonSearchbar
} from "@ionic/vue";
import {
	add,
	arrowBackOutline,
	pencilOutline,
	trashOutline
} from 'ionicons/icons';
import { defineComponent, toRaw, ref } from 'vue';
import AddAddress from './AddAddress.vue';
import EditAddress from './EditAddress.vue';
import StatusToast from '@/components/StatusToast.vue';
import axios from 'axios';
export default defineComponent({
    name: "OperatorAddresses",
    components:{
      IonContent,
			IonButton,
      IonGrid,
      IonRow,
			IonIcon,
			IonButtons,
			IonModal,
      IonItem,
      IonHeader,
      IonCol,
      IonTitle,
      IonToolbar,
			AddAddress,
			EditAddress,
			IonSearchbar
		},
    methods:{
			changeView(view){
				this.currentPage = view;
			},
			getEditData(index){
				this.editData = toRaw(this.filteredCoordinates[index]);
			},
			parseAddEmited(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					this.changeView('table');
					this.getAddresses();
				}
			},
			parseModalEmitted(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					modalController.dismiss();
					this.getAddresses();
				}
			},
			getAddresses(){
				axios.post(this.$api_add + localStorage.getItem("org") +'coordinates/getdistinct').then((res)=>{
					if(res.data.result == 'ok'){
						this.coordinates = toRaw(res.data.data);
						if(this.filteredCoordinates == null){
							this.filteredCoordinates = this.coordinates;
						}
						else{
							this.searchCoordinates(this.searchQuery);
						}
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.addressResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.addressResponses.getFailed') + e);
					this.toastObject['status'] = false;
				})
			},
			deleteAddress(index){
				const deldata = toRaw(this.filteredCoordinates[index]);
				//test call
				axios.post(this.$api_add + localStorage.getItem("org") +'coordinates/delete', deldata).then((res)=>{
					if(res.data.result == 'ok'){
						this.filteredCoordinates.splice(index,1);
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.addressResponses.deleteSuccess'));
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.addressResponses.deleteFailure') + res.statusText);
						this.toastObject['status']  = false
					}
					this.showToast();

				}).finally(()=>{
					this.getAddresses();
				}).catch((e)=>{
					this.toastObject['message'] = String(this.$t('operator.addressResponses.deleteFailure') + e);
					this.toastObject['status'] = false;
					this.showToast();
				});
			},
			changeIcon(){
				if(this.currentPage == 'table'){
					return add;
				}
				else if(this.currentPage == 'AddAddress'){
					return arrowBackOutline;
				}
			},
			showToast(){
				StatusToast.methods.openToast(this.toastObject);
			},
			searchCoordinates(value){
				const query = toRaw(value).toLowerCase();
				if(!query){
					this.filteredCoordinates = this.coordinates;
				}
				else{
					this.filteredCoordinates = this.coordinates.filter((x)=>{
						const xvalues = String(Object.values(x).join(",")).toLowerCase();
						return xvalues.includes(query);
					});
				}
				this.searchQuery = query;
			},
			async showDeleteAlert(index){
				const data = this.filteredCoordinates[index];
				const message = this.$t('general.address')+`: `+data.address+`<br>`+this.$t('general.latitude')+
					`: `+data.latitude+'<br>'+this.$t('general.longitude')+`: `+data.longitude

				const alert = await alertController.create({
					header: this.$t('operator.confirmDeleteSchool'),
					message: message,
					buttons: [{
							text: this.$t('general.cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							id: 'cancel-button'
						},
						{
							id: 'confirm-button',
							text: this.$t('operator.delete'),
							cssClass:'deletealert',
							handler: () => {
								this.deleteAddress(index);
							},
						}],
				});
				await alert.present();

				await alert.onDidDismiss();
			}
    },
		setup(){
			const isOpenRef = ref(false);
			const setOpen = (state) => isOpenRef.value = state;
			return{
				isOpenRef,
				setOpen,
        add,
				alertController,
				pencilOutline,
				trashOutline
			}

		},
		mounted(){
			this.getAddresses();
		},
    data(){
      return{
				toastObject:{
					"message": null,
					"status": false
				},
				editData: null,
				currentPage: 'table',
				coordinates: [],
				filteredCoordinates: null,
				searchQuery: null,
				isdesktop: this.$isdesktop
      }
    }
});
</script>
<style>
  .schoolsTable{
		height: 90vh;
	}
	.tableHeader {
    display: flex; /* or inline-flex */
    flex-direction: row
	}
	.btmborder{
		border-bottom: solid 1px #cfcdca;
	}
	.iconbtn{
		font-size: 1.2em;
	}

	@media only screen and (max-width: 1300px) {
		.smallertext{
			font-size:0.8em;
		}
	}
</style>
