<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-header>
					<ion-toolbar>
						<ion-title>{{$t('operator.confirmBase')}}</ion-title>
					</ion-toolbar>
				</ion-header>
				<ion-content class="ion-padding ion-margin-top routeTable" v-if="currentPage == 'table'">
          <ion-grid>
						<ion-row>
						<ion-col size="6">
							<ion-row>
								<ion-col size="12">
									<ion-label class="bld">{{$t('general.dateBase')}}</ion-label>
									<ion-list>
										<ion-item>
											<ion-datetime-button datetime="datetime"></ion-datetime-button>
										</ion-item>
									</ion-list>
								</ion-col>
								<ion-col size="12">
									<ion-list-header class="bld">{{$t('operator.confirmSummary')}}</ion-list-header>
									<ion-list v-if="logAmounts.length > 0 ">
										<ion-item v-for="amount in logAmounts" :key="amount.status" :color="getColor(amount.statuscode)">
											<ion-badge slot="end" :color="getColor(amount.statuscode)">{{amount.amount}}</ion-badge>
											<ion-label>{{amount.status}}</ion-label>
										</ion-item>
									</ion-list>
									<ion-list v-else>
										<ion-item>
											<ion-note color="primary">{{$t('operator.confirmSearchNote')}}</ion-note>
										</ion-item>
									</ion-list>
								</ion-col>
								</ion-row>
						</ion-col>
						<ion-col size="12">
						<ion-accordion-group multiple="true">
							<ion-accordion value="archive">
								<ion-item slot="header" color="light">
									<ion-label>{{$t('operator.confirmsBase')}}</ion-label>
								</ion-item>
								<div class="ion-padding" slot="content">
									<ion-row class="tableHeader">
										<ion-col size="2">
											<ion-item lines="none">
												<b>{{$t('operator.timestamp')}}</b>
											</ion-item>
										</ion-col>
										<ion-col size="2">
											<ion-item lines="none">
												<b>{{$t('operator.confirmUser')}}</b>
											</ion-item>
										</ion-col>
										<ion-col size="2">
											<ion-item lines="none">
												<b>{{$t('operator.role')}}</b>
											</ion-item>
										</ion-col>
										<ion-col size="2">
											<ion-item lines="none">
												<b>{{$t('general.roles.child')}}</b>
											</ion-item>
										</ion-col>
										<ion-col size="2">
											<ion-item lines="none">
												<b>{{$t('operator.confirmBase')}}</b>
											</ion-item>
										</ion-col>
										<ion-col size="2">
											<ion-item lines="none"><b>{{ $t('operator.message') }}</b></ion-item>
										</ion-col>
								</ion-row>
								<ion-row v-for="(log,index) in logs" :key="index">
									<ion-col size="12" class="topborder">
										<ion-row>
											<ion-col size="2">
												<ion-item lines="none" class="daystext">
													{{log.timestamp_parsed}}
												</ion-item>
											</ion-col>
											<ion-col size="2">
												<ion-item lines="none">
													{{log.user}}
												</ion-item>
											</ion-col>
											<ion-col size="2">
												<ion-item lines="none">
													{{(log.role)}}
												</ion-item>
											</ion-col>
												<ion-col size="2">
													<ion-item lines="none">
														{{log.child}}
													</ion-item>
														</ion-col>
														<ion-col size="2">
															<ion-item lines="none" :color="getColor(log.status)">
																{{log.statusText}}
															</ion-item>
														</ion-col>
														<ion-col size="2">
															<ion-item lines="none">{{ log.message }}</ion-item>
														</ion-col>
													</ion-row>
												</ion-col>
											</ion-row>
										</div>
									</ion-accordion>
								</ion-accordion-group>
							</ion-col>
						</ion-row>
          </ion-grid>
				</ion-content>
			</ion-col>
		</ion-row>
	</ion-grid>
	<ion-modal :keep-contents-mounted="true">
		<ion-datetime id="datetime" :locale="locale == 'fi' ? 'fi-FI' : 'en-GB'" :change="setDate()" presentation="date" :firstDayOfWeek="1" v-model="this.selected_date"></ion-datetime>
	</ion-modal>
</template>

<script>
import {
  IonContent,
  IonGrid,
	//IonAccordion,
	//IonAccordionGroup,
  IonRow,
	//IonList,
	IonLabel,
	IonModal,
  IonItem,
	IonList,
	IonBadge,
  IonHeader,
  IonCol,
  IonTitle,
  IonToolbar,
	modalController,
	IonAccordion,
	IonAccordionGroup,
	alertController,
	IonDatetime,
	IonDatetimeButton,
	IonNote,
	IonListHeader
} from "@ionic/vue";
import { defineComponent, ref, toRaw } from 'vue';
import {
	archiveOutline
} from 'ionicons/icons';
import StatusToast from '@/components/StatusToast.vue';
import axios from 'axios';
import moment from 'moment';
export default defineComponent({
		name: "OperatorLogs",
		components:{
			IonContent,
			IonGrid,
			//IonAccordion,
			//IonAccordionGroup,
			IonRow,
			//IonList,
			IonLabel,
			IonModal,
			//IonIcon,
			IonItem,
			IonHeader,
			IonAccordion,
			IonAccordionGroup,
			IonCol,
			IonTitle,
			IonToolbar,
			IonDatetime,
			IonList,
			IonBadge,
			IonDatetimeButton,
			IonNote,
			IonListHeader
		},
		methods:{
			changeView(view){
				this.currentPage = view;
			},
			getKeys(obj){
				return Object.keys(obj[0]);
			},
			secondsToMinutes(sec){
				return (sec/60).toFixed(0);
			},
			parseAddEmited(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					this.changeView('table');
				}
				this.getLogs();
			},
			setDate(){
				this.formatted_date = moment(this.selected_date).format('YYYY-MM-DD');
				this.getLogs();
			},
			async getLogs(date){
				axios.post(this.$api_add + localStorage.getItem("org") +'logs/getlogsbyday', {"date": this.selected_date}).then((res)=>{
					if (res.data.result == 'ok') {
						//this.logs = res.data.data;
						this.parseLogs(res.data.data);
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.feedbackResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.feedbackResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			findUser(id){
				const user = toRaw(this.users).filter((io)=>{
					//console.log(io.id, id);
					return io.id == id;
				})[0];
				//console.log(user);
				if(user != null && user != undefined){
					return String(user.firstname + " " + user.lastname);
				}
			},
			findRole(id){
				const role = toRaw(this.users).filter((io)=>{
					if(io.id == id){
						return String(io.role);
					}
				})

				return role;
			},
			findChild(id){
				const child = this.children.filter((io)=>{
					return io.id == id;
				})[0];

				if(child != null && child != undefined){
					return String(child.firstname + " " + child.lastname);
				}
			},
			parseLogs(data){
				const users = toRaw(this.users);
				const childs = toRaw(this.children);
				let parsed = [];
				data.forEach((l)=>{
					let usr = users.find((u)=>{
						return u.id == l.userid;
					});
					let chld = childs.find((c)=>{
						return c.id == l.childid;
					});
					let obj = {
						"user": (usr != null && usr != undefined) ? String(usr.firstname + " " + usr.lastname) : this.$t('operator.unknown'),
						"child": (chld != null && usr != undefined) ? String(chld.firstname + " "  + chld.lastname) : this.$t('operator.unknown'),
						"role": this.$t(String('general.roles.'+usr.role)),
						"timestamp_parsed": moment(l.timestamp).format('DD.MM.YYYY HH:mm:ss'),
						"statusText": this.$t(String('parent.confirmStatus.'+l.status)),
						"status": l.status,
						"unixts": moment(l.timestamp).format('x'),
						"message": l.message
					}
					parsed.push(obj);
				});

				this.logs = parsed.sort((a,b)=>{
					return b.unixts - a.unixts;
				});
				this.sumLogs();
			},
			getUsers(){
				axios.post(this.$api_add + localStorage.getItem("org") +'users/getallminimized').then((res)=>{
					if(res.data.result == 'ok'){
						this.users = toRaw(res.data.data);
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.userResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			sumLogs(){ //get the total number of log based on log type
				const groups = this.groupBy(this.logs, 'statusText');
				const keys = Object.keys(groups);
				let results = [];

				keys.forEach((key)=>{

					results.push({"status":key, "amount": groups[key].length, "statuscode": groups[key][0].status});
				});

				this.logAmounts = results;
			},
			getChildren(){
				axios.post(this.$api_add + localStorage.getItem("org") +'child/getall').then((res)=>{
					if(res.data.result == 'ok'){
						const dt = toRaw(res.data.data);
						this.children = dt;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				});
			},
			groupBy(arr, property) {
				return arr.reduce(function (memo, x) {
					if (!memo[x[property]]) { memo[x[property]] = []; }
					memo[x[property]].push(x);
					return memo;
				}, {});
			},
			getColor(status){
				let color = null;
				switch (parseInt(status)) {
					case 0:
						color = "primary";
						break;
					case 1:
						color = "success";
						break;
					case 2:
						color = "secondary";
						break;
					case 3:
						color = "tertiary";
						break;
					case 4:
						color = "danger";
						break;
					case 5:
						color = "warning";
						break;
					case 6:
						color = "warning";
						break;
					case 7:
						color = "danger";
						break;
					default:
						color="primary";
				}

				return color;
			}
		},
		setup(){

			const isOpenRef = ref(false);
			const isCopyRef = ref(false);
			const setOpen = (state) => isOpenRef.value = state;
			const setCopy = (state) => isCopyRef.value = state;

			return {
				isOpenRef,
				isCopyRef,
				setOpen,
				setCopy,
				alertController,
				archiveOutline,
				moment
			}
		},
		mounted(){
			this.getUsers();
			this.getChildren();
			this.getLogs();
		},
	data() {
			return{
				toastObject:{
					"message": null,
					"status": false
				},
				currentPage: 'table',
				editStatus: false,
				showMessage: false,
				statusMessage: null,
				logs: null,
				filteredlogs: null,
				archive: null,
				processedArchive: null,
				sortLastValue: null,
				isdesktop: this.$isdesktop,
				selected_date: moment().format('YYYY-MM-DD'),
				formatted_date: moment().format('YYYY-MM-DD'),
				users: null,
				children: null,
				logAmounts: [],
				locale: localStorage.getItem('locale')
      }
    }
});
</script>
<style>
  .routeTable{
		height: 90vh;
	}
	.tableHeader {
    display: flex; /* or inline-flex */
    flex-direction: row;
	}
	.topborder{
		border-top: solid 1px #cfcdca;
	}
	.iconbtn{
		font-size: 1.2em;
	}
	.bld{
		font-weight: bold;
	}
	.routetable{
		max-height:30vh;
		overflow: auto;
	}
	.bigmodal {
  align-items: center;
  justify-content: center;
  &::part(content){
    width: 95vw;
    height: 95vh;
  }
}
	.daystext{
		display:table-caption;
	}
	.deletealert{
		color: red !important;
	},
	.nomargins{
		margin:0;
		text-align: left;
		justify-content: flex-start;
	}
</style>
