<template>
<ion-icon :id="'bugButton'+ modalid" :icon="bugOutline" size="large" slot="end" style="padding-right: 16px;"></ion-icon>
<ion-modal :trigger="'bugButton' + modalid">
    <ion-header>
        <ion-toolbar>
            <ion-label>{{$t("info.feedbackTitleBug")}}</ion-label>
            <ion-button @click="modalController.dismiss()" slot="end" color="danger">{{ $t('general.close') }}</ion-button>
        </ion-toolbar>
                    
                </ion-header>

               
                <ion-list class="list-class">
                    <ion-item>
                        <ion-label class="ion-text-wrap">{{ $t('info.feedbackHelpText2') }}</ion-label>
                    </ion-item>
                    <ion-item>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="floating" :label="$t('info.feedbackFormTitle')" v-model="bugfeedback.topic"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-textarea label-placement="floating" :label="$t('info.feedbackFormDesc')" v-model="bugfeedback.desc" :auto-grow="true" :counter="true" :maxlength="2000"></ion-textarea>
                </ion-item>
                <ion-item>
                    <ion-button @click="submitForm"><ion-label>{{$t('info.submit')}}</ion-label></ion-button>
                </ion-item>
            </ion-list>
            
</ion-modal>
</template>
<script lang="ts">
import {defineComponent,inject} from 'vue';
import { bugOutline} from 'ionicons/icons'
import {IonModal, modalController, IonButton,  IonLabel,IonInput,IonTextarea , IonIcon, IonToolbar, IonHeader, toastController,IonItem, IonList} from '@ionic/vue';
import axios from "axios";

export default defineComponent({
    name:"FeedbackModalComponent",
    components:{
        IonModal,
        IonLabel,
        IonIcon,
        IonButton,
        IonToolbar,
        IonHeader,
        IonItem,
        IonInput,
        IonTextarea,
        IonList
        
    },
    props:{
        side: String
    },
    setup(){
        const apiAdd = inject('$api_add');
        return {bugOutline, apiAdd, toastController, modalController}
    },
    methods:{
        submitForm(){
            axios.post(this.apiAdd + localStorage.getItem("org") + 'feedback/createfeedback', {title: this.bugfeedback.topic, description: this.bugfeedback.desc, type: 2}).then(res=>{
                if(res.data.result == "ok"){
                    this.presentToast(this.$t('info.feedbackSuccess'), 3000, 'success');
                    this.bugfeedback.topic = "";
                    this.bugfeedback.desc = "";
                }
                else{
                    this.presentToast(this.$t('info.feedbackFailure'), 3000, 'danger');
                }
            })
        },
        async presentToast(msg:string, duration:number, col:string){
            const toast = await toastController.create({
                message: msg,
                duration: duration,
                position: 'top',
                color: col
            })
            await toast.present();
        }
    },
    data(){
        return {
            modalid: Math.floor(Math.random() * 10000),
            bugfeedback:{
                topic: "",
                desc: ""
            }
        }
    }
})
</script>
<style scoped>
.list-class{
    height: 100%;
}
</style>