import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3732b221"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "container" }
const _hoisted_2 = { novalidate: "" }
const _hoisted_3 = { class: "app-badges" }
const _hoisted_4 = {
  key: 0,
  class: "badge-google",
  href: "https://play.google.com/store/apps/details?id=fi.koulukyyti.digipolis"
}
const _hoisted_5 = {
  key: 1,
  class: "badge-apple",
  href: "https://apps.apple.com/app/koulukyyti/id1637843856"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_info_component = _resolveComponent("info-component")!
  const _component_feedback_modal_component = _resolveComponent("feedback-modal-component")!
  const _component_locale_switcher = _resolveComponent("locale-switcher")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("title.title")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_info_component, {
                slot: "end",
                side: "end"
              }),
              _createVNode(_component_feedback_modal_component, { slot: "end" }),
              _createVNode(_component_locale_switcher, {
                slot: "end",
                onUpdateLocale: _ctx.updateLocale,
                size: "48px",
                onClick: _ctx.$forceUpdate
              }, null, 8, ["onUpdateLocale", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("form", _hoisted_2, [
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        id: "username",
                        label: _ctx.$t('general.username'),
                        "label-placement": "stacked",
                        modelValue: _ctx.loginForm.username,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginForm.username) = $event)),
                        name: "username",
                        type: "text",
                        spellcheck: false,
                        autocapitalize: "off",
                        required: ""
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        label: _ctx.$t('login.password'),
                        "label-placement": "stacked",
                        modelValue: _ctx.loginForm.password,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loginForm.password) = $event)),
                        name: "password",
                        type: "password",
                        required: ""
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { "responsive-sm": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        interface: "popover",
                        modelValue: _ctx.organization,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.organization) = $event)),
                        value: _ctx.organization
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select_option, { value: "" }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tenants, (tenant, index) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              value: tenant.shortname,
                              key: index
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(tenant.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { "responsive-sm": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onLogin(_ctx.loginForm))),
                        expand: "block",
                        disabled: _ctx.organization==''
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("login.confirm")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { "responsive-sm": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { size: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        style: {"cursor":"pointer"},
                        id: "bForgottenUsername",
                        expand: "block"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("login.forgotten_username")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, { size: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        style: {"cursor":"pointer"},
                        id: "bForgottenPassword",
                        expand: "block"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("login.forgotten_password")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _createVNode(_component_ion_modal, {
            ref: "modal",
            trigger: "bForgottenPassword"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toolbar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_buttons, { slot: "start" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.modalController.dismiss()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("general.cancel")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("login.forgotten_password")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_buttons, { slot: "end" }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_component_ion_button, {
                            strong: true,
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.confirm())),
                            disabled: _ctx.organization=='',
                            key: _ctx.organization
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("general.send")), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled"]))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_content, { class: "ion-padding" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            label: _ctx.$t('general.email'),
                            "label-placement": "stacked",
                            modelValue: _ctx.fpForm.email,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.fpForm.email) = $event)),
                            ref: "input",
                            type: "email",
                            placeholder: ""
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select, {
                            interface: "popover",
                            modelValue: _ctx.organization,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.organization) = $event)),
                            value: _ctx.organization
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_select_option, { value: "" }),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tenants, (tenant, index) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  value: tenant.shortname,
                                  key: index
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(tenant.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512),
          _createVNode(_component_ion_modal, {
            ref: "modal",
            trigger: "bForgottenUsername"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toolbar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_buttons, { slot: "start" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.modalController.dismiss()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("general.cancel")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("login.forgotten_username")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_buttons, { slot: "end" }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_component_ion_button, {
                            strong: true,
                            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.confirm_username())),
                            disabled: _ctx.organization=='',
                            key: _ctx.organization
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("general.send")), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled"]))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_content, { class: "ion-padding" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            label: _ctx.$t('general.email'),
                            "label-placement": "stacked",
                            modelValue: _ctx.fpForm.email,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.fpForm.email) = $event)),
                            ref: "input",
                            type: "email",
                            placeholder: ""
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select, {
                            interface: "popover",
                            modelValue: _ctx.organization,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.organization) = $event)),
                            value: _ctx.organization
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_select_option, {
                                value: "",
                                selected: ""
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('general.select')), 1)
                                ]),
                                _: 1
                              }),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tenants, (tenant, index) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  value: tenant.shortname,
                                  key: index
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(tenant.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isPlatform('desktop') || _ctx.isPlatform('mobileweb'))
              ? (_openBlock(), _createElementBlock("a", _hoisted_4, [
                  _createVNode(_component_ion_img, { src: "assets/google-play-badge.png" })
                ]))
              : _createCommentVNode("", true),
            (_ctx.isPlatform('desktop') || _ctx.isPlatform('mobileweb'))
              ? (_openBlock(), _createElementBlock("a", _hoisted_5, [
                  _createVNode(_component_ion_img, { src: "assets/apple.svg" })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}