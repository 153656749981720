<template>
	<div></div>
</template>

<script>
import {popoverController } from '@ionic/vue';
import PopupPopover from './carpopover.vue';
import {defineComponent} from 'vue';

export default defineComponent({
	name: "MapPopup",
  methods: {
    async openPopover(data,ev,route) {
      const popover = await popoverController.create({
          component: PopupPopover,
					componentProps: {cardata: data, routedata:route},
					showBackdrop: false,
					cssClass: 'wide',
					reference: 'event',
					event: ev
      })
      return popover.present();
    },
  }
});
</script>
<style>
.wide{
	--width:20vw;
	--max-height:30vh;
}
</style>
