<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-item>
					<h3>{{$t('operator.editSchool')}}</h3>
				</ion-item>
				<form novalidate>
					<ion-row>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
								<ion-label position="stacked">{{$t('operator.schoolName')}}</ion-label>
									<ion-input
										:placeholder="$t('operator.schoolName')"
										id="schoolName"
										v-model="editSchoolForm.name"
										name="vId"
										type="text"
										:label="$t('operator.schoolName')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
								<ion-label position="stacked">{{$t('general.address')}}</ion-label>
									<ion-input
										:placeholder="$t('general.address')"
										id="schoolAddress"
										v-model="editSchoolForm.address"
										name="sAddress"
										:label="$t('general.address')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
								<ion-label position="stacked">{{$t('general.postalCode')}}</ion-label>
									<ion-input
										:placeholder="$t('general.postalCode')"
										id="schoolPostal"
										v-model="editSchoolForm.postal"
										name="sPostal"
										:label="$t('general.postalCode')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
								<ion-label position="stacked">{{$t('general.city')}}</ion-label>
									<ion-input
										:placeholder="$t('general.city')"
										id="schoolCity"
										v-model="editSchoolForm.city"
										name="sCity"
										type="text"
										:label="$t('general.city')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="editSchool(editSchoolForm)">{{$t('general.save')}}</ion-button>
							<ion-button color="danger" @click="modalController.dismiss()">{{$t('general.close')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonLabel,
	IonInput,
	modalController,
  IonCol,
  IonButton
} from "@ionic/vue";
import { defineComponent, toRaw} from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "editSchool",
		props:['schooldata'],
		emits:['responsedata'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonLabel,
			IonInput,
			IonCol,
			IonButton
		},
    methods:{
			editSchool(form){
				const rawObject = toRaw(form);
				const rawOld = toRaw(this.oldSchool);
				const schooldata = {
					"new":{
						"id": rawObject['id'],
						"name": rawObject['name'],
						"address": String(rawObject['address'] +", " + rawObject['postal'] + " " + rawObject['city'])
					},
					"old":rawOld
				}

				axios.post(this.$api_add + localStorage.getItem("org") +'school/update', schooldata).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.schoolResponses.editSuccess')),
							editStatus: true
						});
					}
					else{
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.schoolResponses.editFailure') + res.statusText),
							editStatus: false
						});
					}
				}).catch((e)=>{
					this.$emit('responsedata', {
						statusMessage: String(this.$t('operator.schoolResponses.editFailure') + e.message),
						editStatus: false
					});
				});
			},
			parseData(){
				const data = toRaw(this.schooldata);
				const firstsplit = data['address'].split(","); //get address
				const secondsplit = firstsplit[1].split(" "); //get postal and city

				this.editSchoolForm.name = data.name;
				this.editSchoolForm.id = data.id;
				this.editSchoolForm.address = firstsplit[0]; //address
				this.editSchoolForm.postal = secondsplit[1]; //postal
				this.editSchoolForm.city = secondsplit[2]; //city

				this.oldSchool.name = data.name;
				this.oldSchool.id = data.id;
				this.oldSchool.address = data.address;
			}
    },
    data(){
      return{
				editSchoolForm: {
					id: null,
					index:null,
					name: null,
					address:null,
					postal: null,
					city: null
				},
				oldSchool:{
					id: null,
					name: null,
					address: null,
				},
				isdesktop: this.$isdesktop
      }
    },
		mounted(){
			this.parseData();
		},
		setup(){
			return{
				modalController
			}
		}
});
</script>
<style>
	.inpt{
		--background: #dedad9;
	}
</style>
