<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>{{$t("title.title")}}</ion-title>

        <info-component slot="end" side="end"/>
        <feedback-modal-component slot="end" />

        <locale-switcher slot="end" @updateLocale="updateLocale" size="48px" @click="$forceUpdate" />
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">

        <div id="container">
            <form novalidate>
                <ion-list>
                    <ion-item>

                        <ion-input id="username"
                        :label="$t('general.username')"
                        label-placement="stacked"
                                   v-model="loginForm.username"
                                   name="username"
                                   type="text"
                                   :spellcheck="false"
                                   autocapitalize="off"
                                   required></ion-input>
                    </ion-item>


                    <ion-item>

                        <ion-input :label="$t('login.password')" label-placement="stacked"  v-model="loginForm.password" name="password" type="password" required></ion-input>
                    </ion-item>


                </ion-list>
                <ion-row responsive-sm>
                    <ion-col>
                        <ion-select interface="popover" v-model="organization" :value="organization">
                          <ion-select-option value=""></ion-select-option>
                          <ion-select-option v-for="(tenant, index) in tenants" :value="tenant.shortname" :key="index">{{tenant.name}}</ion-select-option>
                        </ion-select>
                    </ion-col>
                </ion-row>

                <ion-row responsive-sm>
                    <ion-col>
                        <ion-button @click="onLogin(loginForm)" expand="block" :disabled="organization==''">{{$t("login.confirm")}}</ion-button>
                    </ion-col>
                </ion-row>
                <ion-row responsive-sm>
									<ion-col size="12">
											<ion-label style="cursor:pointer;" id="bForgottenUsername" expand="block">
													{{$t("login.forgotten_username")}}
											</ion-label>
									</ion-col>
                    <ion-col size="12">
                        <ion-label style="cursor:pointer;" id="bForgottenPassword" expand="block">
                            {{$t("login.forgotten_password")}}
                        </ion-label>
                    </ion-col>
                </ion-row>
            </form>



          </div>



        <ion-modal ref="modal" trigger="bForgottenPassword">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="start">
                        <ion-button @click="modalController.dismiss()">{{$t("general.cancel")}}</ion-button>
                    </ion-buttons>
                    <ion-title>{{$t("login.forgotten_password")}}</ion-title>
                    <ion-buttons slot="end">
                        <ion-button :strong="true" @click="confirm()" :disabled="organization==''" :key="organization">{{$t("general.send")}}</ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-header>
            <ion-content class="ion-padding">
              <ion-list>
                <ion-item>

                    <ion-input :label="$t('general.email')" label-placement="stacked" v-model="fpForm.email" ref="input" type="email" placeholder=""></ion-input>
                </ion-item>
                <ion-item>
                  <ion-select interface="popover" v-model="organization" :value="organization">
                          <ion-select-option value=""></ion-select-option>
                          <ion-select-option v-for="(tenant, index) in tenants" :value="tenant.shortname" :key="index">{{tenant.name}}</ion-select-option>
                        </ion-select>
                </ion-item>
              </ion-list>
            </ion-content>
        </ion-modal>
				<ion-modal ref="modal" trigger="bForgottenUsername">
						<ion-header>
								<ion-toolbar>
										<ion-buttons slot="start">
												<ion-button @click="modalController.dismiss()">{{$t("general.cancel")}}</ion-button>
										</ion-buttons>
										<ion-title>{{$t("login.forgotten_username")}}</ion-title>
										<ion-buttons slot="end">
												<ion-button :strong="true" @click="confirm_username()" :disabled="organization==''" :key="organization">{{$t("general.send")}}</ion-button>
										</ion-buttons>
								</ion-toolbar>
						</ion-header>
						<ion-content class="ion-padding">
							<ion-list>
								<ion-item>

										<ion-input :label="$t('general.email')" label-placement="stacked" v-model="fpForm.email" ref="input" type="email" placeholder=""></ion-input>
								</ion-item>
								<ion-item>
									<ion-select interface="popover" v-model="organization" :value="organization">
													<ion-select-option value="" selected>{{$t('general.select')}}</ion-select-option>
                          <ion-select-option v-for="(tenant, index) in tenants" :value="tenant.shortname" :key="index">{{tenant.name}}</ion-select-option>
												</ion-select>
								</ion-item>
							</ion-list>
						</ion-content>
				</ion-modal>
    </ion-content>
    <ion-footer>
        <div class="app-badges">
        <a class="badge-google" v-if="isPlatform('desktop') || isPlatform('mobileweb')" href="https://play.google.com/store/apps/details?id=fi.koulukyyti.digipolis">
          <ion-img src="assets/google-play-badge.png"></ion-img>
        </a>
        <a class="badge-apple" v-if="isPlatform('desktop') || isPlatform('mobileweb')" href="https://apps.apple.com/app/koulukyyti/id1637843856">
          <ion-img  src="assets/apple.svg"></ion-img>
        </a>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">

    import { IonButtons, IonImg, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonItem, IonCol, IonRow, IonList, IonLabel, IonInput, toastController, IonModal,modalController, IonSelect, IonSelectOption, IonFooter } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { ScreenOrientation, OrientationLockType, OrientationLockOptions} from '@capacitor/screen-orientation';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import InfoComponent from '../components/Info.vue';
import FeedbackModalComponent from '../components/FeedbackModal.vue';
import {isPlatform} from '@ionic/vue';
import {informationCircleOutline} from 'ionicons/icons';
import {PushNotifications, Token} from '@capacitor/push-notifications';

export default defineComponent({
  name: 'HomePage',
    components: {
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonItem,
    IonCol,
    IonRow,
    IonList,
    IonInput,
    IonLabel,
    LocaleSwitcher,
    IonModal,
    InfoComponent,
    IonSelect,
    IonSelectOption,
    IonImg,
    FeedbackModalComponent


},
  setup() {
      const router = useRouter();

      //localStorage.setItem("id", "");
      //localStorage.setItem("role", "");
      //localStorage.setItem("token", "");
      

      return { router, LocaleSwitcher, modalController, informationCircleOutline, InfoComponent, isPlatform, FeedbackModalComponent};
    },
  methods:{
      onLogin(form: { username: string, password: string, pushtoken: string}) {
        if(form.username == "demodriver"){
          localStorage.setItem("role", "demo");
          this.$router.push('/driverdemo');
          if(!isPlatform('desktop')){

            const lock = async () =>{
              await ScreenOrientation.lock({orientation: 'landscape'})
            }
            lock();
          }

          }else{



            localStorage.setItem("id", "");
            localStorage.setItem("role", "");
            localStorage.setItem("token", "");
            if((this.organization != null && this.organization != undefined) && this.organization.length > 0){
              localStorage.setItem("org", this.organization + "/");
            }
            else{
              localStorage.setItem("org", "");
            }
            axios.post(this.$api_add + localStorage.getItem("org") + 'users/authenticate', form, {}).then(result => {
              if(result.data.result === "ok"){
                localStorage.setItem("pushtoken", this.loginForm.pushtoken);
                localStorage.setItem("id", result.data.data.userid);
                localStorage.setItem("role", result.data.data.role);
                localStorage.setItem("token", result.data.token);
                localStorage.setItem("locale", (result.data.locale ?? 'fi'));


        axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
				if(result.data.data.role == 'operator'){
					try{
						localStorage.setItem("dashboardItems", JSON.parse(result.data.data.dashboardstate));
						localStorage.setItem("dashboardIdCounter", result.data.data.dashboardcount);
					}
					catch(e){
						console.log("No dashboard found or broken json")
					}
				}
        if(!isPlatform('desktop') && result.data.data.role == 'driver'){

const lock = async () =>{
  await ScreenOrientation.lock({orientation:'landscape'})
}
lock();
}
        this.$router.replace('/' + result.data.data.role);
        }else{
            this.openToast(this.$t("general.invalidPassword"));
            this.loginForm.password = "";

        }

      }).catch(error => {
        this.openToast(error);
        this.loginForm.password = "";
      });

    }
      },
      checkLogin() {
          if (localStorage.getItem("token") != undefined && localStorage.getItem("token") != null) {
              let token = localStorage.getItem("token");
              const config = {
                  headers: { Authorization: `Bearer ` + token }
              };
              axios.post(this.$api_add + localStorage.getItem("org") + 'users/checktoken', {}, config).then(result => {
                  if (result.data.result === "ok") {
                      if(localStorage.getItem("role") == "driver" && !isPlatform('desktop')){
                        const lock = async () =>{
                          await ScreenOrientation.lock({orientation: 'landscape'})
                        }
                      lock();

                      }
                      this.$router.push('/' + localStorage.getItem("role"));
                  } else {
                      //this.openToast(this.$t("general.invalidPassword"));


                  }

              }).catch(() => {
                  //this.openToast(error);
              });
          }
      },
      confirm() {
        if((this.organization != null && this.organization != undefined) && this.organization.length > 0){
          localStorage.setItem("org", this.organization + "/");
        }
        else{
          localStorage.setItem("org", "");
        }
          axios.post(this.$api_add + localStorage.getItem("org") +'registration/forgottenpassword', { "email": this.fpForm.email })
              .then(res => {
                  if (res.data.result == "ok") {
                      this.openToast(this.$t("login.pw_request_received"));
                  }
                  else {
                      this.openToast(this.$t("login.pw_request_failed"));
                  }

              });

      },
			confirm_username() {
				if((this.organization != null && this.organization != undefined) && this.organization.length > 0){
					localStorage.setItem("org", this.organization + "/");
				}
				else{
					localStorage.setItem("org", "");
				}
					axios.post(this.$api_add + localStorage.getItem("org") +'registration/forgottenusername', { "email": this.fpForm.email })
							.then(res => {
									if (res.data.result == "ok") {
											this.openToast(this.$t("login.pw_request_received"));
									}
									else {
											this.openToast(this.$t("login.pw_request_failed"));
									}

							});

			},
      
    async openToast(msg: string){
      const toast = toastController
        .create({
          message: msg,
          duration: 3000,
          color: 'danger'
        });
        (await toast).present();
    },
    orgValue(){
      if(localStorage.getItem("org")){
        this.organization = localStorage.getItem("org").replace("/","");
        return this.organization;
      }
    },
    changeOrgValue(){
      localStorage.setItem("org", this.organization + "/");
    },
    updateLocale(locale: string){
      this.$root.$i18n.locale = locale;
    },
    getTenants(){
      let aAddress = this.$configtenant;

      if(aAddress == null || aAddress == undefined){
        console.log("Missing config tenant!");
      }
      else{
        let config = {
          headers: {
            "Koulukyyti-API-Key": this.$configkey,
          }
        };

        axios.post(aAddress +'config/gettenants', config).then(res => {

          if (res.data.result == "ok") {
            this.tenants = res.data.data;
          }
          else{
            console.log("Could not get tenants");
          }
        }).catch((err)=>{
          console.log("Failed to fetch tenants");

        });
      }
    }


  },
  data(){
    return{
      loginForm: {
        username: "",
        password: "",
        pushtoken: ""
        },
        fpForm: {
            email:""
        },
        organization: "",
        tenants:[]
    }
  },
  created() {
    if (localStorage.getItem("org")){
      this.organization = localStorage.getItem("org").replace("/","");
    }
    else{
      this.organization = "";
      localStorage.setItem("org", "");
    }
    
  },
  updated(){
    this.$root.$i18n.locale = localStorage.getItem("locale") ?? 'fi';
    },
  mounted() {
    if(!isPlatform('desktop') && !isPlatform('mobileweb')){


PushNotifications.requestPermissions().then(res =>{
  if(res.receive === 'granted'){
    PushNotifications.register();
  }
  else{
    console.log("no push permission")
  }
})
PushNotifications.addListener('registration',
(token: Token) => {

  this.loginForm.pushtoken = token.value;
}
);
PushNotifications.addListener('registrationError',
(error: any) => {
  alert('Error on registration: ' + JSON.stringify(error));
}
);
}
    //this.organization = localStorage.getItem("org");
    if (localStorage.getItem("org")){
      this.organization = localStorage.getItem("org").replace("/","");
    }
    else{
      this.organization = "";
      localStorage.setItem("org", "");
    }
    
    
    this.checkLogin();
    this.getTenants();

  }
});




</script>

<style scoped>
#container {
  text-align: center;
  height: 80vh;
  width: 100vw;
  position: relative;

}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
ion-icon {
  font-size: 48px;
  padding-left: 16px;
}
.app-badges{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  margin-left: auto;
  margin-right:auto;
}

.badge-apple {
  width: 10.5vw;
  min-width: 150px;
  margin-left: 2vw;
}
.badge-google {
  width: 10vw;
  min-width: 140px;
}
</style>
