<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<form novalidate>
					<ion-row>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('operator.schoolName')"
										id="schoolName"
										v-model="schoolForm.name"
										name="vId"
										type="text"
										:label="$t('operator.schoolName')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.address')"
										id="schoolAddress"
										v-model="schoolForm.address"
										name="sAddress"
										:label="$t('general.address')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.postalCode')"
										id="vehicleNumber"
										v-model="schoolForm.postal"
										name="vId"
										type="text"
										:label="$t('general.postalCode')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.city')"
										id="vehicleNumber"
										v-model="schoolForm.city"
										name="vId"
										type="text"
										:label="$t('general.city')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="registerSchool(schoolForm)">{{$t('operator.addSchool')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonLabel,
	IonInput,
  IonCol,
  IonButton
} from "@ionic/vue";
import { defineComponent, toRaw } from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "AddSchool",
		emits: ['goback'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonInput,
			IonCol,
			IonButton
		},
    methods:{
			registerSchool(form){
				const rawObject = toRaw(form);
				const schooldata = {
					"name": rawObject['name'],
					"address": String(rawObject['address'] +", " + rawObject['postal'] + " " + rawObject['city'])
				}
				//test call

				axios.post(this.$api_add + localStorage.getItem("org") +'school/create', schooldata).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('goback', {statusMessage: String(res.status + " " + this.$t('operator.schoolResponses.addSuccess')), editStatus: true});
					}
					else{
						this.$emit('goback', {statusMessage: String(res.status + " " + this.$t('operator.schoolResponses.addFailure') + res.statusText), editStatus: false});
					}
				}).catch((e)=>{
					this.$emit('goback', {statusMessage: String(this.$t('operator.schoolResponses.addFailure') + e.message), editStatus: false});
				});

			}
    },
    data(){
      return{
				schoolForm: {
					name: null,
					address:null,
					postal: null,
					city: null
				},
				isdesktop: this.$isdesktop
      }
    },
		setup(){
			return{

			}
		}
});
</script>
<style>
	.inpt{
		--background: #dedad9;
	}
</style>
