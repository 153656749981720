<template v-slot>
    <ion-page>
      <ion-modal :is-open="modal" @didDismiss="modal = false">
        <ion-header>
        <ion-toolbar>
            <ion-title>{{ $t('driver.addStudent') }}</ion-title>
            <ion-button color="danger" slot="end" @click="modal = false">{{ $t('driver.close') }}</ion-button>
        </ion-toolbar>
      </ion-header>
        <ion-content>
          <ion-item>
            <ion-label position="stacked">{{ $t('driver.chooseSchool') }}</ion-label>
            <ion-select v-model="schoolChosen">
              <ion-select-option v-for="school in schools" :key="school.id" :value="school.id">{{ school.name }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-button >{{ $t('driver.getInfo') }}</ion-button>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">{{ $t('driver.chooseStudents') }}</ion-label>
            <ion-select v-model="childrenChosen" :multiple="true">
              <ion-select-option v-for="child in childrenBySchool" :key="child.id" :value="child.id">{{ child.firstname + ' ' + child.lastname }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-button>{{ $t('driver.addChild') }}</ion-button>
          </ion-item>
        </ion-content>
      </ion-modal>
      <ion-modal trigger="car-button">
        <ion-content>
        <ion-radio-group>
          <ion-list-header>
            <ion-label>{{ $t("driver.chooseCar") }}</ion-label>
          </ion-list-header>
          <ion-item v-for="car of cars" :key="car.id" :ref="`car-${car.id}`">
            <ion-label>{{ car.number }}</ion-label>
            <ion-label>{{ car.type }}</ion-label>
            <ion-radio @click="modalController.dismiss()"></ion-radio>
          </ion-item>
        </ion-radio-group>
        <ion-item>
          <ion-button slot="end" @click="modalController.dismiss()" :color="'danger'" >{{ $t('parent.close') }}</ion-button>
        </ion-item>
      </ion-content>
      </ion-modal>
      <ion-modal trigger="absences">
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ $t('general.absences') }}</ion-title>
            <ion-button slot="end" @click="modalController.dismiss()">{{ $t('driver.close') }}</ion-button>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-list>
            <ion-item>
              <ion-label>{{ $t('general.name') }}</ion-label>
              <ion-label>{{ $t('general.datefrom') }}</ion-label>
              <ion-label>{{ $t('general.dateto') }}</ion-label>
            </ion-item>
            <ion-item v-for="absent in absences" :key="absent.timestamp">
              <ion-label>{{ absent.firstname + ' ' + absent.lastname  }}</ion-label>
              <ion-label>{{ moment(absent.datefrom).utc().format("DD.MM.yyy HH:mm") }}</ion-label>
              <ion-label>{{ moment(absent.dateto).utc().format("DD.MM.yyy HH:mm") }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-modal>
      <ion-modal trigger="message-button">
        <ion-list style="height:100%;overflow-y:scroll;">
          <ion-item>
            <ion-button slot="end" color="secondary">{{ $t('driver.newMessage') }}</ion-button>
            <ion-button slot="end" @click="modalController.dismiss()" color="danger">{{ $t('parent.close') }}</ion-button>
          </ion-item>
          <ion-list-header>
            <ion-label>{{ $t('driver.sender') }}</ion-label>
            <ion-label>{{ $t('driver.message') }}</ion-label>
          </ion-list-header>
          <ion-item v-for="mes of messages" :key="mes.id" :ref="`mes-${mes.id}`">
            <ion-label>{{ mes.name }}</ion-label>
            <ion-label style="white-space:normal;">{{ mes.message }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-modal>
      <ion-modal trigger="map-button">
        <ion-content>
        <ion-radio-group>
          <ion-list-header>
            <ion-label>{{ $t("driver.chooseRoute") }}</ion-label>
          </ion-list-header>
          <ion-item v-for="r of routes" :key="r.id" :ref="`route-${r.id}`">
            <ion-label>{{ r.name }}</ion-label>
            <ion-label>{{ $t('operator.routeTypes.' + r.type.toString()) }}</ion-label>
            <ion-radio @click="modalController.dismiss()">
            </ion-radio>
          </ion-item>
        </ion-radio-group>

        <ion-item>
          <ion-button slot="end" @click="modalController.dismiss()" color="danger">{{ $t('parent.close') }}</ion-button>
        </ion-item>
      </ion-content>
      </ion-modal>
      <ion-modal trigger="new-button">
        <ion-header>
          <ion-toolbar>
            <ion-title>Siirtolomake</ion-title>
            <ion-button slot="end" @click="modalController.dismiss()">{{ $t('driver.close') }}</ion-button>
          </ion-toolbar>
        </ion-header>
        <ion-content>
        <FormKit
    type="form"
    #default="{ state: { valid } }"
    :plugins="[stepPlugin]"
    :actions="false"
  >
    <ul class="steps">
      <li
        v-for="(step, stepName) in steps"
        :class="['step']"
        @click="activeStep = stepName"
        :data-step-valid="step.valid && step.errorCount === 0"
        :data-step-active="activeStep === stepName"
        :key="stepName"
      >
        <span

          class="step--errors"
          v-text="step.errorCount + step.blockingCount"
        />

      </li>
    </ul>

    <!-- .form-body solely for styling -->
    <div class="form-body">
      <section :class="{ invisible: activeStep !== 'formType'}">
        <FormKit
          type="group"
          id="formType"
          name="formType"
        >
          <FormKit
            :v-model="formType"
            type="select"
            :label="$t('driver.formType')"
            name="form_type"
            :placeholder="$t('driver.formTypePlace')"
            validation="required"
            :options="[
              {label: $t('driver.formTypeNewRoute'), value: 'new-route'},
              {label: $t('driver.formTypeTransfer'), value: 'transfer'}
            ]"
          />


        </FormKit>
      </section>

      <section  :class="{ invisible: activeStep !== 'children'}">
        <FormKit
          id="children"
          type="group"
          name="children"
        >
          <FormKit
            v-model="transferForm.children"
            type="checkbox"
            :label="$t('driver.formTypeTransferChildren')"
            name="children"
            multiple
            placeholder="Oppilaat"
            :help="$t('driver.formTypeTransferChildrenHelp')"
            validation="required|min:1"

          />


        </FormKit>
      </section>

      <section :class="{ invisible: activeStep !== 'route'}">
        <FormKit
          id="route"
          type="group"
          name="route"
        >
          <FormKit
            v-model="transferForm.routeid"
            type="select"
            :label="$t('driver.formTypeTransferRoute')"
            :help="$t('driver.formTypeTransferRouteHelp')"
            name="route"
            :placeholder="'Valitse Reitti'"
            validation="required"

          />

        </FormKit>
      </section>

      <!-- NEW: Adds Next / Previous navigation buttons. -->
      <div class="step-nav">
      <FormKit type="button" :disabled="activeStep == 'formType'" @click="setStep(-1)" :class="'form-previous'" >{{ $t('driver.previous') }}</FormKit>
      <FormKit type="button" class="next" :disabled="activeStep == 'application' " @click="setStep(1)" >{{ $t('driver.next') }}</FormKit>
    </div>


    </div>

    <!-- NEW: Adds submit button. -->
    <FormKit type="button" :label="$t('driver.formTypeSubmit')"  :disabled="!valid" class="form-submit" id="subbutton"/>
  </FormKit>
  </ion-content>
      </ion-modal>

      <ion-split-pane id="sidepanel" content-id="menu-content" when="xs">

        <ion-menu :side="side" content-id="menu-content" menu-id="menu-id">
          <!--<div v-if="side == 'end'" class="divider"></div>-->
          <ion-header>
            <ion-toolbar>
              <info-component side="end" style="padding-right: 16px;"/>
              <ion-icon :icon="listCircleOutline" id="absences" slot="end" size="large"  style="padding-right: 16px;" />
              <ion-icon :icon="gitBranchOutline" id="new-button" slot="end" size="large" style="padding-right: 16px;" />
              <ion-icon  :icon="chatboxOutline" id="message-button"  slot="end" size="large"
                style="padding-right:16px"></ion-icon>
              <ion-icon :icon="swapHorizontalOutline" slot="end" size="large"
                style="padding-right: 16px;" />
              <ion-icon :icon="carOutline" id="car-button" slot="end" size="large"  style="padding-right: 16px;">
              </ion-icon>

              <ion-icon :icon="mapOutline" id="map-button" slot="end" size="large"  style="padding-right: 16px;">
              </ion-icon>
              <locale-switcher  size="36px" @click="$forceUpdate" slot="end" />
              <logout-component  size="36px" slot="end" style="padding-left: 16px;" />
            </ion-toolbar>
          </ion-header>
          <ion-header>
            <ion-toolbar>
              <ion-title class="toolbar-title" slot="start"><div class="ion-text-wrap">{{ routeNumber.name }}</div></ion-title>


              <ion-toggle v-if="routeNumber.name.length > 0" slot="end" color="success" :disabled="optimized.disabled" v-model="optimized.value" :checked="optimized.value"></ion-toggle>

            </ion-toolbar>
          </ion-header>
          <ion-content id="side-panel-content" ref="sidepanel">
            <ion-accordion-group value="numbers" ref="accordionGroup">
              <ion-accordion v-if="routeNumber.type == 1">
                <ion-item slot="header">
                  <ion-label>{{ $t('driver.afternoonConfirmations') }}</ion-label>
                </ion-item>
                <ion-list slot="content" inset="true">
                  <ion-item>
                  <ion-label>{{ $t('driver.addStudent') }}</ion-label>
                  <ion-button id="open-modal">{{$t('driver.addStudent')}}</ion-button>
                  </ion-item>
                  <ion-item v-for="stops of routeStops" :key="stops.id" :ref="`stopconfirm-${stops.id}`">
                    <ion-label :color="stops.color">{{ stops.firstName }}</ion-label>
                    <ion-button color="danger" >{{ $t('driver.delete') }}</ion-button>
                    <ion-button style="visibility:hidden"></ion-button>
                    <ion-button >{{ $t('driver.confirm') }}</ion-button>
                  </ion-item>
                  <ion-item>
                    <ion-button >{{ $t('driver.startRoute') }}</ion-button>
                  </ion-item>
                </ion-list>
              </ion-accordion>
              <ion-accordion v-for="(stops, index) in routeStops" :key="stops.id" :ref="`stops-${stops.id}`" :value="stops.id">
                <ion-item slot="header">
                  <ion-label :color="stops.color" class="ion-text-wrap" >{{index+1 + ". " + stops.targetAddress }}</ion-label>

                  <ion-label  :color="stops.color" class="ion-text-wrap" >{{ stops.firstName }}</ion-label>

                  <ion-label :color="stops.color">{{ stops.targetTime }}</ion-label>

                </ion-item>

                <ion-list slot="content" inset="true">

                  <ion-item>{{ $t("driver.address") }}: {{ stops.targetAddress }}</ion-item>
                  <ion-item>{{ $t("driver.targetTime") }}: {{ stops.targetTime }}</ion-item>
                  <ion-item>{{ $t("driver.helpLevel") }}: {{ stops.targetAssistance }}</ion-item>
                  <ion-grid>
                    <ion-row v-if="routeNumber.type == 0">
                      <ion-col>
                        <ion-button color="primary" class="confirmButton"
                          >
                          <ion-icon :icon="personAddOutline" class="confirmIcon"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col>
                        <ion-button color="warning" class="confirmButton" >
                          <ion-icon :icon="homeOutline" class="confirmIcon" />
                        </ion-button>
                      </ion-col>
                      <ion-col>
                        <ion-button color="danger" class="confirmButton" >
                          <ion-icon :icon="personRemoveOutline" class="confirmIcon"></ion-icon>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                    <ion-row v-if="routeNumber.type == 1">
                      <ion-col>
                        <ion-button color="primary" class="confirmButton" >
                          <ion-icon :icon="personRemoveOutline" class="confirmIcon"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col>
                        <ion-button color="danger" class="confirmButton" >
                          <ion-icon :icon="personAddOutline" class="confirmIcon"></ion-icon>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>



                </ion-list>
              </ion-accordion>

            </ion-accordion-group>
            <ion-item v-if="routeNumber.type == 0">
              <ion-button>{{ $t('driver.confirmAll') }}</ion-button>
            </ion-item>
            <ion-item v-if="routeNumber.type == 1">
              <ion-button>{{ $t('driver.endDrive') }}</ion-button>
            </ion-item>


          </ion-content>

        </ion-menu>






        <ion-content :fullscreen="true" id="menu-content">

          <MapComponent :carid="car.id" :routeid="routeNumber.id" :routelinestring="linestrings" :stops="stops" :doneStops="doneStops"
            ref="mapRef" />

        </ion-content>
      </ion-split-pane>

    </ion-page>

  </template>

  <script lang="ts">
  import {
    IonAccordion,
    IonAccordionGroup,
    IonRadioGroup,
    IonRadio,
    IonListHeader,
    IonContent,
    IonSplitPane,
    IonMenu,
    IonItem,
    IonList,
    IonToolbar,
    IonHeader,
    IonButton,
    IonTitle,
    IonModal,
    IonLabel,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    modalController,
    createGesture,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonToggle

  } from "@ionic/vue";
  import { defineComponent, ref } from 'vue';
  import MapComponent from '../components/Map.vue'
  import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
  import { mapOutline, carOutline, personAddOutline, personRemoveOutline, swapHorizontalOutline, chatboxOutline, homeOutline,  gitBranchOutline, listCircleOutline} from 'ionicons/icons'
  import { useRouter } from "vue-router";

  import moment from 'moment';

  import LogoutComponent from '@/components/Logout.vue';

  import InfoComponent from '../components/Info.vue';

  import useSteps from '../helpers/useSteps.js'



  export default defineComponent({
    name: "DemoPage",
    components: {
      IonAccordion,
      IonAccordionGroup,
      IonContent,
      IonModal,
      IonSplitPane,
      IonMenu,
      IonItem,
      IonList,
      IonToolbar,
      IonHeader,
      IonButton,
      IonTitle,
      IonRadioGroup,
      IonRadio,
      IonListHeader,
      IonLabel,
      IonIcon,
      IonGrid,
      IonRow,
      IonCol,
      MapComponent,
      LocaleSwitcher,
      LogoutComponent,
      IonPage,
      InfoComponent,
      IonSelect,
    IonSelectOption,
      IonToggle,


    },

    setup() {
      const router = useRouter();
      if (localStorage.getItem("role") !== "demo") {
        router.push("/home");
      }
      const camel2title = (str:string) => str
      .replace(/([A-Z])/g, (match:string) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .trim()
      const { steps, visitedSteps, activeStep, setStep, stepPlugin } = useSteps()
      let messagesGot = ref([Object({
        id: Number,
        name: String,
        message: String,
        timestamp: Date
      })]);
      const routesGot = ref([Object({
        id: Number,
        children: [String],
        assignedvehicle: String,
        name: String,
        chi: Object({
          id: [String],
          names: [String],
          assistance: [String]
        }),
        routelength: Number,
        routetime: Number,
        type: Number,
        timestamp: Date,
        startpoint: Object({
          location_id: String,
          lat: Number,
          lon: Number,
          start_time: String
        }),
        nonoptimizedroute: Object({
          type: String,
          coordinates: [Number, Number]
        }),
        optimizedpoints: Object({
          drawnRoute: [Object({
            coordinates: [Number, Number],
            type: String,
          })],
          points: [Object({
            type: String,
            location_id: String,
            address: Object({
              location_id: String,
              lat: Number,
              lon: Number,
            }),
            arr_time: Number,
            arr_date_time: String,
            end_time: Number,
            end_date_time: String,
            distance: Number,
            driving_time: Number,
            preparation_time: Number,
            waiting_time: Number,
            load_before: [Number],
            load_after: [Number],
            done: Boolean


          })]

        }),
        waypoints: [Object({
          pickup: String,
          picklat: Number,
          picklon: Number,
          delivery: String,
          dellat: Number,
          dellon: Number,
          size: [Number],
          missing: String,
          timewindow: String,
          duration: Number,
          child: String
        })],
        days: String,
        end_point: String
      })]);


      messagesGot.value.shift();
      const accordionGroup = ref();
      const logAccordionValue = () => {
        if (accordionGroup.value) {
          //console.log(accordionGroup.value.$el.value);
        }
      }
      const closeAccordion = () => {
        if (accordionGroup.value) {
          accordionGroup.value.$el.value = undefined;
        }
      }
      const sidepanel = ref();
      const checkStepValidity = (stepName:string) => {
    return (steps[stepName].errorCount > 0 || steps[stepName].blockingCount > 0) && visitedSteps.value.includes(stepName)
  }

      return {

        accordionGroup,
        closeAccordion,
        logAccordionValue,
        swapHorizontalOutline,
        mapOutline,
        carOutline,

        homeOutline,
        messagesGot,
        personRemoveOutline,
        personAddOutline,
        routesGot,
        modalController,

        chatboxOutline,
        sidepanel,
        createGesture,
        gitBranchOutline,
        camel2title,
        steps,
        visitedSteps,
        activeStep,
        setStep,
        stepPlugin,
        checkStepValidity,
        listCircleOutline,
        moment
      };
    },

    methods: {



    },
    data() {
      return {
        stops: [[]],
        linestrings: [[]],
        routeStops: [


        ],
        side: "end",
        routeNumber: {
          id: 0,
          name: "",
          type: -1,
          updated: null,
          lastupdate:"123"
        },
        routes: [],
        car: {
          id: 0,
          number: 0
        },
        cars: [],
        childrenStatus: [],
        children: [],
        messages: [],
        checkrouteinterval: null,
        formType: 'transfer',
        transferForm:{
          children:[],
          days: 1,
          routetype: 0,
          routeid: 1
        },
        childrenBySchool:[],
        schools:[],
        modal: false,
        schoolChosen: "",
        childrenChosen: [],
        absences: [],
        doneStops:[],
        optimized: {
          disabled: false,
          value: true
        }
      }
    },

    mounted() {


      this.$root.$i18n.locale = localStorage.getItem("locale");


      this.messages = this.messagesGot;

    },
    updated() {
      this.$root.$i18n.locale = localStorage.getItem("locale");
    }
  });
  </script>
  <style>
  .confirmButton {
    height: 96px;
    width: 96px;
  }

  .confirmIcon {
    font-size: 64px;
  }

  #side-panel-content {
    overflow-y: auto;
  }
  ion-split-pane {
    --side-min-width: 100px;
    --side-max-width: 1800px;
    --side-width: calc(100vw - 60%);
  }

  .split-pane-md.split-pane-visible>.split-pane-side {


    border-right: var(--border);
    border-left: 0;
  }
  .divider {
    height: 100%;
    width: 8px;

    position: flex;
    right: 0;
    top: 0;
    bottom: 0;

    background: #e2e2e2;

    z-index: 10;

    cursor: w-resize;

  }
  .formkit-form {
    flex-shrink: 0;
    margin-top: 1em;
    margin-bottom: 1em;

    border: 1px solid var(--gray);
    border-radius: 0.5em;
    box-shadow: 0.25em 0.25em 1em 0 rgba(0,0,0,0.1);
  }

  #app .source-content {
    padding: 2em;
    background: transparent;
  }

  .steps {
    list-style-type: none;
    margin: 0;
    display: flex;
    padding-left: 0;
    background: var(--gray-l);
    border-radius: 0.4em 0.4em 0 0;
    overflow: hidden;
    border-bottom: 1px solid var(--gray);
  }

  .step {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 16px 20px;
    background: var(--gray-l);
    border-right: 1px solid var(--gray);
    color: gray;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
  }

  .step:last-child {
    box-shadow: 0.1em -0.1 0.1em 0 rgba(0,0,0,0.33)
  }

  .step:hover { cursor: pointer; }

  [data-step-active="true"] {
    color: black;
    background: white !important;
    border-bottom: none;
    position: relative;
  }

  .step--errors,
  [data-step-valid="true"]:after {
    content: '\2713';
    background-color: #54A085;
    position: absolute;
    top: 4px; right: 4px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    z-index: 10;
    display: flex;
    font-size: 10px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: white;
  }

  .step--errors {
    background-color: #ff4949;
    color: #fff;
    z-index: 100;
  }

  .step-nav {
    display: flex;
    margin-top: 2em;
    margin-bottom: 1em;
    justify-content: space-between;
  }

  .form-body {
    padding: 2em;
  }

  .next {
    margin-left: auto;

  }

  .formkit-outer[data-type="submit"] .formkit-wrapper {
    padding: 0 2em 1em 2em;
    display: flex;

  }
  .formkit-outer[data-type="submit"] .formkit-input {
    margin-left: auto;
    margin-right: 0;
    align-content: center;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
  }
  .formkit-outer[data-type="button"] .formkit-input {
    height: 40px;
    width: 75px;
  }


  details {
    border: 1px solid var(--gray);
    background: var(--gray-l);
    border-radius: .15em;
    padding: 1em;
  }

  .formkit-form > .formkit-messages {
    padding: 0 2em 0em 2em;
  }
  .formkit-form > .formkit-messages:last-child {
    padding: 0 2em 2em 2em;
  }

  [data-errors="true"] .formkit-label { color: #ff4949; }
  .formkit-wrapper { max-width: 100%; }
  button:hover, summary { cursor: pointer }
  /*p { max-width: 600px;}
  p small { color: #999; }
  h1 { margin: .25em 0; max-width: 600px;}*/

  @media (max-width: 438px) {
    h1 { font-size: 1.15em; }
    #app .source-content {
      padding: 0.5em;
    }
    .steps {
      flex-direction: column;
    }
    .step {
      border-bottom: 1px solid var(--gray);
      border-right: none;
    }
    .step:last-child {
      border-bottom: none;
    }
    .form-body {
      padding: 1em;
    }
    .formkit-outer[data-type="submit"] .formkit-wrapper {
      padding: 0 1em 1em 1em;
      display: flex;
    }
    .formkit-form > .formkit-messages {
      padding: 0 1em 0em 1em;
    }
    .formkit-form > .formkit-messages:last-child {
      padding: 0 1em 1em 1em;
    }
  }
  .invisible{
    display:none;
  }
  #subbutton{
    align-content: center;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
  }
  .toolbar-title {
     text-overflow: inherit;
     white-space: normal;
  }
  </style>
