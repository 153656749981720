<template>
    
        <ion-icon :id="'infoModal' + modalid" size="large" @click="openModal()" :slot="side" :icon="informationCircleOutline" style="padding-right: 16px;"></ion-icon>
    
    <ion-modal :trigger="'infoModal' + modalid" >
      <ion-header>
        <ion-toolbar>
          <ion-segment v-model="segment" :scrollable="true" value="info">
                <ion-segment-button value="info">
                    <ion-icon :icon="informationCircleOutline"></ion-icon>
                    <ion-label>{{$t('info.infoTitle')}}</ion-label>
                </ion-segment-button>
                <ion-segment-button v-if="checkParent() || checkOperator()" value="parent" >
                    <ion-icon :icon="peopleOutline"></ion-icon>
                    <ion-label>{{$t('info.parentTitle')}}</ion-label>
                </ion-segment-button>
                <ion-segment-button v-if="checkDriver() || checkOperator()" value="driver">
                    <ion-icon :icon="busOutline"></ion-icon>
                    <ion-label>{{$t('info.driverTitle')}}</ion-label>
                </ion-segment-button>
                <ion-segment-button v-if="checkAssistant() || checkOperator()" value="assistant">
                    <ion-icon :icon="schoolOutline"></ion-icon>
                    <ion-label>{{$t('info.assistantTitle')}}</ion-label>
                </ion-segment-button>
                <ion-segment-button v-if="checkOperator()" value="operator">
                    <ion-icon :icon="desktopOutline"></ion-icon>
                    <ion-label>{{$t('info.operatorTitle')}}</ion-label>
                </ion-segment-button>
                
                <ion-segment-button value="close" @click="modalController.dismiss()">
                    <ion-icon :icon="closeOutline"></ion-icon>
                    <ion-label>{{$t('info.close')}}</ion-label>
                </ion-segment-button>
            </ion-segment>
          </ion-toolbar>
        </ion-header>
        <ion-content>
            <assistant-info-page v-if="segment == 'assistant' && (checkAssistant() || checkOperator())" />
            <driver-info-page v-if="segment == 'driver' && (checkDriver() || checkOperator())" />
            <operator-info-page v-if="segment == 'operator' && checkOperator()" />
            <info-page v-if="segment == 'info'" />
            <parent-info-page v-if="segment == 'parent' && (checkOperator() || checkParent())" />
        </ion-content>
    </ion-modal>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import { closeOutline, informationCircleOutline, peopleOutline, busOutline, desktopOutline, schoolOutline} from 'ionicons/icons'
import {IonModal, modalController,   IonLabel,  IonIcon,  IonSegment, IonSegmentButton, IonContent, IonHeader, IonToolbar} from '@ionic/vue'
import ParentInfoPage from '../views/tabs/ParentInfoPage.vue';
import InfoPage from '../views/tabs/InfoPage.vue';
import DriverInfoPage from '../views/tabs/DriverInfoPage.vue';
import OperatorInfoPage from '../views/tabs/OperatorInfoPage.vue';
import AssistantInfoPage from '../views/tabs/AssistantInfoPage.vue';
export default defineComponent({
    name:"InfoComponent",
    components:{
        IonModal,
        IonContent,
        IonHeader,
        IonToolbar,
        IonSegment,
        IonLabel,
        IonSegmentButton,
        IonIcon,
        ParentInfoPage,
        InfoPage,
        DriverInfoPage,
        OperatorInfoPage,
        AssistantInfoPage
        
        
    },
    props:{
        side: String
    },
    setup() {
        return {modalController,closeOutline, informationCircleOutline, peopleOutline, busOutline, desktopOutline, schoolOutline};
    },
    methods:{
        checkDriver(){
            if(localStorage.getItem("role") == "driver"){
                return true;
            }else{
                return false;
            }

        },
        checkAssistant(){
            if(localStorage.getItem("role") == "assistant"){
                return true;
            }else{
                return false;
            }

        },
        checkOperator(){
            if(localStorage.getItem("role") == "operator"){
                return true;
            }else{
                return false;
            }

        },
        checkParent(){
            if(localStorage.getItem("role") == "parent"){
                return true;
            }else{
                return false;
            }
        },
        openModal(){
          this.segment = 'info';
        },
        setSegmentValue(a:any){
            this.segment = a;
            console.log(this.segment);
        }
    },
    data(){
        return{
            modalid: Math.floor(Math.random() * 10000),
            segment: "info"
        }
    }
})
</script>

<style scoped>
ion-modal::part(content){
    display:flex;
    flex-direction: column;
    height: 100%;
}
</style>