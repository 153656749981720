import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { novalidate: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('operator.editCar')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("form", _hoisted_1, [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 6 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              placeholder: _ctx.$t('operator.carId'),
                              id: "vehicleId",
                              modelValue: _ctx.editCarForm.name,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editCarForm.name) = $event)),
                              name: "vId",
                              type: "text",
                              label: _ctx.$t('operator.carId'),
                              labelPlacement: "stacked",
                              required: ""
                            }, null, 8, ["placeholder", "modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 6 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              placeholder: _ctx.$t('operator.carConsumption'),
                              id: "vehicleId",
                              modelValue: _ctx.editCarForm.fuelconsumption,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editCarForm.fuelconsumption) = $event)),
                              name: "vCons",
                              type: "text",
                              label: _ctx.$t('operator.carConsumption'),
                              labelPlacement: "stacked",
                              required: ""
                            }, null, 8, ["placeholder", "modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 6 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              placeholder: _ctx.$t('operator.vehicleNumber'),
                              id: "vehicleId",
                              modelValue: _ctx.editCarForm.number,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editCarForm.number) = $event)),
                              name: "vId",
                              type: "text",
                              label: _ctx.$t('operator.vehicleNumber'),
                              labelPlacement: "stacked",
                              required: ""
                            }, null, 8, ["placeholder", "modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 4 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              placeholder: _ctx.$t('operator.registerNumber'),
                              id: "vehicleRegister",
                              modelValue: _ctx.editCarForm.registration,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editCarForm.registration) = $event)),
                              name: "vId",
                              type: "text",
                              label: _ctx.$t('operator.registerNumber'),
                              labelPlacement: "stacked",
                              required: ""
                            }, null, 8, ["placeholder", "modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 6 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              placeholder: _ctx.$t('operator.vehicleEmissions'),
                              id: "vehicleId",
                              modelValue: _ctx.editCarForm.emissions,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editCarForm.emissions) = $event)),
                              name: "vEms",
                              type: "text",
                              label: _ctx.$t('operator.vehicleEmissions'),
                              labelPlacement: "stacked",
                              required: ""
                            }, null, 8, ["placeholder", "modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 6 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              id: "vehicleEqpmnt",
                              placeholder: _ctx.$t('operator.carEquipment'),
                              modelValue: _ctx.editCarForm.servicelevel,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editCarForm.servicelevel) = $event)),
                              name: "vEq",
                              type: "text",
                              label: _ctx.$t('operator.carEquipment'),
                              labelPlacement: "stacked",
                              required: ""
                            }, null, 8, ["placeholder", "modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 6 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              id: "typeSeats",
                              modelValue: _ctx.editCarForm.capacity,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editCarForm.capacity) = $event)),
                              modelModifiers: { number: true },
                              name: "vSeats",
                              label: _ctx.$t('operator.carSeats'),
                              labelPlacement: "stacked",
                              type: "text",
                              required: ""
                            }, null, 8, ["modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, { size: "12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.editCar(_ctx.editCarForm)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('general.save')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          color: "danger",
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.modalController.dismiss()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('general.close')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}