<template v-slot>
  <ion-page>
    <ion-modal :is-open="modal" @didDismiss="modal = false; resetTransferForm()">
      <ion-header>
      <ion-toolbar>
          <ion-title>{{ $t('driver.addStudent') }}</ion-title>
          <ion-button color="danger" slot="end" @click="modal = false">{{ $t('driver.close') }}</ion-button>
      </ion-toolbar>
    </ion-header>
      <ion-content>
        <ion-item>
          <ion-label position="stacked">{{ $t('driver.chooseSchool') }}</ion-label>
          <ion-select v-model="schoolChosen">
            <ion-select-option v-for="school in schools" :key="school.id" :value="school.id">{{ school.name }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-button @click="getChildrenBySchool(schoolChosen)">{{ $t('driver.getInfo') }}</ion-button>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">{{ $t('driver.chooseStudents') }}</ion-label>
          <ion-select v-model="childrenChosen" :multiple="true">
            <ion-select-option v-for="child in childrenBySchool" :key="child.id" :value="child.id">{{ child.firstname + ' ' + child.lastname }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-button @click="addChildToRoute()">{{ $t('driver.addChild') }}</ion-button>
        </ion-item>
      </ion-content>
    </ion-modal>
    <ion-modal trigger="car-button">
      <ion-content>
      <ion-radio-group>
        <ion-list-header>
          <ion-label>{{ $t("driver.chooseCar") }}</ion-label>
        </ion-list-header>
        <ion-item v-for="car of cars" :key="car.id" :ref="`car-${car.id}`">
          <ion-label>{{ car.number }}</ion-label>
          <ion-label>{{ car.type }}</ion-label>
					<ion-label>{{ car.name }}</ion-label>
          <ion-radio @click="carChosen(car.id, car.number); modalController.dismiss()"></ion-radio>
        </ion-item>
      </ion-radio-group>
      <ion-item>
        <ion-button slot="end" @click="modalController.dismiss()" :color="'danger'" >{{ $t('parent.close') }}</ion-button>
      </ion-item>
    </ion-content>
    </ion-modal>
    <ion-modal trigger="absences">
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ $t('general.absences') }}</ion-title>
          <ion-button slot="end" @click="modalController.dismiss()">{{ $t('driver.close') }}</ion-button>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-grid>
          <ion-row>
            <ion-col> <ion-label>{{ $t('general.name') }}</ion-label></ion-col>
            <ion-col><ion-label>{{ $t('operator.morningDrive') }}</ion-label></ion-col>
            <ion-col><ion-label>{{ $t('operator.afternoonDrive') }}</ion-label></ion-col>
            <ion-col><ion-label>{{ $t('operator.lastModifier') }}</ion-label></ion-col>
          </ion-row>
          <ion-row v-for="absent in absenceforms" :key="absent.parseddays.date">
          <ion-col><ion-label>{{ absent.firstname + ' ' + absent.lastname  }}</ion-label></ion-col>
          <ion-col><ion-icon
																		:icon="absent.parseddays.morning ? closeOutline : removeOutline"
																		size="large"
																		:color="absent.parseddays.morning ? 'danger' : 'primary'">
																	</ion-icon></ion-col>
          <ion-col><ion-icon
																		:icon="absent.parseddays.afternoon ? closeOutline : removeOutline"
																		size="large"
																		:color="absent.parseddays.afternoon ? 'danger' : 'primary'">
																	</ion-icon></ion-col>
                                  <ion-col><ion-label>{{ absent.lastmodifiername }}</ion-label></ion-col>
          </ion-row>
        </ion-grid>


      </ion-content>
    </ion-modal>
    <ion-modal trigger="message-button">
      <ion-list style="height:100%;overflow-y:scroll;">
        <ion-item>
          <ion-button slot="end" @click="newMessage" color="secondary">{{ $t('driver.newMessage') }}</ion-button>
          <ion-button slot="end" @click="modalController.dismiss()" color="danger">{{ $t('parent.close') }}</ion-button>
        </ion-item>
        <ion-list-header>
					<ion-label>{{ $t('parent.time') }}</ion-label>
          <ion-label>{{ $t('driver.sender') }}</ion-label>
          <ion-label>{{ $t('driver.senderChild') }}</ion-label>
          <ion-label>{{ $t('driver.message') }}</ion-label>
        </ion-list-header>
        <ion-item v-for="mes of messages" :key="mes.id" :ref="`mes-${mes.id}`">
					<ion-label>{{ moment(mes.timestamp).format('DD.MM.yyyy HH:mm:ss') }}</ion-label>
          <ion-label>{{ mes.name }}</ion-label>
          <!--<ion-label>{{ getChildrenFromArray(mes.childnames) }}</ion-label>-->
          <ion-label style="white-space:normal;">{{ mes.message }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-modal>
    <ion-modal trigger="map-button">
      <ion-header>
        <ion-toolbar>
          <ion-label>{{ $t("driver.chooseRoute") }}</ion-label>

        <ion-button slot="end" @click="modalController.dismiss()" color="danger">{{ $t('parent.close') }}</ion-button>
        </ion-toolbar>
      </ion-header>
      <ion-content>
      <ion-radio-group>
				<ion-col size="12">
					<ion-row>
						<ion-col size="12"> <small>{{$t('driver.showRoutes')}}</small></ion-col>
						<ion-col size="4">
							<ion-radio labelPlacement="end" :checked="true" value="allroutes" @click="filterRouteList(null)">{{$t('driver.allRoutes')}}</ion-radio>
						</ion-col>
						<ion-col size="4">
							<ion-radio labelPlacement="end" value="0" @click="filterRouteList('0')">{{$t('driver.morningRoutes')}}</ion-radio>
						</ion-col>
						<ion-col size="4">
							<ion-radio labelPlacement="end" value="1" @click="filterRouteList('1')">{{$t('driver.afternoonRoutes')}}</ion-radio>
						</ion-col>
						<ion-col size="4">
							<ion-radio labelPlacement="end" value="2" @click="filterRouteList('assigned')">{{$t('driver.assignedRoutes')}}</ion-radio>
						</ion-col>
            <ion-col size="4">
							<ion-radio labelPlacement="end" value="23" @click="filterRouteList('empty')">{{$t('driver.emptyRoutes')}}</ion-radio>
						</ion-col>
					</ion-row>
        </ion-col>
        <ion-item v-for="r of filteredRoutes" :key="r.id" :ref="`route-${r.id}`">
          <ion-item-divider>
          <ion-radio @click="updateRouteChosen(r.id, r.name, r.type, r.timestamp); modalController.dismiss(); rideConfirms = []">

              <ion-row class="ion-justify-content-between" id="rtList">
                <ion-col size="12">
                  <span class="ion-text-wrap">{{ r.name }}</span>
                </ion-col>
                <ion-col>
                  <span>{{ $t('operator.routeTypes.' + r.type.toString()) }}</span>
                </ion-col>
                <ion-col>
                  <span>{{ findCar(r.assignedvehicle) }}</span>
                </ion-col>


          </ion-row>

          </ion-radio>
          </ion-item-divider>
        </ion-item>
      </ion-radio-group>


    </ion-content>
    </ion-modal>
    <ion-modal trigger="new-button" @didDismiss="resetTransferForm()">
      <ion-header>
        <ion-toolbar>
          <ion-title>Siirtolomake</ion-title>
          <ion-button slot="end" @click="modalController.dismiss()">{{ $t('driver.close') }}</ion-button>
        </ion-toolbar>
      </ion-header>
      <ion-content>
      <FormKit
  type="form"
	id="tForm"
  #default="{ state: { valid } }"
  :plugins="[stepPlugin]"
  :actions="false"
>
  <ul class="steps">
    <li
      v-for="(step, stepName) in steps"
      :class="['step', { 'has-errors': checkStepValidity(stepName) }]"
      @click="activeStep = stepName"
      :data-step-valid="step.valid && step.errorCount === 0"
      :data-step-active="activeStep === stepName"
      :key="stepName"
    >
      <span
        v-if="checkStepValidity(stepName)"
        class="step--errors"
        v-text="step.errorCount + step.blockingCount"
      />
      {{ camel2title(stepName) }}
    </li>
  </ul>

  <!-- .form-body solely for styling -->
  <div class="form-body">
    <section :class="{ invisible: activeStep !== 'formType'}">
      <FormKit
        type="group"
        id="formType"
        name="formType"
      >
        <FormKit
          :v-model="formType"
          type="select"
          :label="$t('driver.formType')"
          name="form_type"
          :placeholder="$t('driver.formTypePlace')"
          validation="required"
          :options="[
            {label: $t('driver.formTypeNewRoute'), value: 'new-route'},
            {label: $t('driver.formTypeTransfer'), value: 'transfer'}
          ]"
        />


      </FormKit>
    </section>

    <section  :class="{ invisible: activeStep !== 'children'}">
      <FormKit
        id="children"
        type="group"
        name="children"
      >
        <FormKit
          v-model="transferForm.children"
          type="checkbox"
					id="fchildform"
          :label="$t('driver.formTypeTransferChildren')"
          name="children"
          multiple
          placeholder="Oppilaat"
          :help="$t('driver.formTypeTransferChildrenHelp')"
          validation="required|min:1"
          :options="getChildrenFromRoute()"
        />


      </FormKit>
    </section>

    <section :class="{ invisible: activeStep !== 'route'}">
      <FormKit
        id="route"
        type="group"
        name="route"
      >
        <FormKit
          v-model="transferForm.routeid"
          type="select"
          :label="$t('driver.formTypeTransferRoute')"
          :help="$t('driver.formTypeTransferRouteHelp')"
          name="route"
          :placeholder="'Valitse Reitti'"
          validation="required|not:nullroute"
          :options="getRoutesFromData()"
        />
				<!-- NEW: Adds submit button. -->
				<br>
				<FormKit type="button" :label="$t('driver.formTypeSubmit')" @click="submitTransferForm().then((il)=>{activeStep = 'formType';}).catch((e)=>{console.log(e);});" :disabled="!valid" class="form-submit" id="subbutton"/>
      </FormKit>
    </section>

    <!-- NEW: Adds Next / Previous navigation buttons. -->
    <div class="step-nav">
      <FormKit type="button" :disabled="activeStep == 'formType'" @click="setStep(-1)" :class="'form-previous'" >{{ $t('driver.previous') }}</FormKit>
      <FormKit type="button" class="next" :disabled="activeStep == 'application' " @click="setStep(1)" >{{ $t('driver.next') }}</FormKit>
    </div>


  </div>
</FormKit>
</ion-content>
    </ion-modal>

    <ion-split-pane id="sidepanel" content-id="menu-content" when="xs" ref="menu">

      <ion-menu :side="side" content-id="menu-content" menu-id="menu-id">
        <!--<div v-if="side == 'end'" class="divider"></div>-->
        <ion-header>
          <ion-toolbar>
            <ion-icon :icon="mapHidden()" size="large" slot="start" @click="toggleMap()" style="padding-right: 16px;"/>
            <ion-icon :icon="listCircleOutline" id="absences" slot="end" size="large" @click="getAbsenceforms" style="padding-right: 16px;" />
            <ion-icon :icon="gitBranchOutline" id="new-button" slot="end" size="large" style="padding-right: 16px;" />
            <ion-icon @click="updateMessages" :icon="chatboxOutline" id="message-button"  slot="end" :color="pnr ? 'danger' : ''" size="large"
              style="padding-right:16px"></ion-icon>

            <ion-icon :icon="carOutline" id="car-button" slot="end" size="large" @click="getCars" style="padding-right: 16px;">
            </ion-icon>

            <ion-icon :icon="mapOutline" id="map-button" slot="end" size="large" @click="getRoutes" style="padding-right: 16px;">
            </ion-icon>

            <ion-icon :icon="menuOutline" id="menu-button" slot="end" size="large" />
            <ion-popover trigger="menu-button" side="bottom" alignment="start">
              <ion-content>
                <ion-list :inset="true" style="width: min-content;">
                <info-component side="start" />
                <ion-item><ion-label>{{ $t('driver.user') + ": " + myAccount.username}}</ion-label></ion-item>
                <ion-item><ion-label>{{ $t('driver.car') + ": "+ car.number }}</ion-label></ion-item>
                <ion-icon :icon="swapHorizontalOutline" slot="end" size="large" @click="swapUI" style="padding-right: 16px;" />
                <locale-switcher @updateLocale="updateLocale" size="36px" @click="$forceUpdate" slot="end" />
                <logout-component @click="stopTimers()" size="36px" slot="end" />
            </ion-list>
            </ion-content>
            </ion-popover>
          </ion-toolbar>
        </ion-header>
        <ion-header>
          <ion-toolbar>
            <ion-title class="toolbar-title" slot="start"><div class="ion-text-wrap">{{ routeNumber.name }}</div></ion-title>


            <ion-toggle v-if="routeNumber.name.length > 0" slot="end" @ionChange="routeLineStrings" color="success" :disabled="optimized.disabled" v-model="optimized.value" :checked="optimized.value"></ion-toggle>

          </ion-toolbar>
        </ion-header>
        <ion-content id="side-panel-content" ref="sidepanel">
          <ion-accordion-group v-if="routeNumber.type == 1" >
            <ion-accordion  v-for="(pickup, key, index) of sortedPickDeli" :key="pickup + index">
              <ion-item slot="header">
                <ion-label>{{ key }}</ion-label>
              </ion-item>
              <ion-list slot="content" :inset="true">
                <ion-item>
                <ion-label>{{ $t('driver.addStudent') }}</ion-label>
                <ion-button id="open-modal" @click="getSchools(); modal = true">{{$t('driver.addStudent')}}</ion-button>
                </ion-item>
                <ion-item v-for="stops of pickup" :key="stops.id" :ref="`stopconfirm-${stops.id}`">
                  <ion-label :color="stops.color">{{ stops.firstName }}</ion-label>
                  <ion-button color="warning" @click="AbsentConfirm(stops.id, stops.childid, 5,false); pushToConfirms($event, stops.id, stops.childid, 5,false)">{{ $t('driver.delete') }}</ion-button>
                  <ion-button style="visibility:hidden"></ion-button>
                  <!--<ion-button :click="RideConfirm(stops.id, stops.childid, 10,false)">{{ $t('driver.confirm')}}</ion-button>-->
									<ion-toggle
										class="ctoggle"
										slot="end"
										labelPlacement="start"
										:enable-on-off-labels="true"
										:key="stops.childid"
										:checked="stops.rideconfirm"
										@click="pushToConfirms($event,stops.id, stops.childid, 10, false)"
										>{{$t('driver.confirm')}}</ion-toggle>
                </ion-item>
                <ion-item>
                  <ion-button @click="sendLateNotification(key)">{{ $t('driver.startRoute') }}</ion-button>
                </ion-item>
              </ion-list>
            </ion-accordion>
            </ion-accordion-group>
            <ion-accordion-group value="numbers" ref="accordionGroup">

            <ion-accordion v-for="(stops, index) in routeStops" :key="stops.id" :ref="`stops-${stops.id}`" :value="stops.id">
              <ion-item slot="header">
                <ion-label :color="stops.color" class="ion-text-wrap" >{{index+1 + ". " + stops.targetAddress}}</ion-label>

                <ion-label  :color="stops.color" class="ion-text-wrap" >{{ stops.firstName }}</ion-label>

                <ion-label :color="stops.color">{{ stops.targetTime }}</ion-label>

              </ion-item>

              <ion-list slot="content" :inset="true">

                <ion-item>{{ $t("driver.address") }}: {{ stops.targetAddress }} <ion-icon color="warning" slot="end" size="large" :icon="warningOutline" @click="reportDisturbance(stops.childid)"></ion-icon></ion-item>
                <ion-item>{{ $t("driver.targetTime") }}: {{ stops.targetTime }}</ion-item>
                <ion-item>{{ $t("driver.helpLevel") }}: {{ stops.targetAssistance }}</ion-item>
                <ion-item v-if="parentPhoneExists(stops.childid)">

                  <ion-label>{{ $t('driver.parentPhone') + String(getParentPhone(stops.childid,true)) + " " + String(getParentName(stops.childid,true))}}</ion-label>
                  <ion-icon :icon="callOutline" @click="callParent(stops.childid,true)" />
                </ion-item>
                <ion-item v-if="parentPhoneExists(stops.childid) && getParentPhone(stops.childid,false) != ''">

                  <ion-label> {{ $t('driver.parentPhone') + String(getParentPhone(stops.childid,false)) + " " + String(getParentName(stops.childid,false))}}</ion-label>
                  <ion-icon :icon="callOutline" @click="callParent(stops.childid,false)" />
                </ion-item>
                <ion-grid>
                  <ion-row v-if="routeNumber.type == 0">
                    <ion-col>
                      <ion-button color="primary" class="confirmButton"
                        @click="(RideConfirm(stops.id, stops.childid, 2,true))">
                        <ion-icon :icon="personAddOutline" class="confirmIcon"></ion-icon>
                      </ion-button>
                    </ion-col>
                    <ion-col>
                      <ion-button color="warning" class="confirmButton" @click="AbsentConfirm(stops.id, stops.childid, 5,true)">
                        <ion-icon :icon="homeOutline" class="confirmIcon" />
                      </ion-button>
                    </ion-col>
                    <ion-col>
                      <ion-button color="danger" class="confirmButton" @click="LateConfirm(stops.id, stops.childid, 4)">
                        <ion-icon :icon="personRemoveOutline" class="confirmIcon"></ion-icon>
                      </ion-button>
                    </ion-col>
                  </ion-row>
                  <ion-row v-if="routeNumber.type == 1">
                    <ion-col>
                      <ion-button color="primary" class="confirmButton" @click="RideConfirm(stops.id, stops.childid, 1,true)">
                        <ion-icon :icon="personRemoveOutline" class="confirmIcon"></ion-icon>
                      </ion-button>
                    </ion-col>
                    <ion-col>
                      <ion-button color="danger" class="confirmButton" @click="LateConfirm(stops.id, stops.childid, 6)">
                        <ion-icon :icon="personAddOutline" class="confirmIcon"></ion-icon>
                      </ion-button>
                    </ion-col>
                  </ion-row>
                </ion-grid>



              </ion-list>
            </ion-accordion>
            <ion-accordion v-if="routeNumber.type == 0">
              <ion-item slot="header">
                <ion-label>{{ $t('driver.confirmAll') }}</ion-label>
              </ion-item>
            <ion-item v-for="(pickup, key, i) of sortedPickDeli" :key="pickup+i" slot="content">
              <ion-label>{{ key }}</ion-label>
            <ion-button @click="confirmToSchool(key)">{{ $t('driver.confirmAll') }}</ion-button>
          </ion-item>
        </ion-accordion>
          </ion-accordion-group>

          <ion-item v-if="routeNumber.type == 0 || routeNumber.type == 1">
            <ion-button @click="endRoute">{{ $t('driver.endDrive') }}</ion-button>
          </ion-item>


        </ion-content>

      </ion-menu>






      <ion-content :fullscreen="true" id="menu-content">

        <MapComponent :carid="car.id" :routeid="routeNumber.id" :routelinestring="linestrings" :school="locSchool" :stops="stops" :doneStops="doneStops" :componentKey="componentKey"
          ref="mapRef" />

      </ion-content>
    </ion-split-pane>

  </ion-page>

</template>

<script lang="ts">
import {
  IonAccordion,
  IonAccordionGroup,
  IonRadioGroup,
  IonRadio,
  IonListHeader,
  IonContent,
  IonSplitPane,
  IonMenu,
  IonItem,
  IonList,
  IonToolbar,
  IonHeader,
  IonButton,
  alertController,
  IonTitle,
  IonModal,
  IonLabel,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
  createGesture,
  IonPage,
  IonSelect,
  IonSelectOption,
  toastController,
  IonToggle,
	onIonViewDidEnter,
  isPlatform,
  IonItemDivider,
  IonPopover
} from "@ionic/vue";
import { defineComponent, ref, inject } from 'vue';
import MapComponent from '../components/Map.vue'
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import { warningOutline,menuOutline, chevronBackOutline, chevronForwardOutline,closeOutline, removeOutline,mapOutline, carOutline, callOutline, personAddOutline, personRemoveOutline, swapHorizontalOutline, chatboxOutline, homeOutline, gitBranchOutline, listCircleOutline} from 'ionicons/icons'
import { useRouter } from "vue-router";
import axios from "axios";
import moment from 'moment';
import { PushNotifications } from '@capacitor/push-notifications';

import LogoutComponent from '@/components/Logout.vue';
import { v4 as uuid } from 'uuid';
import InfoComponent from '../components/Info.vue';
import useSteps from '../helpers/useSteps.js'
import '@formkit/themes/genesis'
import { reset } from '@formkit/core';
import { App } from '@capacitor/app';



export default defineComponent({
  name: "DriverPage",
  components: {
    IonAccordion,
    IonAccordionGroup,
    IonContent,
    IonModal,
    IonSplitPane,
    IonMenu,
    IonItem,
    IonPopover,
    IonList,
    IonToolbar,
    IonHeader,
    IonButton,
    IonTitle,
    IonRadioGroup,
    IonRadio,
    IonListHeader,
    IonLabel,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    MapComponent,
    LocaleSwitcher,
    LogoutComponent,
    IonPage,
    InfoComponent,
    IonSelect,
  IonSelectOption,
    IonToggle,
    IonItemDivider

  },

  setup() {
    const router = useRouter();
		onIonViewDidEnter(()=>{
			const token = localStorage.getItem("token");
			const role = localStorage.getItem("role");
			if ((token  == null || token == undefined || token == "") && (role !== "driver" || role === undefined ||role === null)) {
				console.log()
				router.push("/home");
			}
		})
    const camel2title = (str:string) => str
    .replace(/([A-Z])/g, (match:string) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()
    const { steps, visitedSteps, activeStep, setStep, stepPlugin } = useSteps()
    let messagesGot = ref([Object({
      id: Number,
      name: String,
      message: String,
      timestamp: Date
    })]);
    const routesGot = ref([Object({
      id: Number,
      children: [String],
      assignedvehicle: String,
      name: String,
      chi: Object({
        id: [String],
        names: [String],
        assistance: [String]
      }),
      routelength: Number,
      routetime: Number,
      type: Number,
      timestamp: Date,
      startpoint: Object({
        location_id: String,
        lat: Number,
        lon: Number,
        start_time: String
      }),
      nonoptimizedroute: Object({
        type: String,
        coordinates: [Number, Number]
      }),
      optimizedpoints: Object({
        drawnRoute: [Object({
          coordinates: [Number, Number],
          type: String,
        })],
        points: [Object({
          type: String,
          location_id: String,
          address: Object({
            location_id: String,
            lat: Number,
            lon: Number,
          }),
          arr_time: Number,
          arr_date_time: String,
          end_time: Number,
          end_date_time: String,
          distance: Number,
          driving_time: Number,
          preparation_time: Number,
          waiting_time: Number,
          load_before: [Number],
          load_after: [Number],
          done: Boolean


        })]

      }),
      waypoints: [Object({
        pickup: String,
        picklat: Number,
        picklon: Number,
        delivery: String,
        dellat: Number,
        dellon: Number,
        size: [Number],
        missing: String,
        timewindow: String,
        duration: Number,
        child: String
      })],
      days: String,
      end_point: String
    })]);
    moment.utc().format();
    const dayOfWeek = moment().isoWeekday();
    const apiAdd = inject('$api_add');
    axios.post(apiAdd + localStorage.getItem("org") +'route/getbyweekday_driver', { "days": dayOfWeek }).then(res => {

      res.data.data.forEach((element: any) => {
        routesGot.value.push({
          id: Number(element.id),
          chi: element.chi,
          children: element.children,
          assignedvehicle: element.assignedvehicle,
          name: element.name,
          routelength: element.routelength,
          routetime: element.routetime,
          type: element.type,
          timestamp: element.timestamp,
          startpoint: JSON.parse(element.startpoint),
          optimizedpoints: JSON.parse(element.optimizedpoints),
					nonoptimizedroute: JSON.parse(element.nonoptimizedroute),
          waypoints: JSON.parse(element.waypoints),
          days: element.days,
          endpoint: element.endpoint,
          tempdata: element.tempdata
        });

      });
      routesGot.value.shift();
    }).catch(e => {
      if (e != undefined && e.response != undefined && e.response.status == 401) {
        location.reload();
      }

    });
    let carsGot = ref([Object({
      id: Number,
      name: String,
      type: String,
      number: Number,
      fuelconsumption: String,
      emissions: Number,
      servicelevel: String,
      registration: String,
      capacity: Number
    })]);

    axios.post(apiAdd + localStorage.getItem("org") + 'vehicles/getvehicles', {}).then(res => {
      res.data.data.forEach((element: any) => {
        carsGot.value.push({
          id: element.id,
          name: element.name,
          type: element.type,
          number: element.number,
          fuelconsumption: element.fuelconsumption,
          emissions: element.emissions,
          servicelevel: element.servicelevel,
          registration: element.registration,
          capacity: element.capacity
        })
      });
    });
    carsGot.value.shift();
    axios.post(apiAdd + localStorage.getItem("org") + 'messages/gettodaysmessages', {}).then(res => {
      res.data.data.forEach((element: any) => {
        messagesGot.value.push({
          id: Number(element.id),
          name: element.name,
          message: element.message,
          timestamp: element.timestamp

        })
      });
    }).catch(e => { console.log(e); });
    messagesGot.value.shift();
    const accordionGroup = ref();
    const logAccordionValue = () => {
      if (accordionGroup.value) {
        //console.log(accordionGroup.value.$el.value);
      }
    }
    const closeAccordion = () => {
      if (accordionGroup.value) {
        accordionGroup.value.$el.value = undefined;
      }
    }
    const sidepanel = ref();
    const checkStepValidity = (stepName:string) => {
  return (steps[stepName].errorCount > 0 || steps[stepName].blockingCount > 0) && visitedSteps.value.includes(stepName)
}

    return {
      warningOutline,
      accordionGroup,
      closeAccordion,
      logAccordionValue,
      swapHorizontalOutline,
      mapOutline,
      carOutline,
      carsGot,
      closeOutline,
      removeOutline,
      homeOutline,
      messagesGot,
      personRemoveOutline,
      personAddOutline,
      routesGot,
      modalController,
      apiAdd,
      callOutline,
      chatboxOutline,
      sidepanel,
      createGesture,
      gitBranchOutline,
      camel2title,
      steps,
      visitedSteps,
      activeStep,
      setStep,
      stepPlugin,
      checkStepValidity,
      listCircleOutline,
      chevronBackOutline,
      chevronForwardOutline,
      moment,
      isPlatform,
      menuOutline
    };
  },

  methods: {
    async presentToast(msg:string, dura:number, col:string, pos:any) {
        const toast = await toastController.create({
          message: msg,
          duration: dura,
          color: col,
          position: pos

        });

        await toast.present();
      },
    async LateConfirm(id: number, childid: string, status: number) {
      let defaultText = 'driver.confirmHelpTextLate';
      if (this.routeNumber.type == 0) {
        defaultText = 'driver.confirmHelpTextLate';
      }
      else {
        defaultText = 'driver.confirmHelpTextLate2';
      }
      const alert = await alertController.create({
        header: this.$t("driver.areYouSure"),
        message: this.$t(defaultText),
        buttons: [
          {
            text: this.$t("driver.confirmYes"),
            role: 'yes',
            cssClass: 'primary'
          },
          {
            text: this.$t("driver.confirmNo"),
            role: 'no',
            cssClass: 'danger'
          }
        ]
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();

      if (role == 'yes') {
        axios.post(this.apiAdd + localStorage.getItem("org") + 'logs/create',
          {
            userid: localStorage.getItem("id"),
            childid: childid,
            status: status,
            message: this.routeNumber.id
          }
        ).then((res: any) => {
          if (res.data.result == "ok") {
            let j = Object.keys(this.sortedPickDeli)
            j.forEach((key:any) =>{
            this.routeStops.find((el) => el.id == id).color = "danger";
            if(this.sortedPickDeli[key].includes(id)){
                this.sortedPickDeli[key].find((el:any) => el.id == id).color = "danger";
              }
            this.doneStops.push([
              this.routeStops[this.routeStops.findIndex((el) => el.id == id)].lon,
              this.routeStops[this.routeStops.findIndex((el) => el.id == id)].lat
            ])
            })
            this.presentToast("Myöhästymiskuittaus onnistui.", 3000, 'success','top');
          }
          else{
            this.presentToast("Myöhästymiskuittaus epäonnistui.", 3000, 'danger','top');
          }
        })


      } else if (role == 'no') {
        this.routeStops.find((el) => el.id == id).color = "";
      }
      this.switchAccordion(id);
    },
    async AbsentConfirm(id: number, childid: string, status: number, sw:boolean) {
      let defaultText = 'driver.confirmHelpTextAbsent';
      const alert = await alertController.create({
        header: this.$t("driver.areYouSure"),
        message: this.$t(defaultText),
        buttons: [
          {
            text: this.$t("driver.confirmWholeday"),
            role: 'wholeday',
            cssClass: 'primary'
          },
          {
            text: this.$t("driver.confirmAfternoon"),
            role: 'afternoon',
            cssClass: 'primary'
          },
          {
            text: this.$t("driver.confirmMorning"),
            role: 'morning',
            cssClass: 'primary'
          },
          {
            text: this.$t("driver.confirmNo"),
            role: 'no',
            cssClass: 'danger'
          },
        ]
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();

      if (role == 'wholeday' || role == 'afternoon' || role == 'morning') {
        axios.post(this.apiAdd + localStorage.getItem("org") + 'logs/create',
          {
            userid: localStorage.getItem("id"),
            childid: childid,
            status: status,
            message: this.routeNumber.id
          }
        ).then((res: any) => {
          if (res.data.result == "ok") {
            let j = Object.keys(this.sortedPickDeli)
            j.forEach((key:any) =>{
            this.routeStops.find((el) => el.id == id).color = "warning";
            if(this.sortedPickDeli[key].includes(id)){
                this.sortedPickDeli[key].find((el:any) => el.id == id).color = "warning";
              }
              this.updateChildrenStatus(childid, status);

            })
              this.presentToast("Poissaolo ilmoitus onnistui.", 3000, 'success','top');
          }
          else{
            this.presentToast("Poissaolo ilmoitus epäonnistui.", 3000, 'danger','top');
          }
        })
        let temppi = {
          morning: false,
          afternoon: false
        }

        switch(role){
          case 'wholeday':
            temppi.afternoon = true;
            temppi.morning = true;
          break;
          case 'morning':
            temppi.morning = true;
            temppi.afternoon = false;
          break;

          case 'afternoon':
            temppi.afternoon = true;
            temppi.morning = false;
          break;
        }
        let temp = {
          "routeid": this.routeNumber.id,

          "weekForm":[{
            "id": null as any,
            "lastmodifier": localStorage.getItem("id"),
            "year": moment().year(),
            "childid": childid,
            "parentid": null as any,
            "week": moment().isoWeek(),
            "days":[{
              "date": moment().format('yyyy-MM-DD'),
              "morning": temppi.morning,
              "afternoon": temppi.afternoon
            }]
          }]
        }
        axios.post(this.apiAdd + localStorage.getItem("org") + 'exceptions/parseform_driver', temp).then(res =>{
          if(res.data.result == 'ok'){
            this.presentToast('Poissaolo ilmoitus onnistui!', 3000, 'success', 'top');
          }
        })

      } else if (role == 'no') {
        this.routeStops.find((el) => el.id == id).color = "";
      }
      if(sw){
      this.switchAccordion(id);
      }
    },
    stopTimers() {
      if (this.checkrouteinterval != null) {
        clearInterval(this.checkrouteinterval);
      }
    },

    addChildToRoute(){
      this.transferForm.children = this.childrenChosen;
      this.transferForm.routetype = 1;
      this.transferForm.routeid = this.routeNumber.id;
      this.transferForm.days = moment().isoWeekday();

      this.submitTransferForm().then((yu)=>{
				//do nothing
			});
      this.childrenChosen = [];
    },
    getSchools(){
      axios.post(this.apiAdd + localStorage.getItem("org") + 'school/getall', {}).then(res =>{
        this.schools = res.data.data;
      })
    },
    getChildrenBySchool(id:any){
      axios.post(this.apiAdd + localStorage.getItem("org") + 'child/getbyschool',{"schoolid":id}).then(res =>{
        this.childrenBySchool = res.data.data;
      })
    },
		getChildrenAndParents(){
			axios.post(this.apiAdd + localStorage.getItem("org") + 'child/getchildandparents').then(res =>{
				console.log(res.data)
				this.childrenAndParents = res.data.data;
			})
		},
		findChildren(parentid:any){
			const dt = this.childrenAndParents;
			let result = dt.map((ch)=>{
				if(ch.parentid == parentid){
					return ch.childname
				}
			}).filter((h)=>{
				return h != undefined
			});

			return [...new Set(result)];
		},
    getChildrenFromRoute(){
      let temp = [] as any;
      this.routeStops.forEach(chi =>{
        temp.push(
          {label: chi.firstName, value: chi.childid}
        )
      })
      return temp;
    },
    getRoutesFromData(){
      let temp = [] as any;
      this.routes.forEach(route =>{
        temp.push(
          {
            label: route.emptyroute ? String(route.name + " (" +this.$t('general.emptyRoute')+ ")") : route.name,
            value: route.id, type: route.type
          }
        )
      });
			let temp2 = temp.filter((io:any)=>{
				return io.type == this.routeNumber.type;
			});
			temp2.unshift({label: this.$t('driver.chooseRoute'), value:'nullroute'});
      this.transferForm.days = this.routes[0].days;
      return temp2;
    },
    getRouteType(){
      let i = this.routes.findIndex(route => route.id === this.transferForm.routeid);
      this.transferForm.routetype = this.routes[i].type;
    },
    submitTransferForm(){
			const rs = new Promise((resolve, reject)=>{
				let temp = [] as any;
				this.getRouteType();
				axios.post(this.apiAdd + localStorage.getItem("org") + 'route/transfer_children',this.transferForm).then(res =>{
					if(res.data.result == 'ok'){
						res.data.data.forEach((child:any) =>{
						temp.push(child);
						this.checkForRouteUpdate();
					})
					}
					else{
						this.presentToast(this.$t('driver.transferFailed'), 3000, 'danger','top');
					}
					this.resetTransferForm();
					this.$formkit.reset('tForm');
					resolve("ok");
				});
			});

		return rs;

    },
		resetTransferForm(){
			this.transferForm.children = [];
			this.transferForm.routeid = 0;
			this.transferForm.days = moment().isoWeekday();
			this.transferForm.routetype = 0;
		},
		pushToConfirms(event: any, id: number, childid: string, status: number, sw: boolean){
			let dt = {
				"id": id,
				"childid": childid,
				"status": status,
				"sw": sw
			};
			let check = event.target.checked != undefined ? !event.target.checked : true;
			if(check){
				this.rideConfirms.push(dt);
			}
			else{
				this.rideConfirms = this.rideConfirms.filter((io)=>{
					if(io.status != 5){
						return io.childid != childid
					}
					else{
						return true;
					}
				})
			}
		},
    async RideConfirm(id: number, childid: string, status: number, sw: boolean) {
      let defaultText = 'driver.confirmHelpText';
      if (this.routeNumber.type == 0) {
        defaultText = 'driver.confirmHelpText';
      }
      else if (status == 10) {
        defaultText = 'driver.confirmHelpTextSchoolPickup';
      }
      else {
        defaultText = 'driver.confirmHelpText2';
      }
      if (status == 10) {
        status = 2;
      }
      const alert = await alertController.create({
        header: this.$t("driver.areYouSure"),
        message: this.$t(defaultText),
        buttons: [
          {
            text: this.$t("driver.confirmYes"),
            role: 'yes',
            cssClass: 'primary'
          },
          {
            text: this.$t("driver.confirmNo"),
            role: 'no',
            cssClass: 'danger'
          }
        ]
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      if (role == 'yes') {

        axios.post(this.apiAdd + localStorage.getItem("org") + 'logs/create',
          {
            userid: localStorage.getItem("id"),
            childid: childid,
            status: status,
            message: this.routeNumber.id
          }
        ).then((res: any) => {
          if (res.data.result == "ok") {
            let j = Object.keys(this.sortedPickDeli)
            j.forEach((key:any) =>{
            if (status == 2) {
              this.routeStops.find((el) => el.id == id).color = "tertiary";
              if(this.sortedPickDeli[key].includes(id)){
                this.sortedPickDeli[key].find((el:any) => el.id == id).color = "tertiary";
              }

              if(this.routeNumber.type == 0){
              this.doneStops.push([
              this.routeStops[this.routeStops.findIndex((el) => el.id == id)].lon,
              this.routeStops[this.routeStops.findIndex((el) => el.id == id)].lat
            ])
              }
            }
            else if (status == 3) {
              this.routeStops.find((el) => el.id == id).color = "success";
              this.sortedPickDeli[key].find((el:any) => el.id == id).color = "success";
            }
            else if (status == 1) {
              this.routeStops.find((el) => el.id == id).color = "success";

              if(this.sortedPickDeli[key].includes(id)){
                this.sortedPickDeli[key].find((el:any) => el.id == id).color = "success";
              }

              if(this.routeNumber.type == 1){
              this.doneStops.push([
              this.routeStops[this.routeStops.findIndex((el) => el.id == id)].lon,
              this.routeStops[this.routeStops.findIndex((el) => el.id == id)].lat
            ])

              }
            }

            if (status == 2) {
              this.updateChildrenStatus(childid, status);
            }
          })
            this.presentToast("Kuittaus onnistui.", 3000, 'success','top');
          }
          else{
            this.presentToast("Kuittaus epäonnistui.", 3000, 'danger','top');
          }

        })

      } else if (role == 'no') {
        this.routeStops.find((el) => el.id == id).color = "";
      }
      if(sw){
      this.switchAccordion(id);
      }
    },
    async sendLateNotification(key:any) {
      let children  = [] as any;
      this.sortedPickDeli[key].forEach((child: any) => {

        if (this.rideConfirms.findIndex((e: any) => e.childid == child.childid) == -1) {


          children.push({id:child.childid,name:child.firstName});


          //this.routeStops.find((el) => el.childid == child.childid).color = "danger";

        }
      });
      let defaultText = 'driver.lateNotification';
      let listOfChildren = "<ul>"
      children.forEach((child:any) =>{
        listOfChildren += "<li>"+child.name+"</li>"
      })
      if(listOfChildren == "<ul>"){
        listOfChildren += "<li>"+this.$t('driver.noneLate')+"</li>"
      }
      listOfChildren += "</ul>"
      const alert = await alertController.create({
        header: this.$t("driver.areYouSure"),
        message: "<p>"+this.$t(defaultText)+"</p>" + listOfChildren,
        buttons: [
          {
            text: this.$t("driver.confirmYes"),
            role: 'yes',
            cssClass: 'primary'
          },
          {
            text: this.$t("driver.confirmNo"),
            role: 'no',
            cssClass: 'danger'
          }
        ]
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      if(role == 'yes'){

				this.rideConfirms.forEach((rc)=>{
					if(rc.status == 10){
						axios.post(this.apiAdd + localStorage.getItem("org") + 'logs/create', {
							userid: localStorage.getItem("id"),
							childid: rc.childid,
							status: 2,
							message: this.routeNumber.id
						});
					}
				});

        children.forEach((child:any) =>{
          axios.post(this.apiAdd + localStorage.getItem("org") + 'logs/create', {
            userid: localStorage.getItem("id"),
            childid: child.id,
            status: 4,
            message: this.routeNumber.id
          });
        })


        if(children){
          let childid = [] as any;
          children.forEach((child:any) =>{
            childid.push(child.id);
          })

          axios.post(this.apiAdd + localStorage.getItem("org") + 'users/child_is_late_evening', {
						userid: localStorage.getItem("id"),
						children: childid,
						routeid: this.routeNumber.id
					}).then((res)=>{
						//console.log("child_is_late_evening res ", res);
						this.checkForRouteUpdate();
					});

      children.forEach((chi:any) =>{
        let index = this.childrenStatus.findIndex((e:any) => e.id == chi.childid);
        if(index > 0){
          index = index-1;
        }
        console.log(index)
        this.childrenStatus.splice(index,1);
        console.log(this.childrenStatus)
      })
    }
			this.updateLogs(this.routeNumber.id);
      }

    },
    mapHidden(){
      if(this.mapStatus == true){
        return chevronForwardOutline;
      }else{
        return chevronBackOutline;

      }

    },
    toggleMap(){
    const el = document.querySelector('#sidepanel > ion-menu');
      if(this.mapStatus == true){
        this.mapStatus = false;
				if(el instanceof HTMLElement){
					el.style.setProperty('--side-width', '40vw');
					el.style.setProperty('--side-max-width', '');
				}
      }else{
        this.mapStatus = true;
				if(el instanceof HTMLElement){
          el.style.setProperty('--side-width', '100vw');
					el.style.setProperty('--side-max-width', '100vw');
				}
      }
    },
    confirmToSchool(key:any) {
      let sorted = JSON.parse(JSON.stringify(this.sortedPickDeli[key]));
      let succeeded = true;
			let promises:any[] = [];
      this.childrenStatus.forEach((child: any) => {
        if(child.status == 2 && (sorted.find((c:any) => c.childid == child.id) != undefined)){

        let ax = axios.post(this.apiAdd + localStorage.getItem("org") + 'logs/create', {
          userid: localStorage.getItem("id"),
          childid: child.id,
          status: 3,
          message: this.routeNumber.id
        });
				promises.push(ax);
      }
      });

			Promise.allSettled(promises).then((values)=>{
				const res = values.map((v:any)=>{
					return v.value.status
				});
				const set = [...new Set(res)];
				if(set[0] == 200){
					this.presentToast("Kuittaus onnistui.", 3000, 'success','top');
				}else{
					this.presentToast("Kuittaus epäonnistui.", 3000, 'danger','top');
				}

			}).finally(()=>{
				this.updateLogs(this.routeNumber.id);
				this.childrenStatus = [];
				this.doneStops.push(this.linestrings[this.linestrings.length-1]);
			});
    },
    sendNewMessage(data: any) {
      axios.post(this.apiAdd + localStorage.getItem("org") + 'messages/create', {
        userid: localStorage.getItem("id"),
        message: data
      }).then(() => {
        this.updateMessages();
        this.modalController.dismiss();
      })
    },
    async newMessage() {

      let inp: any = { data: [] };


      let obj = {
        label: this.$t('driver.message'),
        type: 'textarea',
        value: ""
      };
      inp.data.push(obj);


      const alert = await alertController.create({
        header: this.$t('driver.newMessage'),
        buttons: [
          {
            text: this.$t('general.cancel'),
            role: 'cancel',
            handler: () => { console.log("cancel") }
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: (data: string) => { console.log("ok"); console.log(data); this.sendNewMessage(data[0]); }
          }
        ],
        inputs: inp.data
      });
      await alert.present();

      await alert.onDidDismiss();
    },
    getChildrenFromArray(names:string[]){
      if(names.length == 1){
        return names[0];
      }
      if(names.length > 1){
        for(var i = 0; i<names.length; i++){
          var temp = "";
          if(i == names.length-1){
            temp += names[i]
            return temp;
          }else{
            temp += names[i] + ", ";
          }
        }
      }
      return "";
    },
    async updateMessages() {
			let msgs: any[] = [];
      axios.post(this.apiAdd + localStorage.getItem("org") + 'messages/gettodaysmessages', {}).then(res => {
				res.data.data.forEach((element: any) => {
					msgs.push({
						id: Number(element.id),
						name: element.name,
						message: element.message,
						timestamp: element.timestamp,
						children: this.findChildren(element.userid)
					})
				});
				this.messages = msgs;
        this.pnr = false;
      }).catch(e => { console.log(e); });
    },

    updateRouteChosen(id: number, name: string, type: number, timestamp: any) {
			//console.log("updateRouteChosen old ", this.routeNumber)
			//console.log("update ", id, name, type, timestamp)
      this.routeNumber.id = id;
      this.routeNumber.name = name;
      this.routeNumber.type = type;
      this.routeNumber.updated = timestamp;
      this.routeLineStrings();
			let routecar = this.getAssignedVehicle(id);
			//console.log("updateRouteChosen new ", this.routeNumber);
			//console.log("routestops", this.routeStops);
      this.doneStops = [];
      if (this.car.id != 0 && this.car.id != routecar) {
        this.sendAttachedVehicle(this.routeNumber.id, this.car.id);
      }
      this.updateLogs(id);
      if (this.checkrouteinterval != null) {
        clearInterval(this.checkrouteinterval);
      }
      this.checkrouteinterval = setInterval(() => {
        this.checkForRouteUpdate();
      }, 60 * 1000);

			console.log(this.routeNumber.id, this.rideConfirms);
    },
    updateChildrenStatus(id: string, status:number){
      const idx = this.childrenStatus.findIndex((c:any) => c.id == id);
      if(idx > -1){
        this.childrenStatus[idx].status = status;
      }else{
        this.childrenStatus.push({
          id: id,
          status:status
        })
      }
    },
    updateLogs(id: number) {
      axios.post(this.apiAdd + localStorage.getItem("org") + 'logs/get_present_in_car', {
        userid: localStorage.getItem("id"),
        routeid: id
      }).then((res) => {
        if (res.data.result == "ok") {
          let d = res.data.data;

          if (d != undefined && d.length > 0) {
            let j = Object.keys(this.sortedPickDeli)
            for (let i = 0; i < d.length; i++) {

              j.forEach((key:any) =>{


              if (d[i].id != undefined && d[i].id != null) {
                this.updateChildrenStatus(d[i].id, d[i].status);

                if (d[i].status == 2 || d[i].status == 6) {
                  this.routeStops.find((el) => el.childid == d[i].id).color = "tertiary";
                  if(this.sortedPickDeli[key].includes(d[i].id)){
                    this.sortedPickDeli[key].find((el:any) => el.childid == d[i].id).color = "tertiary";
                  }

                  if(this.routeNumber.type == 0){
                    this.doneStops.push([
                    this.routeStops[this.routeStops.findIndex((el) => el.childid == d[i].id)].lon,
                    this.routeStops[this.routeStops.findIndex((el) => el.childid == d[i].id)].lat
                  ])
                  }

                }
                else if (d[i].status == 1 || d[i].status == 3) {
                  this.routeStops.find((el) => el.childid == d[i].id).color = "success";
                  if(this.sortedPickDeli[key].includes(d[i].id)){
                    this.sortedPickDeli[key].find((el:any) => el.childid == d[i].id).color = "success";
                  }
                  if(this.routeNumber.type == 1){
                    this.doneStops.push([
                  this.routeStops[this.routeStops.findIndex((el) => el.childid == d[i].id)].lon,
                  this.routeStops[this.routeStops.findIndex((el) => el.childid == d[i].id)].lat
                ])
                  }

                }
								else if (d[i].status == 4) {
                  this.routeStops.find((el) => el.childid == d[i].id).color = "danger";
                  if(this.sortedPickDeli[key].includes(d[i].id)){
                    this.sortedPickDeli[key].find((el:any) => el.childid == d[i].id).color = "danger";
                  }
                  this.doneStops.push([
                  this.routeStops[this.routeStops.findIndex((el) => el.childid == d[i].id)].lon,
                  this.routeStops[this.routeStops.findIndex((el) => el.childid == d[i].id)].lat
                ])
                }
                else if (d[i].status == 5) {
									console.log("status 5 ", d[i])
                  this.routeStops.find((el) => el.childid == d[i].id).color = "warning";
                  if(this.sortedPickDeli[key].includes(d[i].id)){
                    this.sortedPickDeli[key].find((el:any) => el.childid == d[i].id).color = "warning";
                  }
                  this.doneStops.push([
                  this.routeStops[this.routeStops.findIndex((el) => el.childid == d[i].id)].lon,
                  this.routeStops[this.routeStops.findIndex((el) => el.childid == d[i].id)].lat
                ])
                }

              }
              else if (d[i].tempid != undefined && d[i].tempid != null) {
                this.updateChildrenStatus(d[i].tempid, d[i].status);

                if (d[i].status == 2 || d[i].status == 6) {
                  this.routeStops.find((el) => el.childid == d[i].tempid).color = "tertiary";
                }
                else if (d[i].status == 1 || d[i].status == 3) {
                  this.routeStops.find((el) => el.childid == d[i].tempid).color = "success";
                }
								else if (d[i].status == 4) {
									this.routeStops.find((el) => el.childid == d[i].tempid).color = "danger";
								}
                else if (d[i].status == 5) {
                  this.routeStops.find((el) => el.childid == d[i].tempid).color = "warning";
                }
              }
            })
            }


          }

        }
      })

    },
    async sendAttachedVehicle(routeid: number, carid: number) {
			let routecar = this.getAssignedVehicle(routeid);
			if(carid != routecar){
				const alert = await alertController.create({
					header: routecar != null ? this.$t("driver.vehicleDetermined") : this.$t("driver.vehicleNotDetermined") ,
					message: this.$t("driver.changeAssignedVehicle"),
					buttons: [
						{
							text: this.$t("driver.confirmYes"),
							role: 'yes',
							cssClass: 'primary'
						},
						{
							text: this.$t("driver.confirmNo"),
							role: 'no',
							cssClass: 'danger'
						}
					]
				});
				await alert.present();

				const { role } = await alert.onDidDismiss();

				if(role == 'yes'){
					axios.post(this.apiAdd + localStorage.getItem("org") + 'route/updatevehicle', {
						userid: localStorage.getItem("id"),
						vehicleid: carid,
						routeid: routeid
					}).then((res) => {
						console.log(res);
					})
				}
			}
    },
		getAssignedVehicle(routeid:number){
			let rt = this.routes.find((uk)=>{
				return uk.id == routeid
			});

			return rt.assignedvehicle;
		},
    updateLocale(locale: string) {
      this.$root.$i18n.locale = locale;
    },
    switchAccordion(id:number){
      const elem = this.accordionGroup.$el;
      let elemHeight = document.querySelector("#side-panel-content").clientHeight;
      let height = document.querySelector("#side-panel-content > ion-accordion-group > ion-accordion.md.accordion-animated.accordion-expanded").clientHeight;
      elem.value = id + 1;
      this.sidepanel.$el.scrollToPoint(0,(elemHeight/2)+(height/2),0);
    },
    carChosen(id: number, carNumber: number) {
      this.car.id = Number(id);
      this.car.number = carNumber;
      if (this.routeNumber.id != 0) {
        this.sendAttachedVehicle(this.routeNumber.id, this.car.id);
      }
    },
    getAbsences(){
      axios.post(this.apiAdd + localStorage.getItem("org") + 'exceptions/drivergetdaily', {}).then(res =>{
        this.absences = res.data.data;
      })
    },
		getAbsenceforms(){
			axios.post(this.apiAdd + localStorage.getItem("org") + 'exceptions/drivergetdailyforms', {}).then(res =>{
				this.absenceforms = res.data.data;
			})
		},
    routeLineStrings() {
      let counter = 0;
      let childid: string[] = [];
        let names: string[] = [];
        let count = 0;
        let index = 0;
      let testArray: any[] = [];
      this.cntr = 0;
      let timestamp;
      this.locSchool = [];
      this.linestrings = [];
      this.stops = [];
      this.routeStops = [];
      console.log("route2", this.routes, this.optimized.value);
      const tempRoute = this.routes.find(e => e.id === this.routeNumber.id);
      if(tempRoute.nonoptimizedroute == undefined){
        this.optimized.disabled = true;
        this.optimized.value = true;
      }
      else{
        this.optimized.disabled = false;
      }
      if (tempRoute != undefined) {
        if(this.optimized.value){
          tempRoute.optimizedpoints.drawnRoute.forEach((coords: any) => {
          if (coords.type == "LineString") {
            this.linestrings.push(...coords.coordinates);
          }
        });
        }
        else{
          tempRoute.nonoptimizedroute.coordinates.forEach((coords: any) => {

            this.linestrings.push(coords);

        });
        }
        this.linestrings.flat();
        this.linestrings.shift();

        this.children = tempRoute.children;


        tempRoute.children.forEach((child: string) => {
          const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
          if (regexExp.test(child)) {
            childid.push(child);
            let cIndex = tempRoute.chi.id.findIndex((e: string) => e == child);
            if (cIndex != -1) {
              names.splice(count, 0, tempRoute.chi.names[cIndex]);
              testArray.push({
                "childname": tempRoute.chi.names[cIndex],
                "childid": child
              })
            }
						else{
							names.splice(count, 0, '');
              testArray.push({
                "childname": '',
                "childid": child
              })
						}

            let tIndex = tempRoute.tempdata.tempid.findIndex((e: string) => e == child)
            if (tIndex != -1) {
              names.splice(count, 0, tempRoute.tempdata.tempname[tIndex]);
              testArray.push({
                "childname": tempRoute.tempdata.tempname[tIndex],
                "childid": child
              })
            }

          } else {
            if (tempRoute.tempdata != undefined && tempRoute.tempdata.tempname != undefined && tempRoute.tempdata.tempname.length > 0) {
              for (let io = 0; io < tempRoute.tempdata.tempname.length; io++) {
                if (tempRoute.tempdata.tempname[io] == child || tempRoute.tempdata.tempid[io] == child) {
                  testArray.push({
                    "childname": child,
                    "childid": tempRoute.tempdata.tempid[io]
                  })
                  childid.push(tempRoute.tempdata.tempid[io]);
                  break;
                }
              }
              names.push(child);
            }
            else {
              childid.push(uuid().toString());
              names.push(child);
              testArray.push({
                "childname": child,
                "childid": uuid().toString()
              })
            }

          }
          count++;
        })
        //}
      }
      let schoolName = "";
      let routeName = tempRoute.name;
      let routeId = 0;
      if(this.optimized.value){
        tempRoute.optimizedpoints.points.forEach((child: any) => {
        //console.log(child);
        if (child.type == "pickupShipment" && this.routeNumber.type == 0) {
          index = this.sortIndexes(names, childid, child.location_id.replace("pickup_", ""),index);
          routeId = parseInt(child.id.replace(routeName,""));

            timestamp = moment.unix(child.arr_time).utc();
            this.schools.forEach(school=>{
              if(school.address == tempRoute.waypoints[routeId].delivery){
                schoolName = school.name;
              }
            })

          this.routeStops.push({
            id: counter,
            firstName: (names[index] || ''),
            childid: childid[index],
            targetAddress: tempRoute.waypoints[routeId].pickup,
            targetTime: moment.utc(timestamp).format('HH:mm'),
            targetAmount: 1,
            pickdeli: schoolName,
            targetAssistance: '',
            color: "",
            lat: child.address.lat,
            lon: child.address.lon,
						rideconfirm: this.findRideConfirm(childid[index])
          })
          if(!this.locSchool.includes([tempRoute.waypoints[routeId].dellon,tempRoute.waypoints[routeId].dellat])){
            this.locSchool.push([tempRoute.waypoints[routeId].dellon,tempRoute.waypoints[routeId].dellat])
          }
          this.stops.push([child.address.lon, child.address.lat]);
          counter++;
        } else if (child.type == "deliverShipment" && this.routeNumber.type == 1) {
          index = this.sortIndexes(names, childid, child.location_id.replace("delivery_", ""),index);
          routeId = parseInt(child.id.replace(routeName,""));
          timestamp = moment.unix(child.arr_time).utc();
          this.schools.forEach(school=>{
              if(school.address == tempRoute.waypoints[routeId].pickup){
                schoolName = school.name;
              }
            })
          this.routeStops.push({
            id: counter,
            firstName: names[index],
            childid: childid[index],
            targetAddress: tempRoute.waypoints[routeId].delivery,
            targetTime: moment.utc(timestamp).format('HH:mm'),
            targetAmount: 1,
            pickdeli: schoolName,
            targetAssistance: '',
            color: "",
            lat: child.address.lat,
            lon: child.address.lon,
						rideconfirm: this.findRideConfirm(childid[index])
          })
          if(!this.locSchool.includes([tempRoute.waypoints[routeId].picklon,tempRoute.waypoints[routeId].picklat])){
            this.locSchool.push([tempRoute.waypoints[routeId].picklon,tempRoute.waypoints[routeId].picklat])
          }
          this.stops.push([child.address.lon, child.address.lat]);
          counter++;
        }
      });
      }else{
        if(tempRoute.type == 0){
          tempRoute.waypoints.forEach((way:any) =>{
            this.schools.forEach(school=>{
              if(school.address == way.delivery){
                schoolName = school.name;
              }
            })
            let t_child = {"childname": "", "childid": ""}
            t_child = testArray.filter((cyu)=>{
              return cyu.childid == way.child
            })[0];
          this.routeStops.push({
            id: counter,
            //firstName: names[counter],
            //childid: childid[counter],
            firstName: t_child.childname,
            childid: t_child.childid,
            targetAddress: way.pickup,
            targetTime: way.timewindow,
            targetAmount: 1,
            pickdeli: schoolName,
            targetAssistance: '',
            color: "",
            lat: way.picklat,
            lon: way.picklon,
						//rideconfirm: this.findRideConfirm(childid[counter])
            rideconfirm: this.findRideConfirm(t_child.childid)
          })
          if(!this.locSchool.includes([way.dellon,way.dellat])){
            this.locSchool.push([way.dellon,way.dellat])
          }
          this.stops.push([way.picklon, way.picklat]);
          counter++;
        })
        }
        else if(tempRoute.type == 1){
          tempRoute.waypoints.forEach((way:any) =>{
            this.schools.forEach(school=>{
              if(school.address == way.pickup){
                schoolName = school.name;
              }
            })
              let t_child = {"childname": "", "childid": ""}
              t_child = testArray.filter((cyu)=>{
                return cyu.childid == way.child
              })[0];
          this.routeStops.push({
            id: counter,
            //firstName: names[counter],
            //childid: childid[counter],
            firstName: t_child.childname,
            childid: t_child.childid,
            targetAddress: way.delivery,
            targetTime: way.timewindow,
            pickdeli: schoolName,
            targetAmount: 1,
            targetAssistance: '',
            color: "",
            lat: way.dellat,
            lon: way.dellon,
						//rideconfirm: this.findRideConfirm(childid[counter])
            rideconfirm: this.findRideConfirm(t_child.childid)
          })
          if(!this.locSchool.includes([way.picklon,way.picklat])){
            this.locSchool.push([way.picklon,way.picklat])
          }
          this.stops.push([way.dellon, way.dellat]);
          counter++;
        })
        }

      }

      let i = 0;
      tempRoute.chi.assistance.forEach((note: string) => {
        if (note !== "") {
          let rIndex = this.routeStops.findIndex(e => e.childid === tempRoute.chi.id[i]);
          //console.log(rIndex);
          if (rIndex >= 0) {
            this.routeStops[rIndex].targetAssistance = note;
          }
        }
        i++;
      })

			console.log("that",this.routeStops);
      const groupBy = (x:any,f:any)=>x.reduce((a:any,b:any,i:any)=>((a[f(b,i,x)]||=[]).push(b),a),{});
      this.sortedPickDeli = groupBy(this.routeStops, (v:any) => v.pickdeli);
      console.log("this",this.sortedPickDeli);
      this.updateLogs(this.routeNumber.id);
      this.getParentPhoneNumbers();


    },
    sortIndexes(names: string[], ids: string[], testVar: string, lIndex: number) {
      let lastIndex = ids.lastIndexOf(testVar);
      let index = ids.findIndex((e: string) => e === testVar);
      if(index == lastIndex){
        return index;
      }else if(index > lIndex){
        index = lastIndex;
        return index;
      }

      if (index === -1) {
        index = names.findIndex((e: string) => e === testVar);

      }

      return index;

    },
    swapUI() {
      if (this.side === "start") {
        this.side = "end";


      } else {
        this.side = "start";

      }

    },
    async reportDisturbance(id:any){
      let defaultText = 'driver.confirmHelpTextDisruption';
      const alert = await alertController.create({
        header: this.$t("driver.areYouSure"),
        message: this.$t(defaultText),
        buttons: [
          {
            text: this.$t("driver.bullying"),
            role: 'bullying',
            cssClass: 'primary'
          },
          {
            text: this.$t("driver.tobacco"),
            role: 'tobacco',
            cssClass: 'primary'
          },
          {
            text: this.$t("driver.vandalism"),
            role: 'vandalism',
            cssClass: 'primary'
          },
          {
            text: this.$t("driver.disruption"),
            role: 'disruption',
            cssClass: 'primary'
          },
          {
            text: this.$t("driver.confirmNo"),
            role: 'no',
            cssClass: 'danger'
          },
        ]
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();

      switch (role){
        case 'bullying':
        axios.post(this.apiAdd + localStorage.getItem("org") + 'logs/create', {
          userid: localStorage.getItem("id"),
          childid: null,
          status: 7,
          message: this.routeNumber.id + " " + role
        });
        break;
        case 'tobacco':
        axios.post(this.apiAdd + localStorage.getItem("org") + 'logs/create', {
          userid: localStorage.getItem("id"),
          childid: null,
          status: 7,
          message: this.routeNumber.id + " " + role
        });
        break;
        case 'vandalism':
        axios.post(this.apiAdd + localStorage.getItem("org") + 'logs/create', {
          userid: localStorage.getItem("id"),
          childid: null,
          status: 7,
          message: this.routeNumber.id + " " + role
        });
        break;
        case 'disruption':
        axios.post(this.apiAdd + localStorage.getItem("org") + 'logs/create', {
          userid: localStorage.getItem("id"),
          childid: null,
          status: 7,
          message: this.routeNumber.id + " " + role
        });
        break;

      }

    },
    async checkForRouteUpdate() {
      axios.post(this.apiAdd + localStorage.getItem("org") + 'route/check_route_changes', {
        userid: localStorage.getItem("id"),
        id: this.routeNumber.id,
        updated: moment.utc(this.routeNumber.updated).format('YYYY-MM-DDTHH:mm:ss.sss'),
				rawtime: String(this.routeNumber.updated)
      }).then((res) => {
        if (res.data.result == "true") {
          this.routeNumber.lastupdate = this.routeNumber.updated;
          axios.post(this.apiAdd + localStorage.getItem("org") + 'route/getbyweekday_driver', { "days": moment().isoWeekday() }).then(async(res) => {
            this.routes = [];
            let newArray: any[] = [];
            res.data.data.forEach((element: any) => {
              let d = {};
              d = {
                id: Number(element.id),
                chi: element.chi,
                children: element.children,
                assignedvehicle: element.assignedvehicle,
                name: element.name,
                routelength: element.routelength,
                routetime: element.routetime,
                type: element.type,
                timestamp: element.timestamp,
                startpoint: JSON.parse(element.startpoint),
                optimizedpoints: JSON.parse(element.optimizedpoints),
                nonoptimizedroute: JSON.parse(element.nonoptimizedroute),
                waypoints: JSON.parse(element.waypoints),
                days: element.days,
                endpoint: element.endpoint,
                tempdata: element.tempdata,
                emptyroute: JSON.parse(element.waypoints).length > 0 ? false : true
              };

              let f = {};
              f = JSON.parse(JSON.stringify(d));
              newArray.push(f);

              if (this.routeNumber.id == element.id) {
                this.routeNumber.updated = moment.utc(element.timestamp);
              }

            });
            this.routes = JSON.parse(JSON.stringify(newArray));

            this.updateRouteChosen(this.routeNumber.id, this.routeNumber.name, this.routeNumber.type, this.routeNumber.updated);

            this.getParentPhoneNumbers();
            //this.showNewRouteMessage(); //TODO crashes
            this.presentToast('Reitti päivitetty!', 3000, 'success', 'top');
          }).catch(e => {
            if (e != undefined && e.response != undefined && e.response.status == 401) {
              location.reload();
            }

          });
        }

      })
    },
    getParentPhoneNumbers(){
      this.parentPhoneNumbers = [];
      let childArr = [] as Array<string>;
      this.routeStops.forEach(stop =>{
        childArr.push(stop.childid);
      })
      axios.post(this.apiAdd + localStorage.getItem("org") + 'users/getparentphones',{"children":childArr}).then(par =>{
        Object.entries(par.data.data).forEach(([key,value]:any) =>{
          childArr.forEach(chi =>{
          if(chi == key){
            if(value[1]){
              this.parentPhoneNumbers.push(
              {
                childid: value[0].childid,
                parent1: {
                  name: value[0].parentname,
                  phone: value[0].phone
                },
                parent2: {
                  name: value[1].parentname,
                  phone: value[1].phone
                }
              }
            )
            }else{
              this.parentPhoneNumbers.push(
              {
                childid: value[0].childid,
                parent1: {
                  name: value[0].parentname,
                  phone: value[0].phone
                },
                parent2: {
                  name: "",
                  phone: ""
                }
              }
            )
            }

          }
        })
        })

      })
      console.log(this.parentPhoneNumbers)
    },
		findRideConfirm(childid:any){
			let res = this.rideConfirms.findIndex((e: any) => e.childid == childid) != -1
			return res;
		},
    parentPhoneExists(id:any){
      if(this.parentPhoneNumbers.find(a => a.childid == id)){
        return true;
      }
      else {
        return false;
      }
    },
    getParentName(id:any,idx:any){
      if(idx){
        return this.parentPhoneNumbers[this.parentPhoneNumbers.findIndex(a => a.childid == id)].parent1.name;
      }else{
        return this.parentPhoneNumbers[this.parentPhoneNumbers.findIndex(a => a.childid == id)].parent2.name;
      }


    },
    getParentPhone(id:any,idx:any){
      if(idx){
        return this.parentPhoneNumbers[this.parentPhoneNumbers.findIndex(a => a.childid == id)].parent1.phone;
      }else{
        return this.parentPhoneNumbers[this.parentPhoneNumbers.findIndex(a => a.childid == id)].parent2.phone;
      }

    },
    callParent(id:any,idx:any){
      window.open('tel:' + this.getParentPhone(id,idx))
    },
    async showNewRouteMessage() {
      const alert = await alertController.create({
        header: this.$t("driver.routeupdated"),
        message: this.$t("driver.routeupdated"),
        buttons: [
          {
            text: "ok",
            cssClass: 'primary'
          }

        ]
      });
      await alert.present();
    },
    getIndex(a:number,b:number){
      let total = 0;
      let index = 0;

      let keys = Object.keys(this.sortedPickDeli) as [any];
      keys.forEach((key:any) =>{
        total += this.sortedPickDeli[key].length;
      })
      if(a == 0){
        index = b+1;
        return index;
      }else if(a > 0){
        let c = 0;
        for(let i = 0; i < a; i++){
          c += this.sortedPickDeli[keys[i]].length;
        }
        index = c+1+b;
        return index;
      }else{
        return total;
      }


    },
    getRoutes() {

      axios.post(this.apiAdd + localStorage.getItem("org") + 'route/getbyweekday_driver', { "days": moment().isoWeekday() }).then(res => {
        let newArray: any[] = [];
        res.data.data.forEach((element: any) => {
          let d = {};
          d = {
            id: Number(element.id),
            chi: element.chi,
            children: element.children,
            assignedvehicle: element.assignedvehicle,
            name: element.name,
            routelength: element.routelength,
            routetime: element.routetime,
            type: element.type,
            timestamp: element.timestamp,
            startpoint: JSON.parse(element.startpoint),
            optimizedpoints: JSON.parse(element.optimizedpoints),
						nonoptimizedroute: JSON.parse(element.nonoptimizedroute),
            waypoints: JSON.parse(element.waypoints),
            days: element.days,
            endpoint: element.endpoint,
            tempdata: element.tempdata,
            emptyroute: JSON.parse(element.waypoints).length > 0 ? false : true
          };
          let f = {};
          f = JSON.parse(JSON.stringify(d));
          newArray.push(f);


        });
        if(this.routes != newArray){
        newArray.sort((a,b)=>{
          if ( a.name < b.name){
            return -1;
          }
          if ( a.name > b.name){
            return 1;
          }
          return 0;
        });
        this.routes = JSON.parse(JSON.stringify(newArray));
				this.filteredRoutes = this.routes.filter((tr)=>{
          return !tr.emptyroute
        });
        }



      }).catch(e => {
        if (e != undefined && e.response != undefined && e.response.status == 401) {
          location.reload();
        }

      });
    },
    getCars() {
      let tempCars: any[] = [];

      axios.post(this.apiAdd + localStorage.getItem("org") + 'vehicles/getvehicles', {}).then(res => {
      res.data.data.forEach((element: any) => {
        let d = {};
        d = {
          id: element.id,
          name: element.name,
          type: element.type,
          number: element.number,
          fuelconsumption: element.fuelconsumption,
          emissions: element.emissions,
          servicelevel: element.servicelevel,
          registration: element.registration,
          capacity: element.capacity
        };
        let f = {};
        f = JSON.parse(JSON.stringify(d));
        tempCars.push(f);
      });
       if(this.cars != tempCars){
      this.cars = JSON.parse(JSON.stringify(tempCars));

    }
    });


    console.log(this.cars)

    },
		filterRouteList(routetype: string){
			if(routetype != null){
				if(routetype == '0' || routetype == '1'){
					this.filteredRoutes = this.routes.filter((io)=>{
						return io.type==routetype && io.waypoints.length > 0;
					});
				}
				else if(routetype == 'assigned'){
					this.filteredRoutes = this.routes.filter((io)=>{
						return io.assignedvehicle==this.car.id && io.waypoints.length > 0;
					});
				}
        else if(routetype == 'empty'){
            this.filteredRoutes = this.routes.filter((tr)=>{
                return tr.emptyroute
            });
        }
			}
			else{
				this.filteredRoutes = this.routes.filter((tr)=>{
          return !tr.emptyroute
        });
			}
			console.log(this.filteredRoutes)
		},
		findCar(id: string | null){
			if(id != undefined && id != null){
				const rs = this.cars.find((i)=>{
					return i.id == id;
				});

				return rs.name;
			}
		},
    getMessages() {
      let msgs: any[] = [];
      axios.post(this.apiAdd + localStorage.getItem("org") + 'messages/gettodaysmessages', {}).then(res => {
      res.data.data.forEach((element: any) => {
        msgs.push({
          id: Number(element.id),
          name: element.name,
          message: element.message,
          timestamp: element.timestamp,
					children: this.findChildren(element.userid)
        })
      });
    }).catch(e => { console.log(e); });
    msgs.shift().sort((a:any,b:any)=>{
			return a.id - b.id
		});
    if(this.messages != msgs){

      this.messages = msgs;
    }
    },
    forceRerender(){
      this.componentKey += 1;
    },
    async endRoute(){
      const alert = await alertController.create({
        header: this.$t("driver.areYouSure"),
        message: this.$t("driver.endFare"),
        buttons: [
          {
            text: this.$t("driver.confirmYes"),
            role: 'yes',
            cssClass: 'primary'
          },
          {
            text: this.$t("driver.confirmNo"),
            role: 'no',
            cssClass: 'danger'
          }
        ]
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();

      if (role == 'yes') {
      this.stops = [[]];
      this.linestrings = [[]];
      this.routeStops = [];
      this.routeNumber = {
        id: 0,
        name: "",
        type: -1,
        updated: null,
        lastupdate: "123"
      };
      this.childrenStatus = [];
      this.children = [];
      this.doneStops = [];
      }
    },
		checkLogin() {
				if (localStorage.getItem("token") != undefined && localStorage.getItem("token") != null) {
						let token = localStorage.getItem("token");
						const config = {
								headers: { Authorization: `Bearer ` + token }
						};
						axios.post(this.$api_add + localStorage.getItem("org") + 'users/checktoken', {}, config).then(result => {
								if (result.data.result === "ok") {
									//Do nothing
								} else {
									localStorage.removeItem("id");
									localStorage.removeItem("role");
									localStorage.removeItem("token");
									localStorage.removeItem("dashboardItems");
									localStorage.removeItem("dashboardIdCounter");
									localStorage.removeItem("cubetoken");
									localStorage.removeItem('pushtoken');
									this.$router.push('/');

								}

						}).catch((err) => {
								//this.openToast(error);
								localStorage.removeItem("id");
								localStorage.removeItem("role");
								localStorage.removeItem("token");
								localStorage.removeItem("dashboardItems");
								localStorage.removeItem("dashboardIdCounter");
								localStorage.removeItem("cubetoken");
								localStorage.removeItem('pushtoken');
								this.$router.push('/');
						});
				}
		},
    getMyAccount(){
      axios.post(this.apiAdd + localStorage.getItem("org") + 'users/getmyaccount', {id: localStorage.getItem("id")}).then(res => {
        this.myAccount = res.data.data;
      })
    },


  },
  data() {
    return {
      mapStatus: false,
      menuWidth: 60,
      stops: [[]],
      linestrings: [[]],
      routeStops: [


      ],
      myAccount:{},
      side: "end",
      routeNumber: {
        id: 0,
        name: "",
        type: -1,
        updated: null,
        lastupdate:"123"
      },
      routes: [],
			filteredRoutes: [],
      car: {
        id: 0,
        number: 0
      },
      cars: [],
      parentPhoneNumbers: [],
      childrenStatus: [],
      children: [],
      messages: [],
      checkrouteinterval: null,
      formType: 'transfer',
      transferForm:{
        children:[],
        days: 1,
        routetype: 0,
        routeid: 1
      },
      childrenBySchool:[],
      schools:[],
      modal: false,
      schoolChosen: "",
      childrenChosen: [],
      absences: [],
			absenceforms: [],
      doneStops:[],
      optimized: {
        disabled: false,
        value: true
      },
      sortedPickDeli: [],
      locSchool:[],
      cntr: 0,
      pnr: false,
      componentKey: 0,
			rideConfirms: [],
			childrenAndParents:[]
    }
  },

  mounted() {
    this.forceRerender();
    this.getSchools();
    this.$root.$i18n.locale = localStorage.getItem("locale") ?? 'fi';
    this.routes = this.routesGot;
    this.cars = this.carsGot;
    this.getChildrenAndParents();
    if(isPlatform('ios') || isPlatform('android')){
      const addListeners = async () => {
      await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('Push notification received: ', notification);
      this.pnr = true;
      });
    }
    addListeners();
    }
    this.getMyAccount();
		App.addListener('appStateChange', ({ isActive }) => {
			if(isActive){
				this.checkLogin();
			}
		})
    this.messages = this.messagesGot;

  },
  updated() {
    this.$root.$i18n.locale = localStorage.getItem("locale") ?? 'fi';
  }
});
</script>
<style>
.confirmButton {
  height: 96px;
  width: 96px;
}

.confirmIcon {
  font-size: 64px;
}

#side-panel-content {
  overflow-y: auto;
}
ion-split-pane {
  --max-width: 5000px;
  --min-width: 100px;
}

.split-pane-md.split-pane-visible>.split-pane-side {


  border-right: var(--border);
  border-left: 0;
}
.divider {
  height: 100%;
  width: 8px;

  position: flex;
  right: 0;
  top: 0;
  bottom: 0;

  background: #e2e2e2;

  z-index: 10;

  cursor: w-resize;

}
.formkit-form {
  flex-shrink: 0;
  margin-top: 1em;
  margin-bottom: 1em;

  border: 1px solid var(--gray);
  border-radius: 0.5em;
  box-shadow: 0.25em 0.25em 1em 0 rgba(0,0,0,0.1);
}

#app .source-content {
  padding: 2em;
  background: transparent;
}

.steps {
  list-style-type: none;
  margin: 0;
  display: flex;
  padding-left: 0;
  background: var(--gray-l);
  border-radius: 0.4em 0.4em 0 0;
  overflow: hidden;
  border-bottom: 1px solid var(--gray);
}

.step {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  background: var(--gray-l);
  border-right: 1px solid var(--gray);
  color: gray;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.step:last-child {
  box-shadow: 0.1em -0.1 0.1em 0 rgba(0,0,0,0.33)
}

.step:hover { cursor: pointer; }

[data-step-active="true"] {
  color: black;
  background: white !important;
  border-bottom: none;
  position: relative;
}

.step--errors,
[data-step-valid="true"]:after {
  content: '\2713';
  background-color: #54A085;
  position: absolute;
  top: 4px; right: 4px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  z-index: 10;
  display: flex;
  font-size: 10px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
}

.step--errors {
  background-color: #ff4949;
  color: #fff;
  z-index: 100;
}

.step-nav {
  display: flex;
  margin-top: 2em;
  margin-bottom: 1em;
  justify-content: space-between;
}

.form-body {
  padding: 2em;
}

.next {
  margin-left: auto;

}

.formkit-outer[data-type="submit"] .formkit-wrapper {
  padding: 0 2em 1em 2em;
  display: flex;

}
.formkit-outer[data-type="submit"] .formkit-input {
  margin-left: auto;
  margin-right: 0;
  align-content: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.formkit-outer[data-type="button"] .formkit-input {
  height: 40px;
  width: 75px;
}


details {
  border: 1px solid var(--gray);
  background: var(--gray-l);
  border-radius: .15em;
  padding: 1em;
}

.formkit-form > .formkit-messages {
  padding: 0 2em 0em 2em;
}
.formkit-form > .formkit-messages:last-child {
  padding: 0 2em 2em 2em;
}

[data-errors="true"] .formkit-label { color: #ff4949; }
.formkit-wrapper { max-width: 100%; }
button:hover, summary { cursor: pointer }
/*p { max-width: 600px;}
p small { color: #999; }
h1 { margin: .25em 0; max-width: 600px;}*/

@media (max-width: 438px) {
  h1 { font-size: 1.15em; }
  #app .source-content {
    padding: 0.5em;
  }
  .steps {
    flex-direction: column;
  }
  .step {
    border-bottom: 1px solid var(--gray);
    border-right: none;
  }
  .step:last-child {
    border-bottom: none;
  }
  .form-body {
    padding: 1em;
  }
  .formkit-outer[data-type="submit"] .formkit-wrapper {
    padding: 0 1em 1em 1em;
    display: flex;
  }
  .formkit-form > .formkit-messages {
    padding: 0 1em 0em 1em;
  }
  .formkit-form > .formkit-messages:last-child {
    padding: 0 1em 1em 1em;
  }
}
.invisible{
  display:none;
}
#subbutton{
  align-content: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.toolbar-title {
   text-overflow: inherit;
   white-space: normal;
}
.ctoggle{
	--handle-background: #eb7769;
	--handle-background-checked: var(--ion-color-primary);
	--track-background: #eb7769;
	--track-background-checked: var(--ion-color-primary);
}
@media (prefers-color-scheme: dark) {
	.formkit-input{
		color: white;
	}
	.formkit-option{
		color:black;
	}
	li.formkit-option{
		color: white;
	}
	.formkit-help{
		color:white;
	}
	#rtList{
		color:white;
	}
}
@media (prefers-color-scheme: light) {
	#rtList{
		color:black;
	}
}
.routeSelectionHalf{
  width: 50%;
}
.routeSelectionQuarter{
  width: 25%;
}
</style>
