<template>
		<ion-grid>
			<ion-row>
				<ion-col>
					<ion-content class="ion-padding">
						<ion-list-header class="bigtext">{{this.data.vehiclename}}</ion-list-header>
						<ion-list>
							<ion-item>
								<ion-label>{{$t('operator.registerNumber')}}</ion-label>
								<ion-label>{{this.data.registration}}</ion-label>
							</ion-item>
							<ion-item>
								<ion-label>{{$t('operator.currentRoute')}}</ion-label>
								<ion-label>{{this.route.routename}}</ion-label>
							</ion-item>
							<ion-item>
								<ion-label>{{$t('operator.routePoints')}}</ion-label>
								<ion-label>{{this.route.donePoints}}</ion-label>
							</ion-item>
						</ion-list>
					</ion-content>
				</ion-col>
			</ion-row>
		</ion-grid>
</template>

<script>
import { IonContent, IonGrid, IonRow, IonCol, IonLabel, IonList, IonListHeader, IonItem } from '@ionic/vue';
import { defineComponent, toRaw } from 'vue';

export default defineComponent({
  name: 'PopupPopover',
  components: { IonContent, IonGrid, IonRow, IonCol, IonLabel, IonList, IonListHeader, IonItem },
	props:['cardata', 'routedata'],
	data(){
		const dt = toRaw(this.cardata);
		const rt = toRaw(this.routedata);
		console.log(dt,rt);
		return{
			data: dt,
			route: rt
		}
	}
});
</script>
<style>
.bigtext{
	font-size: 1.5em;
	font-weight: bold;
}
</style>
