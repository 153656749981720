<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-label>{{$t("title.title")}}</ion-label>
                <info-component slot="end" side="end"/>
                <feedback-modal-component />
                <ion-item slot="end"><logout-vue size="36px" /></ion-item>

            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">

            <ion-list>

                <ion-item-divider>

                    <ion-button @click="getExceptions" id="bKuittaus" expand="full" style="min-width: 100%; padding-left: 10%;padding-right: 10%; font-size: large;">
                        {{$t("parent.confirm")}}
                        <ion-icon :icon="checkmarkOutline" slot="end"></ion-icon>
                    </ion-button>

                </ion-item-divider>
                <ion-item-divider>

                    <ion-button @click="getEvents" id="bTapahtumat" expand="full" style="min-width: 100%; padding-left: 10%;padding-right: 10%; font-size: large;">
                        {{$t("parent.confirmLog")}}
                        <ion-icon :icon="newspaperOutline" slot="end"></ion-icon>
                    </ion-button>

                </ion-item-divider>
            <ion-item-divider>

                <ion-button id="bPoissaolov2" expand="full" style="min-width: 100%; padding-left: 10%;padding-right: 10%; font-size: large;" @click="updateAbsencesForm">
                    {{$t("parent.absentForm")}}
                <ion-icon :icon="calendarOutline" slot="end"></ion-icon>
                </ion-button>


</ion-item-divider>
                <ion-item-divider>

                    <ion-button @click="getMyAccount" id="bMuutaOsoite" expand="full" style="min-width: 100%; padding-left: 10%;padding-right: 10%; font-size: large;">
                        {{$t("parent.changeAddress")}}
                        <ion-icon :icon="pencilOutline" slot="end"></ion-icon>
                    </ion-button>

                </ion-item-divider>
                <ion-item-divider>

                    <ion-button @click="getMyAccount" id="bSendMessage" expand="full" style="min-width: 100%; padding-left: 10%;padding-right: 10%; font-size: large;">
                        {{$t("parent.sendMessage")}}
                        <ion-icon :icon="sendOutline" slot="end"></ion-icon>
                    </ion-button>

                </ion-item-divider>
                <ion-item-divider>

                    <ion-button @click="getMyAccount();switchContent('MyAccount');" id="bOmatTiedot" expand="full" style="min-width: 100%; padding-left: 10%;padding-right: 10%; font-size: large;">
                        {{$t("parent.accountInfo")}}
                        <ion-icon :icon="personOutline" slot="end"></ion-icon>
                    </ion-button>

                </ion-item-divider>




            </ion-list>
            <locale-switcher @updateLocale="updateLocale" size="48px" @click="$forceUpdate" />
        </ion-content>

        <ion-modal class="kuittaus" trigger="bKuittaus">
            <ion-header>
                <ion-toolbar>
                    <ion-title>{{$t("parent.transportToday")}}</ion-title>
                    <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-toolbar>
            </ion-header>
            <ion-accordion-group  ref="accordionGroup" style="overflow-y:auto;">
                <ion-accordion v-for="route of myRoutes" :key="route.id">
                    <ion-item  slot="header">
                        <ion-label>{{parseDate(route.days)}}</ion-label>
                        <ion-label v-if="route.type==0">{{route.timewindow}}</ion-label>
                        <ion-label v-else>{{moment.utc(moment.unix(route.arr_time)).format("HH:mm")}}</ion-label>
                        <ion-label>{{route.name}}</ion-label>
                    </ion-item>
                    <ion-list slot="content" :inset="true">
                        <div>
                            <ion-item>{{$t("parent.pickUpAddress")}}: {{route.pickup}} </ion-item>
                            <ion-item>{{$t("parent.dropOffAddress")}}: {{route.delivery}} </ion-item>
                        </div>
                        <!---<div>
                            <ion-button @click="confirm_address_change(route.id,route.routeid)">{{$t("parent.changeAddress")}}</ion-button>

                            <ion-button @click="confirm_cancel(route.id,route.type,route.arr_time,route.routeid)">{{$t("parent.cancelTransport")}}</ion-button>
                        </div>
                        -->
                        <ion-item-sliding>
                            <ion-item-options side="start">
                                <ion-item-option @click="parent_confirm_this(route.id,route.type)">{{$t("parent.confirm")}}</ion-item-option>
                            </ion-item-options>
                            <ion-item color="warning">
                                {{$t("parent.dragToConfirm")}}
                                <ion-icon :icon="arrowForwardOutline"></ion-icon>
                            </ion-item>
                        </ion-item-sliding>
                    </ion-list>
                </ion-accordion>
            </ion-accordion-group>
            <ion-accordion-group value="past">
                <ion-accordion>
                    <ion-item  slot="header">
                        <ion-label>{{$t('parent.pastRoute')}}</ion-label>
                    </ion-item>
                    <ion-list slot="content" :inset="true">

                    <ion-accordion-group value="pastRoutes">
                    <ion-accordion v-for="route of pastRoutes" :key="route.id">
                    <ion-item  slot="header">
                        <ion-label>{{parseDate(route.days)}}</ion-label>
                        <ion-label v-if="route.type==0">{{route.timewindow}}</ion-label>
                        <ion-label v-else>{{moment.utc(moment.unix(route.arr_time)).format("HH:mm")}}</ion-label>
                        <ion-label>{{route.name}}</ion-label>
                    </ion-item>
                    <ion-list slot="content" :inset="true">
                        <div>
                            <ion-item>{{$t("parent.pickUpAddress")}}: {{route.pickup}} </ion-item>
                            <ion-item>{{$t("parent.dropOffAddress")}}: {{route.delivery}} </ion-item>
                        </div>

                    </ion-list>

                </ion-accordion>
                </ion-accordion-group>
            </ion-list>
            </ion-accordion>
            </ion-accordion-group>
        </ion-modal>
        <ion-modal class="kuittaus" trigger="bTapahtumat">
            <ion-header>
                <ion-toolbar>
                    <ion-title>{{$t("parent.confirmLog")}}</ion-title>
                    <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-toolbar>
            </ion-header>
                <ion-accordion-group ref="accordionGroup" style="overflow-y:auto;">
                    <ion-accordion v-for="ev of events" :key="ev.Timestamp">
                        <ion-item slot="header">
                            <ion-label>{{$t("parent.confirmStatus."+ev.confirmstatus)}}</ion-label>
                            <ion-label>{{moment(ev.timestamp).format('DD.MM.YYYY HH:mm')}}</ion-label>
                            <ion-label>{{ev.firstname}}</ion-label>
                        </ion-item>
                        <ion-list slot="content" :inset="true">
                            <ion-item>{{$t("parent.confirmBase")}}: {{$t("parent.confirmStatus."+ev.confirmstatus)}}</ion-item>
                            <ion-item>{{$t("parent.time")}}: {{moment(ev.timestamp).format('DD.MM.YYYY HH:mm:ss')}}</ion-item>
                            <ion-item>{{$t("parent.childName")}}: {{ev.firstname}}</ion-item>
                            <ion-item>{{$t("parent.personConfirmed")}}: {{ev.nameconfirm}}</ion-item>
                            <ion-item v-if="ev.message != null && ev.message.length > 7">{{$t("parent.message")}}: {{ev.message}}</ion-item>
                        </ion-list>
                    </ion-accordion>
                </ion-accordion-group>
        </ion-modal>
        <ion-modal trigger="bMuutaOsoite">
            <ion-header>
                <ion-toolbar>
                    <ion-title>{{$t("parent.changeAddress")}}</ion-title>
                    <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-toolbar>
            </ion-header>
            <ion-item>
                <ion-label class="show-text">{{$t("parent.chooseAddressText")}}</ion-label>
            </ion-item>

            <ion-item>
                <ion-label position="stacked">{{$t("parent.chooseAddress")}}</ion-label>
                <ion-select v-model="newAddressForm.id">
                    <ion-select-option v-for="address of addresses" :key="address.id" :value="address.id">{{address.address}}</ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item>
                <ion-input label-placement="stacked" :label="$t('parent.address')" v-model="newAddressForm.street" type="text"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input label-placement="stacked" :label="$t('parent.postnumber')" v-model="newAddressForm.zipcode" type="number"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input label-placement="stacked" :label="$t('parent.city')" v-model="newAddressForm.city" type="text"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input label-placement="stacked" :label="$t('parent.message')" v-model="newAddressForm.message" type="text"></ion-input>
            </ion-item>
            <ion-item>
                <ion-button @click="sendAddressChangeRequest">{{$t("parent.send")}}</ion-button>
            </ion-item>
        </ion-modal>
        <ion-modal trigger="bOmatTiedot">
            <ion-header>
                <ion-toolbar>
                    <ion-title>{{$t("parent.accountInfo")}}</ion-title>
                    <ion-button  color="danger" slot="end" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-toolbar>
            </ion-header>
            <ion-item class="scrollable">
                <ion-button @click="switchContent('MyAccount');">
                    {{$t('parent.accountInfo')}}
                </ion-button>
                <ion-button @click="switchContent('ChildInfo');">
                    {{$t('parent.childInfo')}}
                </ion-button>
                <ion-button v-if="!accountInfo.mainaccountid" @click="switchContent('AddSubAccount');">
                    {{$t('parent.addSubAccount')}}
                </ion-button>
                <ion-button @click="switchContent('ManageSubAccount');">
                    {{$t('parent.subAccountManagement')}}
                </ion-button>
                <ion-button @click="switchContent('ChangePassword');">
                    {{$t('general.changePassword')}}
                </ion-button>
                <ion-button @click="switchContent('DeleteAccount')">
                    {{ $t('parent.deleteAccount') }}
                </ion-button>
            </ion-item>
            <ion-content class="height80" v-if="accountInfoContent.content == 'MyAccount'">

                    <ion-label>{{$t('parent.accountInfo')}}</ion-label>
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.username')" type="text" :value="accountInfo.username" readonly></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.firstName')" type="text" v-model="accountInfo.firstname" :value="accountInfo.firstname" :readonly="edit.disabled"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.lastName')" type="text" v-model="accountInfo.lastname" :value="accountInfo.lastname" :readonly="edit.disabled"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.defaultAddress')" type="text" v-model="accountInfo.addressdefault" :value="accountInfo.addressdefault" :readonly="true"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.email')" type="email" v-model="accountInfo.email" :value="accountInfo.email" :readonly="edit.disabled"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.phone')" type="tel" v-model="accountInfo.phone" :value="accountInfo.phone" :readonly="edit.disabled"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-button @click="enableEdit">{{$t('general.edit')}}</ion-button>
                    </ion-item>
                    <ion-item>
                        <ion-button @click="sendEditAccountEdit">{{$t('general.updateChanges')}}</ion-button>
                        <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                    </ion-item>

            </ion-content>
            <ion-content class="height80" v-if="accountInfoContent.content == 'ChildInfo'">
                <ion-label>{{$t('parent.childInfo')}}</ion-label>
                <ion-list v-for="chi of child" :key="chi.id">
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.firstName')" v-model="chi.firstname" type="text" :value="chi.firstname" :readonly="edit.disabled"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.lastName')" v-model="chi.lastname" type="text" :value="chi.lastname" :readonly="edit.disabled"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('driver.helpLevel')" v-model="chi.assistancelevel" type="text" :value="chi.assistancelevel" :readonly="edit.disabled"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-label position="stacked">{{$t('general.school')}}</ion-label>
                        <ion-select :ok-text="$t('assistant.choose')" :cancel-text="$t('assistant.cancel')" :disabled="edit.disabled" :value="chi.id" v-model="chi.schoolid">
                            <ion-select-option v-for="school of schools" :key="school.id" :value="school.id">{{school.name}}</ion-select-option>
                        </ion-select>
                    </ion-item>
										<ion-item v-if="true == false">
												<ion-toggle :enable-on-off-labels="true" v-model="chi.active" type="text" :value="chi.active" :disabled="edit.disabled" :readonly="edit.disabled">{{$t('parent.isChildActive')}}</ion-toggle>
										</ion-item>
                    <ion-item>
                        <ion-button @click="enableEdit">{{$t('general.edit')}}</ion-button>
                        <ion-button @click="updateChild(chi)">{{$t('general.updateChanges')}}</ion-button>
												<ion-button color="danger" @click="deleteChild(chi)">{{$t('parent.deleteChild')}}</ion-button>
                    </ion-item>
                </ion-list>

                <ion-item-divider></ion-item-divider>
                <ion-header>
                    <ion-label>{{$t('parent.addChild')}}</ion-label>
                </ion-header>
                <ion-item-divider></ion-item-divider>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('general.firstName')" v-model="childForm.firstname" type="text"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('general.lastName')" v-model="childForm.lastname" type="text"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('driver.helpLevel')" v-model="childForm.assistancelevel" type="text"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{$t('general.school')}}</ion-label>
                    <ion-select v-model="childForm.school" :ok-text="$t('assistant.choose')" :cancel-text="$t('assistant.cancel')">
                        <ion-select-option v-for="school of schools" :key="school.id" :value="school.id">{{school.name}}</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item>
                    <ion-button @click="addChild">{{$t('general.send')}}</ion-button>
                    <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-item>
            </ion-content>
            <ion-content class="height80" v-if="accountInfoContent.content == 'AddSubAccount'">
                <ion-label>{{$t('parent.addSubAccount')}}</ion-label>
                <ion-item>
                    <ion-select v-model="addSubForm.role">
                        <ion-select-option value="parent">{{$t('general.roles.parent')}}</ion-select-option>
                        <ion-select-option value="child">{{$t('general.roles.child')}}</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('general.username')" v-model="addSubForm.username" type="text"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('general.password')" v-model="addSubForm.password" type="password"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('general.firstName')" v-model="addSubForm.firstname" type="text"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('general.lastName')" v-model="addSubForm.lastname" type="text"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('general.email')" v-model="addSubForm.email" type="email"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('general.phone')" v-model="addSubForm.phone" type="tel"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-button @click="addSubAcc">Luo alitili</ion-button>
                    <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-item>

            </ion-content>
            <ion-content class="height80" v-if="accountInfoContent.content == 'ManageSubAccount'">
                <ion-label>{{$t('parent.subAccountManagement')}}</ion-label>
                <ion-list v-for="sub of subAccounts" :key="sub.id">
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.username')" type="text" :value="sub.username" readonly></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.firstName')" type="text" :value="sub.firstname" readonly></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.lastName')" type="text" :value="sub.lastname" readonly></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.email')" type="email" :value="sub.email" readonly></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-input label-placement="stacked" :label="$t('general.phone')" type="number" :value="sub.phone" readonly></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-button @click="removeSubAcc(sub)">{{$t('general.delete')}}</ion-button>
                    </ion-item>

                </ion-list>
                <ion-item>
                    <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-item>
            </ion-content>
            <ion-content class="height80" v-if="accountInfoContent.content == 'ChangePassword'">
                <ion-label>{{$t('general.changePassword')}}</ion-label>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('general.currentPassword')" v-model="passChangeForm.pass_change_form_current" type="password"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('general.newPassword')" v-model="passChangeForm.pass_change_form_new1" type="password"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('general.newPasswordAgain')" v-model="passChangeForm.pass_change_form_new2" type="password"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-button @click="changePassword">{{$t('general.changePassword')}}</ion-button>
                    <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-item>

            </ion-content>
            <ion-content class="height80" v-if="accountInfoContent.content == 'DeleteAccount'">

                <ion-label class="ion-text-wrap">{{ $t('parent.deleteAccountInfo') }}</ion-label>
                <ion-item>
                    <ion-input label-placement="stacked" :label="$t('general.currentPassword')" v-model="deleteMyAccountForm.password" type="password"></ion-input>
                </ion-item>
                <ion-item>
                <ion-button @click="deleteMyAccount()">{{ $t('parent.deleteAccount') }}</ion-button>
            </ion-item>


            </ion-content>
        </ion-modal>
        <ion-modal trigger="bSendMessage">
            <ion-header>
                <ion-toolbar>
                    <ion-title>{{$t("parent.sendMessage")}}</ion-title>
                    <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ion-item>
                    <ion-label>{{ $t('parent.sendMessageInfo') }}</ion-label>
                </ion-item>
                <ion-item>

                    <ion-textarea :label="$t('parent.message')" v-model="message" :auto-grow="true"></ion-textarea>
                </ion-item>
                <ion-item>
                    <ion-button @click="sendMessage(message)"><ion-label>{{$t('info.submit')}}</ion-label></ion-button>
                </ion-item>

            </ion-content>
        </ion-modal>
        <ion-modal class="kuittaus" trigger="bPoissaolov2">
            <ion-header>
                <ion-toolbar>
                    <ion-title>{{$t("parent.absentForm")}}</ion-title>
                    <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-toolbar>
            </ion-header>
            <ion-content class="height80">
                <ion-item>
                    <ion-label class="ion-text-wrap">{{$t('parent.absentGuide')}}</ion-label>
                </ion-item>
                <ion-accordion-group ref="chiAcc" value="children">
                    <ion-accordion v-for="chi in child" :key="chi.id" :ref="`child${chi.id}`" :value="chi.id">
                        <ion-item slot="header">
                        <ion-label>{{chi.firstname + ' ' + chi.lastname}}</ion-label>
                    </ion-item>
                    <ion-item slot="content">
                <ion-accordion-group style="width:100%" ref="weekAcc">
                    <ion-accordion v-for="week in weekForm" :key="week.week" :ref="`week${week.week}`" style="width:100%">
                        <ion-item v-if="chi.id == week.childid" slot="header">
                        <ion-label v-if="week.days.find((m:any) => m.morning == true || m.afternoon == true)" :color="`warning`"> {{$t('general.week')+' '+week.week}}</ion-label>
                        <ion-label v-else> {{$t('general.week')+' '+week.week}}</ion-label>
                    </ion-item>
                    <ion-item slot="content">
                        <ion-grid>
                            <ion-row>

                                <ion-col class="grid-content" ><ion-label></ion-label></ion-col>
                                <ion-col class="grid-content" v-for="day, index in week.days" :key="day.date"><ion-label>{{$t('general.days-short.'+(index+1))}} {{moment(day.date).format("D.M.")}}</ion-label></ion-col>


                            </ion-row>
                            <ion-row>

                                <ion-col class="grid-content"><ion-label>{{$t('general.morning')}}</ion-label></ion-col>
                                <ion-col class="grid-content" v-for="day in week.days" :key="day.date"><ion-checkbox aria-label="" v-model="day.morning" :checked="day.morning"></ion-checkbox></ion-col>


                            </ion-row>
                            <ion-row>

                                <ion-col class="grid-content" ><ion-label>{{$t('general.afternoon')}}</ion-label></ion-col>
                                <ion-col class="grid-content" v-for="day in week.days" :key="day.date"><ion-checkbox aria-label="" v-model="day.afternoon" :checked="day.afternoon" ></ion-checkbox></ion-col>


                            </ion-row>
                        </ion-grid>
                    </ion-item>

                    </ion-accordion>

                </ion-accordion-group>
            </ion-item>
            </ion-accordion>
            </ion-accordion-group>

            </ion-content>
            <ion-footer>
                <ion-toolbar>

                        <ion-button @click="updateAbsences">{{$t('parent.update')}}</ion-button>

                </ion-toolbar>
            </ion-footer>
        </ion-modal>
        <feedback v-if="showreview" />
    </ion-page>
</template>
<script lang="ts">
    import { defineComponent, ref } from 'vue'
    import {
        IonCheckbox,
        IonButton,
        IonModal,
        IonContent,
        IonLabel,
        IonHeader,
        IonItem,
        IonList,
        IonToolbar,
        IonPage,
        IonItemDivider,
        IonAccordion,
        IonAccordionGroup,
        IonItemOption,
        IonItemOptions,
        IonItemSliding,
        IonIcon,
        IonSelect,
        IonSelectOption,
        modalController,
        IonInput,
        IonTitle,
        toastController,
        alertController,
        IonGrid,
        IonCol,
        IonRow,
        IonFooter,
        IonTextarea,
				onIonViewDidEnter,
				onIonViewWillLeave,
				isPlatform,
				IonToggle

    } from '@ionic/vue'
    import { arrowForwardOutline, personOutline, checkmarkOutline, calendarOutline, pencilOutline, newspaperOutline, close,sendOutline } from 'ionicons/icons';
    import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
    import axios from 'axios';
    import { useRouter } from "vue-router";
    import moment from 'moment';
    import Feedback from '@/components/Feedback.vue';
		import { ScreenOrientation } from '@capacitor/screen-orientation';
    import LogoutVue from '@/components/Logout.vue';
    import StatusToast from '@/components/StatusToast.vue';
    import InfoComponent from '../components/Info.vue';
    import FeedbackModalComponent from '../components/FeedbackModal.vue'
		import { App } from '@capacitor/app';
    export default defineComponent({
        name: "ParentPage",
        components: {
            IonButton,
            IonCheckbox,
            IonModal,
            IonContent,
            IonLabel,
            IonHeader,
            IonItem,
            IonList,
            IonToolbar,
            IonPage,
            IonItemDivider,
            IonAccordion,
            IonAccordionGroup,
            IonItemOption,
            IonItemOptions,
            IonItemSliding,
            IonIcon,
            IonSelect,
            IonSelectOption,
            IonInput,
            LocaleSwitcher,
            IonTitle,
            LogoutVue,
            IonCol,
            IonGrid,
            IonRow,
            IonFooter,
            InfoComponent,
            IonTextarea,
            Feedback,
            FeedbackModalComponent,
						IonToggle
        },
        setup() {
            const router = useRouter();
						onIonViewDidEnter(() => {
								console.log('Home page did enter', localStorage.getItem("token"));
								const token = localStorage.getItem("token");
								const role = localStorage.getItem("role");
								if ((token  == null || token == undefined || token == "") && (role !== "parent" || role === undefined ||role === null)) {
									console.log()
									router.push("/home");
								}
						});

            const accordionGroup = ref();
            const logAccordionValue = () => {
                if (accordionGroup.value) {
                    //console.log(accordionGroup.value.$el.value);
                }
            }
            const closeAccordion = () => {
                if (accordionGroup.value) {
                    accordionGroup.value.$el.value = undefined;
                }
            }
            return {
                accordionGroup,
                logAccordionValue,
                closeAccordion,
                arrowForwardOutline,
                modalController,
                personOutline,
                checkmarkOutline,
                calendarOutline,
                pencilOutline,
                newspaperOutline,
                close,
                moment,
                sendOutline,


            }
        },
        data() {
            return {
                events: [
                ],
                addresses: [

                ],
                pastRoutes:[

                ],
                newAddressForm: {
                    "id": "",
                    "street": "",
                    "city": "",
                    "zipcode": "",
                    "message": ""
                },
                accountInfoContent:
                {
                    content: "MyAccount"
                },
                accountInfo: {
                    id: 1,
                    username: null,
                    firstname: null,
                    lastname: null,
                    addressdefault: null,
                    email: null,
                    phone: null,
                    mainaccountid: null
                },
                child: [

                ],
                missingDays: {

                    "startDay": this.toIsoString(moment()),
                    "endDay": this.toIsoString(moment()),
                    "childids": []
                },
                edit: {
                    disabled: true,
                    dt: true
                },
                subAccounts: [

                ],
                schools: [],
                childForm: {
                    firstname: "",
                    lastname: "",
                    school: 0,
                    assistancelevel: "",
										active: ""
                },
                addSubForm: {
                    username: "",
                    firstname: "",
                    lastname: "",
                    addressdefault: "",
                    email: "",
                    phone: "",
                    password: "",
                    role: ""

                },
                childUpdateForm: {
                    firstname: "",
                    lastname: "",
                    school: 0,
                    assistancelevel: ""
                },
                passChangeForm: {
                    pass_change_form_current: "",
                    pass_change_form_new1: "",
                    pass_change_form_new2: ""
                },
                childReceipts: [
                    {
                        date: "",
                        name: "",
                        time: ""
                    }
                ],
                myRoutes: [],
                calendarValues: {
                    start: moment().toISOString()
                },
                weekForm:[

                ],
                deleteMyAccountForm: {
                    password:""
                },
                selectedChild: [],
                absentForm: [],
                message: "",
								showreview: false





            };
        },
        methods: {
            checkIfFeedback(){
                axios.post(this.$api_add + localStorage.getItem("org") + 'appreview/getlatestbyuser',{"userid":localStorage.getItem("id")}).then(res =>{
								if (res.data.data == 'fail') {
                  this.showreview = true;
                }
								else{
									this.showreview = false;
								}

            })

            },
            fillAbsenceForm(){
                this.weekForm = [];
                let objA = {}
								let hlp:any[] = [];

                this.child.forEach(chi =>{
                    if(moment().isoWeek() == 22 || moment().isoWeek() == 23){
                        objA = [{
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().isoWeek(),
                        days:[{
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-6').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }
                    ],
                    },
                    {
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().add(7, 'days').year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(7, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-6').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }
                    ],
                    },
                    {
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().add(14, 'days').year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(14, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }],
                    },
                    {
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().add(21, 'days').year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(21, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        }],

                    }]
                    }
                    else if(moment().add(7, 'days').isoWeek() == 22 || moment().add(7, 'days').isoWeek() == 23){
                        objA = [{
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().isoWeek(),
                        days:[{
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }

                    ],
                    },
                    {
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().add(7, 'days').year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(7, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-6').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }
                    ],
                    },
                    {
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().add(14, 'days').year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(14, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-6').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }

                    ],
                    },
                    {
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().add(21, 'days').year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(21, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        }],
                    }]
                    }
                    else if(moment().add(14, 'days').isoWeek() == 22 || moment().add(14, 'days').isoWeek() == 23){
                        objA = [{
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().isoWeek(),
                        days:[{
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }

                    ],
                    },
                    {
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().add(7, 'days').year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(7, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }

                    ],
                    },
                    {
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().add(14, 'days').year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(14, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-6').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }

                    ],
                    },
                    {
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().add(21, 'days').year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(21, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-6').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }
                    ],
                    }]
                    }
                    else if(moment().add(21, 'days').isoWeek() == 22 || moment().add(21, 'days').isoWeek() == 23){
                        objA = [{
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().isoWeek(),
                        days:[{
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }

                    ],
                    },
                    {
                        id: null,
                        year: moment().add(7, 'days').year(),
                        lastmodifier: localStorage.getItem("id"),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(7, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }

                    ],
                    },
                    {
                        id: null,
                        year: moment().add(14, 'days').year(),
                        childid: chi.id,
                        lastmodifier: localStorage.getItem("id"),
                        parentid: localStorage.getItem("id"),
                        week: moment().add(14, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },


                    ],
                    },
                    {
                        id: null,
                        year: moment().add(21, 'days').year(),
                        childid: chi.id,
                        lastmodifier: localStorage.getItem("id"),
                        parentid: localStorage.getItem("id"),
                        week: moment().add(21, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-6').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }
                    ],
                    }]
                    }
                    else {
                        objA = [{
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().isoWeek(),
                        days:[{
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().year() +'-W'+ moment(moment().isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }

                    ],
                    },
                    {
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().add(7, 'days').year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(7, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(7, 'days').year() +'-W'+ moment(moment().add(7, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        }

                    ],
                    },
                    {
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().add(14, 'days').year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(14, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(14, 'days').year() +'-W'+ moment(moment().add(14, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },


                    ],
                    },
                    {
                        id: null,
                        lastmodifier: localStorage.getItem("id"),
                        year: moment().add(21, 'days').year(),
                        childid: chi.id,
                        parentid: localStorage.getItem("id"),
                        week: moment().add(21, 'days').isoWeek(),
                        days:[{
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-1').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-2').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false
                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-3').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-4').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },
                        {
                            date: moment(moment().add(21, 'days').year() +'-W'+ moment(moment().add(21, 'days').isoWeek(), 'WW').format('WW')+'-5').format("yyyy-MM-DD"),
                            morning: false,
                            afternoon: false

                        },

                    ],
                    }]
                    }
                    hlp.push(
                        objA
                        );
                })
								this.weekForm = hlp.flat();
                console.log(this.weekForm);
            },
						resetData(){
							return {
								events: [],
								addresses: [],
                newAddressForm: {
										"id": "",
										"street": "",
										"city": "",
										"zipcode": "",
										"message": ""
                  },
                  accountInfoContent:
                  {
                    content: "MyAccount"
                  },
                  accountInfo: {
                    id: 1,
                    username: null,
                    firstname: null,
                    lastname: null,
                    addressdefault: null,
                    email: null,
                    phone: null,
                    mainaccountid: null
                  },
                  child: [],
                  missingDays: {
                    "startDay": this.toIsoString(moment()),
                    "endDay": this.toIsoString(moment()),
                    "childids": []
                  },
                  edit: {
                    disabled: true,
                    dt: true
                  },
                  subAccounts: [],
                  schools: [],
                  childForm: {
                    firstname: "",
                    lastname: "",
                    school: 0,
                    assistancelevel: ""
                  },
                  addSubForm: {
                    username: "",
                    firstname: "",
                    lastname: "",
                    addressdefault: "",
                    email: "",
                    phone: "",
                    password: "",
                    role: ""

                  },
                  childUpdateForm: {
                    firstname: "",
                    lastname: "",
                    school: 0,
                    assistancelevel: ""
                  },
                  passChangeForm: {
                      pass_change_form_current: "",
                      pass_change_form_new1: "",
                      pass_change_form_new2: ""
                    },
                    childReceipts: [
                    {
                      date: "",
                      name: "",
                      time: ""
                    }
                  ],
                  myRoutes: [],
                  calendarValues: {
                    start: moment().toISOString()
                  },
                  weekForm:[],
                  selectedChild: [],
                  absentForm: [],
                  message: "",
							};
						},
            updateAbsencesForm(){
                let temp:any = [];
                axios.post(this.$api_add + localStorage.getItem("org") + 'exceptions/get_my_forms',{"parentid": localStorage.getItem("id")})
                .then(res =>{
                    temp = res.data.data;
                    this.weekForm.forEach((week:any, index:number) =>{
                    temp.forEach((t:any) =>{
                        if(t.year.toString() === week.year.toString() && t.childid.toString() === week.childid.toString() && t.week.toString() === week.week.toString()){
                            this.weekForm[index].days = JSON.parse(t.days);
                        }
                    })
                })
                })

            },
            updateAbsences(){
                let values = {
                    message: "",
                    status: false
                };
								//let hlp = this.weekForm.flat();
            axios.post(this.$api_add + localStorage.getItem("org") + 'exceptions/parseform',{"weekForm":this.weekForm}).then(res =>{
                if (res.data.result == 'ok') {
                                values.message = this.$t("parent.absentRequestReceived");
                                values.status = true;
                                StatusToast.methods.openToast(values);
                            }
                            else {
                                values.message = this.$t("parent.absentRequestFailed");
                                values.status = false;
                                StatusToast.methods.openToast(values);
                            }
            })


            },

            async openToast(msg: string, col:string) {
                const toast = toastController
                    .create({
                        message: msg,
                        duration: 3000,
                        color: col
                    });
                (await toast).present();
            },
            compareWith(child1: object, child2: object) {
                return child1 == child2;
            },
            sendMessage(msg:string){
                axios.post(this.$api_add + localStorage.getItem("org") + 'messages/create', {'userid': localStorage.getItem("id"), 'message': msg}).then(res =>{
                    if(res.data.result == "ok"){
                        this.openToast(this.$t('parent.sendMessageSuccess'), 'success')
                    }else{
                        this.openToast(this.$t('parent.sendMessageFailure'), 'danger');
                    }
                })
            },
            storeSelect(event: any) {
                const values = event.detail.value;
                if (values != undefined && typeof (values) == typeof ([]) && values.length > 0) {
                    //console.log("enable datetime edit");
                    this.enableDateTimeEdit(false);
                }
                else {
                    //console.log("disable datetime edit");
                    this.enableDateTimeEdit(true);
                }
            },
            sendMissingDay() {

                let c = this.missingDays.childids;

                for (let chi in c) {
                    axios.post(this.$api_add + localStorage.getItem("org") +'exceptions/my_child_create', { "parentid": localStorage.getItem("id"), "childid": c[chi], "datefrom": this.missingDays.startDay, "dateto": this.missingDays.endDay })
                        .then(res => {
                            if (res.data.result == 'ok') {
                                this.openToast(this.$t("parent.absentRequestReceived"),'success');
                                this.modalController.dismiss();
                                this.getEvents();
                            }
														else if(res.data.result == 'alreadyexists'){
															this.openToast(this.$t("parent.absentRequestExists"),'danger');
														}
                            else {
                                this.openToast(this.$t("parent.absentRequestFailed"),'danger');
                            }
                        })
                }

            },
            toIsoString(date: any) {
								return moment.utc(date).toISOString();
                //let tzo = -date.getTimezoneOffset(),
                //    dif = tzo >= 0 ? '+' : '-',
                //    pad = function (num: any) {
                //        return (num < 10 ? '0' : '') + num;
                //    };

                //return date.getFullYear() +
                //    '-' + pad(date.getMonth() + 1) +
                //    '-' + pad(date.getDate()) +
                //    'T' + pad(date.getHours()) +
                //    ':' + pad(date.getMinutes()) +
                //    ':' + pad(date.getSeconds()) +
                //    dif + pad(Math.floor(Math.abs(tzo) / 60)) +
                //    ':' + pad(Math.abs(tzo) % 60);
            },
            switchContent(content: string) {
                this.accountInfoContent.content = content;
            },
            enableEdit() {
                if (this.edit.disabled === false) {
                    this.edit.disabled = true;
                } else {
                    this.edit.disabled = false;
                }
            },
						accountDeletedLogOut(){
								const userid = localStorage.getItem('id');
								const pushtoken = localStorage.getItem('pushtoken');
								axios.post(this.$api_add + localStorage.getItem("org") + 'users/removepushtoken', {"id": userid, "pushtoken": pushtoken}).then(async(u)=>{
									//console.log(u);
								}).catch(()=>{
									//console.log("e", e);
								}).finally(()=>{
									if(!isPlatform('desktop')){
										const unlock = async () => {
											await ScreenOrientation.unlock();
										}
										unlock();
									}
									localStorage.removeItem("role");
									localStorage.removeItem("id");
									localStorage.removeItem("token");
									localStorage.removeItem("org");
									localStorage.removeItem("pushtoken");
									this.$router.push("/")
							});
						},
            enableDateTimeEdit(value: any) {
                this.edit.dt = value;
            },
            sendEditAccountEdit() {
                axios.post(this.$api_add + localStorage.getItem("org") +'users/update_my_account', {
                    "firstname": this.accountInfo.firstname,
                    "lastname": this.accountInfo.lastname,
                    "phone": this.accountInfo.phone,
                    "email": this.accountInfo.email,
                    "id": localStorage.getItem("id")
                })
                    .then(res => {
                        if (res.data.result == "ok") {
                            this.enableEdit();
                            this.getMyAccount();
                            this.modalController.dismiss();
                        }
                    });
            },
            updateLocale(locale: string) {
                this.$root.$i18n.locale = locale;
            },
            getMyAccount() {
                axios.post(this.$api_add + localStorage.getItem("org") +'users/getmyaccount', { "id": localStorage.getItem("id") })
                    .then(res => {
                        this.accountInfo = res.data.data;
                        this.addresses = [];
                        this.addresses.push({
                            "id": 1,
                            "address": res.data.data.addressdefault
                        });
                        if (res.data.data.address1 != undefined && res.data.data.address1.length > 3) {
                            this.addresses.push({
                                "id": 2,
                                "address": res.data.data.address1
                            });
                        }
                        if (res.data.data.address2 != undefined && res.data.data.address2.length > 3) {
                            this.addresses.push({
                                "id": 3,
                                "address": res.data.data.address2
                            });
                        }
                        if (res.data.data.address3 != undefined && res.data.data.address3.length > 3) {
                            this.addresses.push({
                                "id": 4,
                                "address": res.data.data.address3
                            });
                        }
                        this.getChild();
                    }).catch((err)=>{
											console.log("getMyAccount Error ", err)
										})
            },
            sendAddressChangeRequest() {
                let type = 0;
                if (this.newAddressForm.id == "") {
                    type = 0;
                }
                else {
                    type = 1;
                }
                let old = {
                    "id": "",
                    "address": ""
                };
                if (this.newAddressForm.id != "") {
                    old = {
                        "id": this.newAddressForm.id,
                        "address": this.addresses[parseInt(this.newAddressForm.id) - 1].address
                    };
                }
                let values = {
                    "id": this.newAddressForm.id,
                    "address": this.newAddressForm.street + ", " + this.newAddressForm.zipcode + ", " + this.newAddressForm.city
                };

                axios.post(this.$api_add + localStorage.getItem("org") +'requests/create_address_change_request', { "userid": localStorage.getItem("id"), "type": type, "oldvalues": old, "values": values, "message": this.newAddressForm.message })
                    .then(res => {
                        if (res.data.result == "ok") {
                            this.resetNewAddressForm();
                            this.openToast(this.$t("parent.request_sent"),'success');
                            this.modalController.dismiss();
                        }
                        else {
                            this.openToast(this.$t("parent.request_failed"),'danger');
                        }

                    })

            },
            resetNewAddressForm() {
                this.newAddressForm.id = "";
                this.newAddressForm.city = "";
                this.newAddressForm.street = "";
                this.newAddressForm.zipcode = "";
                this.newAddressForm.message = "";
            },
            getSubAccounts() {
                axios.post(this.$api_add + localStorage.getItem("org") +'users/get_my_subaccounts', { "mainaccountid": localStorage.getItem("id") })
                    .then(res => {
                        this.subAccounts = res.data.data;
                    })
            },
            changePassword() {
                if (this.passChangeForm.pass_change_form_new1 == this.passChangeForm.pass_change_form_new2) {
                    if (this.passChangeForm.pass_change_form_new1.length > 3) {
                        axios.post(this.$api_add + localStorage.getItem("org") +'users/update_my_password', {
                            "password": this.passChangeForm.pass_change_form_current,
                            "newpassword": this.passChangeForm.pass_change_form_new1,
                            "id": localStorage.getItem("id")
                        })
                            .then(res => {
                                if (res.data.result == "ok") {
                                    this.passChangeForm.pass_change_form_current = "";
                                    this.passChangeForm.pass_change_form_new1 = "";
                                    this.passChangeForm.pass_change_form_new2 = "";
                                    this.openToast(this.$t("general.passwordChangeOk"),'success');
                                    this.modalController.dismiss();
                                }
                                else {
                                    this.openToast(this.$t("general.passwordChangeFail"),'danger');
                                }
                            });
                    }
                    else {
                        this.openToast(this.$t("general.passwordTooShort"),'danger');
                    }


                }
                else {
                    this.openToast(this.$t("general.passwordNoMatch"),'danger');
                }
            },
            removeSubAcc(subAccount: any) {
                axios.post(this.$api_add + localStorage.getItem("org") +'users/delete_my_sub_user', { "id": subAccount.id, "mainaccountid": localStorage.getItem("id") })
                    .then(() => {
                        this.getSubAccounts();

                    })
            },
            getChild() {
                if (this.accountInfo.mainaccountid == null) {
                    axios.post(this.$api_add + localStorage.getItem("org") +'child/getbyparent', { "parentid": localStorage.getItem("id") })
                        .then(res => {
                            this.child = res.data.data;
                            this.fillAbsenceForm();
                        })
                }
                else {
                    axios.post(this.$api_add + localStorage.getItem("org") +'child/getbyparent', { "parentid": this.accountInfo.mainaccountid })
                        .then(res => {
                            this.child = res.data.data;
                            this.fillAbsenceForm();
                        })
                }


            },
            async addSubAcc() {

                axios.post(this.$api_add + localStorage.getItem("org") +'users/create_my_sub_account', {
                    "firstname": this.addSubForm.firstname,
                    "lastname": this.addSubForm.lastname,
                    "email": this.addSubForm.email,
                    "phone": this.addSubForm.phone,
                    "username": this.addSubForm.username,
                    "password": this.addSubForm.password,
                    "role": this.addSubForm.role,
                    "mainaccountid": localStorage.getItem("id")
                })
                    .then(res => {
                        if (res.data.result == "ok") {
                            this.addSubForm.firstname = "";
                            this.addSubForm.lastname = "";
                            this.addSubForm.phone = "";
                            this.addSubForm.email = "";
                            this.addSubForm.username = "";
                            this.addSubForm.password = "";
                        }
                    });
            },
            addChild() {
                axios.post(this.$api_add + localStorage.getItem("org") + 'child/create_my_child', {
                    "firstname": this.childForm.firstname,
                    "lastname": this.childForm.lastname,
                    "schoolid": this.childForm.school,
                    "assistancelevel": this.childForm.assistancelevel,
                    "parentid": localStorage.getItem("id")
                })
                    .then(res => {
                        if (res.data.result == "ok") {
                            this.openToast(this.$t("parent.request_sent"),'success');
                            this.getChild();
                            this.childForm.firstname = "";
                            this.childForm.lastname = "";
                            this.childForm.assistancelevel = "";
                            this.childForm.school = 0;
                        }
												else{
                            this.openToast(this.$t("parent.request_failed"),'danger');
												}
                    });
            },
            updateChild(child: any) {
                axios.post(this.$api_add + localStorage.getItem("org") + 'child/update_my_child', {
                    "id": child.id,
                    "firstname": child.firstname,
                    "lastname": child.lastname,
                    "assistancelevel": child.assistancelevel,
                    "schoolid": child.schoolid,
                    "parentid": localStorage.getItem("id"),
										"active": child.active
                })
                    .then(res => {
                        if (res.data.result == "ok") {
													this.modalController.dismiss();
                          this.openToast(this.$t("parent.updateSuccess"),'success');
													this.edit.disabled = true;
                        }
                        else {
                            this.openToast(this.$t("parent.updateFailure"),'danger');

                        }


                    })
            },
						async deleteChild(child:any){
							const childIdx = this.child.findIndex((i)=>{
								return i.id == child.id;
							});

							const childSchool = this.schools.find((s)=>{
								return s.id == child.schoolid;
							});

							const msg = String(this.$t('general.name')+ ": " + child.firstname + " " + child.lastname + "<br>" + this.$t('general.school') + ": " + childSchool.name)
							const successMsg = this.$t("parent.childDeletedSuccess");
							const failureMsg = this.$t("parent.childDeletedFailure");
							const alert = await alertController.create({
									header: this.$t('parent.confirmDeleteChild'),
									message: msg,
									buttons: [
											{
													text: this.$t('general.cancel'),
													role: 'cancel',
													handler: () => {console.log("cancel") }
											},
											{
													text: this.$t("parent.deleteChild"),
													role: 'confirm',
													handler: () => { axios.post(this.$api_add + localStorage.getItem("org") + 'child/delete', {
															"id": child.id
														}).then(res => {
															if (res.data.result == "ok") {
																this.child.splice(childIdx, 1);
																this.openToast(successMsg,'success');
															}
															else {
																this.openToast(failureMsg,'danger');
															}
													});
												}
											}
									]
							});
							await alert.present();

							await alert.onDidDismiss();
						},
            getTransports() {
                axios.post(this.$api_add + localStorage.getItem("org") + 'school/getall', {})
                    .then(res => {
                        if (res.data.result == 'ok') {
                            this.schools = res.data.data;
                        }

                    })
            },
            getSchools() {
                axios.post(this.$api_add + localStorage.getItem("org") + 'school/getall', {})
                    .then(res => {
                        if (res.data.result == 'ok') {
                            this.schools = res.data.data;
                        }


                    }).catch(function (error) {
                        if (error.response.status == 401) {
                            location.reload();
                        }
                    });
            },
            getEvents() {
                let pid = localStorage.getItem("id");
                if (this.accountInfo != null && this.accountInfo.mainaccountid != null ) {
                    pid = this.accountInfo.mainaccountid;
                }
                axios.post(this.$api_add + localStorage.getItem("org") + 'logs/get_my_children_logs', { "parentid": pid, "userid": localStorage.getItem("id") })
                    .then(res => {
                        if (res.data.result == 'ok') {
                            this.events = res.data.data;
                            this.getExceptions();
                        }

                    })
            },
            async deleteMyAccount(){
                let defaultText = 'parent.confirmHelpText';

      const alert = await alertController.create({
        header: this.$t("parent.areYouSure"),
        message: this.$t(defaultText),
        buttons: [
          {
            text: this.$t("driver.confirmYes"),
            role: 'yes',
            cssClass: 'primary'
          },
          {
            text: this.$t("driver.confirmNo"),
            role: 'no',
            cssClass: 'danger'
          }
        ]
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      if (role == 'yes') {

                axios.post(this.$api_add + localStorage.getItem("org") + 'users/delete_my_account', { "password": this.deleteMyAccountForm.password, "id": localStorage.getItem("id") })
                    .then(res => {
                        if (res.data.result == 'ok') {
                            this.openToast(this.$t("parent.updateSuccess"),'success');
														this.modalController.dismiss();
                            this.accountDeletedLogOut();
                        }

                    })
                }
            },
            getExceptions() {
                let pid = localStorage.getItem("id");
                if (this.accountInfo.mainaccountid != null) {
                    pid = this.accountInfo.mainaccountid;
                }
                axios.post(this.$api_add + localStorage.getItem("org") + 'exceptions/get_my_child_exceptions', { "parentid": pid, "userid": localStorage.getItem("id") })
                    .then(res => {
                        if (res.data.result == 'ok') {
                            if (res.data.data != undefined && res.data.data.length > 0) {
                                let dd = res.data.data;
                                for (let i = 0; i < dd.length; i++) {
                                    let d = {
                                        "firstname": dd[i].firstname,
                                        "timestamp": dd[i].timestamp,
                                        "nameconfirm": dd[i].nameconfirm,
                                        "confirmstatus": 5,
                                        "message": this.parseDateTime(moment.utc(dd[i].datefrom)) + " - " + this.parseDateTime(moment.utc(dd[i].dateto))
                                    }
                                    this.events.push(d);
                                }
                            }
                        }
                    })
            },
            getMyRoutes() {
                moment.utc().format();
                let dayOfWeek = moment().isoWeekday();
                if (dayOfWeek == 6) {
                    dayOfWeek = 0;
                }
                axios.post(this.$api_add + localStorage.getItem("org") + 'route/get_my_day', { "parentid": localStorage.getItem("id"), days: 1 })
                    .then(res => {
                        if (res.data.result == 'ok') {
                            /* let da = res.data.data;
                            da.sort(function (a: any, b: any) {
                                return a.days - b.days || moment.utc(a.arr_time).valueOf() - moment.utc(b.arr_time).valueOf()
                            });
                            this.myRoutes = res.data.data; */
                            res.data.data.forEach((route:any) =>{
                                if(route.days >= dayOfWeek){
                                    this.myRoutes.push(route);
                                }else{
                                    this.pastRoutes.push(route);
                                }
                            })
                            this.myRoutes.sort(function(a,b){
                                return a.days - b.days || Number(a.timewindow.replace(":","")) - Number(b.timewindow.replace(":",""))
                            })
                            this.pastRoutes.sort(function(a,b){
                                return a.days - b.days || Number(a.timewindow.replace(":","")) - Number(b.timewindow.replace(":",""))
                            })
                        }
                    })
            },
            parseDate(days:any) {
                return this.$t("general.days." + days);
            },
            parseTime(da: any) {
                //let d = new Date(da * 1000);
                //let ret = "";
                //if (d.getHours() < 10) {
                //    ret = ret + "0" + d.getHours();
                //}
                //else {
                //    ret = ret + d.getHours();
                //}
                //if (d.getMinutes() < 10) {
                //    ret = ret + ":0" + d.getMinutes();
                //}
                //else {
                //    ret = ret + ":" + d.getMinutes();
                //}
                return moment.utc(da*1000).format('HH:mm');
            },
            parseDateTime(da: any) {

                //let ret = "";
                //if (da.getDate() < 10) {
                //    ret = ret + "0" + da.getDate();
                //}
                //else {
                //    ret = ret + da.getDate();
                //}
                //if (da.getMonth() < 9) {
                //    ret = ret + ".0" + (da.getMonth() + 1).toString();
                //}
                //else {
                //    ret = ret + "." + (da.getMonth() + 1).toString()
                //}
                //ret = ret + "." + da.getFullYear();
                //if (da.getHours() < 10) {
                //    ret = ret + " 0" + da.getHours();
                //}
                //else {
                //    ret = ret + " " + da.getHours();
                //}
                //if (da.getMinutes() < 10) {
                //    ret = ret + ":0" + da.getMinutes();
                //}
                //else {
                //    ret = ret + ":" + da.getMinutes();
                //}
                //if (da.getSeconds() < 10) {
                //    ret = ret + ":0" + da.getSeconds();
                //}
                //else {
                //    ret = ret + ":" + da.getSeconds();
                //}
                return moment.utc(da).format('DD.MM.YYYY HH:mm:ss');
            },
            route_cancel_this_child(id: any,type:any,arr_time:any,routeid:any) {

                //let start = new Date(arr_time*1000);
                //let end = new Date(arr_time*1000);
								let start = moment.utc(arr_time*1000);
								let end = moment.utc(arr_time*1000);
                if (type == 0) {
                    //start.setHours(0);
                    //start.setMinutes(0);
                    //start.setSeconds(0);
										start.set('hour', 0);
										start.set('minute', 0);
										start.set('second', 0)

                    //end.setHours(11);
                    //end.setMinutes(0);
                    //end.setSeconds(0);
										end.set('hour', 11);
										end.set('minute', 0);
										end.set('second', 0)

                }
                else {
                    //start.setHours(11);
                    //start.setMinutes(0);
                    //start.setSeconds(1);
										start.set('hour', 11);
										start.set('minute', 0);
										start.set('second', 1)
										end.set('hour', 23);
										end.set('minute', 59);
										end.set('second', 59)
                    //end.setHours(23);
                    //end.setMinutes(59);
                    //end.setSeconds(59);
                }

                axios.post(this.$api_add + localStorage.getItem("org") + 'exceptions/my_child_cancel', { "parentid": localStorage.getItem("id"), "childid": id, "datefrom": start, "dateto": end })
                    .then(res => {
                        if (res.data.result == 'ok') {
                            axios.post(this.$api_add + localStorage.getItem("org") + 'route/cancel_this_child', { "parentid": localStorage.getItem("id"), "childid": id, "routeid":routeid })
                                .then(res2 => {
                                    if (res2.data.result == 'ok') {
                                        this.openToast(this.$t("parent.absentRequestReceived"),'success');
                                        this.getEvents();
                                        this.getMyRoutes();
                                        this.modalController.dismiss();
                                    }
                                    else {
                                        this.openToast(this.$t("parent.absentRequestFailed"),'danger');
                                    }
                                });

                        }
                        else {
                            this.openToast(this.$t("parent.absentRequestFailed"),'danger');
                        }
                    })
            },
            parent_confirm_this(id: any, type: any) {
                let status = 0;
                if (type == 0) {
                    status = 2;
                }
                else {
                    status = 1;
                }
                axios.post(this.$api_add + localStorage.getItem("org") + 'logs/create_my_child', { "parentid": localStorage.getItem("id"), "childid": id, "status": status, "message": "" })
                    .then(res => {
                        if (res.data.result == 'ok') {
                            this.modalController.dismiss();
                        }
                        else {
                           //
                        }
                    })
            },
            async confirm_cancel(id: any,type: any,arr_time:any,routeid:any) {
                const alert = await alertController.create({
                    header: this.$t('parent.absentForm'),
                    buttons: [
                        {
                            text: this.$t('general.cancel'),
                            role: 'cancel',
                            handler: () => {console.log("cancel") }
                        },
                        {
                            text: 'OK',
                            role: 'confirm',
                            handler: () => { this.route_cancel_this_child(id,type,arr_time,routeid) }
                        }
                    ]
                });
                await alert.present();

                await alert.onDidDismiss();

            },
            async confirm_address_change(id: any, routeid: any) {
                let inp:any = {data:[]};
                let addresses = this.addresses;
                for (let address in addresses) {
                    let obj = {
                        label: addresses[address].address,
                        type: 'radio',
                        value: addresses[address].address
                    };
                    inp.data.push(obj);

                }
                const alert = await alertController.create({
                    header: '',
                    buttons: [
                        {
                            text: this.$t('general.cancel'),
                            role: 'cancel',
                            handler: () => { console.log(""); }
                        },
                        {
                            text: 'OK',
                            role: 'confirm',
                            handler: (data:string) => {  this.sendChangeThisAddress(data,id,routeid); }
                        }
                    ],
                    inputs: inp.data
                });
                await alert.present();
                await alert.onDidDismiss();

            },
            sendChangeThisAddress(values: any,childid: any,routeid:any) {

                axios.post(this.$api_add + localStorage.getItem("org") + 'route/change_address', { "parentid": localStorage.getItem("id"), "childid": childid, "routeid":routeid,"address":values })
                    .then(res => {
                        if (res.data.result == 'ok') {
                            this.modalController.dismiss();
                            this.getMyRoutes();
                        }
                        else {
                            console.log("fail");
                        }
                    })
                this.modalController.dismiss();
            },
            startChange() {
                if (this.missingDays.startDay >= this.missingDays.endDay) {
                    let a = moment(this.missingDays.startDay).add(1,"days");
                    this.missingDays.endDay = a.toISOString();
                }
            },
            endChange() {
                if (this.missingDays.endDay <= this.missingDays.startDay) {
                    let a = moment(this.missingDays.startDay).add(1, "days");
                    this.missingDays.endDay = a.toISOString();
                }
            },
						checkLogin() {
								if (localStorage.getItem("token") != undefined && localStorage.getItem("token") != null) {
										let token = localStorage.getItem("token");
										const config = {
												headers: { Authorization: `Bearer ` + token }
										};
										axios.post(this.$api_add + localStorage.getItem("org") + 'users/checktoken', {}, config).then(result => {
												if (result.data.result === "ok") {
														//do nothing
												} else {
													localStorage.removeItem("role");
													localStorage.removeItem("id");
													localStorage.removeItem("token");
													localStorage.removeItem("pushtoken");
													this.openToast(this.$t("general.loginExpired"), 'warning');
													this.$router.push('/');

												}

										}).catch((err) => {
												//this.openToast(error);
												localStorage.removeItem("role");
												localStorage.removeItem("id");
												localStorage.removeItem("token");
												localStorage.removeItem("pushtoken");
												this.openToast(this.$t("general.loginExpired"), 'warning');
												this.$router.push('/');
										});
								}
						},
        },

        updated() {
            this.$root.$i18n.locale = localStorage.getItem("locale") ?? 'fi';
        },
        mounted() {


						this.$root.$i18n.locale = localStorage.getItem("locale") ?? 'fi';
						this.checkLogin();
						this.getSchools();
						this.getMyAccount();
						this.getSubAccounts();
						this.getEvents();
						this.getMyRoutes();


					onIonViewWillLeave(()=>{
						Object.assign(this.$data, this.resetData());
					});

					onIonViewDidEnter(()=>{
						this.checkIfFeedback();
						this.checkLogin();
					});

					App.addListener('appStateChange', ({ isActive }) => {
						if(isActive){
							this.checkLogin();
						}
					})
        },
    })
</script>
<style scoped>
    #mainmenu {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .scrollable {
        overflow-x: auto;
    }

    .show-text {
        word-wrap: break-word;
        white-space: normal;
    }

    .height80 {
        height: 70%;
    }
    .grid-content {
        display:flex;
        justify-content: center;
        align-items: center;
    }
</style>
