<template>
  <div class="chart-renderer">
		<line-chart :library="{options:{onClick: function(evt, item){console.log(evt, item)}}}" :download="{background: '#fff'}" :data="series" v-if="chartType=='line'"></line-chart>
		<pie-chart :library="{options:{onClick: function(evt, item){console.log(evt, item)}}}" :download="{background: '#fff'}" :data="pie" v-if="chartType=='pie'"></pie-chart>
		<bar-chart :library="{options:{onClick: function(evt, item){console.log(evt, item)}}}" :download="{background: '#fff'}" :data="series" v-if="chartType=='bar'"></bar-chart>
		<column-chart :library="{options:{onClick: function(evt, item){console.log(evt, item)}}}" :download="{background: '#fff'}" :data="series" v-if="chartType=='column'"></column-chart>
		<column-chart :library="{options:{onClick: function(evt, item){console.log(evt, item)}}}" :download="{background: '#fff'}" :stacked="true" :data="series" v-if="chartType=='stacked_column'"></column-chart>
		<area-chart :download="true" :data="series" v-if="chartType=='area'"></area-chart>
	</div>
</template>

<script>
export default {
  name: "ChartRenderer",
  props: {
    resultSet: {
      type: Object,
      required: true,
    },
		chartType:{
			type: String,
			required: true
		}
  },
  computed: {
    series() {
      const seriesNames = this.resultSet.seriesNames();
      const pivot = this.resultSet.chartPivot();
      const series = [];

      seriesNames.forEach((e) => {
        const data = pivot.map((p) => [p.x, p[e.key]]);
        series.push({ name: e.key, data });
      });

      return series;
    },
		bar(){
			const pivot = this.resultSet.tablePivot();
			return pivot;
		},
		pie(){
			const seriesNames = this.resultSet.seriesNames();
      const pivot = this.resultSet.chartPivot();

      let series = [];
      seriesNames.forEach((e) => {
        const data = pivot.map((p) =>{
					return [e.key, p[e.key]]
				});
        series = series.concat(data);
      });
      return series;
		}
  },
	methods:{

	}
};
</script>
