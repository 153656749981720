<template>
    <img v-on="listeners" :src="currentImage" @click="updateActiveReaction" />
</template>

<script>
import reactionMixin from './reaction-mixin.js'
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'VueReaction',
  mixins: [reactionMixin],
  
  props: {
    value: {
      default: '',
      type: [String, Number]
    },
    reaction: {
      type: String
    },
    selectedImage: {
      type: String
    },
    hoverImage: {
      type: String
    },
    image: {
      type: String
    }
  },
  computed: {
    currentImage () {
      return this.inactive || this.disabled
        ? this.image
        : this.hoverx
          ? this.hoverImage
          : this.value === this.reaction
            ? this.selectedImage
            : this.image
    }
  }
})
</script>

<style>

</style>