import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { createI18n } from 'vue-i18n';
import { IonicVue, isPlatform } from '@ionic/vue';
import fi from './locales/fi.json';
import en from './locales/en.json';

import VueChartkick from 'vue-chartkick';
import { apolloClient } from './graphql/client.js';
import { createApolloProvider } from '@vue/apollo-option'
import 'chartkick/chart.js';
import {plugin, defaultConfig} from "@formkit/vue";
import {en as eng, fi as fin} from "@formkit/i18n";
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
const messages = {
  fi: fi,
  en: en
}

const i18n = createI18n({
  locale: 'fi',
  messages,
})

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});
const app = createApp(App)
  .use(IonicVue,{
		innerHTMLTemplatesEnabled: true
	})
  .use(router)
	.use(VueChartkick)
	.use(apolloProvider)
  .use(plugin, defaultConfig({
    locales:{eng, fin},
    locale: 'fi'
  }))
  .use(i18n);



//declaring types
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$api_add: string;
		$opt_add: string;
    $screenOrientation: any;
		$isdesktop: boolean;
    $configtenant: string;
    $configkey: string;
	}
}

let api_add = null;
let opt_add = null;
let config_tenant = process.env.VUE_APP_CONFIG_TENANT;
let config_key = process.env.VUE_APP_CONFIG_KEY;

if(process.env.NODE_ENV == 'development'){
	api_add = process.env.VUE_APP_DEV_API_ADD;
	opt_add = process.env.VUE_APP_DEV_OPT_ADD;
}
else{
	api_add = process.env.VUE_APP_PROD_API_ADD;
	opt_add = process.env.VUE_APP_PROD_OPT_ADD;
}

const cubeadd = process.env.VUE_APP_CUBE_ADD;

const is_desktop = isPlatform('desktop');

app.config.globalProperties.$api_add = api_add;
app.config.globalProperties.$opt_add = opt_add;
app.config.globalProperties.$cube_add = cubeadd;
app.config.globalProperties.$isdesktop = is_desktop;
app.config.globalProperties.$configtenant = config_tenant;
app.config.globalProperties.$configkey = config_key;

app.provide('$api_add', api_add);
app.provide('$opt_add', opt_add);
app.provide('$cube_add', cubeadd);
app.provide('$isdesktop', is_desktop);
app.provide('$configtenant', config_tenant);
app.provide('$configkey', config_key);


app.use(IonicVue, { swipeBackEnabled: false });
router.isReady().then(() => {
  app.mount('#app');
});
