<template>
    <div class="locale-switcher">
    <ion-icon v-if="this.locale === 'fi'" :src="require('@/svg/fi.svg')" class="icon" @click="openSelect"></ion-icon>
    <ion-icon v-if="this.locale === 'en'" :src="require('@/svg/gb.svg')" class="icon" @click="openSelect"></ion-icon>
    <ion-select class="selekti" ref="languageSelect" v-model="locale" @IonChange="changeLocale">
    <ion-select-option class="language-select" value="fi">Suomi</ion-select-option>
    <ion-select-option class="language-select" value="en">English</ion-select-option>
    </ion-select>
    </div>
</template>

<script>
import {
    IonIcon,
    IonSelect,
    IonSelectOption,

} from "@ionic/vue";
import axios from 'axios';


export default{
    name: "LocaleSwitcher",
    components:{
        IonIcon,
        IonSelect,
        IonSelectOption,


    },

    props:{
        size: String
    },

    methods:{
    changeLocale(locale){
        localStorage.setItem("locale", locale.detail.value);
        this.$emit('updateLocale', locale.detail.value);
        this.locale = locale.detail.value;
				const userid = localStorage.getItem('id');
				if(userid != null && userid != undefined && userid != ""){
					const load = {
						"id": userid,
						"locale": locale.detail.value
					};
					axios.post(this.$api_add + localStorage.getItem("org") + 'users/update_my_locale', load).then(() => {
						console.log("locale updated");
					})
				}

    },
    openSelect(){
        this.$refs.languageSelect.$el.open();
    }
    },
    data() {
      console.log(localStorage.getItem("locale"));
        return{
            locale: localStorage.getItem("locale") ?? 'fi'
        };
    },

}
</script>
<style scoped>
.icon {
    font-size: v-bind(size);
    border: 1px solid black;
}
.selekti{
    display: none;
}

#alert-input-2-0 div.alert-button-inner.sc-ion-alert-md div.alert-radio-label.sc-ion-alert-md{
    background-image: url(@/svg/fi.svg);
}
#alert-input-2-1 > div > div.alert-radio-label.sc-ion-alert-md{
    content: url(@/svg/gb.svg);
}


</style>
