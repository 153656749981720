<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-title>{{$t('title.title')}}</ion-title>
                <ion-menu-button slot="end" auto-hide="false" @click="openMenu()"></ion-menu-button>
            </ion-toolbar>
        </ion-header>
        <ion-menu side="end" content-id="menu-content" menu-id="menu">

            <ion-header>
                <ion-toolbar>
                    <ion-title>{{$t('assistant.menu')}}</ion-title>
                    <locale-switcher @updateLocale="updateLocale" size="36px" @click="$forceUpdate" slot="end" />
                    <logout-vue @click="cancelTimers()" size="36px" slot="end" />
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ion-list>
                    <ion-item>
                        <ion-button id="bAccountInfo">
                            {{$t('assistant.account')}}
                            <ion-icon :icon="person"></ion-icon>
                        </ion-button>
                    </ion-item>
                    <!--
                    <ion-item>
                        <ion-button id="bClass">{{$t('assistant.class')}} <ion-icon :icon="schoolOutline" /></ion-button>
                    </ion-item>
                    -->
                    <ion-item v-if="currentSchool != 0">

                        <ion-select id="mySelect" interface="action-sheet" multiple="true" :ok-text="$t('assistant.choose')" :cancel-text="$t('assistant.cancel')" @IonChange="chooseStudents" >
                            <div slot="label"><ion-icon :icon="peopleOutline" />{{$t('assistant.chooseStudents')}}</div>
                            <ion-select-option v-for="item of students" :value="item" :key="item.id">{{item.firstname}} {{item.lastname}}.</ion-select-option>
                        </ion-select>
                    </ion-item>
                    <ion-item>
                        <ion-button id="message-button">{{$t('driver.messages')}}</ion-button>
                    </ion-item>

                </ion-list>
            </ion-content>
        </ion-menu>
        <ion-router-outlet id="menu-content"></ion-router-outlet>
        <ion-content>
            <ion-modal @willPresent="getSchools" :is-open="schoolOpenRef">
                <ion-radio-group v-model="currentSchool">
                    <ion-list-header>
                        <ion-label>{{$t("assistant.chooseSchool")}}</ion-label>
                    </ion-list-header>
                    <ion-item v-for="school of schools" :key="school.id" :ref="`school-${school.id}`">
                        <ion-label>{{school.name}}</ion-label>
                        <ion-radio @click="schoolSelected(school.id); setSchoolOpen(false)" :value="school.id"></ion-radio>
                    </ion-item>
                </ion-radio-group>
            </ion-modal>
            <div id="container">
                <ion-content :key="update">
                <ion-list id="list-container" v-if="viewModifier == 'morning'" :inset="true">
                    <ion-item>
                        <ion-button expand="block" size="large" slot="start" :color="morning" @click="toggleView('morning')">{{$t('assistant.morning')}}</ion-button>
                        <ion-button expand="block" size="large" slot="end" :color="afternoon" @click="toggleView('afternoon');getCarNumbers();">{{$t('assistant.afternoon')}}</ion-button>
                    </ion-item>
                    <ion-item v-for="item of chosenStudents" :key="item.id">
                        <ion-label position="fixed" class="ion-text-wrap">{{item.firstname}} {{item.lastname}}.</ion-label>
                        <ion-label class="list-label" position="fixed">{{item.arr_time != undefined ? item.arr_time : $t('assistant.noTimeFound')}}</ion-label>
                        <ion-label class="list-label" position="fixed">{{$t('parent.confirmStatus.' + item.status) }}</ion-label>
                        <ion-button v-if="parseInt(item.status) < 3" color="primary" @click="onConfirm(item.id)">{{$t('parent.confirm')}} <ion-icon :icon="checkmarkOutline" /></ion-button>
                    </ion-item>
                </ion-list>
                <ion-list id="list-container" v-if="viewModifier == 'afternoon'">
                    <ion-item>
                        <ion-button expand="block" size="large" slot="start" :color="morning" @click="toggleView('morning');">{{$t('assistant.morning')}}</ion-button>
                        <ion-button expand="block" size="large" slot="end" :color="afternoon" @click="toggleView('afternoon');getCarNumbers();">{{$t('assistant.afternoon')}}</ion-button>
                    </ion-item>
                    <ion-item v-for="item of chosenStudents" :key="item.id">
                        <ion-label position="fixed">{{item.firstname}} {{item.lastname}}.</ion-label>
                        <ion-label class="list-label" position="fixed">{{item.dep_time != undefined ? item.dep_time : $t('assistant.noTimeFound')}}</ion-label>
                        <ion-label class="list-label" position="fixed" style="white-space:normal;">{{item.ride != "" ? item.ride : $t('assistant.noVehicleFound')}}</ion-label>

                    </ion-item>
                </ion-list>
            </ion-content>
            </div>
        </ion-content>
        <ion-modal trigger="bAccountInfo">
            <ion-header>{{$t('assistant.account')}}</ion-header>
            <ion-item></ion-item>
            <ion-item>
                <ion-label position="stacked">{{$t('general.username')}}</ion-label>
                <ion-input readonly>{{account.username}}</ion-input>
            </ion-item>
            <ion-item>
                <ion-label position="stacked">{{$t('general.email')}}</ion-label>
                <ion-input type="text" v-model="accountForm.email" :value="accountForm.email"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label position="stacked">{{$t('general.phone')}}</ion-label>
                <ion-input v-model="accountForm.phone" :value="accountForm.phone"></ion-input>
            </ion-item>

            <ion-item>
                <ion-button @click="updateAccount">{{$t('assistant.send')}} <ion-icon :icon="sendOutline" /></ion-button>

                <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t('assistant.close')}} <ion-icon :icon="close" /></ion-button>
            </ion-item>
        </ion-modal>
        <ion-modal ref="myModal" trigger="message-button">
            <ion-list style="height:100%;overflow-y:scroll;">
                <ion-item>
                    <ion-button slot="end" @click="newMessage" color="secondary">{{$t('driver.newMessage')}}</ion-button>
                    <ion-button slot="end" @click="modalController.dismiss()" color="danger">{{$t('parent.close')}}</ion-button>
                </ion-item>
                <ion-list-header>
                    <ion-label>{{$t('driver.sender')}}</ion-label>
                    <ion-label>{{$t('driver.message')}}</ion-label>
                </ion-list-header>
                <ion-item v-for="mes of messages" :key="mes.id" :ref="`mes-${mes.id}`">
                    <ion-label>{{mes.name}}</ion-label>
                    <ion-label style="white-space:normal;">{{mes.message}}</ion-label>
                </ion-item>
            </ion-list>
        </ion-modal>
        <ion-modal trigger="bClass">
            <ion-content>
            <ion-header>{{$t('assistant.classHeader')}}</ion-header>
            <ion-item>
            <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t('assistant.close')}} <ion-icon :icon="close" /></ion-button>
        </ion-item>
        <ion-list>
            <ion-list-header>{{$t('assistant.studentList')}}</ion-list-header>
            <ion-item v-for="student in students" :key="'student-'+student.id">
                <ion-label>
                    {{student.firstname}}
                </ion-label>
                <ion-select>
                    <ion-select-option>6A</ion-select-option>
                </ion-select>
                <ion-button>{{$t('assistant.confirm')}}</ion-button>
            </ion-item>
        </ion-list>
    </ion-content>
        </ion-modal>
    </ion-page>
</template>
<script lang="ts">
    import { defineComponent, ref, inject } from 'vue'
    import {
        IonPage, IonContent, IonToolbar, IonHeader, modalController, IonItem, IonRadio, IonRadioGroup,
        IonListHeader, IonSelect, IonSelectOption, IonList, IonModal, IonInput, IonLabel, IonButton, IonTitle,
        IonMenu, menuController, IonIcon, IonRouterOutlet, IonMenuButton, toastController, alertController,
        onIonViewDidEnter
    } from '@ionic/vue';

import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import axios from 'axios';
import {person, close, sendOutline, pencil, checkmarkOutline, peopleOutline, schoolOutline} from "ionicons/icons";
import { useRouter } from 'vue-router';
import LogoutVue from '@/components/Logout.vue';
import moment from 'moment';

export default defineComponent({
    name: 'AssistantPage',
    components:{
    IonPage,
    IonContent,
    IonToolbar,
    IonHeader,
    IonItem,
    IonList,
    IonLabel,
    IonMenu,
    IonButton,
    IonTitle,
    IonRouterOutlet,
    IonIcon,
    IonMenuButton,
    LocaleSwitcher,
    IonModal,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonRadio,
    IonRadioGroup,
    IonListHeader,
    LogoutVue,
    },

    setup() {
        const router = useRouter();
        const apiAdd = inject('$api_add');
    if(localStorage.getItem("token") == "" && localStorage.getItem("role") !== "assistant"){
      router.push("/home");
    localStorage.setItem("school", "");
    if(localStorage.getItem("studentschosen") == "" || localStorage.getItem("studentschosen") == null){
        localStorage.setItem("studentschosen", "{}");
    }

    }
    const routesGot = ref([Object({
      id: Number,
      children: [String],
      assignedvehicle: String,
      name: String,
      chi: Object({
        id: [String],
        names: [String],
        assistance: [String]
      }),
      routelength: Number,
      routetime: Number,
      type: Number,
      timestamp: Date,
      startpoint: Object({
        location_id: String,
        lat: Number,
        lon: Number,
        start_time: String
      }),
      optimizedpoints: Object({
        drawnRoute: [Object({
          coordinates: [Number, Number],
          type: String,
        })],
        points: [Object({
          type: String,
          location_id: String,
          address: Object({
            location_id: String,
            lat: Number,
            lon: Number,
          }),
          arr_time: Number,
          arr_date_time: String,
          end_time: Number,
          end_date_time: String,
          distance: Number,
          driving_time: Number,
          preparation_time: Number,
          waiting_time: Number,
          load_before: [Number],
          load_after: [Number],
          done: Boolean


        })]

      }),
      waypoints: [Object({
        pickup: String,
        picklat: Number,
        picklon: Number,
        delivery: String,
        dellat: Number,
        dellon: Number,
        size: [Number],
        missing: String,
        timewindow: String,
        duration: Number,
        child: String
      })],
      days: String,
      end_point: String
    })]);
        let messagesGot = [Object({
            id: Number,
            name: String,
            message: String,
            timestamp: Date
        })];
        axios.post(apiAdd + localStorage.getItem('org') + 'messages/gettodaysmessages', {}).then(res => {
            res.data.data.forEach((element: any) => {
                messagesGot.push({
                    id: Number(element.id),
                    name: element.name,
                    message: element.message,
                    timestamp: element.timestamp,

                })
            });
        }).catch(e => { console.log(e); });
        messagesGot.shift();
    const schoolOpenRef = ref(false);
    const setSchoolOpen = (state: boolean) => schoolOpenRef.value = state;



        return { person, close, sendOutline, pencil, checkmarkOutline, modalController, peopleOutline, schoolOpenRef, setSchoolOpen, messagesGot, schoolOutline ,routesGot}
    },
    methods: {
        async openToast(msg: string) {
            const toast = toastController
                .create({
                    message: msg,
                    duration: 3000,
                    color: 'danger'
                });
            (await toast).present();
        },
        onConfirm(id: number) {
            this.confirmStudent(id);
        },
        async openMenu(){
            await menuController.open('menu');
        },
        toggleView(mod:string){
            this.viewModifier = mod;
            this.updateColor();
            this.getRoutes();
        },
        chooseStudents(student: any){
            this.chosenStudents = student.detail.value;
            this.updatePresent();
            if (this.updateInterval != null) {
                clearInterval(this.updateInterval);
            }
            this.updateInterval = setInterval(this.updatePresent, 60 * 1000);
            this.getCarNumbers();
            localStorage.setItem("studentschosen", JSON.stringify(this.chosenStudents));
            this.update = !this.update;
        },
        updateLocale(locale: string){
      this.$root.$i18n.locale = locale;
    },

        getSchools(){
            axios.post(this.$api_add + localStorage.getItem("org") +'school/getbyassistant',{})
                .then(res => {
                    if (res.data.result == 'ok') {
                        this.schools = res.data.data;
                        if (this.schools.length == 1) {
                            this.schoolSelected(this.schools[0].id);
                            this.currentSchool = this.schools[0].id;
                            this.address = this.schools[0].address;
                            this.updatePresent();
                        }
                    }


                }).catch(function (error) {
                    if (error.response.status == 401) {
                        location.reload();
                    }
                });

        },
        schoolChosen(schoolid:number){
            localStorage.setItem("school", schoolid.toString());
            this.currentSchool = schoolid;
            this.students = [];
            axios.post(this.$api_add + localStorage.getItem("org") +'child/getbyassistant',{"schoolid": schoolid})
            .then(res => {
                res.data.data.map((r: any) => {
                    r.status = 0;
                    r.ride = "";
                    r.ridestart = "";
                    this.students.push(r);

                });

            })
            .catch(error => console.log(error))
        },

        confirmStudent(childid:number){
            axios.post(this.$api_add + localStorage.getItem("org") +'logs/create',
            {
                "userid": localStorage.getItem("id"),
                "childid": childid,
                "status": "3"
              }).then((res)=>{
                this.updateChild(childid, "3");
              }).finally(()=>{
                this.update = !this.update;
              });

        },
        updateChild(id: number, status: string) {
            //console.log(this.students);
            //console.log(this.chosenStudents);
            for (let i = 0; i < this.students.length; i++) {
                if (this.students[i].id == id) {
                    this.students[i].status = status;
                    break;
                }
            }
            //this.students.
        },
        schoolSelected(school: number) {
            this.schoolChosen(school);
            //localStorage.setItem("school",school.toString());
        },
        checkSchool(){
            if(localStorage.getItem("school") === ""){
                return true;
            }
            else{
                return false;
            }
        },
        cancelTimers() {
            if (this.updateInterval != null) {
                clearInterval(this.updateInterval);
            }
        },
        getAccount(){
            axios.post(this.$api_add + localStorage.getItem("org") +'users/getmyaccount', {id: localStorage.getItem("id")},{})
            .then(response =>{
                this.account.username = response.data.data.username;
                this.accountForm.email = response.data.data.email;
                this.accountForm.phone = response.data.data.phone;
            })
        },
        getClasses(){
            console.log();
        },
        updateClasses(){
            console.log();
        },
        getRoutes(){
            this.routesGot = [];
            moment.utc().format();
            const dayOfWeek = moment().isoWeekday();
            if(this.viewModifier == 'morning'){
                axios.post(this.$api_add + localStorage.getItem("org") +'route/getbyweekday_type',{"days": dayOfWeek, "type": 0})
                .then(res =>{
                    res.data.data.forEach((element: any) => {
                this.routesGot.push({
          id: Number(element.id),
          chi: element.chi,
          children: element.children,
          assignedvehicle: element.assignedvehicle,
          name: element.name,
          routelength: element.routelength,
          routetime: element.routetime,
          type: element.type,
          timestamp: element.timestamp,
          startpoint: JSON.parse(element.startpoint),
          optimizedpoints: JSON.parse(element.optimizedpoints),
          waypoints: JSON.parse(element.waypoints),
          days: element.days,
          endpoint: element.endpoint,
          tempdata: element.tempdata
        });

      });

      //console.log(this.routesGot);
      this.getTime();
                })
            }else if(this.viewModifier == 'afternoon'){
                axios.post(this.$api_add + localStorage.getItem("org") +'route/getbyweekday_type',{"days": dayOfWeek, "type": 1})
                .then(res =>{
                    res.data.data.forEach((element: any) => {
                this.routesGot.push({
          id: Number(element.id),
          chi: element.chi,
          children: element.children,
          assignedvehicle: element.assignedvehicle,
          name: element.name,
          routelength: element.routelength,
          routetime: element.routetime,
          type: element.type,
          timestamp: element.timestamp,
          startpoint: JSON.parse(element.startpoint),
          optimizedpoints: JSON.parse(element.optimizedpoints),
          waypoints: JSON.parse(element.waypoints),
          days: element.days,
          endpoint: element.endpoint,
          tempdata: element.tempdata
        });

      });

      //console.log(this.routesGot)
      this.getTime();
                })
            }


        },
        setClassInfo(){
            localStorage.setItem("koulukyyti_classnumbers", this.classNumbers.toString());
        },
        getTime(){
            let t = "";
            if(this.viewModifier == 'morning'){
                t = "delivery_"
                this.chosenStudents.forEach(student =>{
                this.routesGot.forEach(route =>{
                    route.optimizedpoints.points.forEach((point:any) =>{
                        let check = t+student.id.toString();
                        //console.log(check, point.location_id);

                        if(check == point.location_id){
                            let time = moment.unix(point.arr_time).utc();
                            //console.log(time.format("HH:mm"), student.id);

                            student.arr_time = time.format("HH:mm");

                        }
                    })
                })
            })
            }else if(this.viewModifier == 'afternoon'){
                t = "pickup_"
                this.chosenStudents.forEach(student =>{
                this.routesGot.forEach(route =>{
                    route.optimizedpoints.points.forEach((point:any) =>{
                        let check = t+student.id.toString();
                        if(check == point.location_id){
                            let time = moment.unix(point.arr_time).utc();
                            student.dep_time = time.format("HH:mm");

                        }
                    })
                })
            })
            }


        },
        getClassInfo(){
            let temp = localStorage.getItem("koulukyyti_classnumbers");
            temp.replace('[','').replace(']', '');
            let array = temp.split(',');
            array.forEach(s =>{
                this.classNumbers.push(parseInt(s));
            })

        },
        updateAccount() {
            axios.post(this.$api_add + localStorage.getItem("org") + 'users/update_my_account', { id: localStorage.getItem("id"), email: this.accountForm.email, phone: this.accountForm.phone }, {})
                .then(response => {
                    if (response.data.result == 'ok') {
                        this.openToast(this.$t("parent.updateSuccess"));

                        this.getAccount();
                        this.modalController.dismiss();
                    }
                    else {
                        this.openToast(this.$t("parent.updateFailure"));
                    }
                });
        },
        updatePresent() {
            if (this.currentSchool > 0) {
                axios.post(this.$api_add + localStorage.getItem("org") + 'logs/get_present_in_school', { id: localStorage.getItem("id"), schoolid: this.currentSchool }, {})
                    .then(response => {
                        if (response.data.result == 'ok') {

                            let d = response.data.data;
                            for (let i = 0; i < this.chosenStudents.length; i++) {
                                for (let j = 0; j < d.length; j++) {
                                    if (this.chosenStudents[i].id == d[j].id) {
                                        if (parseInt(this.chosenStudents[i].status) < parseInt(d[j].status)) {
                                            this.chosenStudents[i].status = d[j].status
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            console.log("error");
                        }
                    }).catch((e)=>{
                      console.log(e);
                    })
                    this.update = !this.update;
                    this.getCarNumbers();
            }

        },
        getCarNumbers() {

          //console.log("koulu ", this.currentSchool);

            axios.post(this.$api_add + localStorage.getItem("org") + 'school/getcarnumbers', { id: localStorage.getItem("id"), schoolid: this.currentSchool }, {})
                .then(response => {
                    if (response.data.result == 'ok') {
                        //console.log(response.data.data);
                        let d = response.data.data;
                        for (let i = 0; i < this.chosenStudents.length; i++) {
                            for (let j = 0; j < d.length; j++) {
                                if (d[j].children.includes(this.chosenStudents[i].id)) {
                                    let json = JSON.parse(d[j].startpoint);
                                    //console.log(d[j].vehicle);

                                    if (d[j].vehicle == undefined && d[j].vehicle == null) {
                                        this.chosenStudents[i]['ride'] = "";
                                    }
                                    else {

                                        this.chosenStudents[i]['ride'] = d[j].vehicle;
                                    }
                                    this.chosenStudents[i]['ridestart'] = json.start_time;

                                }
                            }
                        }
                    }
                    else {
                        console.log("error");
                    }
                    this.update = !this.update;
                }).catch((e)=>{
                  console.log(e);
                });

        },
        sendNewMessage(data: any) {
            axios.post(this.$api_add + localStorage.getItem("org") + 'messages/create', {
                userid: localStorage.getItem("id"),
                message: data
            }).then(() => {
                this.updateMessages();
                this.modalController.dismiss();
            })
        },
        async newMessage() {

            let inp: any = { data: [] };


            let obj = {
                label: this.$t('driver.message'),
                type: 'textarea',
                value: ""
            };
            inp.data.push(obj);


            const alert = await alertController.create({
                header: this.$t('driver.newMessage'),
                buttons: [
                    {
                        text: this.$t('general.cancel'),
                        role: 'cancel',
                        handler: () => { console.log("cancel") }
                    },
                    {
                        text: 'OK',
                        role: 'confirm',
                        handler: (data: string) => { console.log("ok"); console.log(data); this.sendNewMessage(data[0]); }
                    }
                ],
                inputs: inp.data
            });
            await alert.present();

            await alert.onDidDismiss();
        },
        async updateMessages() {

            axios.post(this.$api_add + localStorage.getItem("org") + 'messages/gettodaysmessages', {}).then(res => {
                this.messages = res.data.data;

            }).catch(e => { console.log(e); });
            console.log("update messages");
        },
        updateColor(){
            if(this.viewModifier === 'afternoon'){
                this.afternoon = 'success';
                this.morning = 'primary';

            }else{
                this.morning = 'success';
                this.afternoon = 'primary';
            }
        },
        openSelect(select:any){
          try{
            select.open();
          }
          catch(e){
            //do nothing
          }
        }
    },

    updated() {
        this.$root.$i18n.locale = localStorage.getItem("locale") ?? 'fi';
    },
    mounted() {
        this.chosenStudents = JSON.parse(localStorage.getItem("studentschosen")) != null ? JSON.parse(localStorage.getItem("studentschosen")) : [];
        this.getSchools();
        this.getAccount();
        this.openMenu();
        this.getRoutes();

        this.$root.$i18n.locale = localStorage.getItem("locale") ?? 'fi';
        setTimeout(() => {

          if(this.chosenStudents.length == 0){
            this.openSelect(document.getElementById('mySelect'))
          }
        }, 1000);
        //setTimeout(() =>this.openSelect(), 1000)
        if(this.chosenStudents != null){


            if (this.updateInterval != null) {
                clearInterval(this.updateInterval);
            }
            this.updateInterval = setInterval(this.updatePresent, 60 * 1000);
            this.getCarNumbers();
        }
    },
    data() {
        return{
            morning:'success',
            afternoon: 'primary',
            viewModifier: 'morning',
            currentSchool: 0,
            currentLocale: localStorage.getItem("locale"),
            chosenStudents:[

            ],
            schools:[

            ],
            students:[

            ],
            account:{
                username: "",
                email: "",
                phone: 0,
                school: localStorage.getItem("school")
            },
            accountForm: {
                email: "",
                phone:0
            },
            updateInterval: null,
            messages: this.messagesGot,
            classNumbers: [],
            classes: [],
            address: "",
            mySelect: null,
            update: false
        };
    }
})
</script>
<style>
#container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

#list-container{
    width:100%;
    overflow-y: auto;

}
.list-label{
    flex: 0 0 75px !important;
    min-width: 75px !important;

}
@media (min-width: 0px){
    #list-container{
    width:100%;
    overflow-y: auto;

}
}
@media (min-width: 576px) {
    #list-container{
    width:90%;
    overflow-y: auto;

}
 }


@media (min-width: 768px) {
    #list-container{
    width:80%;
    overflow-y: auto;

}
 }


@media (min-width: 992px) {
    #list-container{
    width:70%;
    overflow-y: auto;

}
 }


@media (min-width: 1200px) {
    #list-container{
    width:60%;
    overflow-y: auto;

}
}


@media (min-width: 1400px) {
    #list-container{
    width:50%;
    overflow-y: auto;

}
 }
</style>
