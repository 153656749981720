import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_info_component = _resolveComponent("info-component")!
  const _component_locale_switcher = _resolveComponent("locale-switcher")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("title.title")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_info_component, {
                slot: "end",
                side: "end"
              }),
              _createVNode(_component_locale_switcher, {
                slot: "end",
                onUpdateLocale: _ctx.updateLocale,
                size: "48px",
                onClick: _ctx.$forceUpdate
              }, null, 8, ["onUpdateLocale", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true })
    ]),
    _: 1
  }))
}