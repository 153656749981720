<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-item>
					<h3>{{$t('operator.editCar')}}</h3>
				</ion-item>
				<form novalidate>
					<ion-row>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('operator.carId')"
										id="vehicleId"
										v-model="editCarForm.name"
										name="vId"
										type="text"
										:label="$t('operator.carId')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('operator.carConsumption')"
										id="vehicleId"
										v-model="editCarForm.fuelconsumption"
										name="vCons"
										type="text"
										:label="$t('operator.carConsumption')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('operator.vehicleNumber')"
										id="vehicleId"
										v-model="editCarForm.number"
										name="vId"
										type="text"
										:label="$t('operator.vehicleNumber')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('operator.registerNumber')"
										id="vehicleRegister"
										v-model="editCarForm.registration"
										name="vId"
										type="text"
										:label="$t('operator.registerNumber')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('operator.vehicleEmissions')"
										id="vehicleId"
										v-model="editCarForm.emissions"
										name="vEms"
										type="text"
										:label="$t('operator.vehicleEmissions')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
            <ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
									<ion-input
										id="vehicleEqpmnt"
										:placeholder="$t('operator.carEquipment')"
										v-model="editCarForm.servicelevel"
										name="vEq"
										type="text"
										:label="$t('operator.carEquipment')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-input
									id="typeSeats"
									v-model.number="editCarForm.capacity"
									name="vSeats"
									:label="$t('operator.carSeats')"
									labelPlacement="stacked"
									type="text"
									required
								></ion-input>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="editCar(editCarForm)">{{$t('general.save')}}</ion-button>
							<ion-button color="danger" @click="modalController.dismiss()">{{$t('general.close')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script lang="ts">
import {
  IonGrid,
  IonRow,
  IonItem,
	IonInput,
	modalController,
  IonCol,
  IonButton
} from "@ionic/vue";
import { defineComponent, toRaw, } from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "EditCar",
		props:['cardata'],
		emits:['responsedata'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonInput,
			IonCol,
			IonButton
		},
    methods:{
			editCar(form: object){
				const dt = toRaw(form);


				axios.post(this.$api_add + localStorage.getItem("org") +'vehicles/update', dt).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.vehicleResponses.editSuccess')),
							editStatus: true
						});
					}
					else{
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.vehicleResponses.editFailure') + res.statusText),
							editStatus: false
						});
					}
				}).catch((e)=>{
					this.$emit('responsedata', {
						statusMessage: String(this.$t('operator.vehicleResponses.editFailure') + e.message),
						editStatus: false
					});
				});
			}
    },
    data(){
			const data = toRaw(this.cardata);
      return{
				editCarForm: {
					name: data.name,
					type: data.type,
					number: data.number,
					fuelconsumption: data.fuelconsumption,
					emissions: data.emissions,
					registration: data.registration,
					servicelevel: data.servicelevel,
					capacity: data.capacity,
					id: data.id
				},
				isdesktop: this.$isdesktop
      }
    },
		setup(){
			return{
				modalController
			}
		}
});
</script>
<style>
	.inpt{
		--background: #dedad9;
	}
</style>
