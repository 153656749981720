<template>
	<ion-grid>
	<form novalidate>
		<ion-row>
			<ion-col size="12">
				<ion-item>
					<h3>{{$t('operator.editExceptions')}}</h3>
				</ion-item>
			</ion-col>
			<ion-col size="12" v-if="weeks.length > 0">
				<ion-item>
					<ion-select v-model="selectedWeek" class="fullwidth" :placeholder="$t('general.week')" :label="$t('general.week')" labelPlacement="stacked">
						<ion-select-option v-for="w in weeks" :value="w" :key="w">{{w}}</ion-select-option>
					</ion-select>
				</ion-item>
			</ion-col>
			<ion-col size="12" v-if="children.length > 0">
				<ion-item>
					<ion-select v-model="selectedChild" :placeholder="$t('general.childName')" :label="$t('general.childName')" labelPlacement="stacked">
						<ion-select-option v-for="c in children" :value="c.id" :key="c.id">{{String(c.firstname + " " + c.lastname)}}</ion-select-option>
					</ion-select>
				</ion-item>
			</ion-col>
			<ion-col size="12">
				<ion-button color="secondary" size="small" :disabled="(selectedChild == null || selectedChild == undefined) ? true : false" @click="setData()">{{$t('operator.getExceptionInfo')}}</ion-button>
			</ion-col>
			<ion-col size="12" v-if="childData.length > 0 && (selectedChild != null && selectedChild != undefined)">
				<ion-note>{{$t('operator.absentGuide')}}</ion-note>
				<ion-item>
				<ion-row>
				<ion-col size="2" class="nopadding smalltxt">
					<ion-row>
						<ion-col size="12">
							<ion-item class="nopadding" lines="none">
								<p class="smalltxt ion-text-wrap bld">{{$t('operator.exceptionDay')}}</p>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-item class="nopadding" lines="none">
								<p class="smalltxt ion-text-wrap bld">{{$t('operator.morningDrive')}}</p>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-item class="nopadding" lines="none">
								<p class="smalltxt ion-text-wrap bld">{{$t('operator.afternoonDrive')}}</p>
							</ion-item>
						</ion-col>
					</ion-row>
				</ion-col>
				<ion-col v-for="d in childData[0].days" :key="d.date" size="2" class="ion-padding">
					<ion-row>
						<ion-col size="12">
							{{moment.utc(d.date).format('DD.MM')}}
						</ion-col>
							<ion-col size="12" class="pd32">
							<ion-checkbox v-model="d.morning" :checked="d.morning" />
							</ion-col>
							<ion-col size="12" class="pd32">
								<ion-checkbox v-model="d.afternoon" :checked="d.afternoon" />
								</ion-col>
							</ion-row>
						</ion-col>
					</ion-row>
				</ion-item>
			</ion-col>
			<ion-col v-else size="12" class="ion-text-center ion-padding">
				<ion-note>{{$t('operator.absentNoData')}}</ion-note>
			</ion-col>
			<ion-col size="12" class="topborder">
					<ion-button @click="editException(editExceptionForm)">{{$t('general.save')}}</ion-button>
					<ion-button color="danger" @click="modalController.dismiss()">{{$t('general.cancel')}}</ion-button>
			</ion-col>
		</ion-row>
	</form>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	modalController,
  IonCol,
  IonButton,
	IonSelect,
	IonSelectOption,
	IonCheckbox,
	IonNote
} from "@ionic/vue";
import { defineComponent, toRaw } from 'vue';
import axios from 'axios';
import moment from 'moment';
export default defineComponent({
    name: "EditExceptionForm",
		props: ['childs', 'exceptionforms'],
		emits: ['responsedata'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonCol,
			IonButton,
			IonSelect,
			IonSelectOption,
			IonCheckbox,
			IonNote
		},
    methods:{
			setData(){
				if (this.selectedWeek != null && this.forms.length > 0) {
					const dt = toRaw(this.forms).filter((op)=>{
						return op.week == this.selectedWeek;
					});
					const dt0 = dt.find((io)=>{
						return io.childid == this.selectedChild;
					});
					if(dt0 != null && dt0 != undefined){
						dt0['lastmodifier'] = localStorage.getItem('id');
						this.childData = [dt0];
					}
					else{
						const dt01 = this.createBlank();
						this.childData = dt01;
					}
				}
				else{
					const dt0 = this.createBlank();
					this.childData = dt0;
				}
			},
			editException(){
				const dt = toRaw(this.childData);
				axios.post(this.$api_add + localStorage.getItem("org") +'exceptions/parseform', {"weekForm":dt}).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.exceptionResponses.editSuccess')),
							showMessage: true,
							editStatus: true
						});
					}
					else{
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.exceptionResponses.editFailure') + res.statusText),
							showMessage: true,
							editStatus: false
						});
					}
				}).catch((e)=>{
					this.$emit('responsedata', {
						statusMessage: String(this.$t('operator.exceptionResponses.editFailure') + e.message),
						showMessage: true,
						editStatus: false
					});
				});
			},
			createForm(week0){
				const week = moment().set({'week': week0});
				let form = [];
				//loop through
				for (var i = 0; i < 5; i++) {
					let j = i+1;
					let loopday = week.isoWeekday(j);
					form.push({
						"date": loopday.format('YYYY-MM-DD'),
						"morning": false,
						"afternoon": false
					});
				}
				return form;
			},
			findParent(carray,cid){
				if(cid == this.selectedChild){
					const cdata = carray.filter((io)=>{
						return io.id == this.selectedChild;
					});
					//console.log("findparent result ", cdata);
					return cdata[0]['parentid'];
				}
			},
			createBlank(){
				const curryear= moment().format('YYYY');
				const rform = this.createForm(this.selectedWeek);
				const dt0 = [{
					"id": null,
					"lastmodifier": localStorage.getItem("id"),
					"week": this.selectedWeek,
					"year": curryear,
					"days": rform,
					"childid": this.selectedChild,
					"parentid": this.findParent(toRaw(this.children), this.selectedChild)
				}];
				//console.log(dt0, this.children);
				return dt0;
			}
		},
    data(){
			const children = toRaw(this.childs);
			const c_forms = toRaw(this.exceptionforms);
			const currWeek = moment.utc().isoWeek();

			let weeks = [];
			/* How about week 52?
			for (let i = currWeek; i < (currWeek+4); i++) {
				weeks.push(i);
			}*/
			weeks.push(moment().isoWeek())
			weeks.push(moment().add(7, 'days').isoWeek())
			weeks.push(moment().add(14, 'days').isoWeek())
			weeks.push(moment().add(21, 'days').isoWeek())

      return{
				children: children,
				forms: c_forms,
				weeks: weeks,
				selectedWeek: null,
				filteredData: [],
				selectedChild: null,
				childData: [],
				schools: [],
				selectedSchool: null,
      }
    },
		setup(){
			return{
				modalController,
				moment
			}
		}
});
</script>
<style>
	.bigarea{
		height:50%;
	}
	.fullwidth{
		width: 100%;
	}
	.nopadding{
		padding:0;
	}
	.topborder{
		border-top: solid 1px #cfcdca;
	}
	.smalltxt{
		font-size:0.9em
	}
	.bld{
		font-weight: bold;
	}
	.pd32{
		padding:20px;
	}
	@media only screen and (max-height: 801px) {
		.smalltxt{
			font-size:0.7em
		}

	}

</style>
