<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<form novalidate>
					<ion-row>
            <ion-col>
							<ion-row>
								<ion-col size="12">
									<ion-item>
										<ion-select :label="$t('general.parent')" labelPlacement="stacked" :placeholder="$t('general.parent')" :change="getParentChilds()" name="childparent" v-model="exceptionForm.parentid" id="childParent">
											<ion-select-option value="-1" key="null" disabled selected>{{$t('parent.choose')}}</ion-select-option>
											<ion-select-option :value="opId" key="null">{{$t('general.roles.operator')}}</ion-select-option>
											<ion-select-option v-for="(parent, index) in parents" :value="parent.id" :key="index">{{parent.name}}</ion-select-option>
										</ion-select>
									</ion-item>
									<ion-item>
										<ion-select :label="$t('general.childName')" labelPlacement="stacked" :placeholder="$t('general.childName')" v-model="selectedIndex">
											<ion-select-option value="-1" key="null" disabled selected>{{$t('parent.choose')}}</ion-select-option>
											<ion-select-option v-for="(child, index) in filteredChildren" :value="index" :key="index">{{String(child.firstname +" "+ child.lastname)}}</ion-select-option>
										</ion-select>
									</ion-item>
								</ion-col>
								<ion-col>
									<ion-datetime :label="$t('operator.exceptionStart')" labelPlacement="stacked" locale="fi-FI" hour-cycle="h23" first-day-of-week="1" v-model="exceptionForm.datefrom" :min="minDate">
										<span slot="time-label">{{$t('general.time')}}</span>
									</ion-datetime>
								</ion-col>
								<ion-col>
									<ion-datetime :label="$t('operator.exceptionEnd')" labelPlacement="stacked" locale="fi-FI" hour-cycle="h23" first-day-of-week="1" v-model="exceptionForm.dateto" :min="minDate">
										<span slot="time-label">{{$t('general.time')}}</span>
									</ion-datetime>
								</ion-col>
							</ion-row>
						</ion-col>
						<ion-col>
							<ion-button @click="createException(exceptionForm)">{{$t('operator.addException')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonSelect,
	IonSelectOption,
  IonCol,
  IonButton,
	IonDatetime
} from "@ionic/vue";
import { defineComponent, toRaw } from 'vue';
import axios from 'axios';
import moment from 'moment';

export default defineComponent({
    name: "OperatorAddException",
		emits: ['goback'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonSelect,
			IonSelectOption,
			IonCol,
			IonButton,
			IonDatetime
		},
    methods:{
			createException(){
				let dt = this.filteredChildren[this.selectedIndex];
				let obj = {
					"childid": dt.id,
					"parentid": this.exceptionForm.parentid,
					"datefrom": this.exceptionForm.datefrom,
					"dateto": this.exceptionForm.dateto
				};

				axios.post(this.$api_add + localStorage.getItem("org") +'exceptions/create', obj).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('goback', { statusMessage: String(res.status + " " + this.$t('operator.exceptionResponses.addSuccess')), editStatus: true });
					}
					else if(res.data.result == 'alreadyexists'){
						this.$emit('goback', { statusMessage: String(res.status + " " + this.$t('operator.exceptionResponses.alreadyExists')), editStatus: false });
					}
					else{
						this.$emit('goback', { statusMessage: String(res.status + " " + this.$t('operator.exceptionResponses.addFailure')), editStatus: false });
					}
				}).catch((e)=>{
						this.$emit('goback', { statusMessage: String(this.$t('operator.exceptionResponses.addSuccess') + " " + e), editStatus: false });
				});
			},
			getParentChilds(){
					if(this.exceptionForm.parentid != this.opId && this.exceptionForm.parentid != null){

						this.filteredChildren = this.children.filter((x)=>{
							return x.parentid == this.exceptionForm.parentid
						});

						this.selectedIndex = null;
					}
					else{
						this.filteredChildren = this.children;
					}
			},
			getParents(){
				axios.post(this.$api_add + localStorage.getItem("org") +'users/getparents').then((res)=>{
					if(res.data.result == 'ok'){
						let data = toRaw(res.data.data);
						for (let i = 0; i < data.length; i++) {
							data[i]['name'] = data[i]['firstname'] + " " + data[i]['lastname'];
						}

						this.parents = data;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.exceptionResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.exceptionResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			async getChildren(){
				axios.post(this.$api_add + localStorage.getItem("org") +'child/getall').then((res)=>{
					if(res.data.result == 'ok'){
						this.children = res.data.data;
						this.filteredChildren = res.data.data;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.exceptionResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.exceptionResponses.getFailed') + e);
					this.toastObject['status'] = false;
				})
			},
			containsOnly(array1, array2){
				return array2.every(elem => array1.includes(elem))
			}
    },
		mounted(){
			this.getChildren();
			this.getParents();
			this.opId = localStorage.getItem('id');

			const currday = moment.utc();
			this.exceptionForm.datefrom = currday.toISOString();
			this.exceptionForm.dateto = currday.add('1', 'days').toISOString();
		},
    data(){
      return{
				selectedRole: 0,
				exceptionForm: {
          datefrom: null,
					dateto: null,
					child: null,
					parentid: null
				},
				children: null,
				filteredChildren: null,
				parents: null,
				selectedIndex: null,
				opId:null,
				minDate: moment.utc().toISOString()
      }
    },
		setup(){
			return{

			}
		}
});
</script>
<style>
	.bigarea{
		height:50%;
	}
	.bld{
		font-weight: bold;
	}
</style>
