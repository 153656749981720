<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-item>
					<h3>{{$t('operator.editAddress')}}</h3>
				</ion-item>
				<form novalidate>
					<ion-row>
						<ion-col size="12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.address')"
										id="addressName"
										v-model="editAddressForm.address"
										name="vId"
										type="text"
										:label="$t('general.address')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.latitude')"
										id="addressLatitude"
										v-model="editAddressForm.latitude"
										name="sAddress"
										:label="$t('general.latitude')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.longitude')"
										id="addressLongitude"
										v-model="editAddressForm.longitude"
										name="sPostal"
										:label="$t('general.longitude')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="editAddress(editAddressForm)">{{$t('general.save')}}</ion-button>
							<ion-button color="danger" @click="modalController.dismiss()">{{$t('general.close')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonInput,
	modalController,
  IonCol,
  IonButton
} from "@ionic/vue";
import { defineComponent, toRaw} from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "EditAddress",
		props:['addressdata'],
		emits:['responsedata'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonInput,
			IonCol,
			IonButton
		},
    methods:{
			editAddress(form){
				const rawObject = toRaw(form);
				axios.post(this.$api_add + localStorage.getItem("org") +'coordinates/update', rawObject).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.addressResponses.editSuccess')),
							editStatus: true
						});
					}
					else{
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.addressResponses.editFailure') + res.statusText),
							editStatus: false
						});
					}
				}).catch((e)=>{
					this.$emit('responsedata', {
						statusMessage: String(this.$t('operator.addressResponses.editFailure') + e.message),
						editStatus: false
					});
				});
			},
			parseData(){
				const data = toRaw(this.addressdata);
				this.editAddressForm.id = data.id;
				this.editAddressForm.address = data.address;
				this.editAddressForm.latitude = data.latitude;
				this.editAddressForm.longitude = data.longitude;
			}
    },
    data(){
      return{
				editAddressForm: {
					id: null,
					index:null,
					address:null,
					latitude: null,
					longitude: null
				},
				isdesktop: this.$isdesktop
      }
    },
		mounted(){
			this.parseData();
		},
		setup(){
			return{
				modalController
			}
		}
});
</script>
<style>
	.inpt{
		--background: #dedad9;
	}
</style>
