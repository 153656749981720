<template>
    <ion-icon :icon="logOutOutline" @click="onLogout" class="icon"></ion-icon>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";
import { logOutOutline } from "ionicons/icons";
import { ScreenOrientation } from '@capacitor/screen-orientation';
import {isPlatform} from '@ionic/vue';
import axios from 'axios';
export default defineComponent({
    name:"LogoutComponent",
    components:{
        IonIcon
    },
    props:{
        size: String
    },
    setup(){
        return{ logOutOutline}
    },
    methods:{
        onLogout(){
            const userid = localStorage.getItem('id');
            const pushtoken = localStorage.getItem('pushtoken');
						axios.post(this.$api_add + localStorage.getItem("org") + 'users/removepushtoken', {"id": userid, "pushtoken": pushtoken}).then(async()=>{
							//console.log(u);
						}).catch(()=>{
							//console.log("e", e);
						}).finally(()=>{
                            if(!isPlatform('desktop')){
      const unlock = async () => {
    await ScreenOrientation.unlock();
  }
  unlock();
    }
              localStorage.removeItem("role");
              localStorage.removeItem("id");
              localStorage.removeItem("token");
							localStorage.removeItem("pushtoken");
              this.$router.replace({path:"/"})
            });
        }
    }
})


</script>
<style scoped>
.icon {
    font-size: v-bind(size);

}
</style>
