import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_info_component = _resolveComponent("info-component")!
  const _component_feedback_modal_component = _resolveComponent("feedback-modal-component")!
  const _component_logout_vue = _resolveComponent("logout-vue")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_locale_switcher = _resolveComponent("locale-switcher")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_item_option = _resolveComponent("ion-item-option")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_feedback = _resolveComponent("feedback")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("title.title")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_info_component, {
                slot: "end",
                side: "end"
              }),
              _createVNode(_component_feedback_modal_component),
              _createVNode(_component_ion_item, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_logout_vue, { size: "36px" })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item_divider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _ctx.getExceptions,
                    id: "bKuittaus",
                    expand: "full",
                    style: {"min-width":"100%","padding-left":"10%","padding-right":"10%","font-size":"large"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.confirm")) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.checkmarkOutline,
                        slot: "end"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item_divider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _ctx.getEvents,
                    id: "bTapahtumat",
                    expand: "full",
                    style: {"min-width":"100%","padding-left":"10%","padding-right":"10%","font-size":"large"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.confirmLog")) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.newspaperOutline,
                        slot: "end"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item_divider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    id: "bPoissaolov2",
                    expand: "full",
                    style: {"min-width":"100%","padding-left":"10%","padding-right":"10%","font-size":"large"},
                    onClick: _ctx.updateAbsencesForm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.absentForm")) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.calendarOutline,
                        slot: "end"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item_divider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _ctx.getMyAccount,
                    id: "bMuutaOsoite",
                    expand: "full",
                    style: {"min-width":"100%","padding-left":"10%","padding-right":"10%","font-size":"large"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.changeAddress")) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.pencilOutline,
                        slot: "end"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item_divider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _ctx.getMyAccount,
                    id: "bSendMessage",
                    expand: "full",
                    style: {"min-width":"100%","padding-left":"10%","padding-right":"10%","font-size":"large"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.sendMessage")) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.sendOutline,
                        slot: "end"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item_divider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.getMyAccount();_ctx.switchContent('MyAccount');}),
                    id: "bOmatTiedot",
                    expand: "full",
                    style: {"min-width":"100%","padding-left":"10%","padding-right":"10%","font-size":"large"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.accountInfo")) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.personOutline,
                        slot: "end"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_locale_switcher, {
            onUpdateLocale: _ctx.updateLocale,
            size: "48px",
            onClick: _ctx.$forceUpdate
          }, null, 8, ["onUpdateLocale", "onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, {
        class: "kuittaus",
        trigger: "bKuittaus"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.transportToday")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    color: "danger",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalController.dismiss()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                      _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion_group, {
            ref: "accordionGroup",
            style: {"overflow-y":"auto"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myRoutes, (route) => {
                return (_openBlock(), _createBlock(_component_ion_accordion, {
                  key: route.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { slot: "header" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.parseDate(route.days)), 1)
                          ]),
                          _: 2
                        }, 1024),
                        (route.type==0)
                          ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(route.timewindow), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.moment.utc(_ctx.moment.unix(route.arr_time)).format("HH:mm")), 1)
                              ]),
                              _: 2
                            }, 1024)),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(route.name), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_list, {
                      slot: "content",
                      inset: true
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("parent.pickUpAddress")) + ": " + _toDisplayString(route.pickup), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("parent.dropOffAddress")) + ": " + _toDisplayString(route.delivery), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createVNode(_component_ion_item_sliding, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item_options, { side: "start" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item_option, {
                                  onClick: ($event: any) => (_ctx.parent_confirm_this(route.id,route.type))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("parent.confirm")), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_item, { color: "warning" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("parent.dragToConfirm")) + " ", 1),
                                _createVNode(_component_ion_icon, { icon: _ctx.arrowForwardOutline }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 512),
          _createVNode(_component_ion_accordion_group, { value: "past" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_accordion, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('parent.pastRoute')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_list, {
                    slot: "content",
                    inset: true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_accordion_group, { value: "pastRoutes" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pastRoutes, (route) => {
                            return (_openBlock(), _createBlock(_component_ion_accordion, {
                              key: route.id
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, { slot: "header" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.parseDate(route.days)), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    (route.type==0)
                                      ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(route.timewindow), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.moment.utc(_ctx.moment.unix(route.arr_time)).format("HH:mm")), 1)
                                          ]),
                                          _: 2
                                        }, 1024)),
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(route.name), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_list, {
                                  slot: "content",
                                  inset: true
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", null, [
                                      _createVNode(_component_ion_item, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t("parent.pickUpAddress")) + ": " + _toDisplayString(route.pickup), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_ion_item, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t("parent.dropOffAddress")) + ": " + _toDisplayString(route.delivery), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, {
        class: "kuittaus",
        trigger: "bTapahtumat"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.confirmLog")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    color: "danger",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.modalController.dismiss()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                      _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion_group, {
            ref: "accordionGroup",
            style: {"overflow-y":"auto"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (ev) => {
                return (_openBlock(), _createBlock(_component_ion_accordion, {
                  key: ev.Timestamp
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { slot: "header" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("parent.confirmStatus."+ev.confirmstatus)), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.moment(ev.timestamp).format('DD.MM.YYYY HH:mm')), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(ev.firstname), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_list, {
                      slot: "content",
                      inset: true
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("parent.confirmBase")) + ": " + _toDisplayString(_ctx.$t("parent.confirmStatus."+ev.confirmstatus)), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("parent.time")) + ": " + _toDisplayString(_ctx.moment(ev.timestamp).format('DD.MM.YYYY HH:mm:ss')), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("parent.childName")) + ": " + _toDisplayString(ev.firstname), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("parent.personConfirmed")) + ": " + _toDisplayString(ev.nameconfirm), 1)
                          ]),
                          _: 2
                        }, 1024),
                        (ev.message != null && ev.message.length > 7)
                          ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("parent.message")) + ": " + _toDisplayString(ev.message), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, { trigger: "bMuutaOsoite" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.changeAddress")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    color: "danger",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.modalController.dismiss()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                      _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "show-text" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("parent.chooseAddressText")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "stacked" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("parent.chooseAddress")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_select, {
                modelValue: _ctx.newAddressForm.id,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newAddressForm.id) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addresses, (address) => {
                    return (_openBlock(), _createBlock(_component_ion_select_option, {
                      key: address.id,
                      value: address.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(address.address), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                "label-placement": "stacked",
                label: _ctx.$t('parent.address'),
                modelValue: _ctx.newAddressForm.street,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newAddressForm.street) = $event)),
                type: "text"
              }, null, 8, ["label", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                "label-placement": "stacked",
                label: _ctx.$t('parent.postnumber'),
                modelValue: _ctx.newAddressForm.zipcode,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newAddressForm.zipcode) = $event)),
                type: "number"
              }, null, 8, ["label", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                "label-placement": "stacked",
                label: _ctx.$t('parent.city'),
                modelValue: _ctx.newAddressForm.city,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newAddressForm.city) = $event)),
                type: "text"
              }, null, 8, ["label", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                "label-placement": "stacked",
                label: _ctx.$t('parent.message'),
                modelValue: _ctx.newAddressForm.message,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newAddressForm.message) = $event)),
                type: "text"
              }, null, 8, ["label", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, { onClick: _ctx.sendAddressChangeRequest }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("parent.send")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, { trigger: "bOmatTiedot" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.accountInfo")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    color: "danger",
                    slot: "end",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.modalController.dismiss()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                      _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, { class: "scrollable" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                onClick: _cache[10] || (_cache[10] = ($event: any) => {_ctx.switchContent('MyAccount');})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('parent.accountInfo')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                onClick: _cache[11] || (_cache[11] = ($event: any) => {_ctx.switchContent('ChildInfo');})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('parent.childInfo')), 1)
                ]),
                _: 1
              }),
              (!_ctx.accountInfo.mainaccountid)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    onClick: _cache[12] || (_cache[12] = ($event: any) => {_ctx.switchContent('AddSubAccount');})
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.addSubAccount')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_button, {
                onClick: _cache[13] || (_cache[13] = ($event: any) => {_ctx.switchContent('ManageSubAccount');})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('parent.subAccountManagement')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                onClick: _cache[14] || (_cache[14] = ($event: any) => {_ctx.switchContent('ChangePassword');})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.changePassword')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.switchContent('DeleteAccount')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('parent.deleteAccount')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.accountInfoContent.content == 'MyAccount')
            ? (_openBlock(), _createBlock(_component_ion_content, {
                key: 0,
                class: "height80"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.accountInfo')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.username'),
                        type: "text",
                        value: _ctx.accountInfo.username,
                        readonly: ""
                      }, null, 8, ["label", "value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.firstName'),
                        type: "text",
                        modelValue: _ctx.accountInfo.firstname,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.accountInfo.firstname) = $event)),
                        value: _ctx.accountInfo.firstname,
                        readonly: _ctx.edit.disabled
                      }, null, 8, ["label", "modelValue", "value", "readonly"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.lastName'),
                        type: "text",
                        modelValue: _ctx.accountInfo.lastname,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.accountInfo.lastname) = $event)),
                        value: _ctx.accountInfo.lastname,
                        readonly: _ctx.edit.disabled
                      }, null, 8, ["label", "modelValue", "value", "readonly"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.defaultAddress'),
                        type: "text",
                        modelValue: _ctx.accountInfo.addressdefault,
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.accountInfo.addressdefault) = $event)),
                        value: _ctx.accountInfo.addressdefault,
                        readonly: true
                      }, null, 8, ["label", "modelValue", "value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.email'),
                        type: "email",
                        modelValue: _ctx.accountInfo.email,
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.accountInfo.email) = $event)),
                        value: _ctx.accountInfo.email,
                        readonly: _ctx.edit.disabled
                      }, null, 8, ["label", "modelValue", "value", "readonly"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.phone'),
                        type: "tel",
                        modelValue: _ctx.accountInfo.phone,
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.accountInfo.phone) = $event)),
                        value: _ctx.accountInfo.phone,
                        readonly: _ctx.edit.disabled
                      }, null, 8, ["label", "modelValue", "value", "readonly"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { onClick: _ctx.enableEdit }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.edit')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { onClick: _ctx.sendEditAccountEdit }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.updateChanges')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_ion_button, {
                        slot: "end",
                        color: "danger",
                        onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.modalController.dismiss()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                          _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.accountInfoContent.content == 'ChildInfo')
            ? (_openBlock(), _createBlock(_component_ion_content, {
                key: 1,
                class: "height80"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.childInfo')), 1)
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.child, (chi) => {
                    return (_openBlock(), _createBlock(_component_ion_list, {
                      key: chi.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              "label-placement": "stacked",
                              label: _ctx.$t('general.firstName'),
                              modelValue: chi.firstname,
                              "onUpdate:modelValue": ($event: any) => ((chi.firstname) = $event),
                              type: "text",
                              value: chi.firstname,
                              readonly: _ctx.edit.disabled
                            }, null, 8, ["label", "modelValue", "onUpdate:modelValue", "value", "readonly"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              "label-placement": "stacked",
                              label: _ctx.$t('general.lastName'),
                              modelValue: chi.lastname,
                              "onUpdate:modelValue": ($event: any) => ((chi.lastname) = $event),
                              type: "text",
                              value: chi.lastname,
                              readonly: _ctx.edit.disabled
                            }, null, 8, ["label", "modelValue", "onUpdate:modelValue", "value", "readonly"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              "label-placement": "stacked",
                              label: _ctx.$t('driver.helpLevel'),
                              modelValue: chi.assistancelevel,
                              "onUpdate:modelValue": ($event: any) => ((chi.assistancelevel) = $event),
                              type: "text",
                              value: chi.assistancelevel,
                              readonly: _ctx.edit.disabled
                            }, null, 8, ["label", "modelValue", "onUpdate:modelValue", "value", "readonly"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { position: "stacked" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('general.school')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_select, {
                              "ok-text": _ctx.$t('assistant.choose'),
                              "cancel-text": _ctx.$t('assistant.cancel'),
                              disabled: _ctx.edit.disabled,
                              value: chi.id,
                              modelValue: chi.schoolid,
                              "onUpdate:modelValue": ($event: any) => ((chi.schoolid) = $event)
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schools, (school) => {
                                  return (_openBlock(), _createBlock(_component_ion_select_option, {
                                    key: school.id,
                                    value: school.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(school.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["ok-text", "cancel-text", "disabled", "value", "modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1024),
                        (true == false)
                          ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_toggle, {
                                  "enable-on-off-labels": true,
                                  modelValue: chi.active,
                                  "onUpdate:modelValue": ($event: any) => ((chi.active) = $event),
                                  type: "text",
                                  value: chi.active,
                                  disabled: _ctx.edit.disabled,
                                  readonly: _ctx.edit.disabled
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('parent.isChildActive')), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["modelValue", "onUpdate:modelValue", "value", "disabled", "readonly"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, { onClick: _ctx.enableEdit }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('general.edit')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_ion_button, {
                              onClick: ($event: any) => (_ctx.updateChild(chi))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('general.updateChanges')), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]),
                            _createVNode(_component_ion_button, {
                              color: "danger",
                              onClick: ($event: any) => (_ctx.deleteChild(chi))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('parent.deleteChild')), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  _createVNode(_component_ion_item_divider),
                  _createVNode(_component_ion_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('parent.addChild')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item_divider),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.firstName'),
                        modelValue: _ctx.childForm.firstname,
                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.childForm.firstname) = $event)),
                        type: "text"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.lastName'),
                        modelValue: _ctx.childForm.lastname,
                        "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.childForm.lastname) = $event)),
                        type: "text"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('driver.helpLevel'),
                        modelValue: _ctx.childForm.assistancelevel,
                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.childForm.assistancelevel) = $event)),
                        type: "text"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.school')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select, {
                        modelValue: _ctx.childForm.school,
                        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.childForm.school) = $event)),
                        "ok-text": _ctx.$t('assistant.choose'),
                        "cancel-text": _ctx.$t('assistant.cancel')
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schools, (school) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: school.id,
                              value: school.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(school.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "ok-text", "cancel-text"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { onClick: _ctx.addChild }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.send')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_ion_button, {
                        slot: "end",
                        color: "danger",
                        onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.modalController.dismiss()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                          _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.accountInfoContent.content == 'AddSubAccount')
            ? (_openBlock(), _createBlock(_component_ion_content, {
                key: 2,
                class: "height80"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.addSubAccount')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        modelValue: _ctx.addSubForm.role,
                        "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.addSubForm.role) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select_option, { value: "parent" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('general.roles.parent')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "child" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('general.roles.child')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.username'),
                        modelValue: _ctx.addSubForm.username,
                        "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.addSubForm.username) = $event)),
                        type: "text"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.password'),
                        modelValue: _ctx.addSubForm.password,
                        "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.addSubForm.password) = $event)),
                        type: "password"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.firstName'),
                        modelValue: _ctx.addSubForm.firstname,
                        "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.addSubForm.firstname) = $event)),
                        type: "text"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.lastName'),
                        modelValue: _ctx.addSubForm.lastname,
                        "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.addSubForm.lastname) = $event)),
                        type: "text"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.email'),
                        modelValue: _ctx.addSubForm.email,
                        "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.addSubForm.email) = $event)),
                        type: "email"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.phone'),
                        modelValue: _ctx.addSubForm.phone,
                        "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.addSubForm.phone) = $event)),
                        type: "tel"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { onClick: _ctx.addSubAcc }, {
                        default: _withCtx(() => [
                          _createTextVNode("Luo alitili")
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_ion_button, {
                        slot: "end",
                        color: "danger",
                        onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.modalController.dismiss()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                          _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.accountInfoContent.content == 'ManageSubAccount')
            ? (_openBlock(), _createBlock(_component_ion_content, {
                key: 3,
                class: "height80"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.subAccountManagement')), 1)
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subAccounts, (sub) => {
                    return (_openBlock(), _createBlock(_component_ion_list, {
                      key: sub.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              "label-placement": "stacked",
                              label: _ctx.$t('general.username'),
                              type: "text",
                              value: sub.username,
                              readonly: ""
                            }, null, 8, ["label", "value"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              "label-placement": "stacked",
                              label: _ctx.$t('general.firstName'),
                              type: "text",
                              value: sub.firstname,
                              readonly: ""
                            }, null, 8, ["label", "value"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              "label-placement": "stacked",
                              label: _ctx.$t('general.lastName'),
                              type: "text",
                              value: sub.lastname,
                              readonly: ""
                            }, null, 8, ["label", "value"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              "label-placement": "stacked",
                              label: _ctx.$t('general.email'),
                              type: "email",
                              value: sub.email,
                              readonly: ""
                            }, null, 8, ["label", "value"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              "label-placement": "stacked",
                              label: _ctx.$t('general.phone'),
                              type: "number",
                              value: sub.phone,
                              readonly: ""
                            }, null, 8, ["label", "value"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              onClick: ($event: any) => (_ctx.removeSubAcc(sub))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('general.delete')), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        slot: "end",
                        color: "danger",
                        onClick: _cache[35] || (_cache[35] = ($event: any) => (_ctx.modalController.dismiss()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                          _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.accountInfoContent.content == 'ChangePassword')
            ? (_openBlock(), _createBlock(_component_ion_content, {
                key: 4,
                class: "height80"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.changePassword')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.currentPassword'),
                        modelValue: _ctx.passChangeForm.pass_change_form_current,
                        "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.passChangeForm.pass_change_form_current) = $event)),
                        type: "password"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.newPassword'),
                        modelValue: _ctx.passChangeForm.pass_change_form_new1,
                        "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.passChangeForm.pass_change_form_new1) = $event)),
                        type: "password"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.newPasswordAgain'),
                        modelValue: _ctx.passChangeForm.pass_change_form_new2,
                        "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.passChangeForm.pass_change_form_new2) = $event)),
                        type: "password"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { onClick: _ctx.changePassword }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.changePassword')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_ion_button, {
                        slot: "end",
                        color: "danger",
                        onClick: _cache[39] || (_cache[39] = ($event: any) => (_ctx.modalController.dismiss()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                          _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.accountInfoContent.content == 'DeleteAccount')
            ? (_openBlock(), _createBlock(_component_ion_content, {
                key: 5,
                class: "height80"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.deleteAccountInfo')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        "label-placement": "stacked",
                        label: _ctx.$t('general.currentPassword'),
                        modelValue: _ctx.deleteMyAccountForm.password,
                        "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.deleteMyAccountForm.password) = $event)),
                        type: "password"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[41] || (_cache[41] = ($event: any) => (_ctx.deleteMyAccount()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('parent.deleteAccount')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, { trigger: "bSendMessage" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.sendMessage")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    color: "danger",
                    onClick: _cache[42] || (_cache[42] = ($event: any) => (_ctx.modalController.dismiss()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                      _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.sendMessageInfo')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_textarea, {
                    label: _ctx.$t('parent.message'),
                    modelValue: _ctx.message,
                    "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.message) = $event)),
                    "auto-grow": true
                  }, null, 8, ["label", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[44] || (_cache[44] = ($event: any) => (_ctx.sendMessage(_ctx.message)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.submit')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, {
        class: "kuittaus",
        trigger: "bPoissaolov2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.absentForm")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    color: "danger",
                    onClick: _cache[45] || (_cache[45] = ($event: any) => (_ctx.modalController.dismiss()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                      _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, { class: "height80" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.absentGuide')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_accordion_group, {
                ref: "chiAcc",
                value: "children"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.child, (chi) => {
                    return (_openBlock(), _createBlock(_component_ion_accordion, {
                      key: chi.id,
                      ref_for: true,
                      ref: `child${chi.id}`,
                      value: chi.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, { slot: "header" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(chi.firstname + ' ' + chi.lastname), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, { slot: "content" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_accordion_group, {
                              style: {"width":"100%"},
                              ref_for: true,
                              ref: "weekAcc"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekForm, (week) => {
                                  return (_openBlock(), _createBlock(_component_ion_accordion, {
                                    key: week.week,
                                    ref_for: true,
                                    ref: `week${week.week}`,
                                    style: {"width":"100%"}
                                  }, {
                                    default: _withCtx(() => [
                                      (chi.id == week.childid)
                                        ? (_openBlock(), _createBlock(_component_ion_item, {
                                            key: 0,
                                            slot: "header"
                                          }, {
                                            default: _withCtx(() => [
                                              (week.days.find((m) => m.morning == true || m.afternoon == true))
                                                ? (_openBlock(), _createBlock(_component_ion_label, {
                                                    key: 0,
                                                    color: `warning`
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t('general.week')+' '+week.week), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024))
                                                : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t('general.week')+' '+week.week), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024))
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_ion_item, { slot: "content" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_grid, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_row, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_col, { class: "grid-content" }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_ion_label)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(week.days, (day, index) => {
                                                    return (_openBlock(), _createBlock(_component_ion_col, {
                                                      class: "grid-content",
                                                      key: day.date
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_ion_label, null, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.$t('general.days-short.'+(index+1))) + " " + _toDisplayString(_ctx.moment(day.date).format("D.M.")), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024))
                                                  }), 128))
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_row, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_col, { class: "grid-content" }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_ion_label, null, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_ctx.$t('general.morning')), 1)
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  }),
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(week.days, (day) => {
                                                    return (_openBlock(), _createBlock(_component_ion_col, {
                                                      class: "grid-content",
                                                      key: day.date
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_ion_checkbox, {
                                                          "aria-label": "",
                                                          modelValue: day.morning,
                                                          "onUpdate:modelValue": ($event: any) => ((day.morning) = $event),
                                                          checked: day.morning
                                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "checked"])
                                                      ]),
                                                      _: 2
                                                    }, 1024))
                                                  }), 128))
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_row, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_col, { class: "grid-content" }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_ion_label, null, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_ctx.$t('general.afternoon')), 1)
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  }),
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(week.days, (day) => {
                                                    return (_openBlock(), _createBlock(_component_ion_col, {
                                                      class: "grid-content",
                                                      key: day.date
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_ion_checkbox, {
                                                          "aria-label": "",
                                                          modelValue: day.afternoon,
                                                          "onUpdate:modelValue": ($event: any) => ((day.afternoon) = $event),
                                                          checked: day.afternoon
                                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "checked"])
                                                      ]),
                                                      _: 2
                                                    }, 1024))
                                                  }), 128))
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 2
                            }, 1536)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 512)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_footer, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, { onClick: _ctx.updateAbsences }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.update')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.showreview)
        ? (_openBlock(), _createBlock(_component_feedback, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}