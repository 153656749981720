import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { novalidate: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createElementVNode("form", _hoisted_1, [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 4 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              placeholder: _ctx.$t('general.username'),
                              id: "driverUsername",
                              modelValue: _ctx.driverForm.username,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.driverForm.username) = $event)),
                              name: "driverUsername",
                              label: _ctx.$t('general.username'),
                              labelPlacement: "stacked",
                              type: "text",
                              required: ""
                            }, null, 8, ["placeholder", "modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 4 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              placeholder: _ctx.$t('general.password'),
                              id: "driverPass",
                              modelValue: _ctx.driverForm.password,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.driverForm.password) = $event)),
                              name: "driverPass",
                              type: "text",
                              label: _ctx.$t('general.password'),
                              labelPlacement: "stacked",
                              required: ""
                            }, null, 8, ["placeholder", "modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 4 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              placeholder: _ctx.$t('general.firstName'),
                              id: "driverFirstName",
                              modelValue: _ctx.driverForm.firstname,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.driverForm.firstname) = $event)),
                              name: "driverFirstName",
                              type: "text",
                              label: _ctx.$t('general.firstName'),
                              labelPlacement: "stacked",
                              required: ""
                            }, null, 8, ["placeholder", "modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 4 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              placeholder: _ctx.$t('general.lastName'),
                              id: "driverLastname",
                              modelValue: _ctx.driverForm.lastname,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.driverForm.lastname) = $event)),
                              name: "driverLastname",
                              type: "text",
                              label: _ctx.$t('general.lastName'),
                              labelPlacement: "stacked",
                              required: ""
                            }, null, 8, ["placeholder", "modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 4 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              id: "driverEmail",
                              placeholder: _ctx.$t('operator.driverEmail'),
                              modelValue: _ctx.driverForm.email,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.driverForm.email) = $event)),
                              name: "driverEmail",
                              type: "text",
                              label: _ctx.$t('operator.driverEmail'),
                              labelPlacement: "stacked",
                              required: ""
                            }, null, 8, ["placeholder", "modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, {
                      size: _ctx.isdesktop ? 4 : 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              id: "driverPhone",
                              placeholder: _ctx.$t('operator.driverPhone'),
                              modelValue: _ctx.driverForm.phone,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.driverForm.phone) = $event)),
                              name: "driverPhone",
                              type: "text",
                              label: _ctx.$t('operator.driverPhone'),
                              labelPlacement: "stacked",
                              required: ""
                            }, null, 8, ["placeholder", "modelValue", "label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["size"]),
                    _createVNode(_component_ion_col, { size: "12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.registerDriver(_ctx.driverForm)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('operator.addDriver')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}