<template>
	<div></div>
</template>

<script>
import { toastController } from '@ionic/vue';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';
import {defineComponent} from 'vue';
export default defineComponent({
	name: "StatusToast",
  methods: {
    async openToast(values) {
      const toast = await toastController.create({
        message: values.message,
        duration: 5000,
				color: (values.status) ? 'success' : 'danger',
				position: 'top',
				icon: (values.status) ? checkmarkOutline : closeOutline
      })
			return toast.present();
    }
  },
	setup(){
		return{
			checkmarkOutline,
			closeOutline
		}
	}
});
</script>
