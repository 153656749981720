<template>
    <ion-page>
        <ion-content>

        </ion-content>
    </ion-page>
</template>
<script lang="ts">
import {IonPage, IonContent} from '@ionic/vue';
import {defineComponent} from 'vue';
export default defineComponent({

    name: "OperatorInfoPage",
    components:{
        IonPage,
        IonContent
    }
})

</script>

<style scoped>

</style>
