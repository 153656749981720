import Good from '@/assets/good.gif'
import Hate from '@/assets/hate.gif'
import Natural from '@/assets/natural.gif'
import Excellent from '@/assets/excellent.gif'
import Disappointed from '@/assets/disappointed.gif'
import GoodInactive from '@/assets/good_inactive.png'
import HateInactive from '@/assets/hate_inactive.png'
import NaturalInactive from '@/assets/natural_inactive.png'
import ExcellentInactive from '@/assets/excellent_inactive.png'
import DisappointedInactive from '@/assets/disappointed_inactive.png'
import GoodActive from '@/assets/good_active.png'
import HateActive from '@/assets/hate_active.png'
import NaturalActive from '@/assets/natural_active.png'
import ExcellentActive from '@/assets/excellent_active.png'
import DisappointedActive from '@/assets/disappointed_active.png'

export default {
  data () {
    return {
      good: Good,
      hate: Hate,
      natural: Natural,
      excellent: Excellent,
      disappointed: Disappointed,
      goodInactive: GoodInactive,
      hateInactive: HateInactive,
      naturalInactive: NaturalInactive,
      excellentInactive: ExcellentInactive,
      disappointedInactive: DisappointedInactive,
      goodActive: GoodActive,
      hateActive: HateActive,
      naturalActive: NaturalActive,
      excellentActive: ExcellentActive,
      disappointedActive: DisappointedActive
    }
  }
}