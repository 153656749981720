import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import OperatorPage from '../views/OperatorPage.vue';
import DriverPage from '../views/DriverPage.vue';
import AssistantPage from '../views/AssistantPage.vue';
import ParentPage from '../views/ParentPage.vue';
import ChildPage from '../views/ChildPage.vue';
import RegisterPage from '../views/RegisterPage.vue';
import ForgottenPage from '../views/ForgottenPage.vue';
import ParentInfoPage from '../views/tabs/ParentInfoPage.vue';
import InfoPage from '../views/tabs/InfoPage.vue';
import DriverInfoPage from '../views/tabs/DriverInfoPage.vue';
import OperatorInfoPage from '../views/tabs/OperatorInfoPage.vue';
import AssistantInfoPage from '../views/tabs/AssistantInfoPage.vue';
import DemoPage from '../views/DemoPage.vue';
import GDPRPage from '../views/GDPRPage.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/operator',
    name: 'Operator',
    component: OperatorPage
  },
  {
    path: '/assistant',
    name: 'Assistant',
    component: AssistantPage
  },
  {
    path: '/driver',
    name: 'Driver',
    component: DriverPage
  },
  {
    path: '/parent',
    name: 'Parent',
    component: ParentPage
  },
  {
    path: '/child',
    name: 'Child',
    component: ChildPage
  },
  {
    path: '/register/:role/:validateid/:tenantid',
    name: 'Register',
    component: RegisterPage
  },
  {
    path: '/forgotten/:validateid/:tenantid',
    name: 'Forgotten',
    component: ForgottenPage
  },
  {
    path: '/tabs/parent',
    name: "Parent Info",
    component: ParentInfoPage
  },
  {
    path: '/tabs/operator',
    name: "Operator Info",
    component: OperatorInfoPage
  },
  {
    path: '/tabs/assistant',
    name: "Assistant Info",
    component: AssistantInfoPage
  },
  {
    path: '/tabs/driver',
    name: "Driver Info",
    component: DriverInfoPage
  },
  {
    path: '/tabs/info',
    name: "Info",
    component: InfoPage
  },
  {
    path: '/tietosuoja',
    name: "Tietosuoja",
    component: GDPRPage
  },
  {
    path: '/driverdemo',
    name: "Driver Demo",
    component: DemoPage
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
