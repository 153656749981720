<template>
    <ion-modal isOpen="true">
        <ion-header>
            <ion-toolbar>
                <ion-label class="surveyText">{{ $t('survey.title') }}</ion-label>
                    <ion-icon slot="end" size="large" color="danger" :icon="closeOutline" @click="modalController.dismiss()"></ion-icon>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-label class="surveyText">{{ $t('survey.question1') }}</ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col class="vue-reaction" v-for="(reactionItem, index) in reactionItems" :key="index">
                        <vue-reaction @click="setOverall(index+1)" v-model="reactionValue" :reaction="String(index+1)" :hover-image="reactionItem.hoverImage" :image="reactionItem.image" :selected-image="reactionItem.selectedImage"></vue-reaction>
                        <ion-label class="surveyText">{{ $t('survey.helptext.'+String(index+1)) }}</ion-label>
                        <div class="effect"></div>
                      </ion-col>
                </ion-row>
                <ion-row class="row">
                    <ion-col>
                        <ion-label class="surveyText">{{ $t('survey.question2') }}</ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <star-rating class="survey" :rating="3" :show-rating="false" @update:rating="setQuality"></star-rating>
                  </ion-col>
                </ion-row>
                <ion-row class="row">
                    <ion-col>
                        <ion-label class="surveyText">{{ $t('survey.question3') }}</ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <star-rating class="survey" :show-rating="false" :rating="3" @update:rating="setSecurity"></star-rating>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-button class="surveyText row" @click="sendFeedbacks">{{ $t('general.sendclose') }}</ion-button>
                  </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-modal>
</template>
<script>
import {defineComponent} from 'vue';
import {IonModal, modalController,   IonLabel,  IonIcon,  IonContent, IonHeader, IonToolbar, IonRow, IonGrid, IonCol} from '@ionic/vue';
import {closeOutline} from 'ionicons/icons';
import VueReaction from './feedback/Reaction.vue';
import feedbackMixin from './feedback/feedback-mixin.js';
import StatusToast from '@/components/StatusToast.vue';
import axios from 'axios';
import StarRating from 'vue-star-rating';
export default defineComponent({
    name:'FeedbackComponent',
    components:{
        IonModal,
        IonLabel,
        IonHeader,
        IonToolbar,
        IonIcon,
        IonContent,
        IonRow,
        IonGrid,
        IonCol,
        VueReaction,
        StarRating
    },
    mixins:[feedbackMixin],
    props: {
    value: {
      default: '',
      type: [String, Number]
    },
    labels: {
      default: () => [],
      type: Array,
      validator: v => v && (v.length === 0 || v.length === 5)
    },
    labelClass: {
      default: '',
      type: [Object, Array, String]
    },
    emojiWidth: {
      default: '',
      type: [String, Number]
    },
    emojiHeight: {
      default: '',
      type: [String, Number]
    },
    containerWidth: {
      default: '',
      type: [String, Number]
    },
    containerHeight: {
      default: '',
      type: [String, Number]
    }
  },
    setup(){
        return {modalController,closeOutline}
    },
    data(){
        return {
      reactionValue: '',
      reactionItems: [],
      quality: 3,
      security:3,
      overall: 3,
    }
    },
    mounted () {
    this.reactionValue = this.value
    this.reactionItems = [
      {
        hoverImage: this.hate,
        image: this.hateInactive,
        selectedImage: this.hateActive,
        label: this.labels[0] || ''
      },
      {
        hoverImage: this.disappointed,
        image: this.disappointedInactive,
        selectedImage: this.disappointedActive,
        label: this.labels[1] || ''
      },
      {
        hoverImage: this.natural,
        image: this.naturalInactive,
        selectedImage: this.naturalActive,
        label: this.labels[2] || ''
      },
      {
        hoverImage: this.good,
        image: this.goodInactive,
        selectedImage: this.goodActive,
        label: this.labels[3] || ''
      },
      {
        hoverImage: this.excellent,
        image: this.excellentInactive,
        selectedImage: this.excellentActive,
        label: this.labels[4] || ''
      }
    ]
  },
  methods:{
    sendFeedbacks(){
      this.sendFeedback("overall", this.overall);
      this.sendFeedback("quality", this.quality);
      this.sendFeedback("security", this.security);
      this.modalController.dismiss();
    },
    setQuality(rating){
      this.quality = rating;
    },
    setSecurity(rating){
      this.security = rating;
    },
    setOverall(rating){
      this.overall = rating;
    },
    sendFeedback(quiztype,number){
      const data = {
        userid: localStorage.getItem("id"),
        rating: number,
        role: localStorage.getItem("role"),
        type: quiztype
      }
      let values = {
                    message: "",
                    status: false
                };
      axios.post(this.$api_add + localStorage.getItem("org") + 'appreview/createreview',data).then(res =>{
                if (res.data.result == 'ok') {
                                values.message = this.$t("info.feedbackSuccess");
                                values.status = true;
                                StatusToast.methods.openToast(values);
                            }
                            else {
                                values.message = this.$t("info.feedbackFailure");
                                values.status = false;
                                StatusToast.methods.openToast(values);
                            }
            })
            
    }
  },
  watch: {
    reactionValue (value) {
      this.$emit('input', value)
    },
    value (val) {
      this.reactionValue = val
    }
  }
})
</script>
<style>
.surveyText{
  text-align: center;
  display:block;
}
.survey{
  justify-content: center;
}
.row{
  margin-top: 2em;
}
</style>
