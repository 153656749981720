<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-item>
					<h3>{{$t('operator.editRoute')}}</h3>
				</ion-item>
				<form>
					<ion-row>
						<ion-col size="12">
							<h3>{{$t('operator.routeInfo')}}</h3>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										placeholder="Reitin nimi"
										id="routeName"
										v-model="editRouteForm.routename"
										name="routeName"
										:label="$t('operator.routeName')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-select :label="$t('operator.routeType')" labelPlacement="stacked" disabled :placeholder="$t('operator.routeType')" v-model="editRouteForm.type" :value="editRouteForm.type" :change="setType(editRouteForm.type)">
										<ion-select-option value="0">{{$t('operator.routeTypes.0')}}</ion-select-option>
										<ion-select-option value="1">{{$t('operator.routeTypes.1')}}</ion-select-option>
									</ion-select>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-select :label="$t('operator.routeDays')" labelPlacement="stacked" :placeholder="$t('operator.routeDays')" v-model="editRouteForm.days" :value="editRouteForm.days">
										<ion-select-option value="1">{{$t('general.days.1')}}</ion-select-option>
										<ion-select-option value="2">{{$t('general.days.2')}}</ion-select-option>
										<ion-select-option value="3">{{$t('general.days.3')}}</ion-select-option>
										<ion-select-option value="4">{{$t('general.days.4')}}</ion-select-option>
										<ion-select-option value="5">{{$t('general.days.5')}}</ion-select-option>
										<ion-select-option value="6">{{$t('general.days.6')}}</ion-select-option>
									</ion-select>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
								<ion-select :label="$t('operator.routeEndPoint')" labelPlacement="stacked" :placeholder="$t('general.school')" v-model="editRouteForm.endPointIdx">
									<ion-select-option :value="null" key="9999">{{$t('operator.noEndPoint')}}</ion-select-option>
									<ion-select-option v-for="(school, index) in schools" :value="index" :key="index">{{school.name}}</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
								<ion-select :label="$t('operator.assignedVehicle')" labelPlacement="stacked" :placeholder="$t('operator.assignedVehicle')" v-model="editRouteForm.assignedvehicle">
									<ion-select-option :value="null" key="9999">{{$t('general.noneBase')}}</ion-select-option>
									<ion-select-option :selected="car.id == editRouteForm.assignedvehicle ? true : false" v-for="(car, index) in cars" :value="car.id" :key="index">{{car.name}}</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<h5>{{$t('operator.routeStartInfo')}}</h5>
						</ion-col>
						<ion-col :size="isdesktop ? 8 : 12" :hidden="this.routetype">
							<ion-row>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item>
										<ion-input
											id="startAdd"
											:placeholder="$t('general.address')"
											v-model="editRouteForm.startadd"
											name="startAdd0"
											:label="$t('general.address')"
											labelPlacement="stacked"
											type="text"
											required
										></ion-input>
								</ion-item>
							</ion-col>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item>
										<ion-input
											id="startAdd"
											:placeholder="$t('general.postalCode')"
											v-model="editRouteForm.startpostal"
											name="startPostal"
											type="text"
											:label="$t('general.postalCode')"
											labelPlacement="stacked"
											required
										></ion-input>
								</ion-item>
							</ion-col>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item>
										<ion-input
											id="startAdd"
											:placeholder="$t('general.city')"
											v-model="editRouteForm.startcity"
											name="startCity"
											type="text"
											:label="$t('general.city')"
											labelPlacement="stacked"
											required
										></ion-input>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-col>
					<ion-col size="6" :hidden="!this.routetype">
						<ion-item>
							<ion-select :label="$t('operator.startAddress')" labelPlacement="stacked" :placeholder="$t('general.school')" :change="setRouteSchool()" v-model="editRouteForm.selectedSchoolIdx">
								<ion-select-option v-for="(school, index) in schools" :value="index" :key="index">{{school.name}}</ion-select-option>
							</ion-select>
						</ion-item>
					</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										id="startTime"
										:placeholder="$t('operator.startTime')"
										v-model="editRouteForm.starttime"
										name="startTime"
										type="text"
										:label="$t('operator.startTime')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<h3>{{$t('operator.routePoints')}}</h3>
						</ion-col>
						<ion-col size="12" class="ion-margin-top">
							<ion-label class="bld">{{$t('operator.pickUpAddressInfo')}}</ion-label>
						</ion-col>
						<ion-col size="12" :hidden="this.routetype">
							<ion-row>
								<ion-col :size="isdesktop ? 4 : 12">
									<ion-item>
										<ion-select :label="$t('operator.pickupAddress')" labelPlacement="stacked" interface="popover" :placeholder="$t('general.school')" :change="setPointAddress('pickup')" v-model="pointForm.selectedAddressIdx">
											<ion-select-option v-for="(address, index) in filteredAddresses" :value="index" :key="index">{{address.address}}</ion-select-option>
										</ion-select>
									</ion-item>
									<ion-note class="ion-padding" color="secondary">{{$t('operator.selectAddressOrType')}}</ion-note>
								</ion-col>
								<ion-col :size="isdesktop ? 4 : 12">
									<ion-item>
											<ion-input
												id="pointPickAdd"
												:placeholder="$t('general.address')"
												v-model="pointForm.pickupadd"
												name="pointpickadd"
												type="text"
												:label="$t('general.address')"
												labelPlacement="stacked"
												required
												></ion-input>
										</ion-item>
								</ion-col>
								<ion-col :size="isdesktop ? 4 : 12">
									<ion-item>
											<ion-input
												id="pointPickAdd"
												:placeholder="$t('general.postalCode')"
												v-model="pointForm.pickuppostal"
												name="pointpickadd"
												:label="$t('general.postalCode')"
												labelPlacement="stacked"
												type="text"
												required
												></ion-input>
										</ion-item>
								</ion-col>
								<ion-col :size="isdesktop ? 4 : 12">
									<ion-item>
											<ion-input
												id="pointPickAdd"
												:placeholder="$t('general.city')"
												v-model="pointForm.pickupcity"
												name="pointpickadd"
												:label="$t('general.city')"
												labelPlacement="stacked"
												type="text"
												required
												></ion-input>
										</ion-item>
								</ion-col>
							</ion-row>
						</ion-col>
						<ion-col size="12" :hidden="!this.routetype">
								<ion-row>
									<ion-col :size="isdesktop ? 4 : 12" :hidden="!this.routetype">
										<ion-item>
											<ion-select :label="$t('operator.pickupAddress')" labelPlacement="stacked" :placeholder="$t('operator.pickupAddress')" :change="setSchool()" v-model="pointForm.selectedSchoolIdx">
												<ion-select-option v-for="(school, index) in schools" :value="index" :key="index">{{school.name}}</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col :size="isdesktop ? 4 : 12" :hidden="this.routetype">
										<ion-item class="ionic-no-padding">
											<ion-input
												id="pointDelAdd"
												:placeholder="$t('general.address')"
												v-model="pointForm.deliveryadd"
												name="pointdeladd"
												type="text"
												:label="$t('general.address')"
												labelPlacement="stacked"
												required
											></ion-input>
										</ion-item>
									</ion-col>
									<ion-col :size="isdesktop ? 4 : 12" :hidden="this.routetype">
										<ion-item>
											<ion-input
												id="pointDelAdd"
												:placeholder="$t('general.postalCode')"
												v-model="pointForm.deliverypostal"
												name="pointdeladd"
												:label="$t('general.postalCode')"
												labelPlacement="stacked"
												type="text"
												required
											></ion-input>
										</ion-item>
									</ion-col>
									<ion-col :size="isdesktop ? 4 : 12" :hidden="this.routetype">
										<ion-item>
											<ion-input
												id="pointDelAdd"
												:placeholder="$t('general.city')"
												v-model="pointForm.deliverycity"
												name="pointdeladd"
												type="text"
												:label="$t('general.city')"
												labelPlacement="stacked"
												required
											></ion-input>
									</ion-item>
								</ion-col>
								</ion-row>
						</ion-col>
						<ion-col size="12" class="ion-margin-top">
							<ion-label class="bld">{{$t('operator.deliveryAddressInfo')}}</ion-label>
						</ion-col>
						<ion-col :hidden="this.routetype" :size="isdesktop ? 4 : 12">
							<ion-item>
								<ion-select :label="$t('operator.deliveryAddress')" labelPlacement="stacked" :placeholder="$t('general.school')" :change="setSchool()" v-model="pointForm.selectedSchoolIdx">
									<ion-select-option v-for="(school, index) in schools" :value="index" :key="index">{{school.name}}</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>
						<ion-col size="12" :hidden="!this.routetype">
							<ion-row>
						<ion-col :size="isdesktop ? 4 : 12" :hidden="!this.routetype">
							<ion-item>
								<ion-select :label="$t('operator.deliveryAddress')" labelPlacement="stacked" interface="popover" :placeholder="$t('operator.deliveryAddress')" :change="setPointAddress('delivery')" v-model="pointForm.selectedAddressIdx">
										<ion-select-option v-for="(address, index) in filteredAddresses" :value="index" :key="index">{{address.address}}</ion-select-option>
								</ion-select>
								</ion-item>
								<ion-note class="ion-padding" color="secondary">{{$t('operator.selectAddressOrType')}}</ion-note>
							</ion-col>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item class="ionic-no-padding">
									<ion-input
										id="pointDelAdd"
										:placeholder="$t('general.address')"
										v-model="pointForm.deliveryadd"
										name="pointdeladd"
										:label="$t('general.address')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
								</ion-item>
							</ion-col>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item>
									<ion-input
										id="pointDelAdd"
										:placeholder="$t('general.postalCode')"
										v-model="pointForm.deliverypostal"
										name="pointdeladd"
										type="text"
										:label="$t('general.postalCode')"
										labelPlacement="stacked"
										required
									></ion-input>
								</ion-item>
							</ion-col>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item>
									<ion-input
										id="pointDelAdd"
										:placeholder="$t('general.city')"
										v-model="pointForm.deliverycity"
										name="pointdeladd"
										:label="$t('general.city')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
								</ion-item>
							</ion-col>
						</ion-row>
						</ion-col>
						<ion-col size="12" class="ion-margin-top">
							<ion-label class="bld">{{$t('operator.childInfo')}}</ion-label>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-row>
								<ion-col>
									<ion-item>
										<ion-select :label="String($t('general.childName'))" labelPlacement="stacked" :placeholder="$t('general.childName')" v-model="pointForm.childIdx">
											<ion-select-option v-for="(child, index) in children" :value="index" :key="index">{{String(child.firstname +" "+ child.lastname)}}</ion-select-option>
										</ion-select>
									</ion-item>
							</ion-col>
							<ion-col :hidden="!this.pointForm.showNameField" :size="isdesktop ? 4 : 12">
								<ion-item>
									<ion-input
											id="delChild"
												class="ion-margin-top"
												:placeholder="$t('general.childNameAlt')"
												v-model="pointForm.child"
												name="delChild"
												:label="String($t('general.childNameAlt'))"
												labelPlacement="stacked"
												type="text"
												required
											></ion-input>
										</ion-item>
									</ion-col>
								</ion-row>
							</ion-col>
							<ion-col :hidden="this.routetype" :size="isdesktop ? 4 : 12">
								<ion-item>
									<ion-input
										id="startStoptime"
										:placeholder="$t('operator.startStopTime')"
										v-model="pointForm.timespent"
										name="startStoptime"
										:label="$t('operator.startStopTime')"
										labelPlacement="stacked"
										type="number"
										value="2"
										required
									></ion-input>
								</ion-item>
							</ion-col>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item>
									<ion-input
										id="delTimewindow"
										:placeholder="this.routetype ? $t('operator.deliveryTime') : $t('operator.pickupTime')"
										v-model="pointForm.timewindow"
										name="delTimewindow"
										:label="this.routetype ? $t('operator.deliveryTime') : $t('operator.pickupTime')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
								</ion-item>
							</ion-col>
						<ion-col>
							<ion-item lines="none">
								<ion-button :size="isdesktop ? '' : 'full'" @click="addPoint(pointForm)">{{$t('operator.addPoint')}}</ion-button>
							</ion-item>
						</ion-col>
						<ion-col size="12" class="btmborder topborder ion-margin-top">
							<ion-row>
								<ion-col size="12">
									<ion-item lines="none">
										<ion-toggle :enable-on-off-labels="true" labelPlacement="end" color="success" slot="start" v-model="this.reorder" :checked="!this.reorder">{{this.reorder ? $t('operator.reorderEnabled') : $t('operator.reorderDisabled')}}</ion-toggle>
									</ion-item>
								</ion-col>
								<ion-col size="12">
									<ion-row>
										<ion-col class="bld tabletfont" :size="isdesktop ? '' : 4">
											{{$t('operator.orderNumber')}}
										</ion-col>
										<ion-col class="bld tabletfont" :size="isdesktop ? '' : 4">
											{{$t('operator.pickupAddress')}}
										</ion-col>
										<ion-col class="bld tabletfont" :size="isdesktop ? '' : 4">
											{{$t('operator.deliveryAddress')}}
										</ion-col>
										<ion-col class="bld tabletfont" :size="isdesktop ? '' : 4">
											{{$t('general.childName')}}
										</ion-col>
										<ion-col class="bld tabletfont" :hidden="this.routetype" :size="isdesktop ? '' : 4">
											{{$t('operator.startStopTime')}}
										</ion-col>
										<ion-col class="bld tabletfont" :size="isdesktop ? '' : 4">
											{{this.routetype ? $t('operator.deliveryTime') : $t('operator.pickupTime')}}
										</ion-col>
										<ion-col class="bld tabletfont" :size="isdesktop ? '' : 4">
										</ion-col>
									</ion-row>
								</ion-col>
								<ion-col size="12">
									<ion-row class="pointtable" :key="forceUpdate">
										<ion-reorder-group :disabled="!this.reorder" @ionItemReorder="handleReorder($event)" class="fullwidth">
											<ion-reorder class="fullwidth tabletnopadding" v-for="(points, index) in routePoints" :key="index">
												<ion-col size="12">
												<ion-row>
													<ion-col :size="isdesktop ? '' : 4">
														<ion-item class="smalltext">
															<ion-icon v-if="isdesktop" slot="start" :icon="menuOutline" />
															<ion-select :label="isdesktop ? '' : $t('operator.orderNumber')" :labelPlacement="isdesktop ? '' : 'stacked'" :placeholder="index+1" @ionChange="changePointOrder(index, $event.detail.value)">
																<ion-select-option v-for="pidx in Array.from({length:routePoints.length}, (v,i)=>i)" :value="pidx" :key="pidx" :selected="pidx == index ? true : false">{{pidx+1}}</ion-select-option>
															</ion-select>
														</ion-item>
													</ion-col>
													<ion-col :size="isdesktop ? '' : 4">
														<ion-item class="smalltext">
															<ion-input :label="isdesktop ? '' : $t('operator.pickupAddress')" :labelPlacement="isdesktop ? '' : 'stacked'" :value="points.pickup" @ionChange="editPointAddress(index, 'pickup')" v-model="points.pickup"></ion-input>
														</ion-item>
													</ion-col>
													<ion-col :size="isdesktop ? '' : 4">
														<ion-item class="smalltext">
															<ion-input :label="isdesktop ? '' : $t('operator.deliveryAddress')" :labelPlacement="isdesktop ? '' : 'stacked'" :value="points.delivery" @ionChange="editPointAddress(index, 'delivery')" v-model="points.delivery"></ion-input>
														</ion-item>
													</ion-col>
													<ion-col :size="isdesktop ? '' : 4">
														<ion-item class="smalltext">
															<ion-select v-if="allChildren.length > 0" :label="isdesktop ? '' : $t('general.childName')" :labelPlacement="isdesktop ? '' : 'stacked'" :placeholder="$t('general.childName')" :value="points.child" :change="changeChild(points.child, index)" v-model="points.child">
																<ion-select-option v-for="child in allChildren" :value="child.id" :key="child.id" :selected="points.child == child.id">{{String(child.firstname +" "+ child.lastname)}}</ion-select-option>
															</ion-select>
														</ion-item>
													</ion-col>
													<ion-col :hidden="this.routetype" :size="isdesktop ? '' : 4">
														<ion-item class="smalltext">
															<ion-input :label="isdesktop ? '' : $t('operator.startStopTime')" :labelPlacement="isdesktop ? '' : 'stacked'" :value="points.timespent" v-model="points.timespent"></ion-input>
														</ion-item>
													</ion-col>
													<ion-col :size="isdesktop ? '' : 4">
														<ion-item class="smalltext">
															<ion-input :label="isdesktop ? '' : (this.routetype ? $t('operator.deliveryTime') : $t('operator.pickupTime'))" :labelPlacement="isdesktop ? '' : 'stacked'" :value="points.timewindow" v-model="points.timewindow"></ion-input>
														</ion-item>
													</ion-col>
													<ion-col :size="isdesktop ? '' : 4">
														<ion-button color="danger" :size="isdesktop ? 'small' : 'full'" @click="deletePoint(index)">
															{{$t('operator.delete')}}
														</ion-button>
													</ion-col>
												</ion-row>
										</ion-col>
									</ion-reorder>
									</ion-reorder-group>
									</ion-row>
								</ion-col>
							</ion-row>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="editRoute(editRouteForm)">{{$t('general.save')}}</ion-button>
							<ion-button color="danger" @click="modalController.dismiss()">{{$t('general.cancel')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
				<StatusToast :show="showMessage" :message="statusMessage" :status="editStatus"/>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonLabel,
	modalController,
	IonSelect,
	IonSelectOption,
	IonInput,
  IonCol,
  IonButton,
  IonReorder,
  IonReorderGroup,
	IonToggle,
	IonNote,
	onIonViewDidEnter,
	IonIcon
} from "@ionic/vue";
import { defineComponent, toRaw, ref } from 'vue';
import axios from 'axios';
import {
	menuOutline
} from 'ionicons/icons';
import StatusToast from '@/components/StatusToast.vue';
export default defineComponent({
    name: "EditRoute",
		props: ['routedata','cardata'],
		emits: ['responsedata'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonLabel,
			IonSelect,
			IonSelectOption,
			IonInput,
			IonCol,
			IonButton,
			StatusToast,
			IonReorder,
			IonToggle,
			IonReorderGroup,
			IonNote,
			IonIcon
		},
    methods:{
			async editRoute(form){
				//Combine forms
				const rawObject = {...form};
				let points = toRaw(this.routePoints); //parse proxy object
				const rawdays = String(toRaw(this.editRouteForm.days));
				const childs = [];
				const schoolids = [];
				if(rawObject['startaddress'] == null && !this.routetype){
					rawObject['startaddress'] = String(rawObject['startadd'].trim() + ", " + rawObject['startpostal'].trim() + " " + rawObject['startcity'].trim())
				}
				else if(rawObject['startaddress'] == null && this.routetype && this.editRouteForm.selectedSchoolIdx != null){
					const schooldata = this.schools[this.editRouteForm.selectedSchoolIdx];
					rawObject['startaddress'] = schooldata.address;
				}
				for (let i = 0; i < points.length; i++) {
					if(points[i]['timespent'] != null  && rawObject['type'] == 0){
						points[i]['duration'] = (parseInt(points[i]['timespent']))*60;
						//points[i]['timewindow'] = "12:00";
					}
					else if(rawObject['type'] == 1){
						points[i]['duration'] = 0;
						//points[i]['timewindow'] = "17:00";
						if(points[i]['pickup'] == null || points[i]['pickup'] == undefined){
							points[i]['pickup'] = rawObject['startaddress'];
						}
						//points[i]['pickup'] = rawObject['startaddress'];
					}
					childs.push(points[i].child);
					schoolids.push({"child": points[i].child, "schoolid": points[i].schoolid})
				}

				if(this.editRouteForm.endPointIdx != null && this.editRouteForm.endPointIdx != undefined && this.editRouteForm.endPointIdx != -1){
					rawObject['endaddress'] = this.schools[this.editRouteForm.endPointIdx].address;
				}
				//console.log("editRoute ", rawObject);
				rawObject['points'] = points;
				rawObject['days'] = rawdays;
				rawObject['children'] = childs;
				rawObject['jwt'] = localStorage.getItem('token');
				rawObject['schools'] = schoolids;

			axios.post(this.$opt_add + localStorage.getItem("org") + 'createRoute', rawObject).then((res) => {
					if ((res.data.result == 'ok' || res.data == 'ok')) {
						if (res.data.errors == 0) {
                            this.$emit('responsedata', {
                                statusMessage: String(res.status + " " + this.$t('operator.routeResponses.editSuccess')),
								editStatus: true
                            });
						}
						else {
							this.$emit('responsedata', {
								statusMessage: String(res.status + " " + this.$t('operator.routeResponses.editSuccessWithErrors')).replace("$1",res.data.errors),
								editStatus: true
                            });
                        }

					}
					else{
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.routeResponses.editFailure') + res.statusText),
							editStatus: false
						});
					}
				}).catch((e)=>{
					this.$emit('responsedata', {
						statusMessage: String(this.$t('operator.routeResponses.editFailure') + e.message),
						editStatus: false
					});
				});
			},
			addPoint(form){
				const rawObject = {...form};
				const schooldata = toRaw(this.schools[this.pointForm.selectedSchoolIdx]);
				if(!this.pointForm.showNameField){
					const child = toRaw(this.children[this.pointForm.childIdx]);
					rawObject['child'] = child.id;
					rawObject['childName'] = String(child.firstname + " " + child.lastname);
				}
				else{
					rawObject['childName'] = rawObject['child'];
				}
				if(!this.routetype){
					rawObject['pickup'] = String(rawObject['pickupadd'].trim() + ", " + rawObject['pickuppostal'].trim() + " " + rawObject['pickupcity'].trim())
					rawObject['delivery'] = String(schooldata.address);
					//rawObject['schoolid'] = this.schools[this.pointForm.selectedSchoolIdx].id;
				}
				else if(this.routetype){
					rawObject['pickup'] = String(schooldata.address);
					//console.log(this.pointForm.selectedSchoolIdx, this.schools[this.pointForm.selectedSchoolIdx]);
					rawObject['delivery'] = String(rawObject['deliveryadd'].trim() + ", " + rawObject['deliverypostal'].trim() + " " + rawObject['deliverycity'].trim())
					//rawObject['schoolid'] = this.schools[this.pointForm.selectedSchoolIdx].id;
				}
				this.resetPointForm();
				this.routePoints.push(rawObject);
			},
			deletePoint(index){
				this.routePoints.splice(index, 1);
			},
			checkChildSelect(){
				if(this.pointForm.childIdx == -1){
					this.pointForm.showNameField = true;
				}
				else{
					this.pointForm.showNameField = false;
				}
			},
			changeChild(id, index){
				try{
					let data = toRaw(this.routePoints[index]);
					let d0 = toRaw(this.allChildren)
					let found = d0.find((yu)=>{
						return id == yu.id;
					});

					if(found != undefined){
						let name = String(found.firstname + " " + found.lastname);
						data['childName'] = name;
					}
				}
				catch(e){
					//Do nothing
					//console.log(e);
				}
			},
			editPointAddress(index,type){
				try{
					let address = type == 'pickup' ? this.routePoints[index].pickup : this.routePoints[index].delivery;
					if(address != null && address != undefined){
						const first = address.split(",");
						const second = first[1].split(" ").filter((ui)=>{
							return ui != ''
						});
						if(type == 'pickup'){
							this.routePoints[index].pickupadd = first[0];
							this.routePoints[index].pickupcity = second[1];
							this.routePoints[index].pickuppostal = second[0];
						}
						else if(type == 'delivery'){
							this.routePoints[index].deliveryadd = first[0];
							this.routePoints[index].deliverycity = second[1];
							this.routePoints[index].deliverypostal = second[0];
						}
					}
				}
				catch(e){
					//console.log(e);
				}
			},
			parseAddress(address){
				try{
					const splitter = address.split(",");
					const add = splitter[0];
					let postal = "";
					let city = "";
					if (splitter.length > 1) {
						const split0 = splitter[1].split(" ");
						postal = split0[1];
						city = split0[2];
					}
					return {"address": add, "postal": postal, "city": city};
				}
				catch(e){
					return {"address": '', "postal": '', "city": ''};
				}
			},
			parseDays(days){
				let split = days.split(",");
				return split;
			},
			parsePoints(points, type){
				for (let i = 0; i < points.length; i++) {
					points[i]['showExtra'] = type == 0 ? true : false; //show extra info if type is morning
					points[i]['timespent'] = this.parseTime(points[i]['duration']);
				}
				return points;
		},
		checkPoints() {
			let points = this.editRouteForm.points;
			for (let i = 0; i < points.length; i++) {
				try {
					let childId = points[i].child;
					let test = toRaw(this.children.find((el) => {
						return el.id == childId;
					}));
					if (typeof test == 'object') {
						points[i]['childName'] = String(test.firstname + " " + test.lastname);
					}
					else {
						points[i]['childName'] = points[i]['child'];
					}
				}
				catch (error) {
					//do nothing
				}
			}
        },
			setPointAddress(type){
				try{
					const address0 = this.addresses[this.pointForm.selectedAddressIdx].address;
					if(address0 != null && address0 != undefined && address0.length > 1){
						const first = address0.split(",");
						const second = first[1].split(" ").filter((ui)=>{
							return ui != ''
						});
						if(type == 'pickup'){
							this.pointForm.pickupadd = first[0];
							this.pointForm.pickupcity = second[1];
							this.pointForm.pickuppostal = second[0];
						}
						else if(type == 'delivery'){
							this.pointForm.deliveryadd = first[0];
							this.pointForm.deliverycity = second[1];
							this.pointForm.deliverypostal = second[0];
						}
					}
				}
				catch(e){
					//do nothing
				}
			},
			resetPointForm(){
				this.pointForm.pickup = null;
				this.pointForm.pickupadd = null;
				this.pointForm.pickupcity = null;
				this.pointForm.pickuppostal = null;
				this.pointForm.delivery = null;
				this.pointForm.deliveryadd = null;
				this.pointForm.deliverypostal = null;
				this.pointForm.deliverycity = null;
				this.pointForm.child = null;
				this.pointForm.childname = null;
				this.pointForm.timewindow = null;
				this.pointForm.timespent = 1;
				this.pointForm.showNameField = false;
			},
			setType(type){
				if(type == 0){
					this.routetype =  false;
				}
				else{
					this.routetype = true;
				}
			},
			setSchool(){
				const idx = this.pointForm.selectedSchoolIdx;

				if(idx != null){
					const schoolid = toRaw(this.schools[idx]).id;
					this.getChildren(schoolid);
				}
			},
			setRouteSchool(){
				const idx = this.editRouteForm.selectedSchoolIdx;

				if(idx != null && idx != -1){
					const schoolid = toRaw(this.schools[idx]).id;
					this.getChildren(schoolid);
				}
			},
			async getAllChildren(){
				axios.post(this.$api_add + localStorage.getItem("org") + 'child/getall').then((res)=>{
					if(res.data.result == 'ok'){
						this.allChildren = toRaw(res.data.data).sort((a,b)=>{
							return a.lastname - b.lastname
						});
						//this.getTempChildren();
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.userResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			getSchools(){
				axios.post(this.$api_add + localStorage.getItem("org") +'school/getall').then((res)=>{
					if (res.data.result == 'ok') {
						this.schools = toRaw(res.data.data);
						const dt = toRaw(this.routedata);
						const startadd = dt.start.location_id;
						const schoolIdx = toRaw(res.data.data).findIndex((x)=>{
							return x.address == startadd;
						});

						const endadd = dt.end.location_id;

						const endIdx = toRaw(res.data.data).findIndex((x)=>{
							return x.address == endadd;
						});

						this.editRouteForm.endPointIdx = endIdx;
						this.editRouteForm.selectedSchoolIdx = schoolIdx;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.schoolResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.schoolResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			getAddresses(){
				axios.post(this.$api_add + localStorage.getItem("org") +'coordinates/getdistinct').then((res)=>{
					if(res.data.result == 'ok'){
						this.addresses = res.data.data;
						this.filteredAddresses = res.data.data;
					}
				});
			},
			changePointOrder(old_index, new_index, event) {
				if(new_index != null && old_index != new_index){

					const arr = this.routePoints;
					if (new_index >= arr.length) {
						var k = new_index - arr.length + 1;
						while (k--) {
							arr.push(undefined);
						}
					}
					arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
					this.routePoints = arr;
					let nextstate = !this.forceUpdate;
					this.forceUpdate = nextstate;
				}
				//return arr; // for testing
			},
			parseTime(time){
				return (parseInt(time)/60).toFixed(0);
			},
			async getChildren(schoolid){
				const id = parseInt(schoolid);
				this.currentSchool = id;
				axios.post(this.$api_add + localStorage.getItem("org") +'child/getbyschool', {"schoolid":id}).then((res)=>{
					if(res.data.result == 'ok'){
						this.children = toRaw(res.data.data).sort((a,b)=>{
							return a.lastname - b.lastname
						});
						//this.getTempChildren();
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.userResponses.getFailed') + e);
					this.toastObject['status'] = false;
				})
			},
			handleReorder(event){
        this.routePoints = event.detail.complete(this.routePoints);
      }
    },
    data(){
			const rtdata = toRaw(this.routedata);
			const cdata = toRaw(this.cardata);
			const days = this.parseDays(rtdata.days);
			const rtype = String(rtdata.type);
			const pInputs = [];

			const startadd = this.parseAddress(rtdata.start.location_id);

      return{
				toastObject:{
					"message": null,
					"status": false
				},
				editStatus: false,
				showMessage: false,
				statusMessage: null,
				selectedDays: days,
				selectedType: rtdata.type,
				routetype: false,
				editRouteForm: {
					dbid: rtdata.dbid,
					routename: rtdata.name,
					selectedSchoolIdx: null,
					startaddress: null,
					startadd: startadd.address,
					startpostal: startadd.postal,
					startcity: startadd.city,
					starttime: rtdata.start.start_time,
					endaddress: null,
					endadd: null,
					endpostal: null,
					endcity: null,
					points: this.parsePoints(rtdata.points, rtdata.type),
					type: rtype,
					days: days,
					check: "edit",
					jwt: localStorage.getItem('token'),
					endPointIdx: null,
					assignedvehicle: rtdata.assignedvehicle
				},
				pointForm : {
					pickup: null,
					pickupadd: null,
					pickupcity: null,
					pickuppostal: null,
					delivery: null,
					deliveryadd: null,
					deliverypostal: null,
					deliverycity: null,
					child: null,
					childName: null,
					timewindow: null,
					timespent: 1,
					selectedSchoolIdx: null,
					selectedPointSchoolIdx: null,
					childIdx: null,
					showNameField: false,
					selectedAddressIdx: null
				},
				routePoints: toRaw(rtdata.points),
				editinputs:pInputs,
				schools:[],
				children: [],
				allChildren: [],
				currentSchool: null,
				isdesktop: this.$isdesktop,
				addresses: null,
				filteredAddresses: [],
				parents: null,
				reorder: false,
				forceUpdate: false,
				cars: cdata
      }
    },
		setup(){
			const showToastRef = ref(false);
			const showToast = (state) => showToastRef.value = state;
			return{
				modalController,
				ref,
				showToastRef,
				showToast,
				menuOutline
			}
		},
		created(){
			this.getAllChildren();
			this.getSchools();
			this.getAddresses();
		}
});
</script>
<style>
	.inpt{
		--background: #dedad9;
	}
	.bld{
		font-weight: bold;
	}
	.tableHeader {
		display: flex; /* or inline-flex */
		flex-direction: row;
	}
	.btmborder{
		border-bottom: solid 1px #cfcdca;
	}
	.topborder{
		border-top: solid 1px #cfcdca;
	}
	.pointtable{
		max-height:30vh;
		overflow: auto;
	}
	.smalltext{
		font-size: 0.90em;
	}
	@media only screen and (max-height: 799px) {
		.tabletfont{
			font-size:0.90em;
		}
		.tabletnopadding,
		.tabletnopadding *{
			padding: 0 !important;
			margin: 0 !important;
		}

	}
</style>
