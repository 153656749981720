<template>
                   <ion-grid>
                        
                            <ion-label class="ion-text-wrap">{{$t("gdpr."+getTenant()+".docTitle")}}</ion-label>
                       
                            <ion-label class="ion-text-wrap">{{$t("gdpr."+getTenant()+".createdDate")}}</ion-label>
                       
                            <ion-label class="ion-text-wrap">{{$t("gdpr."+getTenant()+".updatedDate")}}</ion-label>
                        
                            <ion-label class="ion-text-wrap">{{$t("gdpr."+getTenant()+".section")}}</ion-label>
                        
                            <ion-label class="ion-text-wrap">{{$t("gdpr."+getTenant()+".lawNumber")}}<br/></ion-label>
                       

                        <ion-item>
                        <ion-label style="white-space: pre-line;"><h2>{{$t("gdpr."+getTenant()+".p1Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p1Content")}}<br/></ion-label>
                    </ion-item>
                        <ion-item>
                        <ion-label style="white-space: pre-line"><h2>{{$t("gdpr."+getTenant()+".p2Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p2Content")}}<br/></ion-label>
                        
                    </ion-item>
                    <ion-item>
                        <ion-label class="ion-text-wrap"><h2>{{$t("gdpr."+getTenant()+".p3Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p3Content")}}<br/></ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label class="ion-text-wrap"><h2>{{$t("gdpr."+getTenant()+".p4Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p4Content")}}<br/></ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label class="ion-text-wrap"><h2>{{$t("gdpr."+getTenant()+".p5Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p5Content")}}<br/></ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label class="ion-text-wrap"><h2>{{$t("gdpr."+getTenant()+".p6Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p6Content")}}<br/></ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label class="ion-text-wrap"><h2>{{$t("gdpr."+getTenant()+".p7Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p7Content")}}<br/></ion-label>

                    </ion-item>
                    <ion-item>
                        <ion-label class="ion-text-wrap"><h2>{{$t("gdpr."+getTenant()+".p8Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p8Content")}}<br/></ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label class="ion-text-wrap"><h2>{{$t("gdpr."+getTenant()+".p9Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p9Content")}}<br/></ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label class="ion-text-wrap"><h2>{{$t("gdpr."+getTenant()+".p10Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p10Content")}}<br/></ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label class="ion-text-wrap"><h2>{{$t("gdpr."+getTenant()+".p11Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p11Content")}}<br/></ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label class="ion-text-wrap"><h2>{{$t("gdpr."+getTenant()+".p12Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p12Content")}}<br/></ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label class="ion-text-wrap"><h2>{{$t("gdpr."+getTenant()+".p13Title")}}</h2><br/>{{$t("gdpr."+getTenant()+".p13Content")}}<br/></ion-label>
                    </ion-item>
                    
                    </ion-grid>
            
</template>
<script lang="ts">
import {  IonItem,IonLabel, IonGrid,  } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'GDPRModal',
    components: {
        IonLabel,
        IonGrid,
        IonItem,
        
    },
    setup(){
        return { localStorage}
    },
    methods:{
        getTenant(){
            return localStorage.getItem("org").replace("/","") || "default";
        }
    }
});
</script>