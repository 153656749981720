<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title>{{$t("title.title")}}</ion-title>

                <info-component slot="end" side="end" />

                <locale-switcher slot="end" @updateLocale="updateLocale" size="48px" @click="$forceUpdate" />
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">

        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, toastController,modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import InfoComponent from '../components/Info.vue';
import {isPlatform} from '@ionic/vue';
import { informationCircleOutline } from 'ionicons/icons';
import { Browser } from '@capacitor/browser';
export default defineComponent({
  name: 'GDPRPage',
    components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    LocaleSwitcher,
        InfoComponent


},
  setup() {
      const router = useRouter();
      return { router, LocaleSwitcher, modalController, informationCircleOutline, InfoComponent };
    },
  methods:{
        async openPdf(){
          await Browser.open({ url: 'kayttoehdot.pdf', windowName: "_self" });
        },
    async openToast(msg: string){
      const toast = toastController
        .create({
          message: msg,
          duration: 3000,
          color: 'danger'
        });
        (await toast).present();
    },
    updateLocale(locale: string){
      this.$root.$i18n.locale = locale;
    },

  },
  data(){
    return{

    }
  },

  updated(){
    this.$root.$i18n.locale = localStorage.getItem("locale");
    },
  mounted() {
    if(!isPlatform('desktop')){
        this.$screenOrientation.unlock();
      }
      this.openPdf();
      
  }
});



</script>

<style scoped>
    #container {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

        #container strong {
            font-size: 20px;
            line-height: 26px;
        }

        #container p {
            font-size: 16px;
            line-height: 22px;
            color: #8c8c8c;
            margin: 0;
        }

        #container a {
            text-decoration: none;
        }

    ion-icon {
        font-size: 48px;
        padding-left: 16px;
    }
</style>
