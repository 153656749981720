<template>
  <div class="map" id="drivermap"></div>
</template>

<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { useGeographic } from "ol/proj";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import VectorSource from "ol/source/Vector";
import MultiPoint from 'ol/geom/MultiPoint.js';
import VectorLayer from "ol/layer/Vector";
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Icon from 'ol/style/Icon';
import { Geolocation } from '@capacitor/geolocation';
import axios from 'axios';
import { defineComponent, inject } from "vue";
import { isPlatform, toastController, getPlatforms } from '@ionic/vue';
import { Control, defaults as defaultControls } from 'ol/control';
    let isWatching = false;
    let isFollowing = true;
export default defineComponent({
  name: "MapComponent",
  props: {
    carid: Number,
    routeid: Number,
    routelinestring: Array[Number,Number],
    stops: Array[Number,Number],
    doneStops: Array[Number,Number],
    school: Array[Number, Number],
    componentKey: Number,
  },
  setup(){

     const apiAdd = inject('$api_add');
    return {apiAdd,isPlatform,getPlatforms};
  },


  data() {
    return {
      initialCoordinates: [24.5676, 65.7343],
      locationOptions: {
        enableHighAccuracy: true,
        timeout: 1000,
        maximumAge: 0
      },
      mainMap: null,
      vectorLayer: null,
      routeCoords: JSON.parse(JSON.stringify(this.routelinestring)),
      route: {
        routeId: this.routeid
      },
      car: {
        id: this.carid,
        lat: Number,
        lon: Number,
        heading: Number,
        speed: Number
        },
      currentLocation: [24.5676, 65.7343],
      locationFeature: null,
      gpsWatcher: null,
      positions: new LineString([], 'XYZM'),
      previousM: 0,
      deltaMean: 500,
      stopsDone: [],

    };
  },

  mounted() {

    useGeographic();
    this.locationFeature = new Feature({
      geometry: new Point(this.currentLocation),
      name:'Current Location'
    });
    const iconLocationStyle = new Style({
      image: new Icon({
        anchor:[0.5, 32],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: 'assets/icon/geolocation_marker_heading.png',
      }),
    });
    this.locationFeature.setStyle(iconLocationStyle);
    this.vectorLayer = new VectorLayer({
      name: 'basemap',
      source: new VectorSource({
        features: [this.locationFeature],
      })
    })
    this.mainMap = new Map({
        controls: defaultControls().extend([new StopFollowing(), new SwitchLocation(), new ZoomIn(), new ZoomOut()]),

        layers: [
          new TileLayer({
            name: 'mainmap',
            source: new OSM(),
            preload: Infinity
          }),

          this.vectorLayer
        ],
        target:'drivermap',
        view: new View({
        center: [24.5676, 65.7343],
        zoom:zoomLevel,
        constrainResolution: true
      }),
      })
   setTimeout(() => {



        this.mainMap.updateSize();

      }, 500);
    setInterval(() => {


      this.updateCarLocation();
    }, 5000);

  },

  methods: {
    markerGenerator(text){
      let svg = '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="61" height="33" viewBox="0 0 61 85" xml:space="preserve"><path d="M31.75 0C48.318 0 61 12.488 61 29.057V30c0 21.834-19.322 49-29.75 55H31C20.572 79 0 51.834 0 30v-.943C0 12.488 13.932 0 30.5 0h1.25z"/><path fill="white" d="M31.688 2C47.428 2 59 13.989 59 29.729v.896C59 51.367 41.119 77 31.212 83h-.237C21.069 77 2 51.367 2 30.625v-.896C2 13.989 14.76 2 30.5 2h1.188z"/><text x="50%" y="50%" dy=".13em" font-size="40" font-weight="bold" text-anchor="middle" fill="red" style="font-family:&quot;Roboto&quot;,sans-serif;text-rendering:optimizeLegibility">'+ text +'</text></svg>'
    return svg;
    },
    radToDeg(rad){
      return (rad * 360) / (Math.PI * 2);
    },
    degToRad(deg){
      return (deg * Math.PI * 2) / 360;
    },
    mod(n) {
      return ((n % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);
    },

    updateSource(coords) {

      let stopPoint = JSON.parse(JSON.stringify(this.stops));
      let doneStopPoint = JSON.parse(JSON.stringify(this.doneStops));

      const source = this.vectorLayer.getSource();
      let doneCoords = [];
      let notDoneCoords = coords;
      let nextStopCoords = [];

    const notDoneStyleFunction = function(){
        const styles = [
          new Style({
            stroke: new Stroke({
              color: '#6073B1',
              width: 3
            })
          })
        ];
        return styles;
      }
      const nextStopStyleFunction = function(){
        const styles = [
          new Style({
            stroke: new Stroke({
              color: '#052955',
              width: 20
            })
          })
        ];
        return styles;
      }

      if(doneStopPoint != undefined && doneStopPoint.length > 0){
        doneStopPoint.sort((a,b) => stopPoint.indexOf(a) - stopPoint.indexOf(b))


          let lastCoord = doneStopPoint[doneStopPoint.length-1];

          let tempCoords = coords;

        for(let i = 0; i < notDoneCoords.length; i++){
					let endDiffX = Math.abs(notDoneCoords[i][1] - lastCoord[1]);
					let endDiffY = Math.abs(notDoneCoords[i][0] - lastCoord[0]);

          if(endDiffX < 0.001 && endDiffY < 0.001){


            doneCoords = tempCoords.slice(0,i+1);

            notDoneCoords = tempCoords.slice(i, coords.length-1 );

          }
        }
      }

      const doneStyleFunction = function(){
        const styles = [
          new Style({
            stroke: new Stroke({
              color: '#AE9C45',
              width: 6
            })
          })
        ];
        return styles;
      }

    let layerNotDone = new VectorLayer({
      name:'route',
      source: new VectorSource({
        features:[new Feature({
        geometry: new LineString(notDoneCoords),
        name:'Line'
      })],
    }),
    style: notDoneStyleFunction
    });
    let layerNextStop = new VectorLayer({
      name:'route',
      source: new VectorSource({
        features:[new Feature({
        geometry: new LineString(nextStopCoords),
        name:'Line'
      })],
    }),
    style: nextStopStyleFunction
    });
   let layerDone = new VectorLayer({
      name:'route',
      source: new VectorSource({
        features:[new Feature({
        geometry: new LineString(doneCoords),
        name:'Line'
      })],
    }),
    style: doneStyleFunction
    });

     const layerMap = new VectorLayer({
      name: 'map',
      source: source,
    });
    let stop = JSON.parse(JSON.stringify(this.stops));
    stop.flat();
    let schoolPoints = JSON.parse(JSON.stringify(this.school));


    const iconStartFeature = new Feature({
      geometry: new Point(coords[0]),
      name: 'Start',
    });
    const iconEndFeature = new Feature({
      geometry: new Point(coords[coords.length-1]),
      name: 'End',
    });
    const iconSchoolFeature = new Feature({
      geometry: new MultiPoint(schoolPoints),
      name: 'Schools',
    });
    //let indexIcon = 1;
    const iconStartStyle = new Style({
  image: new Icon({
    anchor: [0.1, 32],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    src: 'assets/icon/start.png',
  }),
});
const iconEndStyle = new Style({
  image: new Icon({
    anchor: [0.5, 32],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    src: 'assets/icon/finish.png',
  }),
});
const iconSchoolStyle = new Style({
  image: new Icon({
    anchor: [0.5, 32],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    src: 'assets/icon/school.png',
  }),
});

let stopIcons = [];
let indexIcon = 1;
stop.forEach((o)=>{

	let iconUserFeature = new Feature({
		geometry: new Point(o),
		name:'User'
	});
	let sour = 'data:image/svg+xml;utf8,' + this.markerGenerator(indexIcon);
	let iconUserStyle = new Style({
		image: new Icon({
			anchor: [0.5, 32],
			anchorXUnits: 'fraction',
			anchorYUnits: 'pixels',
			src: sour,
		}),
	});
	iconUserFeature.setStyle(iconUserStyle);
	stopIcons.push(iconUserFeature);
	++indexIcon;

})
    iconSchoolFeature.setStyle(iconSchoolStyle);
    iconStartFeature.setStyle(iconStartStyle);
    iconEndFeature.setStyle(iconEndStyle);
    //iconUserFeature.setStyle(iconUserStyle);
    const vectorSource = new VectorSource({
  features: [iconStartFeature, iconEndFeature, iconSchoolFeature ].concat(stopIcons),
});

const flagLayer = new VectorLayer({
  name: 'flag',
  source: vectorSource,
});


    let layers = this.mainMap.getAllLayers();
    layers.forEach(l =>{
      if(l.get('name') === 'route' || l.get('name') === 'flag'){
        this.mainMap.removeLayer(l);
      }
    })

    this.mainMap.addLayer(layerDone);
    this.mainMap.addLayer(layerNotDone);
    this.mainMap.addLayer(layerNextStop);
    this.mainMap.addLayer(flagLayer);
    this.mainMap.addLayer(layerMap);
    //view.fit(source.getExtent());

    this.mainMap.render();

  },
  updateView() {
  // use sampling period to get a smooth transition
  let m = Date.now() - this.deltaMean * 1.5;
  m = Math.max(m, this.previousM);
  this.previousM = m;
  // interpolate position along positions LineString
  const c = this.positions.getCoordinateAtM(m, true);
  if (c) {
    console.log("update view with c:" + c.toString())
    this.mainMap.getView().animate({zoom: zoomLevel},{center:[c[0],c[1]]},{duration:1000},{rotation:-c[2]});
    //this.mainMap.getView().setRotation(-c[2]);
    //this.mainMap.getView().setZoom(19);
    this.mainMap.render();
  }
},
addPosition(position, heading, m, speed) {
  const x = position[0];
  const y = position[1];
  const fCoords = this.positions.getCoordinates();
  const previous = fCoords[fCoords.length - 1];
  const prevHeading = previous && previous[2];
  if (prevHeading) {
    let headingDiff = heading - this.mod(prevHeading);

    // force the rotation change to be less than 180°
    if (Math.abs(headingDiff) > Math.PI) {
      const sign = headingDiff >= 0 ? 1 : -1;
      headingDiff = -sign * (2 * Math.PI - Math.abs(headingDiff));
    }
    heading = prevHeading + headingDiff;
  }
  this.positions.appendCoordinate([x, y, heading, m]);

  // only keep the 20 last coordinates
  this.positions.setCoordinates(this.positions.getCoordinates().slice(-20));
  console.log(this.positions);

  },

  getRouteCoordinates() {
    //TODO: API call to get the route coordinates based on route id.
  },
  async updateCarLocation() {
    //console.log(getPlatforms())
    if(isPlatform('ios') || isPlatform('pwa') || isPlatform('android')){

    let coordinates;
    let locOpts = JSON.parse(JSON.stringify(this.locationOptions));
    if (await this.getPermissions()) {
      if(isWatching){
        if(this.gpsWatcher != null){
          Geolocation.clearWatch({id: this.gpsWatcher});
        }
        Geolocation.watchPosition(locOpts, (coordinates, error) => {
        const m = Date.now();
        console.log([coordinates.coords.longitude, coordinates.coords.latitude], this.degToRad(coordinates.coords.heading) || 0, m, coordinates.coords.speed || 0);
        this.addPosition([coordinates.coords.longitude, coordinates.coords.latitude], this.degToRad(coordinates.coords.heading) || 0, m, coordinates.coords.speed || 0);
        this.car.lon = coordinates.coords.longitude;
        this.car.lat = coordinates.coords.latitude;
        this.currentLocation = [this.car.lon, this.car.lat];

        }).then((watchid) =>{
          this.gpsWatcher = watchid;
        });

      }else{
        if(this.gpsWatcher != null){
          Geolocation.clearWatch({id: this.gpsWatcher});
        }
        coordinates = await Geolocation.getCurrentPosition(locOpts);
        const m = Date.now();
        console.log([coordinates.coords.longitude, coordinates.coords.latitude], this.degToRad(coordinates.coords.heading) || 0, m, coordinates.coords.speed || 0);
        this.addPosition([coordinates.coords.longitude, coordinates.coords.latitude], this.degToRad(coordinates.coords.heading) || 0, m, coordinates.coords.speed || 0);
        this.car.lon = coordinates.coords.longitude;
        this.car.lat = coordinates.coords.latitude;
        this.currentLocation = [this.car.lon, this.car.lat];
      }

      if (isFollowing) {
            this.updateView();
            //await this.mainMap.getView().setCenter([this.car.lon, this.car.lat]);
            //await this.mainMap.getView().setZoom(16);

      }
      this.locationFeature.setGeometry(new Point(this.currentLocation));
      await axios.post(this.apiAdd + localStorage.getItem("org") + 'location/updatelocation', { "longitude": this.car.lon, "latitude": this.car.lat, "vehicleid": this.car.id });


    }
    }
  },

  getRouteId() {
    return this.route.routeId;
  },
  updateRouteId(id) {
    console.log(this.initialCoordinates);
    this.routeId = id;
  },
  async getPermissions() {
    const permission = await Geolocation.checkPermissions();

    if (permission.location === "granted") {
      return true;
    } else {
      await Geolocation.requestPermissions();
    }
    return false;
  },
  },
  watch:{
    routelinestring: {
      handler(value,oldvalue){
        if(JSON.parse(JSON.stringify(value)) !== JSON.parse(JSON.stringify(oldvalue))&& value.length > 0){

          this.updateSource(JSON.parse(JSON.stringify(value)));

        }
        else if(value.length == 0){
          this.updateSource([0,0])
        }

      },
      deep: true,
      flush: 'post'

    },
    doneStops: {
      handler(value,oldvalue){

        if(JSON.parse(JSON.stringify(value)) !== JSON.parse(JSON.stringify(oldvalue))&& value.length > 0){

          this.stopsDone = JSON.parse(JSON.stringify(value));
          this.updateSource(JSON.parse(JSON.stringify(this.routelinestring)));

        }


      },
      deep: true,
      flush: 'post'

    }




}

});
let zoomLevel = 16;
    class StopFollowing extends Control {



        constructor() {
            const button = document.createElement('button');
            button.innerHTML = 'F';

            const element = document.createElement('div');
            element.className = 'rotate-north ol-unselectable ol-control';
            element.appendChild(button);

            super({
                element: element
            });

            button.addEventListener('click', this.handleStopFollowing.bind(this), false);
        }

        handleStopFollowing() {
            if (isFollowing) {
                this.presentToast(false);
                isFollowing = false;
            }
            else {
                this.presentToast(true);
                isFollowing = true;
            }
        }
        async presentToast(showthis) {
            let message = "Screen tracking on";
            if (!showthis) {
                message = "Screen tracking off";
            }

            const toast = toastController
                .create({
                    message: message,
                    duration: 3000,
                    color: 'danger',
                    position: "middle"
                });
            (await toast).present();
        }
    }
    class SwitchLocation extends Control {



        constructor() {
            const button = document.createElement('button');
            button.innerHTML = 'L';

            const element = document.createElement('div');
            element.className = 'custom-control ol-unselectable ol-control';
            element.appendChild(button);

            super({
                element: element
            });
            isWatching = true;
            button.addEventListener('click', this.handleStopWatching.bind(this), false);
        }

        handleStopWatching() {
            if (isWatching) {
                this.presentToast(false);
                isWatching = false;
            }
            else {
                this.presentToast(true);
                isWatching = true;
            }
        }
        async presentToast(showthis) {
            let message = "Paikannus kokoajan päällä.";
            if (!showthis) {
                message = "Paikannus päällä 5 sekunnin välein.";
            }

            const toast = toastController
                .create({
                    message: message,
                    duration: 3000,
                    color: 'danger',
                    position: "middle"
                });
            (await toast).present();
        }
    }
    class ZoomIn extends Control{
      constructor() {
            const button = document.createElement('button');
            button.innerHTML = 'Z+';

            const element = document.createElement('div');
            element.className = 'zoom-in ol-unselectable ol-control';
            element.appendChild(button);

            super({
                element: element
            });

            button.addEventListener('click', this.increaseZoom.bind(this), false);
        }
       async increaseZoom(){
          zoomLevel += 1;
          const toast = toastController
                .create({
                    message: "Zoom Level: " + zoomLevel,
                    duration: 3000,
                    color: 'danger',
                    position: "middle"
                });
            (await toast).present();
        }
    }
    class ZoomOut extends Control{
      constructor() {
            const button = document.createElement('button');
            button.innerHTML = 'Z-';

            const element = document.createElement('div');
            element.className = 'zoom-out ol-unselectable ol-control';
            element.appendChild(button);

            super({
                element: element
            });

            button.addEventListener('click', this.decreaseZoom.bind(this), false);
        }
       async decreaseZoom(){
          zoomLevel += -1;
          const toast = toastController
                .create({
                    message: "Zoom Level: " + zoomLevel.toString(),
                    duration: 3000,
                    color: 'danger',
                    position: "middle"
                });
            (await toast).present();
        }
    }
</script>

<style>
#drivermap {
  min-height: 100px;
  height: 100%;
  width: 100%;
}
    .rotate-north {
        top: 65px;
        left: .5em;
    }

    .ol-touch .rotate-north {
        top: 80px;
    }
    .custom-control{
      top: 120px;
      left: .5em;
    }
    .zoom-in{
      top: 165px;
      left: .5em;
    }
    .zoom-out{
      top: 210px;
      left: .5em;
    }
</style>
