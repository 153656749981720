<template>

  <ion-page >
		<ion-split-pane id="sidepanel" content-id="main" when="xs">
			<ion-menu side="end" content-id="side-panel" id="operator-menu">
				<ion-content>
					<ion-grid>
						<ion-col size="12">
							<info-component />
							<locale-switcher @updateLocale="updateLocale" size="24px" @click="$forceUpdate"/>
						</ion-col>
						<ion-col size="12">
							<ion-img src="assets/logo.png"></ion-img>
						</ion-col>
						<ion-col size="12" v-if="isdesktop">
							<ion-list class="ion-text-start">
								<ion-item button="true" @click="changeView('home')" lines="none" color="primary">
									<ion-icon :icon="compassOutline" slot="start" size="large"></ion-icon>
									<ion-label>{{$t('operator.map')}}</ion-label>
								</ion-item>
								<ion-item button="true" color="primary" @click="changeView('routes')">
									<ion-icon :icon="mapOutline" slot="start" size="large"></ion-icon>
									{{$t('operator.routes')}}
								</ion-item>
								<ion-item button="true" color="primary" lines="none" @click="changeView('dailyroutes')">
									<ion-icon :icon="calendarOutline" slot="start" size="large"></ion-icon>
									{{$t('operator.weeklyRoutes')}}
								</ion-item>
								<ion-item button="true" color="primary" lines="none" @click="changeView('late')">
									<ion-icon :icon="	listCircleOutline" slot="start" size="large"></ion-icon>
									{{$t('operator.patchRoutes')}}
								</ion-item>
								<ion-item button="true" color="primary" lines="none" @click="changeView('exceptions')">
									<ion-icon :icon="personRemoveOutline" slot="start" size="large"></ion-icon>
									{{$t('operator.exceptions')}}
								</ion-item>
								<ion-item button="true" color="primary" lines="none" @click="changeView('cars')">
									<ion-icon :icon="carOutline" slot="start" size="large"></ion-icon>
									{{$t('operator.vehicles')}}
								</ion-item>
								<ion-item button="true" color="primary" lines="none" @click="changeView('drivers')">
									<ion-icon :icon="personOutline" slot="start" size="large"></ion-icon>
									{{$t('operator.drivers')}}
								</ion-item>
								<ion-item button="true" color="primary" lines="none" @click="changeView('users')">
									<ion-icon :icon="peopleOutline" slot="start" size="large"></ion-icon>
									{{$t('operator.users')}}
								</ion-item>
								<ion-item button="true" color="primary" lines="none" @click="changeView('schools')">
									<ion-icon :icon="schoolOutline" slot="start" size="large"></ion-icon>
									{{$t('general.schools')}}
								</ion-item>
								<ion-item button="true" color="primary" lines="none" @click="changeView('changes')">
									<ion-icon :icon="fileTrayStackedOutline" slot="start" size="large"></ion-icon>
									{{$t('operator.changeRequests')}}
								</ion-item>
								<ion-item button="true" color="primary" lines="none" @click="changeView('statistics')">
									<ion-icon :icon="analyticsOutline" slot="start" size="large"></ion-icon>
									{{$t('operator.statistics')}}
								</ion-item>
								<ion-item id="message-button" button="true" color="primary" lines="none" @click="getMessages()">
									<ion-icon :icon="chatboxOutline" slot="start" size="large"></ion-icon>
									{{$t('general.messages')}}
								</ion-item>
								<ion-item id="message-button" button="true" color="primary" lines="none" @click="changeView('bulletins')">
									<ion-icon :icon="informationCircleOutline" slot="start" size="large"></ion-icon>
									{{$t('operator.bulletins')}}
								</ion-item>
								<ion-item id="message-button" button="true" color="primary" lines="none" @click="changeView('feedback')">
									<ion-icon :icon="receiptOutline" slot="start" size="large"></ion-icon>
									{{$t('operator.feedbacks')}}
								</ion-item>
								<ion-item id="message-button" button="true" color="primary" lines="none" @click="changeView('logs')">
									<ion-icon :icon="newspaperOutline" slot="start" size="large"></ion-icon>
									{{$t('operator.confirmsBase')}}
								</ion-item>
                <ion-item id="message-button" button="true" color="primary" lines="none" @click="changeView('coordinates')">
                  <ion-icon :icon="locationOutline" slot="start" size="large"></ion-icon>
                  {{$t('operator.savedAddresses')}}
                </ion-item>
							</ion-list>
						</ion-col>
						<ion-col v-else class="ion-text-center">
							<ion-list>
									<ion-button @click="changeView('home')" expand="full" size="large">
										<ion-icon :icon="compassOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('routes')" expand="full" size="large">
										<ion-icon :icon="mapOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('dailyroutes')" expand="full" size="large">
										<ion-icon :icon="calendarOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('late')" expand="full" size="large">
										<ion-icon :icon="	listCircleOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('exceptions')" expand="full" size="large">
										<ion-icon :icon="personRemoveOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('cars')" expand="full" size="large">
										<ion-icon :icon="carOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('drivers')" expand="full" size="large">
										<ion-icon :icon="personOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('users')" expand="full" size="large">
										<ion-icon :icon="peopleOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('schools')" expand="full" size="large">
										<ion-icon :icon="schoolOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('changes')" expand="full" size="large">
										<ion-icon :icon="fileTrayStackedOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('statistics')" expand="full" size="large">
										<ion-icon :icon="analyticsOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button id="message-button" @click="getMessages()" expand="full" size="large">
										<ion-icon :icon="chatboxOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('bulletins')" expand="full" size="large">
										<ion-icon :icon="informationCircleOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('feedback')" expand="full" size="large">
										<ion-icon :icon="receiptOutline" slot="start" size="large"></ion-icon>
									</ion-button>
									<ion-button @click="changeView('logs')" expand="full" size="large">
										<ion-icon :icon="newspaperOutline" slot="start" size="large"></ion-icon>
									</ion-button>
                  <ion-button @click="changeView('coordinates')" expand="full" size="large">
                    <ion-icon :icon="locationOutline" slot="start" size="large"></ion-icon>
                  </ion-button>
							</ion-list>
						</ion-col>
						<ion-col size="12" class="ion-align-items-end" v-if="isdesktop">
							<ion-item button="true" color="danger" lines="none" @click="logOut();" class="ion-margin-top">
								<ion-icon :icon="logOutOutline" slot="start" size="large"></ion-icon>
								{{$t('general.logOut')}}
							</ion-item>
						</ion-col>
						<ion-col size="12" class="ion-align-items-end" v-else>
							<ion-button color="danger" @click="logOut();" class="ion-margin-top" size="large" expand="full">
								<ion-icon :icon="logOutOutline" slot="start" size="large"></ion-icon>
							</ion-button>
						</ion-col>
					</ion-grid>
				</ion-content>
			</ion-menu>
			<ion-content :fullscreen="true" id="main">
				<ion-content v-if="currentPage == 'home'">
					<MapComponent/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'routes'">
					<OperatorRoutes/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'cars'">
					<OperatorCars/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'drivers'">
          <OperatorDrivers/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'users'">
          <OperatorUsers/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'schools'">
					<OperatorSchools/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'changes'">
          <OperatorChanges/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'dailyroutes'">
					<OperatorDaily/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'late'">
					<OperatorLateOnes/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'statistics'">
					<OperatorStatistics/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'exceptions'">
					<OperatorExceptions/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'bulletins'">
					<OperatorBulletins/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'feedback'">
					<OperatorFeedback/>
				</ion-content>
				<ion-content v-else-if="currentPage == 'logs'">
					<OperatorLogs/>
				</ion-content>
        <ion-content v-else-if="currentPage == 'coordinates'">
          <OperatorAddresses/>
        </ion-content>
				<ion-modal trigger="message-button">
						<ion-list style="height:100%;overflow-y:scroll;">
								<ion-item>
										<ion-button slot="end" @click="newMessage" color="secondary">{{$t('driver.newMessage')}}</ion-button>
										<ion-button slot="end" @click="modalController.dismiss()" color="danger">{{$t('parent.close')}}</ion-button>
								</ion-item>
								<ion-list-header>
										<ion-label>{{ $t('parent.time') }}</ion-label>
										<ion-label>{{$t('driver.sender')}}</ion-label>
										<ion-label>{{$t('driver.message')}}</ion-label>
								</ion-list-header>
								<ion-item v-for="mes of messages" :key="mes.id" :ref="`mes-${mes.id}`" >
										<ion-label>{{ moment(mes.timestamp).format('DD.MM.yyyy HH:mm:ss') }}</ion-label>
										<ion-label>{{mes.name}}</ion-label>
										<ion-label style="white-space:normal;">{{mes.message}}</ion-label>
								</ion-item>
						</ion-list>
				</ion-modal>
			</ion-content>
    </ion-split-pane>
  </ion-page>

</template>
<script>
import {
  IonPage,
  IonContent,
  IonSplitPane,
  IonMenu,
	IonImg,
	IonGrid,
	IonItem,
	IonLabel,
	IonCol,
	IonIcon,
  IonList,
	IonModal,
  IonButton,
  alertController,
	modalController,
	IonListHeader,
	onIonViewDidEnter
} from "@ionic/vue";
import { defineComponent } from 'vue';
import MapComponent from './operator/operatorMap.vue'
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import { App } from '@capacitor/app';
import {
	mapOutline,
	compassOutline,
	carOutline,
	personOutline,
	peopleOutline,
	logOutOutline,
	fileTrayStackedOutline,
	schoolOutline,
	chatboxOutline,
	calendarOutline,
	analyticsOutline,
	listCircleOutline,
	personRemoveOutline,
	informationCircleOutline,
	receiptOutline,
	newspaperOutline,
  locationOutline
} from 'ionicons/icons';
import OperatorRoutes from './operator/Routes.vue';
import OperatorCars from './operator/Cars.vue';
import OperatorDrivers from './operator/Drivers.vue';
import OperatorChanges from './operator/Changes.vue';
import OperatorUsers from './operator/Users.vue';
import OperatorDaily from './operator/Dailyroutes.vue';
import OperatorSchools from './operator/Schools.vue';
import OperatorStatistics from './operator/Statistics.vue';
import OperatorLateOnes from './operator/LateChilds.vue';
import OperatorExceptions from './operator/Exceptions.vue';
import OperatorBulletins from './operator/Bulletins.vue';
import OperatorFeedback from './operator/Feedbacks.vue';
import OperatorLogs from './operator/ConfirmLogs.vue';
import OperatorAddresses from './operator/Addresses.vue';
import { useRouter } from "vue-router";
import axios from 'axios';
import moment from 'moment';
import InfoComponent from '../components/Info.vue';

export default defineComponent({
  name: "OperatorPage",
  components: {
    IonContent,
    IonPage,
		IonGrid,
		IonImg,
		IonCol,
		IonIcon,
		IonLabel,
    IonSplitPane,
    IonMenu,
		IonItem,
    IonList,
		IonModal,
		IonButton,
    MapComponent,
		OperatorRoutes,
		OperatorDrivers,
		OperatorChanges,
		OperatorUsers,
		OperatorCars,
		OperatorSchools,
		LocaleSwitcher,
		IonListHeader,
		OperatorDaily,
		OperatorStatistics,
		OperatorLateOnes,
		OperatorExceptions,
		OperatorBulletins,
		OperatorFeedback,
		InfoComponent,
		OperatorLogs,
    OperatorAddresses
  },
  methods:{
		async changeView(view){
			this.currentPage = view;
		},
		async logOut(){
			const userid = localStorage.getItem('id');
			const pushtoken = localStorage.getItem('pushtoken');
			axios.post(this.$api_add + localStorage.getItem("org") + 'users/removepushtoken', {"id": userid, "pushtoken": pushtoken}).then(async()=>{
				//console.log(u);
			}).catch(()=>{
				//console.log("e", e);
			}).finally(()=>{
				localStorage.removeItem("id");
				localStorage.removeItem("role");
				localStorage.removeItem("token");
				localStorage.removeItem("dashboardItems");
				localStorage.removeItem("dashboardIdCounter");
				localStorage.removeItem("cubetoken");
				localStorage.removeItem('pushtoken');

				axios.defaults.headers.common['Authorization'] = "";
				this.$router.replace("/home");
			});
		},
		updateLocale(locale){
      this.$root.$i18n.locale = locale;
		},
		getMessages(){
			axios.post(this.$api_add + localStorage.getItem("org") + 'messages/getmessagesarchive', {}).then(res => {
				const msgs = [];
				res.data.data.forEach((element) => {
					msgs.push(element)
				});
				this.messages = msgs;
			}).catch(e => { console.log(e); });
		},
		sendNewMessage(data) {
				axios.post(this.$api_add + localStorage.getItem("org") + 'messages/create', {
						userid: localStorage.getItem("id"),
						message: data
				}).then(() => {
						this.getMessages(); //update messages
						modalController.dismiss();
				})
		},
		async getDashboard(){
			const id = localStorage.getItem('id');
			axios.post(this.$api_add + localStorage.getItem("org") + 'users/getDashboard', {"id": id}).then((result)=>{
				if(result.data.result == 'ok'){
					let state = (JSON.parse(result.data.data.dashboardstate) || "");
					const count = (result.data.data.dashboardcount || 0 );
					localStorage.setItem("dashboardItems", state);
					localStorage.setItem("dashboardIdCounter", count);
				}
			})
		},
		async newMessage() {

				let inp = {data:[]};


				let obj = {
						label: this.$t('driver.message'),
						type: 'textarea',
						value: ""
				};
				inp.data.push(obj);


				const alert = await alertController.create({
						header: this.$t('driver.newMessage'),
						buttons: [
								{
										text: this.$t('general.cancel'),
										role: 'cancel',
										handler: () => { console.log("cancel") }
								},
								{
										text: 'OK',
										role: 'confirm',
										handler: (data) => { console.log("ok"); console.log(data); this.sendNewMessage(data[0]); }
								}
						],
						inputs:inp.data
				});
				await alert.present();

				await alert.onDidDismiss();
			},
			checkLogin() {
					if (localStorage.getItem("token") != undefined && localStorage.getItem("token") != null) {
							let token = localStorage.getItem("token");
							const config = {
									headers: { Authorization: `Bearer ` + token }
							};
							axios.post(this.$api_add + localStorage.getItem("org") + 'users/checktoken', {}, config).then(result => {
									if (result.data.result === "ok") {
										//do nothing
									} else {
										localStorage.removeItem("id");
										localStorage.removeItem("role");
										localStorage.removeItem("token");
										localStorage.removeItem("dashboardItems");
										localStorage.removeItem("dashboardIdCounter");
										localStorage.removeItem("cubetoken");
										localStorage.removeItem('pushtoken');
										this.$router.push('/');

									}

							}).catch((err) => {
									//this.openToast(error);
									localStorage.removeItem("id");
									localStorage.removeItem("role");
									localStorage.removeItem("token");
									localStorage.removeItem("dashboardItems");
									localStorage.removeItem("dashboardIdCounter");
									localStorage.removeItem("cubetoken");
									localStorage.removeItem('pushtoken');
									this.$router.push('/');
							});
					}
			},
  },
	mounted(){
		const token = localStorage.getItem('token');
		axios.defaults.headers.common['Authorization'] = String("Bearer " + token);
		let lcl = localStorage.getItem("locale");

		if(lcl == undefined || lcl == null){
			lcl = "fi";
		}
		this.$root.$i18n.locale = lcl;

		this.getMessages();
		this.getDashboard();

		App.addListener('appStateChange', ({ isActive }) => {
			if(isActive){
				this.checkLogin();
			}
		})
	},
  data(){
    return{
			currentPage: 'home',
			messages: null,
			isdesktop: this.$isdesktop
    }
  },
	setup(){
		const router = useRouter();
		onIonViewDidEnter(()=>{
			const token = localStorage.getItem("token");
			const role = localStorage.getItem("role");
			if ((token  == null || token == undefined || token == "") && (role !== "operator" || role === undefined ||role === null)) {
				console.log()
				router.push("/home");
			}
		})
		return{
			mapOutline,
			compassOutline,
			carOutline,
			personOutline,
			peopleOutline,
			logOutOutline,
			fileTrayStackedOutline,
			schoolOutline,
			chatboxOutline,
			modalController,
			calendarOutline,
			analyticsOutline,
			listCircleOutline,
			personRemoveOutline,
			informationCircleOutline,
			receiptOutline,
			newspaperOutline,
      locationOutline,
			moment
		}
	},
	updated(){
		this.$root.$i18n.locale = localStorage.getItem("locale") ?? 'fi';
	}
});
</script>
<style>
#main {
  order: 1;
  border-inline-start: var(--border);
}
ion-side-pane{
	order: 2;
}
#operator-menu{
	width: 13vw;
	font-size: 0.9em;
}
.test{
	line-height: 50%;
	text-align:center;
}
@media only screen and (max-height: 800px) {
	#operator-menu{
		width: 15vw;
	}
	.centericon {
		display: flex;
		flex-direction: column;
		align-items: center;
  }
}
</style>
