<template>
	<DashboardGrid :dashboardItems="dashboardItems" />
</template>

<script>
import gql from 'graphql-tag';
import DashboardGrid from './components/Grid';

export default {
  name: 'AnalyticsDashboard',
  apollo: {
    dashboardItems: {
      query: gql`
        query {
          dashboardItems {
            id
            name
            layout
            vizState
            type
          }
        }
      `,
      pollInterval: 1000,
			fetchPolicy: 'no-cache',
      deep: true,
      update: (data) => {
        return data['dashboardItems'];
      },
    },
  },

  components: {
    DashboardGrid
  },
  data() {
    return {
      dashboardItems: [],
    };
  },
};
</script>
