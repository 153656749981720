<template>
    <ion-page>
        <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>{{$t('register.register')}}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
        <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{$t('general.register')}}</ion-title>


        </ion-toolbar>
      </ion-header>
      <locale-switcher @updateLocale="updateLocale" size="36px" />
      <div id="container">
          <form novalidate>
              <ion-list>
                  <ion-item hidden="true" style="visibility:hidden;">
                      <ion-input :hidden="true"
                                label="Rooli"
                                label-placement="stacked"
                                 id="role"
                                 :v-model="regForm.role"
                                 name="role"
                                 type="text"
                                 :spellcheck="false"
                                 autocapitalize="off"
                                 required
                                 disabled
                                 ></ion-input>
                  </ion-item>
                  <ion-item>
                      <ion-input id="name"
                      :label="$t('general.firstName')"
                                label-placement="stacked"
                                 v-model="regForm.firstname"
                                 name="name"
                                 type="text"
                                 :spellcheck="false"
                                 autocapitalize="on"
                                 autocomplete="given-name"
                                 required></ion-input>
                  </ion-item>
                  <ion-item>
                      <ion-input id="name"
                      :label="$t('general.lastName')"
                                label-placement="stacked"
                                 v-model="regForm.lastname"
                                 name="name"
                                 type="text"
                                 :spellcheck="false"
                                 autocapitalize="on"
                                 autocomplete="family-name"
                                 required></ion-input>
                  </ion-item>

                  <ion-item v-if="assistantMode == 0">
                      <ion-input id="address"
                      :label="$t('general.address')"
                                label-placement="stacked"
                                 v-model="regForm.address"
                                 name="address"
                                 type="text"
                                 :spellcheck="false"
                                 autocapitalize="on"
                                 autocomplete="street-address"
                                 required></ion-input>
                  </ion-item>
                  <ion-item v-if="assistantMode == 0">
                      <ion-input id="address"
                      :label="$t('general.postalCode')"
                                label-placement="stacked"
                                 v-model="regForm.zipcode"
                                 name="zipcode"
                                 type="text"
                                 :spellcheck="false"
                                 autocapitalize="on"
                                 autocomplete="postal-code"
                                 required></ion-input>
                  </ion-item>
                  <ion-item v-if="assistantMode == 0">
                      <ion-label position="stacked" color="primary">{{$t('general.city')}}</ion-label>
                      <ion-input id="address"
                      :label="$t('general.city')"
                                label-placement="stacked"
                                 v-model="regForm.city"
                                 name="city"
                                 type="text"
                                 :spellcheck="false"
                                 autocapitalize="on"
                                 autocomplete="address-level2"
                                 required></ion-input>
                  </ion-item>
                  <ion-item v-if="assistantMode == 1">
                      <ion-select :label="$t('general.address')"
                                label-placement="stacked" v-model="regForm.addressdefault" required>
                          <ion-select-option v-for="school in schools" :key="school.address" :value="school.address">{{school.address}}</ion-select-option>

                      </ion-select>
                  </ion-item>
                  <ion-item>
                      <ion-input id="email"
                      :label="$t('general.email')"
                                label-placement="stacked"
                                 v-model="regForm.email"
                                 name="email"
                                 type="email"
                                 :spellcheck="false"
                                 autocapitalize="off"
                                 autocomplete="email"
                                 required></ion-input>
                  </ion-item>
                  <ion-item>
                      <ion-input id="phone"
                      :label="$t('general.phone')"
                                label-placement="stacked"
                                 v-model="regForm.phone"
                                 name="phone"
                                 type="text"
                                 :spellcheck="false"
                                 autocapitalize="off"
                                 autocomplete="tel"
                                 required></ion-input>
                  </ion-item>
                  <ion-item>
                      <ion-input id="username"
                      :label="$t('general.username')"
                                label-placement="stacked"
                                 v-model="regForm.username"
                                 name="username"
                                 type="text"
                                 :spellcheck="false"
                                 autocapitalize="off"
                                 autocomplete="username"
                                 required></ion-input>
                  </ion-item>
                  <ion-item>
                      <ion-input id="password"
                      :label="$t('general.password')"
                                label-placement="stacked"
                                 v-model="regForm.password"
                                 name="password"
                                 type="password"
                                 :spellcheck="false"
                                 autocapitalize="off"
                                 autocomplete="new-password"
                                 required></ion-input>
                  </ion-item>
                  <ion-item>
                      <ion-label @click="openDisclaimer" style="cursor:pointer;">{{$t('general.disclaimer')}}</ion-label>
                  </ion-item>
                  <ion-item>
                      <ion-checkbox :label="$t('general.disclaimer_approval')" v-model="regForm.consent" color="danger"></ion-checkbox>
                  </ion-item>
              </ion-list>
              <ion-row responsive-sm>
                <ion-col>
                    <ion-button @click="onRegister(regForm)" expand="block">{{$t('general.newAccount')}}</ion-button>
                </ion-col>
                </ion-row>
          </form>
      </div>
    </ion-content>
    </ion-page>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { IonPage, IonContent, IonToolbar, IonHeader, IonItem, IonList, IonLabel, IonInput, IonButton, IonCol, IonRow, IonTitle, toastController, IonSelect, IonSelectOption, IonCheckbox } from '@ionic/vue';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import { useRoute } from 'vue-router';
import { Browser } from '@capacitor/browser';
import axios from 'axios';
export default defineComponent({
    name: 'RegisterPage',
    components:{
    IonPage,
    IonContent,
    IonToolbar,
    IonHeader,
    IonItem,
    IonList,
    IonInput,
    IonLabel,
    IonCol,
    IonRow,
    IonButton,
    IonTitle,
        LocaleSwitcher,
        IonSelect,
        IonSelectOption,
        IonCheckbox,
    },
    setup() {
        const route = useRoute();
        localStorage.setItem("org","");
        return{route};
    },
    data(){
        return{
            regForm: {
                role: this.$route.params.role,
                firstname: null,
                lastname: null,
                address: null,
                addressdefault: null,
                city: null,
                zipcode: null,
                email: null,
                phone: null,
                username: null,
                password: null,
                mainaccountid: null,
                validationid: this.$route.params.validateid,
                consent: false
                
            },
            schools: [],
            assistantMode:0
        };
    },
    mounted() {

        this.$root.$i18n.locale = localStorage.getItem("locale");
        if (this.$route.params.role == "1") {
            this.assistantMode = 1;
            this.getSchools();
        }

        const tenancy = String(this.$route.params['tenantid']+"/");

        localStorage.setItem("org", tenancy);
    },
    methods:{
        async onRegister(form: object) {

            if (this.regForm.consent === true) {
                localStorage.setItem("org", this.$route.params.tenantid.toString() + "/");
                await this.validateRegister().then(r => {
                    form = this.removeWhiteSpaces();
                    if (r) {
                        if (this.assistantMode == 0) {
                            this.regForm.addressdefault = this.regForm.address + " , " + this.regForm.zipcode + " " + this.regForm.city;
                        }

                        axios.post(this.$api_add + localStorage.getItem("org") + "users/create_account", form, { headers: { "Access-Control-Allow-Origin": this.$api_add } }).then(result => {
                            if (result.data.result === "ok") {
                                this.openToast(this.$t("register.success"), "success");
                            }
                            else {
															this.openToast(this.$t("register.fail"), "danger");
                            }
                        })
                    } else {
                        this.openToast(this.$t("register.fail"), "danger");
                    }
                });
            }
            else {
                console.log("false");
            }

            console.log(this.regForm.consent);

        },
        openDisclaimer() {
            Browser.open({ url: 'kayttoehdot.pdf' });
        },
        getSchools() {
            axios.post(this.$api_add + localStorage.getItem("org") + "school/getall", {  }, { headers: { "Access-Control-Allow-Origin": this.$api_add } })
                .then(response => {
                    if (response.data.result == "ok") {
                        this.schools = response.data.data;
                        if (this.schools.length > 0) {
                            this.regForm.addressdefault = this.schools[0].address;
                        }
                    }


                })
        },
        updateLocale(locale: string){
            this.$root.$i18n.locale = locale;
        },
        removeWhiteSpaces(){
            this.regForm.firstname = this.regForm.firstname.trimStart().trimEnd();
            this.regForm.lastname = this.regForm.lastname.trimStart().trimEnd();
            this.regForm.username = this.regForm.username.trimStart().trimEnd();
            this.regForm.address = this.regForm.address.trimStart().trimEnd();
            this.regForm.city = this.regForm.city.trimStart().trimEnd();
            this.regForm.zipcode = this.regForm.zipcode.trimStart().trimEnd();
            this.regForm.email = this.regForm.email.trimStart().trimEnd();
            this.regForm.phone = this.regForm.phone.trimStart().trimEnd();
            this.regForm.password = this.regForm.password.trimStart().trimEnd();
            return this.regForm;
        },
        async validateRegister(){

            const newPromise = new Promise((resolve) => {
                axios.post(this.$api_add + localStorage.getItem("org") +"registration/validateregistration", {email: this.regForm.email, validationid: this.$route.params.validateid, role: this.$route.params.role },{headers:{"Access-Control-Allow-Origin":this.$api_add}})
            .then(response =>{
                if(response.data.result == "ok"){
                    resolve(true);
                }


            })
            })
            return newPromise;
        },
        async openToast(msg:string, color:string){
            const toast = toastController
            .create({
                message: msg,
                duration: 3000,
                color: color
            });
            (await toast).present();
            (await toast).onDidDismiss().then(() =>{
                this.$router.push("/");
            });
        }
    },
})
</script>
