<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';


axios.defaults.headers.common = {};
axios.defaults.headers.common['Digipolis-API-Key'] = process.env.VUE_APP_DB_APIKEY;
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  
});
</script>
