<template>
    <ion-grid>
			<ion-header>
				<ion-toolbar>
					<ion-title>{{$t("operator.statistics")}}</ion-title>
				</ion-toolbar>
			</ion-header>
        <ion-row>
					<ion-col size="12" class="ion-margin">
						<ion-buttons>
							<ion-button size="large" @click="changeView('graphs')">{{$t("operator.analytics.graphs")}}</ion-button>
							<ion-button size="large" @click="changeView('builder')">{{$t("operator.analytics.queryBuilder")}}</ion-button>
						</ion-buttons>
					</ion-col>
					<ion-col size="12" v-if="currentPage == 'builder'">
						<AnalyticsBuilder />
					</ion-col>
					<ion-col size="12" v-if="currentPage == 'graphs'">
						<AnalyticsDashboard />
				</ion-col>
			</ion-row>
    </ion-grid>
</template>

<script lang="ts">
import {
  IonGrid,
  IonRow,
	IonButton,
	IonButtons,
  IonHeader,
  IonCol,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import AnalyticsBuilder from '@/components/analytics/QueryEditor.vue';
import { defineComponent } from 'vue';
import AnalyticsDashboard from './dashboard/Dashboard.vue';

export default defineComponent({
    name:'OperatorStatistics',
    components:{
			AnalyticsBuilder,
			AnalyticsDashboard,
			IonGrid,
			IonRow,
			IonButton,
			IonButtons,
			IonHeader,
			IonCol,
			IonTitle,
			IonToolbar,
    },
    methods:{
			changeView(view: string){
				this.currentPage = view;
			},
    },
    data(){
        return{
						currentPage: 'graphs'
        };
    }
});

</script>
