<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<form novalidate>
					<ion-row>
            <ion-col>
							<ion-item>
								<ion-select :label="$t('operator.role')" labelPlacement="stacked" :placeholder="$t('operator.chooseRole')" v-model="userForm.role">
									<ion-select-option value="0" selected>{{$t('general.parent')}}</ion-select-option>
									<ion-select-option value="1">{{$t('general.assistant')}}</ion-select-option>
									<ion-select-option value="2">{{$t('general.roles.child')}}</ion-select-option>
									<ion-select-option value="3">{{$t('general.roles.operator')}}</ion-select-option>
								</ion-select>
							</ion-item>
							<ion-item v-if="userForm.role != 2">
									<ion-textarea
										id="userEmails"
										rows="10"
										:placeholder="$t('operator.userEmails')"
										v-model="userForm.emails"
										name="userEmails"
										type="text"
										ref="userEmails"
										:label="$t('operator.userEmails')"
										labelPlacement="stacked"
										required
									></ion-textarea>
							</ion-item>
							<ion-item v-else-if="userForm.role == 2" lines="none">
								<ion-row>
									<ion-col :size="isdesktop ? 6 : 12">
										<ion-item>
											<ion-input :label="$t('general.firstName')" labelPlacement="stacked" id="childFirstname" name="childfirstname" :placeholder="$t('general.firstName')" v-model="childForm.firstname" type="text"></ion-input>
										</ion-item>
									</ion-col>
									<ion-col :size="isdesktop ? 6 : 12">
										<ion-item>
											<ion-input :label="$t('general.lastName')" labelPlacement="stacked" id="childLastname" name="childlastname" :placeholder="$t('general.lastName')" v-model="childForm.lastname" type="text"></ion-input>
										</ion-item>
									</ion-col>
									<ion-col :size="isdesktop ? 6 : 12">
										<ion-item>
											<ion-select :label="$t('general.school')" labelPlacement="stacked" :placeholder="$t('general.school')" name="childschool" v-model="childForm.schoolid" id="childSchool">
												<ion-select-option v-for="(school, index) in schools" :value="school.id" :key="index">{{school.name}}</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col :size="isdesktop ? 6 : 12">
										<ion-item>
											<ion-input :label="$t('driver.helpLevel')" labelPlacement="stacked" id="childAssistance" name="childassistance" :placeholder="$t('driver.helpLevel')" v-model="childForm.assistancelevel" type="text"></ion-input>
										</ion-item>
									</ion-col>
									<ion-col :size="isdesktop ? 6 : 12">
										<ion-item>
											<ion-select :label="$t('general.parent')" labelPlacement="stacked" :placeholder="$t('general.parent')" name="childparent" v-model="childForm.parentid" id="childParent">
												<ion-select-option v-for="(parent, index) in parents" :value="parent.id" :key="index">{{parent.name}}</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
								</ion-row>
							</ion-item>
						</ion-col>
						<ion-col v-if="userForm.role != 2">
							<ion-button @click="registerUser(userForm)">{{$t('operator.addUsers')}}</ion-button>
						</ion-col>
						<ion-col v-else-if="userForm.role == 2">
							<ion-button @click="registerChild(childForm)">{{$t('parent.addChild')}}</ion-button>
							<ion-input type="file" @change="onUpload"></ion-input>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
	IonInput,
  IonItem,
	IonSelect,
	IonTextarea,
	IonSelectOption,
  IonCol,
  IonButton
} from "@ionic/vue";
import { defineComponent, toRaw } from 'vue';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
export default defineComponent({
    name: "AddUser",
		emits: ['goback'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonSelect,
			IonSelectOption,
			IonTextarea,
			IonCol,
			IonButton,
			IonInput
		},
    methods:{
			registerChild(form){
				const data = toRaw(form);
				axios.post(this.$api_add + localStorage.getItem("org") +'child/create', data).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('goback', {statusMessage: String(this.$t('operator.userResponses.addSuccess')), editStatus: true});
					}
					else{
						this.$emit('goback', {statusMessage: String(res.status + " " + this.$t('operator.userResponses.addFailure') + res.statusText), editStatus: false});
					}
				}).catch((e)=>{
					this.$emit('goback', {statusMessage: String(this.$t('operator.userResponses.addFailure') + e.message), editStatus: false});
				});
			},
			registerUser(form){
				if(form){
					const data = toRaw(form);
					let emails = data.emails.split('\n');

					//delete empty spaces
					emails = emails.filter((value)=>{
						return value != "";
					});

					const promises = [];
					for (let i = 0; i < emails.length; i++) {
						//create promise for each registration
						promises.push(new Promise((resolve)=>{
							let obj = {
								"email": emails[i],
								"role": data.role,
								"validationid": uuidv4()
							}

							const result = axios.post(this.$api_add + localStorage.getItem("org") +'registration/create', obj);
							resolve(result);
						}))
					}
					Promise.all(promises).then((res)=>{
						//check if all went through
						const statuses = res.map((x)=>{
							return x.data.result;
						});

						const non200 = res.map((x)=>{
							return x.data.result != 'ok';
						});


						if(this.containsOnly(statuses, ['ok'])){
							this.$emit('goback', {statusMessage: String(this.$t('operator.userResponses.addSuccess')), editStatus: true});
						}
						else{
							this.$emit('goback', {statusMessage: String(non200[0].status + " " + this.$t('operator.userResponses.addFailure') + non200[0].statusText), editStatus: false});
						}
					}).catch((e)=>{
						this.$emit('goback', {statusMessage: String(this.$t('operator.userResponses.addFailure') + e.message), editStatus: false});
					});

					//regEx to validate the emails
					//const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
					//console.log(email, regEx.test(email));

				}
			},
			containsOnly(array1, array2){
				return array2.every(elem => array1.includes(elem))
			},
			async getSchools(){
				axios.post(this.$api_add + localStorage.getItem("org") +'school/getall').then((res)=>{
					if(res.data.result == 'ok'){
						this.schools = toRaw(res.data.data);
					}
					else{
						console.log("getSchools fail " + res.statusText);
					}
				}).catch(e=>{
					console.log("getSchools fail " + e);
				})
			},
			getParents(){
				axios.post(this.$api_add + localStorage.getItem("org") +'users/getparents').then((res)=>{
					if(res.data.result == 'ok'){
						let data = toRaw(res.data.data);
						for (let i = 0; i < data.length; i++) {
							data[i]['name'] = data[i]['firstname'] + " " + data[i]['lastname'];
						}

						this.parents = data;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.userResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			onUpload(e){
				let file = e.target.files || e.dataTransfer.files;
				if(!file.length) return;
				this.readFile(file[0])
			},
			readFile(file){
				let suc = 0;
				let failed= 0;
				let reader = new FileReader();
				reader.readAsText(file);
				reader.onload = () => {
				let text = reader.result;
				let lines = text.split('\n')
				for(let i = 1; i < lines.length;i++){
				let data = lines[i].split(';');
				if(data.length == 0){
					continue;
				}
				this.childForm.firstname = data[0];
				this.childForm.lastname = data[1];
				this.childForm.schoolid = this.schools[this.schools.findIndex(e => e.name == data[2])].id;
				this.childForm.assistancelevel = data[3];
				this.childForm.parentid = data[4];
				axios.post(this.$api_add + localStorage.getItem("org") +'child/create', this.childForm).then((res)=>{
				if(res.data.result == 'ok'){
					suc++;
				}
				else{
					failed++;
				}
				})

			}

			}
			this.toastObject['message'] = String(suc +" lasta lisättiin järjestelmään. " + failed + " lasta ei saatu lisättyä järjestelmään.")
			this.toastObject['status'] = true;
		}
    },
		mounted(){
			this.getSchools();
			this.getParents();
		},
    data(){
      return{
				selectedRole: 0,
				userForm: {
          emails: null,
					role: 0
				},
				childForm:{
					firstname: null,
					lastname: null,
					schoolid: null,
					parentid: null,
					assistancelevel: null
				},
				schools: [],
				parents: []
      }
    },
		setup(){
			return{

			}
		}
});
</script>
<style>
	.bigarea{
		height:50%;
	}
</style>
