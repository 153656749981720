<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<form novalidate>
					<ion-row>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.address')"
										id="schoolName"
										v-model="addressForm.address"
										name="vId"
										type="text"
										:label="$t('general.address')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.latitude')+','+$t('general.longitude')"
										id="addressCoordinates"
										v-model="addressForm.coordinates"
										name="vId"
										type="text"
										:label="$t('general.coordinates')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="registerSchool(addressForm)">{{$t('operator.addAddress')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonLabel,
	IonInput,
  IonCol,
  IonButton
} from "@ionic/vue";
import { defineComponent, toRaw } from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "AddAddress",
		emits: ['goback'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonInput,
			IonCol,
			IonButton
		},
    methods:{
			registerSchool(form){
				const rawObject = toRaw(form);
				//test call
        let splitcoords = rawObject.coordinates.split(",");

        let obj = {
          "address":rawObject.address,
          "lat": splitcoords[0],
          "lon": splitcoords[1]
        }

        console.log(obj);
				axios.post(this.$api_add + localStorage.getItem("org") +'coordinates/create', obj).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('goback', {statusMessage: String(res.status + " " + this.$t('operator.addressResponses.addSuccess')), editStatus: true});
					}
					else{
						this.$emit('goback', {statusMessage: String(res.status + " " + this.$t('operator.addressResponses.addFailure') + res.statusText), editStatus: false});
					}
				}).catch((e)=>{
					this.$emit('goback', {statusMessage: String(this.$t('operator.addressResponses.addFailure') + e.message), editStatus: false});
				});

			}
    },
    data(){
      return{
				addressForm: {
					address:null,
					coordinates: null
				},
				isdesktop: this.$isdesktop
      }
    },
		setup(){
			return{

			}
		}
});
</script>
<style>
	.inpt{
		--background: #dedad9;
	}
</style>
