<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-header>
					<ion-toolbar>
						<ion-title>{{currentPage == 'table' ? $t('operator.bulletins') : $t('operator.addBulletin')}}</ion-title>
						<ion-buttons slot="secondary">
							<ion-button size="large" @click="currentPage == 'table' ? changeView('addBulletin') : changeView('table')">
								<ion-icon :icon="changeIcon()" slot="start" size="large"></ion-icon>
								{{currentPage == 'addBulletin' ? $t('operator.bulletins') : $t('operator.addBulletin')}}
							</ion-button>
						</ion-buttons>
					</ion-toolbar>
				</ion-header>
				<ion-content class="ion-padding ion-margin-top routeTable" v-if="currentPage == 'table'">
          <ion-grid>
						<ion-row>
							<ion-col size="12">
								<ion-searchbar id="routeSearch" :placeholder="$t('operator.searchBulletins')" @input="searchBulletins($event.target.value)"></ion-searchbar>
							</ion-col>
						</ion-row>
						<ion-accordion-group multiple="true">
								<ion-accordion value="bulletins">
									<ion-item slot="header" color="light">
										<ion-label>{{$t('operator.bulletins')}}</ion-label>
									</ion-item>
									<div class="ion-padding" slot="content">
						<ion-row class="tableHeader">
							<ion-col size="3">
								<ion-item lines="none">
									<b>{{$t('operator.timestamp')}}</b>
								</ion-item>
							</ion-col>
							<ion-col size="3">
								<ion-item lines="none">
									<b>{{$t('operator.bulletinTitle')}}</b>
								</ion-item>
							</ion-col>
							<ion-col size="3">
								<ion-item lines="none">
									<b>{{$t('operator.bulletinDescription')}}</b>
								</ion-item>
								<ion-col size="3">
								</ion-col>
							</ion-col>
						</ion-row>
              <ion-row v-for="(feedback,index) in filteredBulletins" :key="index">
                <ion-col size="12" class="topborder">
                  <ion-row>
										<ion-col size="3">
											<ion-item lines="none" class="daystext">
												{{feedback.parsed_date}}
											</ion-item>
										</ion-col>
                    <ion-col size="3">
                      <ion-item lines="none">
                        {{feedback.title}}
                      </ion-item>
                    </ion-col>
										<ion-col size="3">
											<ion-item lines="none">
												{{feedback.description}}
											</ion-item>
										</ion-col>
										<ion-col size="3" v-if="isdesktop">
											<ion-item lines="none">
												<ion-row>
													<ion-col size="12">
														<ion-button size="full" @click="getEditData(index),setOpen(true)">{{$t('operator.modify')}}</ion-button>
													</ion-col>
													<ion-col size="12">
														<ion-button size="full" @click="showDeleteAlert(index)" color="danger">{{$t('operator.delete')}}</ion-button>
													</ion-col>
												</ion-row>
											</ion-item>
										</ion-col>
                    <ion-col size="3" v-else>
                      <ion-item lines="none">
												<ion-row>
													<ion-col size="12">
														<ion-button size="full" @click="getEditData(index),setOpen(true)">
															<ion-icon :icon="pencilOutline"></ion-icon>
														</ion-button>
													</ion-col>
													<ion-col size="12">
														<ion-button size="full" @click="showDeleteAlert(index)" color="danger">
															<ion-icon :icon="trashOutline"></ion-icon>
														</ion-button>
													</ion-col>
												</ion-row>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
						</div>
					</ion-accordion>
					<ion-accordion value="archive">
						<ion-item slot="header" color="light">
							<ion-label>{{$t('general.archive')}}</ion-label>
						</ion-item>
						<div class="ion-padding" slot="content">
							<ion-row class="tableHeader">
								<ion-col size="3">
									<ion-item lines="none">
										<b>{{$t('operator.timestamp')}}</b>
									</ion-item>
								</ion-col>
								<ion-col size="3">
									<ion-item lines="none">
										<b>{{$t('operator.bulletinTitle')}}</b>
									</ion-item>
								</ion-col>
								<ion-col size="3">
									<ion-item lines="none">
										<b>{{$t('operator.bulletinDescription')}}</b>
									</ion-item>
								</ion-col>
								<ion-col size="2">
								</ion-col>
						</ion-row>
						<ion-row v-for="(feedback,index) in processedArchive" :key="index">
							<ion-col size="12" class="topborder">
								<ion-row>
									<ion-col size="3">
										<ion-item lines="none" class="daystext">
											{{feedback.parsed_date}}
										</ion-item>
									</ion-col>
									<ion-col size="3">
										<ion-item lines="none">
											{{feedback.title}}
										</ion-item>
									</ion-col>
									<ion-col size="3">
										<ion-item lines="none">
											{{feedback.description}}
										</ion-item>
									</ion-col>
								</ion-row>
							</ion-col>
						</ion-row>
					</div>
				</ion-accordion>
					</ion-accordion-group>
          </ion-grid>
				</ion-content>
				<ion-content class="ion-padding ion-margin-top routeTable" v-if="currentPage == 'addBulletin'">
					<AddBulletin  @goback="parseAddEmited"/>
				</ion-content>
				<ion-modal :is-open="isOpenRef"  @didDismiss="setOpen(false), getBulletins()">
					<ion-content class="ion-padding ion-margin-top carsTable">
						<EditBulletin :bulletindata="editData" @responsedata="parseModalEmitted"/>
					</ion-content>
				</ion-modal>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonContent,
  IonGrid,
	IonAccordion,
	IonAccordionGroup,
  IonRow,
	//IonList,
	IonButton,
	IonLabel,

	IonIcon,

	IonButtons,
	IonModal,
  IonItem,
  IonHeader,
  IonCol,
  IonTitle,
  IonToolbar,
	modalController,
	IonSearchbar,
	alertController
} from "@ionic/vue";
import {
	add,
	arrowBackOutline,
	chevronUpOutline,
	chevronDownOutline,
	pencilOutline,
	trashOutline
} from 'ionicons/icons';
import { defineComponent, ref, toRaw } from 'vue';
import EditBulletin from './editBulletin.vue';
import AddBulletin from './AddBulletin.vue';
import StatusToast from '@/components/StatusToast.vue';
import axios from 'axios';
import moment from 'moment';
export default defineComponent({
		name: "OperatorBulletins",
		components:{
			IonContent,
			IonGrid,
			IonAccordion,
			IonAccordionGroup,
			IonRow,
			//IonList,
			IonButton,
			IonLabel,
			IonModal,
			IonIcon,
			IonButtons,
			IonItem,
			IonHeader,
			IonCol,
			IonTitle,
			IonToolbar,
			IonSearchbar,
			EditBulletin,
			AddBulletin
		},
		methods:{
			changeView(view){
				this.currentPage = view;
			},
			getKeys(obj){
				return Object.keys(obj[0]);
			},
			secondsToMinutes(sec){
				return (sec/60).toFixed(0);
			},
			parseAddEmited(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					this.changeView('table');
				}
				this.getBulletins();
			},
			parseDate(bulletins){
				for (let i = 0; i < bulletins.length; i++) {
					bulletins[i]['parsed_date'] = moment.utc(bulletins[i].timestamp).format('DD.MM.YYYY HH:mm');
				}
				return bulletins;
			},
			parseModalEmitted(values){
				console.log(values);
				this.getBulletins();
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					modalController.dismiss();
				}
			},
			async getBulletins(){
				axios.post(this.$api_add + localStorage.getItem("org") +'feedback/getbulletin').then((res)=>{
					if (res.data.result == 'ok') {
						//const prsd = this.parseRoutes(res.data.data);
						let parsed = this.parseDate(res.data.data);
						this.bulletins = parsed;
						//if page is opened for the first time, assign routes to filteredBulletins
						if(this.filteredBulletins == null){
							this.filteredBulletins = this.bulletins;
						}
						else{
							//if search has been made, search it again. Updates data
							this.searchBulletins(this.searchQuery);
						}
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.bulletinResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.bulletinResponses.getFailed') + e);
					this.toastObject['status'] = false;
				}).finally(()=>{
					this.getArchive();
				});
			},
			getEditData(index){
				this.editData = toRaw(this.filteredBulletins[index]);
			},
			async getArchive(){
				axios.post(this.$api_add + localStorage.getItem("org") +'feedback/getarchivedbulletin').then((res)=>{
					if(res.data.result == 'ok'){
						this.archive = toRaw(res.data.data);
						this.processedArchive = this.parseDate(this.archive);
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.exceptionResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.exceptionResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			deleteBulletin(index){
				const data = toRaw(this.filteredBulletins[index]);
				const jwt = localStorage.getItem('token');
				const dt = {
					"id": data.id
				};

				axios.post(this.$api_add + localStorage.getItem("org") +'feedback/archive', dt, {headers:{"Authorization": String("Bearer " + jwt)}}).then((res)=>{
					if(res.data.result == 'ok'){
						this.filteredBulletins.splice(index,1);
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.bulletinResponses.deleteSuccess'));
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.bulletinResponses.deleteFailure') + res.statusText);
						this.toastObject['status']  = false
						//this.toastToast(true);
					}
					this.showToast();
					this.getBulletins();
				}).catch((e)=>{
					this.toastObject['message'] = String(this.$t('operator.bulletinResponses.deleteFailure') + e);
					this.toastObject['status'] = false;
					this.showToast();
				});
			},
			changeIcon(){
				if(this.currentPage == 'table'){
					return add;
				}
				else if(this.currentPage == 'addBulletin'){
					return arrowBackOutline;
				}
			},
			searchBulletins(value){
				const query = toRaw(value).toLowerCase();
				if(!query){
					this.filteredBulletins = this.bulletins;
				}
				else{
					this.filteredBulletins = this.bulletins.filter((x)=>{
						//stringify points because otherwise it will be read as [Object object]
						const pts = JSON.stringify(x.points);

						//get other object values, attach them to points and make the whole thing case insensitive with toLowerCase
						const xvalues = String(Object.values(x).join(",")+pts).toLowerCase();
						return xvalues.includes(query);
					});
				}
				this.searchQuery = query;
			},
			showToast(){
				StatusToast.methods.openToast(this.toastObject);
			},

			async showDeleteAlert(index){

				const data = toRaw(this.filteredBulletins[index]);
				let message = `
					`+this.$t('operator.bulletinTitle')+`: `+data.title+`<br>
					`+this.$t('operator.bulletinDescription')+`: `+data.description+`<br>
				`;

				const alert = await alertController.create({
					header: this.$t('operator.confirmBulletinDelete'),
					message: message,
					buttons: [{
							text: this.$t('general.cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							id: 'cancel-button'
						},
						{
							id: 'confirm-button',
							text: this.$t('operator.delete'),
							cssClass:'deletealert',
							handler: () => {
								this.deleteBulletin(index);
							},
						}],
				});
				await alert.present();

				await alert.onDidDismiss();
			},
			sortBy(value){
				if(this.sortClicks == 0){
					this.filteredBulletins = this.filteredBulletins.sort((a,b)=>{
						return a[value] - b[value];
					});
					this.sortClicks++;
					this.sortLastValue = value;
				}
				else if(this.sortClicks == 1 && this.sortLastValue == value){
					this.filteredBulletins = this.filteredBulletins.sort((a,b)=>{
						return b[value] - a[value];
					});
					this.sortClicks = 0;
				}
				else if(this.sortLastValue != value){
					this.sortLastValue = value;
					this.sortClicks = 0;
				}
				else{
					this.sortClicks = 0;
					this.sortLastValue = value;
				}
			},
		},
		setup(){

			const isOpenRef = ref(false);
			const isCopyRef = ref(false);
			const setOpen = (state) => isOpenRef.value = state;
			const setCopy = (state) => isCopyRef.value = state;

			return {
				isOpenRef,
				isCopyRef,
				setOpen,
				setCopy,
				alertController,
				chevronUpOutline,
				chevronDownOutline,
				pencilOutline,
				trashOutline
			}
		},
		mounted(){
			this.getBulletins();
		},
	data() {
			return{
				toastObject:{
					"message": null,
					"status": false
				},
				currentPage: 'table',
				editStatus: false,
				showMessage: false,
				statusMessage: null,
				bulletins: null,
				filteredBulletins: null,
				sortLastValue: null,
				archive:null,
				processedArchive:null,
				isdesktop: this.$isdesktop
      }
    }
});
</script>
<style>
  .routeTable{
		height: 90vh;
	}
	.tableHeader {
    display: flex; /* or inline-flex */
    flex-direction: row;
	}
	.topborder{
		border-top: solid 1px #cfcdca;
	}
	.iconbtn{
		font-size: 1.2em;
	}
	.bld{
		font-weight: bold;
	}
	.routetable{
		max-height:30vh;
		overflow: auto;
	}
	.bigmodal,
	.bigmodal.modal-wrapper{
		--width: 100vh;
		--height: 90vh;
	}
	.daystext{
		display:table-caption;
	}
	.deletealert{
		color: red !important;
	}
</style>
