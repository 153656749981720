import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_accordion_group, { value: "instructions" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_accordion, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.parent.confirmTitle')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "cont" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.parent.confirmContent')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_accordion, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.parent.eventTitle')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "cont" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.parent.eventContent')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_accordion, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.parent.absentTitle')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "cont" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.parent.absentContent')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_accordion, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.parent.messageTitle')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "cont" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.parent.messageContent')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_accordion, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.parent.changesTitle')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "cont" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.parent.changesContent')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_accordion, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.parent.accountTitle')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { slot: "content" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "cont" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('info.parent.accountContent')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}