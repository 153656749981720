

var Latitude;
var Longitude


function getMapLocation(){
    navigator.geolocation.getCurrentPosition(onMapSuccess, onMapError, {enableHighAccuracy: true});
}

var onMapSuccess = function (position){
    Latitude = position.coords.latitude;
    Longitude = position.coords.longitude;


}
function getMap(){
    return [Latitude,Longitude];
}
function onMapError(error) {
    console.log('code: ' + error.code + '\n' +
        'message: ' + error.message + '\n');
}
function watchMapPosition() {

    return navigator.geolocation.watchPosition(onMapWatchSuccess, onMapError, { enableHighAccuracy: true, timeout: 30000 });
}
var onMapWatchSuccess = function (position) {

    var updatedLatitude = position.coords.latitude;
    var updatedLongitude = position.coords.longitude;

    if (updatedLatitude != Latitude && updatedLongitude != Longitude) {

        Latitude = updatedLatitude;
        Longitude = updatedLongitude;

        getMap(updatedLatitude, updatedLongitude);
    }
}
export {getMap, getMapLocation, watchMapPosition};