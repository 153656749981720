<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-header>
					<ion-toolbar>
						<ion-title>{{$t('operator.patchRoute')}}</ion-title>
					</ion-toolbar>
				</ion-header>
				<ion-content class="ion-padding ion-margin-top lateTable">
					<ion-row>
						<ion-col size="4">
							<ion-row>
								<ion-col v-if="lateones.length>0">
									<h5>{{$t('operator.lateChilds')}}</h5>
									<ion-row>
										<ion-col size="12" v-for="(late, index) in lateones" :key="index">
											<ion-item>
												<ion-checkbox class="ion-margin-end" @click="addToSelected(index)"></ion-checkbox>
												{{String($t("operator.timestamp") + ": " + getTime(late.latetimestamp))}}
												<br>
												{{String($t('general.roles.child') + ": " + findChild(late.child))}}
												<br>
												{{late.routetype == 1 ? String($t("operator.pickupAddress") + ": " + getChildSchool(late.pickup)) : String($t("operator.pickupAddress") + ": " + late.pickup)}}
												<br>
												{{late.routetype == 0 ? String($t("operator.deliveryAddress") + ": " + getChildSchool(late.delivery)) : String($t("operator.deliveryAddress") + ": " + late.delivery)}}
										</ion-item>
										</ion-col>
									</ion-row>
								</ion-col>
								<ion-col v-else-if="lateones.length==0 || lateones == undefined">
									<h5 class="ion-text-wrap">{{$t('operator.lateChilds')}}</h5>
									<p class="ion-text-wrap">{{$t('operator.noLateChilds')}}</p>
								</ion-col>
							</ion-row>
						</ion-col>
						<ion-col size="8">
							<form>
								<ion-row>
									<ion-col size="12">
										<h3>{{$t('operator.routeInfo')}}</h3>
									</ion-col>
									<ion-col :size="isdesktop ? 4 : 12">
										<ion-item>
												<ion-select :label="$t('operator.routeType')" labelPlacement="stacked" :placeholder="$t('operator.routeType')" v-model="patchDriverForm.type" required :change="setType(patchDriverForm.type)" :value="0">
													<ion-select-option value="0" :selected="patchDriverForm.type == 0 ? true : false">{{$t('operator.routeTypes.0')}}</ion-select-option>
													<ion-select-option value="1" :selected="patchDriverForm.type == 1 ? true : false">{{$t('operator.routeTypes.1')}}</ion-select-option>
												</ion-select>
										</ion-item>
									</ion-col>
									<ion-col :size="isdesktop ? 4 : 12">
										<ion-item>
											<ion-label position="stacked">{{$t('operator.routeName')}}</ion-label>
												<ion-input
													:placeholder="$t('operator.routeName')"
													id="routeName"
													v-model="patchDriverForm.routename"
													name="routeName"
													:label="$t('operator.routeName')"
													labelPlacement="stacked"
													type="text"
													required
												></ion-input>
										</ion-item>
									</ion-col>
									<ion-col :size="isdesktop ? 4 : 12">
										<ion-item>
											<ion-select :label="$t('operator.routeEndPoint')" labelPlacement="stacked" :placeholder="$t('general.school')" v-model="patchDriverForm.endPointIdx">
												<ion-select-option :value="null" key="9999">{{$t('operator.noEndPoint')}}</ion-select-option>
												<ion-select-option v-for="(school, index) in schools" :value="index" :key="index">{{school.name}}</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col size="12" class="ion-margin-top">
										<h5>{{$t('operator.routeStartInfo')}}</h5>
									</ion-col>
									<ion-col :size="isdesktop ? 8 : 12" :hidden="this.routetype">
										<ion-row>
										<ion-col :size="isdesktop ? 4 : 12">
											<ion-item>
													<ion-input
														id="startAdd"
														:placeholder="$t('general.address')"
														v-model="patchDriverForm.startadd"
														name="startAdd0"
														:label="$t('general.address')"
														labelPlacement="stacked"
														type="text"
														required
													></ion-input>
											</ion-item>
										</ion-col>
										<ion-col :size="isdesktop ? 4 : 12">
											<ion-item>
												<ion-label position="stacked">{{$t('general.postalCode')}}</ion-label>
													<ion-input
														id="startAdd"
														:placeholder="$t('general.postalCode')"
														v-model="patchDriverForm.startpostal"
														name="startPostal"
														:label="$t('general.postalCode')"
														labelPlacement="stacked"
														type="text"
														required
													></ion-input>
											</ion-item>
										</ion-col>
										<ion-col :size="isdesktop ? 4 : 12">
											<ion-item>
													<ion-input
														id="startAdd"
														:placeholder="$t('general.city')"
														v-model="patchDriverForm.startcity"
														name="startCity"
														:label="$t('general.city')"
														labelPlacement="stacked"
														type="text"
														required
													></ion-input>
											</ion-item>
										</ion-col>
									</ion-row>
								</ion-col>
								<ion-col :size="isdesktop ? 4 : 12" :hidden="!this.routetype">
									<ion-item>
										<ion-select :label="$t('operator.startAddress')" labelPlacement="stacked" :placeholder="$t('general.school')" :change="setRouteSchool()" v-model="patchDriverForm.selectedSchoolIdx">
											<ion-select-option v-for="(school, index) in schools" :value="index" :key="index">{{school.name}}</ion-select-option>
										</ion-select>
									</ion-item>
								</ion-col>
								<ion-col :size="isdesktop ? 4 : 12">
									<ion-item>
										<ion-label position="stacked">{{$t('operator.startTime')}}</ion-label>
											<ion-input
												id="startTime"
												:placeholder="$t('operator.startTime')"
												v-model="patchDriverForm.starttime"
												name="startTime"
												:label="$t('operator.startTime')"
												labelPlacement="stacked"
												type="text"
												required
											></ion-input>
									</ion-item>
								</ion-col>
									<ion-col size="12">
										<h5>Lapset</h5>
										<ion-list>
											<ion-item  v-for="(selected, index) in selectedchilds" :key="index">
												{{findChild(selected.child)}}
											</ion-item>
										</ion-list>
									</ion-col>
									<ion-col size="12">
										<ion-button :disabled="selectedchilds.length == 0 ? true : false" @click="registerForm(patchDriverForm)">{{$t('operator.createPatchDrive')}}</ion-button>
									</ion-col>
								</ion-row>
							</form>
						</ion-col>
					</ion-row>
				</ion-content>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
	IonContent,
  IonGrid,
  IonRow,
  IonItem,
  IonHeader,
  IonCol,
	IonLabel,
  IonTitle,
  IonToolbar,
	IonList,
	IonCheckbox,
	IonInput,
	IonSelect,
	IonSelectOption,
	IonButton
} from "@ionic/vue";
import { add, arrowBackOutline } from 'ionicons/icons';
import { defineComponent, toRaw, ref } from 'vue';
import StatusToast from '@/components/StatusToast.vue';
import moment from 'moment';
import axios from 'axios';
export default defineComponent({
    name: "OperatorLateOnes",
    components:{
			IonContent,
			IonGrid,
			IonRow,
			IonItem,
			IonHeader,
			IonCol,
			IonTitle,
			IonLabel,
			IonToolbar,
			IonList,
			IonCheckbox,
			IonInput,
			IonSelect,
			IonSelectOption,
			IonButton
		},
    methods:{
			async registerForm(form){
				//Combine forms
				const rawObject = {...form};

				const rawdays = moment().isoWeekday();
				const points = toRaw(this.selectedchilds);
				const childs = points.map((c)=>{
					return c.child;
				});

				if(rawObject['startaddress'] == null && !this.routetype){
					rawObject['startaddress'] = String(rawObject['startadd'].trim() + ", " + rawObject['startpostal'].trim() + " " + rawObject['startcity'].trim())
				}
				else if(rawObject['startaddress'] == null && this.routetype && this.patchDriverForm.selectedSchoolIdx != null){
					const schooldata = this.schools[this.patchDriverForm.selectedSchoolIdx];
					rawObject['startaddress'] = schooldata.address;
				}
				for (let i = 0; i < points.length; i++) {
					if(points[i]['timespent'] != null  && rawObject['type'] == 0){
						points[i]['duration'] = parseInt(points[i]['timespent'])*60;
						//points[i]['timewindow'] = "12:00";
					}
					else if(rawObject['type'] == 1){
						points[i]['duration'] = 0;
						//points[i]['timewindow'] = "17:00";
						points[i]['pickup'] = rawObject['startaddress'];
					}
				}

				if(this.patchDriverForm.endPointIdx != null){
					rawObject['endaddress'] = this.schools[this.patchDriverForm.endPointIdx].address;
				}

				rawObject['points'] = points;
				rawObject['days'] = rawdays;
				rawObject['children'] = childs;
				//rawObject['schools'] = schoolids;
				rawObject['jwt'] = localStorage.getItem('token');

			axios.post(this.$opt_add + localStorage.getItem("org") + 'createPatchDriver', rawObject).then((res) => {
					if (res.data.result == 'ok' || res.data == 'ok') {
						if (res.data.errors == 0) {
							this.toastObject['message'] = String(res.status + " " + this.$t('operator.routeResponses.addSuccess') + res.statusText);
							this.toastObject['status'] = true;
						}
						else {
							this.toastObject['message'] = String(res.status + " " + this.$t('operator.routeResponses.addSuccessWithErrors') + res.statusText);
							this.toastObject['status'] = true;
						}
						this.resetForm();
						this.getLate();
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.routeResponses.addFailure') + res.statusText);
						this.toastObject['status'] = false;
					}
					this.showToast();
				}).catch((e)=>{
					this.toastObject['message'] = String(this.$t('operator.routeResponses.addFailure') + ": " + e);
					this.toastObject['status'] = false;
					this.showToast();
				});
			},
			getLate(){
				const dayOfWeek = moment().isoWeekday();
				axios.post(this.$api_add + localStorage.getItem("org") +'logs/getlatearrivals', {"day": dayOfWeek}).then((res)=>{
					if(res.data.result == 'ok'){
						console.log(res.data.data);
						this.lateones = res.data.data;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.vehicleResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.vehicleResponses.getFailed') + e);
					this.toastObject['status'] = false;
				})
			},
			changeIcon(){
				if(this.currentPage == 'table'){
					return add;
				}
				else if(this.currentPage == 'addCar'){
					return arrowBackOutline;
				}
			},
			showToast(){
				StatusToast.methods.openToast(this.toastObject);
			},
			getChildren(){
				axios.post(this.$api_add + localStorage.getItem("org") +'child/getall').then((res)=>{
					if(res.data.result == 'ok'){
						const dt = toRaw(res.data.data);
						this.children = dt;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				});
			},
			findChild(id){
				const child = this.children.find((c)=>{
					return c.id == id;
				});

				if(child != undefined && child != null){
					const name = String(child['firstname'] + " " + child['lastname']);

					return name;
				}
				else{
					return id;
				}
			},
			getTime(time){
				return moment.utc(time).format('DD.MM.YYYY HH:mm');
			},
			getChildSchool(address){
				const addr = this.schools.find((c)=>{
					return c.address == address;
				});

				if(addr != undefined && addr != null){
					const school = addr.name;
					return school;
				}
				else{
					return address;
				}
			},
			findSchool(id){
				const school = this.schools.find((u)=>{
					return u.id == id;
				});

				if(school != undefined || school != null){
					return school.name;
				}
				else{
					return id;
				}
			},
			addToSelected(index){
				const dt = toRaw(this.lateones[index]);
				const check = this.selectedchilds.findIndex((s)=>{
					return s.child == dt.child;
				});

				if(check == -1){
					this.selectedchilds.push(dt);
				}
				else{
					this.selectedchilds.splice(check,1);
				}
			},
			setSchool(){
				const idx = this.pointForm.selectedSchoolIdx;

				if(idx != null){
					const schoolid = toRaw(this.schools[idx]).id;
					this.currentSchool = schoolid;
					this.getChildren(schoolid);
				}
			},
			setRouteSchool(){
				const idx = this.patchDriverForm.selectedSchoolIdx;

				if(idx != null){
					const schoolid = toRaw(this.schools[idx]).id;
					this.getChildren(schoolid);
				}
			},
			setType(type){
				if(type == 0){
					this.routetype =  false;
				}
				else{
					this.routetype = true;
				}
			},
			async getSchools(){
				axios.post(this.$api_add + localStorage.getItem("org") +'school/getall').then((res)=>{
					if(res.data.result == 'ok'){
						this.schools = toRaw(res.data.data);
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.schoolResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.schoolResponses.getFailed') + e);
					this.toastObject['status'] = false;
				})
			},
			resetForm() {
				this.patchDriverForm.routename = null;
				this.patchDriverForm.startaddress =null;
				this.patchDriverForm.startadd = null;
				this.patchDriverForm.startpostal = null;
				this.patchDriverForm.startcity = null;
				this.patchDriverForm.starttime = null;
				this.patchDriverForm.points = [];
				this.patchDriverForm.type = 0;
				this.patchDriverForm.days = null;
				this.patchDriverForm.children = null;
				this.patchDriverForm.vehicle = null;
				this.patchDriverForm.selectedSchoolIdx = null;
				this.selectedchilds = [];
			}
    },
		setup(){
			const isOpenRef = ref(false);
			const setOpen = (state) => isOpenRef.value = state;
			return{
				isOpenRef,
				setOpen,
        add
			}

		},
		mounted(){
			this.getChildren();
			this.getLate();
			this.getSchools();
		},
    data(){
      return{
				patchDriverForm:{
					routename: null,
					startaddress: null,
					startadd: null,
					startpostal: null,
					startcity: null,
					starttime: null,
					points: [],
					type: 0,
					days: null,
					children: null,
					jwt: localStorage.getItem('token'),
					vehicle: null,
					selectedSchoolIdx: null,
					endPointIdx: null
				},
				routetype: 0,
				toastObject:{
					"message": null,
					"status": false
				},
				editData: null,
				currentPage: 'table',
				lateones:[],
				children: [],
				selectedchilds: [],
				schools: [],
				isdesktop: this.$isdesktop
      }
    }
});
</script>
<style>
  .lateTable{
		height: 90vh;
	}
	.tableHeader {
    display: flex; /* or inline-flex */
    flex-direction: row
	}
	.btmborder{
		border-bottom: solid 1px #cfcdca;
	}
	.iconbtn{
		font-size: 1.2em;
	}

	@media only screen and (max-width: 1300px) {
		.smallertext{
			font-size:0.8em;
		}
	}
</style>
