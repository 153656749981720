import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_AnalyticsBuilder = _resolveComponent("AnalyticsBuilder")!
  const _component_AnalyticsDashboard = _resolveComponent("AnalyticsDashboard")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("operator.statistics")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, {
            size: "12",
            class: "ion-margin"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    size: "large",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeView('graphs')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("operator.analytics.graphs")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    size: "large",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeView('builder')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("operator.analytics.queryBuilder")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.currentPage == 'builder')
            ? (_openBlock(), _createBlock(_component_ion_col, {
                key: 0,
                size: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AnalyticsBuilder)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.currentPage == 'graphs')
            ? (_openBlock(), _createBlock(_component_ion_col, {
                key: 1,
                size: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AnalyticsDashboard)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}