<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-item>
					<h3>{{$t('operator.editUser')}}</h3>
				</ion-item>
				<form novalidate>
					<ion-row>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-input :label="$t('general.firstName')" labelPlacement="stacked" id="childFirstname" name="childfirstname" :placeholder="$t('general.firstName')" v-model="editChildForm.firstname" type="text"></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-input :label="$t('general.lastName')" labelPlacement="stacked" id="childLastname" name="childlastname" :placeholder="$t('general.lastName')" v-model="editChildForm.lastname" type="text"></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-select :label="$t('general.school')" labelPlacement="stacked" :placeholder="$t('general.school')" name="childschool" v-model="editChildForm.schoolid" id="childSchool">
									<ion-select-option value="0" key="0" disabled>{{$t('parent.choose')}}</ion-select-option>
									<ion-select-option v-for="(school, index) in schools" :value="school.id" :key="index">{{school.name}}</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-input :label="$t('driver.helpLevel')" labelPlacement="stacked" id="childAssistance" name="childassistance" :placeholder="$t('driver.helpLevel')" v-model="editChildForm.assistancelevel" type="text"></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-select :label="$t('general.parent')" labelPlacement="stacked" :placeholder="$t('general.parent')" name="childparent" v-model="editChildForm.parentid" id="childParent">
									<ion-select-option value="null" key="null" disabled>{{$t('parent.choose')}}</ion-select-option>
									<ion-select-option v-for="(parent, index) in parents" :value="parent.id" :key="index">{{parent.name}}</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-item>
								<ion-select :label="$t('general.active')" labelPlacement="stacked" :placeholder="$t('general.active')" name="childactive" v-model="editChildForm.active" id="childActive">
									<ion-select-option value="null" key="null" disabled>{{$t('parent.choose')}}</ion-select-option>
									<ion-select-option :value="true" key="0">{{$t('general.generalYes')}}</ion-select-option>
									<ion-select-option :value="false" key="1">{{$t('general.generalNo')}}</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="editUser(editChildForm)">{{$t('general.save')}}</ion-button>
							<ion-button color="danger" @click="modalController.dismiss()">{{$t('general.cancel')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonInput,
	modalController,
	IonSelectOption,
	IonSelect,
  IonCol,
  IonButton
} from "@ionic/vue";
import { defineComponent, toRaw } from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "EditChild",
		props: ['userdata', 'schooldata', 'parentdata'],
		emits: ['responsedata'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonInput,
			IonSelectOption,
			IonSelect,
			IonCol,
			IonButton
		},
    methods:{
			editUser(form){
				const rawObject = toRaw(form);
				console.log(rawObject);
				axios.post(this.$api_add + localStorage.getItem("org") +'child/update', rawObject).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.userResponses.editSuccess')),
							showMessage: true,
							editStatus: true
						});
					}
					else{
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.userResponses.editFailure') + res.statusText),
							showMessage: true,
							editStatus: false
						});
					}
				}).catch((e)=>{
					this.$emit('responsedata', {
						statusMessage: String(this.$t('operator.userResponses.editFailure') + e.message),
						showMessage: true,
						editStatus: false
					});
				});
			}
    },
    data(){
			const userdt = toRaw(this.userdata);
			const parentdt = toRaw(this.parentdata);
			const schooldt = toRaw(this.schooldata);
      return{
				editChildForm: {
					id:userdt.id,
					firstname: userdt.firstname,
					lastname: userdt.lastname,
					schoolid: (userdt.schoolid || null),
					parentid: (userdt.parentid || null),
					assistancelevel: userdt.assistancelevel,
					active: userdt.active
				},
				parents: parentdt,
				schools: schooldt,
				isdesktop: this.$isdesktop
      }
    },
		setup(){
			return{
				modalController
			}
		}
});
</script>
<style>
	.bigarea{
		height:50%;
	}
</style>
