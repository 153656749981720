<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-header>
					<ion-toolbar>
						<ion-title>{{$t('operator.changeRequests')}}</ion-title>
					</ion-toolbar>
				</ion-header>
				<ion-content class="ion-padding ion-margin-top changesTable" v-if="currentPage == 'table'">
					<ion-row>
						<ion-col size="12">
							<ion-searchbar id="userSearch" :placeholder="$t('operator.searchChanges')" @input="searchChanges($event.target.value)"></ion-searchbar>
						</ion-col>
					</ion-row>
					<ion-accordion-group multiple="true">
						<ion-accordion value="request">
							<ion-item slot="header" color="light">
								<ion-label>{{$t('operator.changeRequests')}}</ion-label>
							</ion-item>
							<div class="ion-padding" slot="content">
								<ion-row class="tableHeader">
									<ion-col>
										<b>{{$t('operator.timestamp')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('operator.requestedBy')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('operator.requestType')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('operator.requestTarget')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('operator.beforeChange')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('operator.afterChange')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('general.additionalInfo')}}</b>
									</ion-col>
									<ion-col>
									</ion-col>
								</ion-row>
								<ion-row v-for="(change,index) in filteredChanges" :key="index">
									<ion-col size="12" class="btmborder">
										<ion-row>
											<ion-col>
												<ion-item lines="none">
													{{change.parsedTimestamp}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{change.firstname}} {{change.lastname}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{change.parsedType}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{change.parsedTarget}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{change.parsedold}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{change.parsednew}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{change.message}}
												</ion-item>
											</ion-col>
											<ion-col v-if="isdesktop">
												<ion-item lines="none">
													<ion-row>
														<ion-col size="12">
															<ion-button size="full" @click="setData(index) ,presentAlert('accept')">{{$t('operator.acceptChange')}}</ion-button>
														</ion-col>
														<ion-col size="12">
															<ion-button size="full" @click="setData(index), presentAlert('reject')" color="danger">{{$t('operator.rejectChange')}}</ion-button>
														</ion-col>
														<ion-col size="12">
															<ion-button size="full" @click="setData(index), presentAlert('archive')" color="danger">{{$t('operator.archiveChange')}}</ion-button>
														</ion-col>
													</ion-row>
												</ion-item>
											</ion-col>
											<ion-col v-else>
												<ion-item lines="none">
													<ion-row>
														<ion-col size="12">
															<ion-button @click="setData(index) ,presentAlert('accept')" size="block">
																<ion-icon :icon="checkmarkOutline"></ion-icon>
															</ion-button>
														</ion-col>
														<ion-col size="12">
															<ion-button size="block" @click="setData(index), presentAlert('reject')" color="danger">
																<ion-icon :icon="closeOutline"></ion-icon>
															</ion-button>
														</ion-col>
														<ion-col size="12">
															<ion-button size="block" @click="setData(index), presentAlert('archive')" color="danger">
																<ion-icon :icon="closeOutline"></ion-icon>
															</ion-button>
														</ion-col>
													</ion-row>
												</ion-item>
											</ion-col>
										</ion-row>
									</ion-col>
								</ion-row>
							</div>
						</ion-accordion>
						<ion-accordion value="archive">
							<ion-item slot="header" color="light">
								<ion-label>{{$t('general.archive')}}</ion-label>
							</ion-item>
							<div class="ion-padding" slot="content">
								<ion-row class="tableHeader">
									<ion-col>
										<b>{{$t('operator.timestamp')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('operator.requestedBy')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('operator.requestType')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('operator.requestTarget')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('operator.beforeChange')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('operator.afterChange')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('general.additionalInfo')}}</b>
									</ion-col>
									<ion-col>
										<b>{{$t('operator.status')}}</b>
									</ion-col>
								</ion-row>
								<ion-row v-for="(change,index) in filteredArchive" :key="index">
									<ion-col size="12" class="btmborder">
										<ion-row>
											<ion-col>
												<ion-item lines="none">
													{{change.parsedTimestamp}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{change.firstname}} {{change.lastname}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{change.parsedType}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{change.parsedTarget}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{change.parsedold}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{change.parsednew}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{change.message}}
												</ion-item>
											</ion-col>
											<ion-col>
												<ion-item lines="none">
													{{parseStatus(change.status)}}
												</ion-item>
											</ion-col>
										</ion-row>
									</ion-col>
								</ion-row>
							</div>
						</ion-accordion>
					</ion-accordion-group>
				</ion-content>
        <ion-content class="ion-padding ion-margin-top changesTable" v-if="currentPage == 'addUser'">
					<AddUser />
				</ion-content>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonContent,
  IonGrid,
  IonRow,
	IonButton,
  IonItem,
  IonHeader,
	alertController,
  IonCol,
  IonTitle,
  IonToolbar,
	IonAccordion,
	IonAccordionGroup,
	IonLabel,
	IonSearchbar
} from "@ionic/vue";
import {
	add,
	checkmarkOutline,
	closeOutline
} from 'ionicons/icons';
import { defineComponent, toRaw } from 'vue';
import AddUser from './AddUser.vue';
import axios from 'axios';
import moment from 'moment';
import StatusToast from '@/components/StatusToast.vue';
export default defineComponent({
    name: "OperatorCars",
    components:{
      IonContent,
			IonButton,
      IonGrid,
      IonRow,
      IonItem,
      IonHeader,
      IonCol,
      IonTitle,
      IonToolbar,
			AddUser,
			IonAccordion,
			IonAccordionGroup,
			IonLabel,
			IonSearchbar
		},
    methods:{
			getActive(){

				axios.post(this.$api_add + localStorage.getItem("org") +'requests/getactive').then((res)=>{
					if(res.data.result == 'ok'){
						this.changes = res.data.data;
						this.parseChanges();
						if(this.filteredChanges == null){
							this.filteredChanges = this.changes;
						}
						else{
							this.searchChanges(this.searchQuery);
						}
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.changeResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch((e)=>{
					this.toastObject['message'] = String(this.$t('operator.changeResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			getArchive(){
				axios.post(this.$api_add + localStorage.getItem("org") +'requests/getarchive').then((res)=>{
					if(res.data.result == 'ok'){
						this.archive = res.data.data;
						this.parseArchive();
						if(this.filteredArchive == null){
							this.filteredArchive = this.archive;
						}
						else{
							this.searchChanges(this.searchQuery);
						}
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.changeResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch((e)=>{
					this.toastObject['message'] = String(this.$t('operator.changeResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			setData(index){
				this.selectedConfirm = this.changes[index];
				this.selectedIndex = index;
			},
			parseStatus(status){
				let parsed = null;
				switch (status) {
					case "0":
						parsed = this.$t('general.requestStatuses.0')
						break;
					case "1":
						parsed = this.$t('general.requestStatuses.1')
						break;
					case "2":
						parsed = this.$t('general.requestStatuses.2')
						break;
					case "3":
						parsed = this.$t('general.requestStatuses.3')
						break;
					case null:
						parsed = "-"
						break;
					case undefined:
						parsed = "-"
						break;
					default:
						parsed = this.$t('general.requestStatuses.2')
						break;
				}
				return parsed;
			},
			acceptRequest(data){
				let rawObject = toRaw(data);
				console.log("accept", rawObject);
				axios.post(this.$api_add + localStorage.getItem("org") + 'requests/request_mover', rawObject).then((res) => {

					if(res.status == 200){
						this.changes.splice(this.selectedIndex,1);
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.changeResponses.acceptSuccess'));
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.changeResponses.acceptFailure') + res.statusText);
						this.toastObject['status']  = false
						//this.toastToast(true);
					}
					this.showToast();
					this.getArchive();
					this.getActive();
				}).catch((e)=>{
					this.toastObject['message'] = String(this.$t('operator.changeResponses.acceptFailure') + e);
					this.toastObject['status'] = false;
					this.showToast();
				});
			},
			searchChanges(value){
				const query = toRaw(value).toLowerCase();
				if(!query){
					this.filteredChanges = this.changes;
					this.filteredArchive = this.archive;
				}
				else{
					this.filteredChanges = this.changes.filter((x)=>{
						const xvalues = String(Object.values(x).join(",")).toLowerCase();
						return xvalues.includes(query);
					});

					this.filteredArchive = this.archive.filter((x)=>{
						const xvalues = String(Object.values(x).join(",")).toLowerCase();
						return xvalues.includes(query);
					});
				}
				this.searchQuery = query;
			},
			rejectRequest(data){
				const rawObject = toRaw(data);
				axios.post(this.$api_add + localStorage.getItem("org") + 'requests/request_mover', rawObject).then((res)=>{
					if(res.status == 200){
						this.changes.splice(this.selectedIndex,1);
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.changeResponses.rejectSuccess'));
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = res.status + " " + this.$t('operator.changeResponses.rejectFailure') + res.statusText;
						this.toastObject['status']  = false
						//this.toastToast(true);
					}
					this.showToast();
				}).catch((e)=>{
					this.toastObject['message'] = this.$t('operator.changeResponses.rejectFailure') + e;
					this.toastObject['status'] = false;
					this.showToast();
				});
			},
			archiveRequest(data){
				const rawObject = toRaw(data);
				axios.post(this.$api_add + localStorage.getItem("org") + 'requests/archivethis', rawObject).then((res)=>{
					if(res.status == 200){
						this.changes.splice(this.selectedIndex,1);
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.changeResponses.archiveSuccess'));
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = res.status + " " + this.$t('operator.changeResponses.archiveFailure') + res.statusText;
						this.toastObject['status']  = false
						//this.toastToast(true);
					}
					this.showToast();
				}).catch((e)=>{
					this.toastObject['message'] = this.$t('operator.changeResponses.rejectFailure') + e;
					this.toastObject['status'] = false;
					this.showToast();
				});
			},
			async presentAlert(type) {
				let header = null;
				let message = null;
				let btntext = null;
				const data = toRaw(this.selectedConfirm);
				let text = this.$t('operator.requestedBy') + ": " + data.firstname + " " + data.lastname + "<br>" +
					this.$t('operator.requestType') + ": " + this.$t('operator.changeTypes.' + data.type) + "<br>" +
					this.$t('operator.requestTarget') + ": " + this.$t('operator.changeTarget.' + data.target) + "<br>";
				if (data.oldvalues != undefined && data.oldvalues != null) {
					text = text + this.$t('operator.beforeChange') + ": " + this.parsevalues(data.type, data.target, data.oldvalues) + "<br>";
				}
				if (data.values != undefined && data.values != null) {
                    text = text + this.$t('operator.afterChange') + ": " + this.parsevalues(data.type, data.target, data.values);
                }



				if(type === 'accept'){
					header = this.$t('operator.changeConfirmation');
					message = this.$t('operator.aboutToConfirmChange');
					btntext = this.$t('operator.acceptChange');
				}
				else if(type === 'archive'){
					header = this.$t('operator.changeArchive');
					message = this.$t('operator.aboutToArchiveChange');
					btntext = this.$t('operator.archiveChange');
				}
				else if(type === 'reject'){
					header = this.$t('operator.changeRejection');
					message = this.$t('operator.aboutToRejectChange');
					btntext = this.$t('operator.rejectChange');
				}

				let inputs = [{
					name: 'message',
					id: 'message-id',
					type: 'textarea',
					placeholder: this.$t('operator.optionalMessage')
				}];
				const alert = await alertController.create({
					header: header,
					inputs: type != 'archive' ? inputs : [],
					subHeader: message,
					message: text,
					buttons: [{
							text: this.$t('general.cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							id: 'cancel-button'
						},
						{
							text: btntext,
							id: 'confirm-button',
							handler: (AlertData) => {
								const deldata = {
									id: data.requestid,
									type: data.type, status: '', userid: data.userid,
									target: data.target,
									message: type != 'archive' ? AlertData.message : '',
									oldvalue: data.parsedold,
                  value: data.target != "3" ? this.parsevalues(data.type, data.target, data.values) : data.values
								};
								if(type == 'accept'){
									deldata['status'] = 'accepted';
									this.acceptRequest(deldata);
								}
                else if(type == 'reject'){
                  deldata['status'] = 'rejected';
                  this.rejectRequest(deldata)
                }
								else if(type == 'archive'){
									deldata['status'] = 'archive';
									this.archiveRequest(deldata);
								}
							},
						}],
				});
				await alert.present();

				await alert.onDidDismiss();
			},
			showToast(){
				StatusToast.methods.openToast(this.toastObject);
			},
			parseChanges(){
				this.changes.forEach((change)=>{
					change['parsedTimestamp'] = moment.utc(change.timestamp).format('DD.MM.YYYY HH:mm');
					change['parsedold'] = this.parsevalues(change.type,change.target,change.oldvalues);
					change['parsednew'] = this.parsevalues(change.type,change.target,change.values);
					change['parsedType'] = this.$t('operator.changeTypes.' + change.type)
					change['parsedTarget'] = this.$t('operator.changeTarget.' + change.target)
				});

				//parse by requestid aka newest one first
				this.changes.sort((a,b)=>{
					return parseInt(b['requestid']) - parseInt(a['requestid']);
				});
			},
			parseArchive(){
				this.archive.forEach((change)=>{
					change['parsedTimestamp'] = moment.utc(change.timestamp).format('DD.MM.YYYY HH:mm');
					change['parsedold'] = this.parsevalues(change.type,change.target,change.oldvalues);
					change['parsednew'] = this.parsevalues(change.type,change.target,change.values);
					change['parsedType'] = this.$t('operator.changeTypes.' + change.type)
					change['parsedTarget'] = this.$t('operator.changeTarget.' + change.target)
				});

				//parse by requestid aka newest one first
				this.archive.sort((a,b)=>{
					return parseInt(b['requestid']) - parseInt(a['requestid']);
				});
			},
			parsevalues(requesttype, target, values) {
				let address = "";
				if (requesttype != undefined) {
					if (requesttype == "0") {
						if (target == "0") {
							if (values != undefined && values != null) {
                address = JSON.parse(values);
                return address.address;
							}
							else {
								return "";
							}
						}
					}
					else if (requesttype == "1") {
						if (target == "0") {
							address = JSON.parse(values);
							return address.address;

						}
					}
					else if (requesttype == "2") {
						if (target == "0") {
              address = JSON.parse(values);
              return address.address;
						}
					}
					else {
						return "";
          }
				}
				else {
					return "";
        }
      },
    },
		setup(){

			return{
        add,
				alertController,
				moment,
				checkmarkOutline,
				closeOutline
			}

		},
		mounted(){
			this.getActive();
			this.getArchive();
		},
    data(){
      return{
				toastObject:{
					"message": null,
					"status": false
				},
				currentPage: 'table',
				selectedConfirm: null,
				selectedIndex: null,
				changes: [],
				archive: [],
				filteredChanges: null,
				filteredArchive: null,
				isdesktop: this.$isdesktop,
      }
    }
});
</script>
<style>
  .changesTable{
		height: 90vh;
	}
	.tableHeader {
    display: flex; /* or inline-flex */
    flex-direction: row
	}
	.btmborder{
		border-bottom: solid 1px #cfcdca;
	}
	.iconbtn{
		font-size: 1.2em;
	}
</style>
