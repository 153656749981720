<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<form>
					<ion-row>
						<ion-col size="6">
							<ion-row>
								<ion-col size="12">
								<ion-item>
									<ion-input
										:placeholder="$t('operator.bulletinTitle')"
										id="routeName"
										v-model="bulletinForm.title"
										name="routeName"
										type="text"
										:label="$t('operator.bulletinTitle')"
										labelPlacement="stacked"
										required
									></ion-input>
								</ion-item>
							</ion-col>
							<ion-col size="12">
								<ion-item>
									<ion-textarea
										:placeholder="$t('operator.bulletinDescription')"
										id="routeName"
										v-model="bulletinForm.description"
										name="routeName"
										type="text"
										:label="$t('operator.bulletinDescription')"
										labelPlacement="stacked"
										required
									></ion-textarea>
								</ion-item>
							</ion-col>
							<ion-col size="12">
								<ion-row>
									<ion-col size="12">
										<ion-row>
										<ion-col size="12">
											<ion-item>
												<ion-checkbox :checked="sendEmail" v-model="sendEmail">{{$t("operator.sendBulletinToEmail")}}</ion-checkbox>
											</ion-item>
										</ion-col>
										<ion-col size="12" class="ion-margin-top">
											<h5>{{$t("operator.selectReceivers")}}</h5>
											<ion-row>
												<ion-col class="ion-float-left">
													<ion-checkbox
														@click="selectAll"
														:checked="emailAll"
														v-model="emailAll"
														:disabled="!sendEmail"
													>{{$t("operator.sendToAll")}}</ion-checkbox>
												</ion-col>
												<ion-col class="ion-float-left">
													<ion-checkbox :checked="emailGroups.parent" v-model="emailGroups.parent" :disabled="!sendEmail">{{$t("general.roles.parent")}}</ion-checkbox>
												</ion-col>
												<ion-col class="ion-float-left">
													<ion-checkbox :checked="emailGroups.driver" v-model="emailGroups.driver" :disabled="!sendEmail">{{$t("general.roles.driver")}}</ion-checkbox>
												</ion-col>
												<ion-col class="ion-float-left">
													<ion-checkbox :checked="emailGroups.assistant" v-model="emailGroups.assistant" :disabled="!sendEmail">{{$t("general.roles.assistant")}}</ion-checkbox>
												</ion-col>
												<ion-col class="ion-float-left">
													<ion-checkbox :checked="emailGroups.operator" v-model="emailGroups.operator" :disabled="!sendEmail">{{$t("general.roles.operator")}}</ion-checkbox>
												</ion-col>
											</ion-row>
										</ion-col>
										</ion-row>
									</ion-col>
								</ion-row>
							</ion-col>
							</ion-row>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="registerRoute(bulletinForm)">{{$t('operator.addBulletin')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonInput,
  IonCol,
  IonButton,
	IonTextarea,
	IonCheckbox
} from "@ionic/vue";
import { defineComponent } from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "AddBulletin",
		props: ['somedata'],
		emits: ['goback'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonInput,
			IonCol,
			IonButton,
			IonTextarea,
			IonCheckbox
		},
    methods:{
			async registerRoute(form){
				//Combine forms
				//const rawObject = {...form};
				const rawObject = {
					"bulletin": {...form},
					"email": {
						"sendEmail": this.sendEmail,
						"emailGroups": this.emailGroups
					}
				}
				axios.post(this.$api_add + localStorage.getItem("org") + 'feedback/createbulletin', rawObject).then((res) => {
					if (res.data.result == 'ok' || res.data == 'ok') {
						this.$emit('goback', { statusMessage: String(res.status + " " + this.$t('operator.bulletinResponses.addSuccess')), editStatus: true });
					}
					else{
						this.$emit('goback', {statusMessage: String(res.status + " " + this.$t('operator.bulletinResponses.addFailure') + res.statusText), editStatus: false});
					}
				}).catch((e)=>{
					this.$emit('goback', {statusMessage: String(this.$t('operator.bulletinResponses.addFailure') + e.message), editStatus: false});
				});
			},
			selectAll(){
				if(!this.emailAll){
					this.emailGroups.parent = true;
					this.emailGroups.driver = true;
					this.emailGroups.assistant = true;
					this.emailGroups.operator = true;
				}
				else{
					this.emailGroups.parent = false;
					this.emailGroups.driver = false;
					this.emailGroups.assistant = false;
					this.emailGroups.operator = false;
				}
			}

    },
		watch:{
			routetype:{
				handler(newVal, oldVal)
					{
						//Replace prop with a property that would change
						if(newVal !== oldVal){
							this.routePoints = [];
						}
					},
					deep: true //important to watch object for changes
			}
		},
    data(){
      return{
				toastObject:{
					"message": null,
					"status": false
				},
				bulletinForm: {
					title: null,
					description: null,
					jwt: localStorage.getItem('token'),
				},
				sendEmail: false,
				emailGroups: {
					parent: false,
					driver: false,
					operator: false,
					assistant: false
				},
				emailAll: false
      }
    },
		setup(){
			return{

			}
		}
});
</script>
<style>
	.inpt{
		--background: #dedad9;
	}
	.bld{
		font-weight: bold;
	}
	.tableHeader {
		display: flex; /* or inline-flex */
		flex-direction: row;
	}
	.btmborder{
		border-bottom: solid 1px #cfcdca;
	}
	.topborder{
		border-top: solid 1px #cfcdca;
	}
	.pointtable{
		max-height:30vh;
		overflow: auto;
	}
</style>
