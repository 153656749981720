<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-header>
					<ion-toolbar>
						<ion-title>{{$t('operator.exceptions')}}</ion-title>
					</ion-toolbar>
				</ion-header>
				<ion-content class="ion-padding ion-margin-top routeTable" v-if="currentPage == 'table'">
					<ion-row>
						<ion-col size="12" :hidden="true">
							<ion-searchbar id="userSearch" :placeholder="$t('operator.searchAbsences')" @input="searchExceptions($event.target.value)"></ion-searchbar>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="setOpen(true)">{{$t('operator.editExceptions')}}</ion-button>
						</ion-col>
					</ion-row>
					<ion-accordion-group multiple="true">
						<ion-accordion value="daily">
							<ion-item slot="header" color="light">
								<ion-label>{{String($t('operator.dailyExceptions') + " - " + $t('general.days.'+this.currday) + " " + this.currdate)}}</ion-label>
							</ion-item>
						<div class="ion-padding" slot="content">
							<ion-col size="12" class="topborder">
								<ion-row>
									<ion-col>
										<p class="bld">{{$t('general.childName')}}</p>
									</ion-col>
									<ion-col>
										<p class="bld">{{$t('operator.morningDrive')}}</p>
									</ion-col>
									<ion-col>
										<p class="bld">{{$t('operator.afternoonDrive')}}</p>
									</ion-col>
									<ion-col>
										<p class="bld">{{ $t('operator.lastModifier') }}</p>
									</ion-col>
									<ion-col v-for="(date,idx) in dayForms[this.currdate]" :key="idx" size="12">
										<ion-row>
											<ion-col>
												{{date.childName}}
											</ion-col>
											<ion-col>
												<ion-icon
													:icon="date.morning ? closeOutline : removeOutline"
													size="large"
													:color="date.morning ? 'danger' : 'primary'">
												</ion-icon>
											</ion-col>
											<ion-col>
												<ion-icon
													:icon="date.afternoon ? closeOutline : removeOutline"
													size="large"
													:color="date.afternoon ? 'danger' : 'primary'">
												></ion-icon>
											</ion-col>
											<ion-col>
															<p class="bld">{{ date.lastmodifierName }}</p>
											</ion-col>
										</ion-row>
										</ion-col>
									</ion-row>
								</ion-col>
					</div>
				</ion-accordion>
						<ion-accordion value="request">
							<ion-item slot="header" color="light">
								<ion-label>{{$t('operator.exceptions')}}</ion-label>
							</ion-item>
						<div class="ion-padding" slot="content">
							<ion-row v-for="(c, index) in weeks" :key="index">
								<ion-accordion-group multiple="true" class="fullwidth">
									<ion-accordion value="forms0">
										<ion-item slot="header" color="light">
											<ion-label>{{$t('general.week') +" "+ c}}</ion-label>
										</ion-item>
										<div class="ion-padding fullwidth" slot="content">
											<ion-accordion-group multiple="true">
												<ion-accordion :value="String('forms'+idx0)" v-for="(d,idx0) in grouped_weeks[c]" :key="idx0">
													<ion-item slot="header" color="light">
														<ion-label>{{d.date}}</ion-label>
													</ion-item>
													<div class="ion-padding fullwidth" slot="content">
														<ion-col size="12" class="topborder">
															<ion-row>
																<ion-col>
																	<p class="bld">{{$t('general.childName')}}</p>
																</ion-col>
																<ion-col>
																	<p class="bld">{{$t('operator.morningDrive')}}</p>
																</ion-col>
																<ion-col>
																	<p class="bld">{{$t('operator.afternoonDrive')}}</p>
																</ion-col>
																<ion-col>
																	<p class="bld">{{ $t('operator.lastModifier') }}</p>
																</ion-col>
																<ion-col v-for="(date,idx) in dayForms[d.date]" :key="idx" size="12">
																	<ion-row>
																		<ion-col>
																			{{date.childName}}
																		</ion-col>
																		<ion-col>
																			<ion-icon
																				:icon="date.morning ? closeOutline : removeOutline"
																				size="large"
																				:color="date.morning ? 'danger' : 'primary'">
																			</ion-icon>
																		</ion-col>
																		<ion-col>
																			<ion-icon
																				:icon="date.afternoon ? closeOutline : removeOutline"
																				size="large"
																				:color="date.afternoon ? 'danger' : 'primary'">
																			></ion-icon>
																		</ion-col>
																		<ion-col>
																	<p class="bld">{{ date.lastmodifierName }}</p>
																</ion-col>
																	</ion-row>
																	</ion-col>
																</ion-row>
															</ion-col>
													</div>
												</ion-accordion>
											</ion-accordion-group>
										</div>
									</ion-accordion>
								</ion-accordion-group>
								</ion-row>
					</div>
				</ion-accordion>
				<ion-accordion value="archive">
					<ion-item slot="header" color="light">
						<ion-label>{{$t('general.archive')}}</ion-label>
					</ion-item>
				<div class="ion-padding" slot="content">
					<ion-row v-for="(c, index) in archive_weeks" :key="index">
						<ion-accordion-group multiple="true" class="fullwidth">
							<ion-accordion value="forms0">
								<ion-item slot="header" color="light">
									<ion-label>{{$t('general.week') +" "+ c}}</ion-label>
								</ion-item>
								<div class="ion-padding fullwidth" slot="content">
									<ion-accordion-group multiple="true">
										<ion-accordion :value="String('forms'+idx0)" v-for="(d,idx0) in archive_grouped_weeks[c]" :key="idx0">
											<ion-item slot="header" color="light">
												<ion-label>{{d.date}}</ion-label>
											</ion-item>
											<div class="ion-padding fullwidth" slot="content">
												<ion-col size="12" class="topborder">
													<ion-row>
														<ion-col>
															<p class="bld">{{$t('general.childName')}}</p>
														</ion-col>
														<ion-col>
															<p class="bld">{{$t('operator.morningDrive')}}</p>
														</ion-col>
														<ion-col>
															<p class="bld">{{$t('operator.afternoonDrive')}}</p>
														</ion-col>
														<ion-col>
															<p class="bld">{{ $t('operator.lastModifier') }}</p>
														</ion-col>
														<ion-col v-for="(date,idx) in archive_dayForms[d.date]" :key="idx" size="12">
															<ion-row>
																<ion-col>
																	{{date.childName}}
																</ion-col>
																<ion-col>
																	<ion-icon
																		:icon="date.morning ? closeOutline : removeOutline"
																		size="large"
																		:color="date.morning ? 'danger' : 'primary'">
																	</ion-icon>
																</ion-col>
																<ion-col>
																	<ion-icon
																		:icon="date.afternoon ? closeOutline : removeOutline"
																		size="large"
																		:color="date.afternoon ? 'danger' : 'primary'">
																	</ion-icon>
																</ion-col>
																<ion-col>
															<p class="bld">{{ date.lastmodifierName }}</p>
														</ion-col>
															</ion-row>
															</ion-col>
														</ion-row>
													</ion-col>
											</div>
										</ion-accordion>
									</ion-accordion-group>
								</div>
							</ion-accordion>
						</ion-accordion-group>
						</ion-row>
			</div>
				</ion-accordion>
				</ion-accordion-group>
				</ion-content>
				<ion-content class="ion-padding ion-margin-top routeTable" v-if="currentPage == 'addException'">
					<OperatorAddException  @goback="parseAddEmited"/>
				</ion-content>
				<!--<ion-modal :is-open="isOpenRef"  @didDismiss="setOpen(false), getExceptions()">
					<ion-content class="ion-padding ion-margin-top routeTable">
						<EditException :editdata="editData" @responsedata="parseModalEmitted"/>
					</ion-content>
				</ion-modal>-->
				<ion-modal :is-open="isOpenRef"  @didDismiss="setOpen(false), getForms()" class="bigmodal">
					<ion-content class="ion-padding ion-margin-top routeTable">
						<EditExceptionForm @responsedata="parseModalEmitted" :childs="children" :exceptionforms="rawforms"/>
					</ion-content>
				</ion-modal>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
	IonContent,
	IonGrid,
	IonRow,
	IonButton,
	IonIcon,
	IonItem,
	IonHeader,
	IonCol,
	IonTitle,
	IonToolbar,
	IonModal,
	modalController,
	alertController,
	IonAccordion,
	IonAccordionGroup,
	IonLabel,
	IonSearchbar,
} from "@ionic/vue";
import {
	add,
	arrowBackOutline,
	pencilOutline,
	trashOutline,
	checkmarkOutline,
	closeOutline,
	removeOutline
} from 'ionicons/icons';
import { defineComponent, toRaw, ref } from 'vue';
import axios from 'axios';
import moment from 'moment';
import OperatorAddException from './AddException.vue';
import StatusToast from '@/components/StatusToast.vue';
//import EditException from './EditException.vue';
import EditExceptionForm from './EditExceptionForm.vue';
export default defineComponent({
	name: "operatorExceptions",
	components:{
		IonContent,
		IonGrid,
		IonRow,
		IonButton,
		IonIcon,
		IonModal,
		IonItem,
		IonHeader,
		IonCol,
		IonTitle,
		IonToolbar,
		OperatorAddException,
		EditExceptionForm,
		IonAccordion,
		IonAccordionGroup,
		IonLabel,
		IonSearchbar
	},
	methods:{
		async getExceptions(){
			axios.post(this.$api_add + localStorage.getItem("org") +'exceptions/getall').then((res)=>{
				if(res.data.result == 'ok'){
					this.exceptions = toRaw(res.data.data);
				}
				else{
					this.toastObject['message'] = String(res.status + " " + this.$t('operator.exceptionResponses.getFailed') + res.statusText);
					this.toastObject['status'] = false;
				}
			}).then(()=>{
				this.preprocess();
				this.getArchive();
				this.getDailyExceptions();
			}).catch(e=>{
				this.toastObject['message'] = String(this.$t('operator.exceptionResponses.getFailed') + e);
				this.toastObject['status'] = false;
			});
		},
		async getArchive(){
			axios.post(this.$api_add + localStorage.getItem("org") +'exceptions/getarchive').then((res)=>{
				if(res.data.result == 'ok'){
					this.archive = toRaw(res.data.data);
				}
				else{
					this.toastObject['message'] = String(res.status + " " + this.$t('operator.exceptionResponses.getFailed') + res.statusText);
					this.toastObject['status'] = false;
				}
			}).then(()=>{
				this.preprocessArchive();
			}).catch(e=>{
				this.toastObject['message'] = String(this.$t('operator.exceptionResponses.getFailed') + e);
				this.toastObject['status'] = false;
			});
		},
		async getDailyExceptions(){
			axios.post(this.$api_add + localStorage.getItem("org") +'exceptions/getdaily').then((res)=>{
				if(res.data.result == 'ok'){
					this.dailyexceptions = toRaw(res.data.data);
				}
				else{
					this.toastObject['message'] = String(res.status + " " + this.$t('operator.exceptionResponses.getFailed') + res.statusText);
					this.toastObject['status'] = false;
				}
			}).then(()=>{
				this.preprocessDaily();
			}).catch(e=>{
				this.toastObject['message'] = String(this.$t('operator.exceptionResponses.getFailed') + e);
				this.toastObject['status'] = false;
			});
		},
		archiveException(index){
			const dt = toRaw(this.exceptions[index]);
			axios.post(this.$api_add + localStorage.getItem("org") +'exceptions/archive', dt).then((res)=>{
				if(res.data.result == 'ok'){
					this.exceptions.splice(index,1);
					this.toastObject['message'] = String(res.status + " " + this.$t('operator.exceptionResponses.deleteSuccess'));
					this.toastObject['status'] = true;
				}
				else{
					this.toastObject['message'] = String(res.status + " " + this.$t('operator.exceptionResponses.deleteFailure') + res.statusText);
					this.toastObject['status']  = false
					//this.toastToast(true);
				}
				this.showToast();
			}).catch((e)=>{
				this.toastObject['message'] = String(this.$t('operator.exceptionResponses.deleteFailure') + e);
				this.toastObject['status'] = false;
				this.showToast();
			}).finally(()=>{
				//this.getExceptions();
			});
		},
		getEditData(index){
			this.editData = this.filteredExceptions[index];
		},
		parseAddEmited(values){
			this.toastObject['message'] = values.statusMessage;
			this.toastObject['status']  = values.editStatus;

			this.showToast();
			if(values.editStatus){
				this.changeView('table');
				//this.getExceptions();
			}
		},
		async showDeleteAlert(index){

			const data = toRaw(this.filteredExceptions[index]);
			let message = `
				`+this.$t('general.childName')+`: `+data.childname+`<br>
				`+this.$t('operator.exceptionStart')+`: `+data.datefrom_formatted+`<br>
				`+this.$t('operator.exceptionEnd')+`: `+data.dateto_formatted+`
			`;

			const alert = await alertController.create({
				header: this.$t('operator.confirmExceptionDelete'),
				message: message,
				buttons: [{
						text: this.$t('general.cancel'),
						role: 'cancel',
						cssClass: 'secondary',
						id: 'cancel-button'
					},
					{
						id: 'confirm-button',
						text: this.$t('operator.delete'),
						cssClass:'deletealert',
						handler: () => {
							this.archiveException(index);
						},
					}],
			});
			await alert.present();

			await alert.onDidDismiss();
		},
		async getChildren(){
			axios.post(this.$api_add + localStorage.getItem("org") +'child/getall').then((res)=>{
				if(res.data.result == 'ok'){
					this.children = toRaw(res.data.data);
				}
				else{
					this.toastObject['message'] = String(res.status + " " + this.$t('operator.exceptionResponses.getFailed') + res.statusText);
					this.toastObject['status'] = false;
				}
			}).finally(()=>{
				this.getForms();
				//this.getExceptions();
			}).catch(e=>{
				this.toastObject['message'] = String(this.$t('operator.exceptionResponses.getFailed') + e);
				this.toastObject['status'] = false;
			})
		},
		async getForms(){
			axios.post(this.$api_add + localStorage.getItem("org") +'exceptions/getforms').then((res)=>{

				if(res.data.result == 'ok'){
					const chld0 = toRaw(this.children)
					let dt = res.data.data.map((yu)=>{
							let chld = chld0.find((x)=>{
								return x.id == yu.childid;
							});
							let parsed = JSON.parse(yu.days).map((o)=>{
								return {
									"week": yu.week,
									"year": yu.year,
									"date": moment.utc(o.date).format('DD.MM.YYYY'),
									"morning": o.morning,
									"afternoon":o.afternoon,
									"child": yu.childid,
									"lastmodifierName": yu.lastmodifiername != undefined && yu.lastmodifiername != null ? yu.lastmodifiername : "",
									"childName": chld != undefined && chld != null ? String(chld.firstname + " " + chld.lastname) : String(this.$t('operator.deletedChild')+' ('+yu.childid+')')
								}
							});
							return parsed;
					}).flat();

					this.rawforms = res.data.data.map((ui)=>{
						return{
							"id": ui.id,
							"week": ui.week,
							"year": ui.year,
							"days": JSON.parse(ui.days),
							"childid": ui.childid,
							"parentid": ui.parentid,
							"lastmodifierName": ui.lastmodifiername != undefined && ui.lastmodifiername != null ? ui.lastmodifiername : ""
						}
					});
					const f_dt = dt.filter((hj)=>{
						return hj.morning || hj.afternoon;
					});
					this.exceptionforms = f_dt
					let test = f_dt;
					test.sort(this.compareWeeks);
					let weeks_and_days = test.map((io)=>{
						return {"week": io.week, "date": io.date}
					});

					let asd = Object.values(
						weeks_and_days.reduce((acc, obj) => ({ ...acc, [obj.date]: obj }), {})
					);

					let grouped_weeks = this.groupBy(asd, 'week');
					let grouped_days = this.groupBy(test, 'date');
					let data_weekbased = this.groupBy(test, 'week');
					let weeks = Object.keys(grouped_weeks);
					for (var i = 0; i < weeks.length; i++) {
						grouped_weeks[weeks[i]].sort((a,b)=>{
							return new Date(a.date) - new Date(b.date)
						});
					}

					this.grouped_weeks = grouped_weeks;
					this.dayForms = grouped_days;
					this.weeks = weeks;
					this.weekbased = data_weekbased;
				}
				else{
					this.toastObject['message'] = String(res.status + " " + this.$t('operator.exceptionResponses.getFailed') + res.statusText);
					this.toastObject['status'] = false;
				}
			}).finally(()=>{
				this.getArchiveForms();
			}).catch(e=>{
				console.log(e);
				this.toastObject['message'] = String(this.$t('operator.exceptionResponses.getFailed') + e);
				this.toastObject['status'] = false;
			});
		},
		async getArchiveForms(){
			axios.post(this.$api_add + localStorage.getItem("org") +'exceptions/getforms_archive').then((res)=>{
				if(res.data.result == 'ok'){
					const chld0 = toRaw(this.children)
					let dt = res.data.data.map((yu)=>{
							let chld = chld0.find((x)=>{
								return x.id == yu.childid;
							});
							let parsed = JSON.parse(yu.days).map((o)=>{
								return {
									"week": yu.week,
									"year": yu.year,
									"date": moment.utc(o.date).format('DD.MM.YYYY'),
									"morning": o.morning,
									"afternoon":o.afternoon,
									"child": yu.childid,
									"lastmodifierName": yu.lastmodifiername != undefined && yu.lastmodifiername != null ? yu.lastmodifiername : "",
									"childName": chld != undefined && chld != null ? String(chld.firstname + " " + chld.lastname) : String(this.$t('operator.deletedChild')+' ('+yu.childid+')')
								}
							});
							return parsed;
					}).flat().filter((hj)=>{
						return hj.morning || hj.afternoon;
					});

					this.archiveforms = dt
					let test = dt;
					test.sort(this.compareWeeks);
					let weeks_and_days = test.map((io)=>{
						return {"week": io.week, "date": io.date}
					});

					let asd = Object.values(
						weeks_and_days.reduce((acc, obj) => ({ ...acc, [obj.date]: obj }), {})
					);

					let grouped_weeks0 = this.groupBy(asd, 'week');
					let grouped_days0 = this.groupBy(test, 'date');
					let data_weekbased0 = this.groupBy(test, 'week');

					let weeks = Object.keys(grouped_weeks0).reverse();
					for (var i = 0; i < weeks.length; i++) {
						grouped_weeks0[weeks[i]].sort((a,b)=>{
							return new Date(a.date) - new Date(b.date)
						});
					}

					this.archive_grouped_weeks = grouped_weeks0;
					this.archive_dayForms = grouped_days0;
					this.archive_weeks = weeks
					this.weekbased_archive = data_weekbased0;
				}
				else{
					this.toastObject['message'] = String(res.status + " " + this.$t('operator.exceptionResponses.getFailed') + res.statusText);
					this.toastObject['status'] = false;
				}
			}).catch(e=>{
				this.toastObject['message'] = String(this.$t('operator.exceptionResponses.getFailed') + e);
				this.toastObject['status'] = false;
			});
		},
		compareWeeks( a, b ) {
			if ( a.week < b.week ){
				return -1;
			}
			if ( a.week > b.week ){
				return 1;
			}
			return 0;
		},
		compareWeeks_reverse( a, b ) {
			if ( b.week < a.week ){
				return -1;
			}
			if ( b.week > a.week ){
				return 1;
			}
			return 0;
		},
		async preprocessDaily(){
			let ex = this.dailyexceptions;
			let res = [];
			for (let i = 0; i < ex.length; i++) {
				let chld = this.children.find((x)=>{
					return x.id == ex[i].childid;
				});

				if(chld == undefined || chld == null){
						continue;
				}

				else{
					ex[i]['childname'] = String(chld.firstname + " " + chld.lastname);
					ex[i]['df_ts'] = moment.utc(ex[i].dateffrom).format('X');
					ex[i]['datefrom_formatted'] = moment.utc(ex[i].datefrom).format('DD.MM.YYYY HH:mm:ss');
					ex[i]['dateto_formatted'] = moment.utc(ex[i].dateto).format('DD.MM.YYYY HH:mm:ss');
					ex[i]['timestamp_formatted'] = moment.utc(ex[i].timestamp).format('DD.MM.YYYY HH:mm:ss');
					res.push(ex[i])
				}
			}
			this.processedDaily = res;
		},
		async preprocess(){
			let ex = this.exceptions;
			let res = [];
			for (let i = 0; i < ex.length; i++) {
				let chld = this.children.find((x)=>{
					return x.id == ex[i].childid;
				});

				if(chld == undefined || chld == null){
						continue;
				}

				else{
					ex[i]['childname'] = String(chld.firstname + " " + chld.lastname);
					ex[i]['df_ts'] = moment(ex[i].dateffrom).utc().format('X');
					ex[i]['datefrom_formatted'] = moment(ex[i].datefrom).utc().format('DD.MM.YYYY HH:mm:ss');
					ex[i]['dateto_formatted'] = moment(ex[i].dateto).utc().format('DD.MM.YYYY HH:mm:ss');
					ex[i]['timestamp_formatted'] = moment(ex[i].timestamp).utc().format('DD.MM.YYYY HH:mm:ss');
					ex[i]['df_ts'] = moment.utc(ex[i].dateffrom).format('X');
					ex[i]['datefrom_formatted'] = moment.utc(ex[i].datefrom).format('DD.MM.YYYY HH:mm:ss');
					ex[i]['dateto_formatted'] = moment.utc(ex[i].dateto).format('DD.MM.YYYY HH:mm:ss');
					ex[i]['timestamp_formatted'] = moment.utc(ex[i].timestamp).format('DD.MM.YYYY HH:mm:ss');
					res.push(ex[i])
				}
			}
			this.processedExceptions = res;
			if(this.filteredExceptions == null){
				this.filteredExceptions = this.processedExceptions;
			}
			else{
				this.searchExceptions(this.searchQuery)
			}
		},
		async preprocessArchive(){
			let ex = this.archive;
			let res = [];
			for (let i = 0; i < ex.length; i++) {
				let chld = this.children.find((x)=>{
					return x.id == ex[i].childid;
				});

				if(chld == undefined || chld == null){
						continue;
				}else{
					ex[i]['childname'] = (String(chld.firstname + " " + chld.lastname) || chld.id)
					ex[i]['df_ts'] = moment(ex[i].dateffrom).utc().format('X');
					ex[i]['datefrom_formatted'] = moment(ex[i].datefrom).utc().format('DD.MM.YYYY HH:mm:ss');
					ex[i]['dateto_formatted'] = moment(ex[i].dateto).utc().format('DD.MM.YYYY HH:mm:ss');
					ex[i]['timestamp_formatted'] = moment(ex[i].timestamp).utc().format('DD.MM.YYYY HH:mm:ss');
					ex[i]['df_ts'] = moment.utc(ex[i].dateffrom).format('X');
					ex[i]['datefrom_formatted'] = moment.utc(ex[i].datefrom).format('DD.MM.YYYY HH:mm:ss');
					ex[i]['dateto_formatted'] = moment.utc(ex[i].dateto).format('DD.MM.YYYY HH:mm:ss');
					ex[i]['timestamp_formatted'] = moment.utc(ex[i].timestamp).format('DD.MM.YYYY HH:mm:ss');
					res.push(ex[i]);
				}
			}
			this.processedArchive = res;
			if(this.filteredArchive == null){
				this.filteredArchive = this.processedArchive;
			}
			else{
				this.searchExceptions(this.searchQuery)
			}
		},
		changeIcon(){
			if(this.currentPage == 'table'){
				return add;
			}
			else if(this.currentPage == 'addException'){
				return arrowBackOutline;
			}
		},
		changeView(view){
			this.currentPage = view;
		},
		showToast(){
			StatusToast.methods.openToast(this.toastObject);
		},
		parseModalEmitted(values){
			this.toastObject['message'] = values.statusMessage;
			this.toastObject['status']  = values.editStatus;

			this.showToast();
			if(values.editStatus){
				modalController.dismiss();
				//this.getExceptions();
				this.getForms();
			}
		},
		searchExceptions(value){
			const query = toRaw(value).toLowerCase();
			if(!query){
				this.filteredExceptions = this.processedExceptions;
				this.filteredArchive = this.processedArchive;
			}
			else{
				this.filteredExceptions = this.processedExceptions.filter((x)=>{
					const xvalues = String(Object.values(x).join(",")).toLowerCase();
					return xvalues.includes(query);
				});

				this.filteredArchive = this.processedArchive.filter((x)=>{
					const xvalues = String(Object.values(x).join(",")).toLowerCase();
					return xvalues.includes(query);
				})
			}
			this.searchQuery = query;
		},
		groupBy(arr, property) {
			return arr.reduce(function (memo, x) {
				if (!memo[x[property]]) { memo[x[property]] = []; }
				memo[x[property]].push(x);
				return memo;
			}, {});
		}
	},
	data(){
		this.getChildren();

		return{
			toastObject:{
				"message": null,
				"status": false
			},
			currentPage: 'table',
			editStatus: false,
			showMessage: false,
			statusMessage: null,
			exceptions: null,
			dailyexceptions: null,
			archive:null,
			processedExceptions: null,
			processedArchive: null,
			processedDaily: null,
			filteredExceptions: null,
			filteredArchive: null,
			children: null,
			editData: null,
			searchQuery: null,
			currday: moment.utc().isoWeekday(),
			currdate: moment.utc().format('DD.MM.YYYY'),
			isdesktop: this.$isdesktop,
			exceptionforms: [],
			processedForms: [],
			dayForms: [],
			weeks: [],
			days: [],
			calendar: [],
			grouped_weeks: [],
			weekbased: [],
			rawforms: [],
			archiveforms: [],
			archive_dayForms: [],
			archive_weeks: [],
			weekbased_archive: [],
			archive_grouped_weeks: [],
		}
	},
	setup(){
		const isOpenRef = ref(false);
		const setOpen = (state) => isOpenRef.value = state;
		return{
			isOpenRef,
			setOpen,
			moment,
			pencilOutline,
			trashOutline,
			checkmarkOutline,
			closeOutline,
			removeOutline
		}
	}
});
</script>
<style>
	.tableHeader {
		display: flex; /* or inline-flex */
		flex-direction: row;
	}

	.routeTable{
		height: 90vh;
	}
	.fullwidth{
		width: 100%;
	}
	.bld{
		font-weight: bold;
	}
	.topborder{
		border-top: solid 1px #cfcdca;
	}
	.bigmodal {
  align-items: center;
  justify-content: center;
  &::part(content){
    width: 95vw;
    height: 95vh;
  }
}
</style>
