<template>
    <ion-page>
        <ion-content>
            <ion-accordion-group value="instructions">
                <ion-accordion>
                    <ion-item slot="header"><ion-label>{{ $t('info.parent.confirmTitle') }}</ion-label></ion-item>
                    <ion-item slot="content"><ion-label class="cont">{{ $t('info.parent.confirmContent') }}</ion-label></ion-item>
                    
                </ion-accordion>
                <ion-accordion>
                    <ion-item slot="header"><ion-label>{{ $t('info.parent.eventTitle') }}</ion-label></ion-item>
                    <ion-item slot="content"><ion-label class="cont">{{ $t('info.parent.eventContent') }}</ion-label></ion-item>
                </ion-accordion>
                <ion-accordion>
                    <ion-item slot="header"><ion-label>{{ $t('info.parent.absentTitle') }}</ion-label></ion-item>
                    <ion-item slot="content"><ion-label class="cont">{{ $t('info.parent.absentContent') }}</ion-label></ion-item>
                </ion-accordion>
                <ion-accordion>
                    <ion-item slot="header"><ion-label>{{ $t('info.parent.messageTitle') }}</ion-label></ion-item>
                    <ion-item slot="content"><ion-label  class="cont">{{ $t('info.parent.messageContent') }}</ion-label></ion-item>
                </ion-accordion>
                 <ion-accordion>
                    <ion-item slot="header"><ion-label>{{ $t('info.parent.changesTitle') }}</ion-label></ion-item>
                    <ion-item slot="content"><ion-label class="cont">{{ $t('info.parent.changesContent') }}</ion-label></ion-item>
                </ion-accordion>
                <ion-accordion>
                    <ion-item slot="header"><ion-label>{{ $t('info.parent.accountTitle') }}</ion-label></ion-item>
                    <ion-item slot="content"><ion-label class="cont">{{ $t('info.parent.accountContent') }}</ion-label></ion-item>
                </ion-accordion>
            </ion-accordion-group>
        </ion-content>
    </ion-page>
</template>
<script lang="ts">
import {IonPage, IonContent,IonAccordionGroup, IonAccordion, IonLabel,IonItem} from '@ionic/vue';
import {defineComponent} from 'vue';
export default defineComponent({

    name: "ParentInfoPage",
    components:{
        IonPage,
        IonContent,
        IonAccordion,
        IonAccordionGroup,
        IonLabel,
        IonItem
    }
})

</script>

<style scoped>
.cont{
        display: flex;
        flex-direction: column;
        white-space: pre-line;
    }
</style>
