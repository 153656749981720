<template>
    <ion-content class="content-1">
            <ion-accordion-group ref="accInfoGroup">
                <ion-accordion value="new">
                <ion-item slot="header">
                    <ion-label>{{$t("info.newTitle")}}</ion-label>
                </ion-item>
                <ion-item slot="content">
                    <ion-list>
                    <ion-item v-for="update in updates" :key="update.id">
                    <ion-label class="ion-text-wrap"><h2>{{update.title}}</h2><h3>{{ moment(update.timestamp).format("DD.MM.yyyy HH:mm") }}</h3>
                    <p class="infoText">{{update.description}}</p>
                </ion-label>
            </ion-item>
                </ion-list>
                </ion-item>
                </ion-accordion>
                <ion-accordion value="feedback">
                <ion-item slot="header">
                    <ion-label>{{$t("info.feedbackTitle")}}</ion-label>
                </ion-item>
                <ion-item slot="content">
                <ion-list>
                    <ion-item>
                        <ion-label class="ion-text-wrap">{{ $t('info.feedbackHelpText') }}</ion-label>
                    </ion-item>
                    <ion-item>

                    <ion-select interface="popover" v-model="feedback.type" :ok-text="$t('assistant.choose')" :cancel-text="$t('assistant.cancel')" value="1" :placeholder="$t('info.feedback1')">
                        <ion-select-option value="1">{{$t('info.feedback1')}}</ion-select-option>
                        <ion-select-option value="2">{{$t('info.feedback2')}}</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item>
                    <ion-input label-placement="floating" :label="$t('info.feedbackFormTitle')" v-model="feedback.topic"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-textarea label-placement="floating" :label="$t('info.feedbackFormDesc')" v-model="feedback.desc" :auto-grow="true" :counter="true" :maxlength="2000"></ion-textarea>
                </ion-item>
                <ion-item>
                    <ion-button @click="submitForm"><ion-label>{{$t('info.submit')}}</ion-label></ion-button>
                </ion-item>
            </ion-list>
                </ion-item>
                </ion-accordion>
                <ion-accordion value="what">
                <ion-item slot="header">
                    <ion-label>{{$t("info.whatTitle")}}</ion-label>
                </ion-item>
                <ion-item slot="content">
                    <ion-text>{{$t("info.what")}}</ion-text>
                </ion-item>
                </ion-accordion>
                <ion-accordion value="why">
                    <ion-item slot="header">
                    <ion-label>{{$t("info.whyTitle")}}</ion-label>
                </ion-item>
                <ion-item slot="content">
                    <ion-text>{{$t("info.why")}}</ion-text>
                </ion-item>
                </ion-accordion>
                <ion-accordion value="who">
                    <ion-item slot="header">
                    <ion-label>{{$t("info.whoTitle")}}</ion-label>
                </ion-item>
                <ion-item slot="content">
                    <ion-text>{{$t("info.who")}}</ion-text>
                </ion-item>
                </ion-accordion>
                <ion-accordion value="developers">
                    <ion-item slot="header">
                    <ion-label>{{$t("info.developersTitle")}}</ion-label>
                </ion-item>
                <ion-item slot="content">

                    <ion-text>{{$t("info.developers")}}</ion-text>

                </ion-item>
                </ion-accordion>

                <ion-accordion value="contact">
                <ion-item slot="header">
                    <ion-label>{{$t("info.contactTitle")}}</ion-label>
                </ion-item>
                <ion-item class="cont" slot="content">
            



                    <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-text>{{$t("info.contact.transportCompany")}}</ion-text>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-text>{{$t("info.contact.owner")}}</ion-text>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-text>{{$t("info.contact.devs")}}</ion-text>
                    </ion-col>
                </ion-row>
                    </ion-grid>
                </ion-item>
                </ion-accordion>
                <ion-accordion value="gdpr">
                <ion-item slot="header">
                    <ion-label>{{$t("info.gdprTitle")}}</ion-label>
                </ion-item>
                <ion-item slot="content">
                    <GDPRModal></GDPRModal>
                </ion-item>
                </ion-accordion>
                <ion-accordion value="support">
                <ion-item slot="header">
                    <ion-label>{{$t("info.supportTitle")}}</ion-label>
                </ion-item>
                <ion-item slot="content">
                    <ion-text>{{$t("info.support")}}</ion-text>
                </ion-item>
                </ion-accordion>
            </ion-accordion-group>
            <ion-item>
            <ion-label>v{{info.version}}</ion-label>
        </ion-item>
            <ion-grid :fixed="true">
                <ion-row>
                    <ion-col size="12">
                        <ion-item class="items">
                        <a href="https://www.auroral.eu/">
                        <ion-img src="assets/icon/AURORAL-Vertical.png"></ion-img>

                    </a>
                    </ion-item>
                    </ion-col>
                    <ion-col size="12">
                        <ion-item class="items">
                    <a href="https://www.auroral.eu/">
                        <ion-icon src="assets/icon/EU-Auroral.svg"></ion-icon>
                    </a>
                </ion-item>
                    </ion-col>
                    <ion-col size="12">
                        <ion-item class="items">
                    <a href="https://www.digipolis.fi/">
                        <ion-img src="assets/icon/Digipolis.png"></ion-img>
                    </a>
                </ion-item>
                    </ion-col>
                    <ion-col size="12">
                        <ion-item class="items">
                        <a href="https://www.oulu.fi/">
                        <ion-img size="large" src="assets/icon/UniOulu.png"></ion-img>
                    </a>
                </ion-item>
                    </ion-col>
                    <ion-col size="12">
                        <ion-item class="items">
                        <a href="https://www.centria.fi/">
                        <ion-img src="assets/icon/Centria.jpg"></ion-img>
                    </a>
                </ion-item>
                    </ion-col>
                </ion-row>

            </ion-grid>

    </ion-content>
</template>
<script lang="ts">
import {isPlatform, IonContent, toastController, IonAccordion, IonList, IonAccordionGroup, IonText, IonLabel, IonGrid, IonRow, IonCol, IonItem, IonIcon, IonImg, IonInput, IonSelect, IonSelectOption, IonButton, IonTextarea } from '@ionic/vue';
import {defineComponent, inject} from 'vue';
import axios from "axios";
import moment from 'moment';
import {App} from '@capacitor/app';
import GDPRModal from "@/components/GDPRModal.vue";
export default defineComponent({

    name: "InfoPage",
    components:{

        IonAccordion,
        IonAccordionGroup,
        IonText,
        IonLabel,
        IonGrid,
        IonRow,
        IonCol,
        IonItem,
        IonIcon,
        IonImg,
        IonContent,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonButton,
        IonList,
        IonTextarea,
        GDPRModal
    },
    setup(){
        const apiAdd = inject('$api_add');
        return{
            apiAdd,
            moment
        }

    },
    data() {
        return{
            feedback:{
                type: 1,
                topic: "",
                desc: "",
            },
            updates: [],
            info:{
                name: "Koulukyyti",
                id: "koulukyyti.digipolis.fi",
                build: "68",
                version: "1.68 web"

            }
        }
    },
    methods:{
        submitForm(){
            axios.post(this.apiAdd + localStorage.getItem("org") + 'feedback/createfeedback', {title: this.feedback.topic, description: this.feedback.desc, type: this.feedback.type}).then(res=>{
                if(res.data.result == "ok"){
                    this.presentToast(this.$t('info.feedbackSuccess'), 3000, 'success');
                    this.feedback.type = 1;
                    this.feedback.topic = "";
                    this.feedback.desc = "";
                }
                else{
                    this.presentToast(this.$t('info.feedbackFailure'), 3000, 'danger');
                }
            })
        },
        getUpdates(){
            axios.post(this.apiAdd + localStorage.getItem("org") + 'feedback/getbulletin').then(result => {
                this.updates = result.data.data;
            })
        },
        async presentToast(msg:string, duration:number, col:string){
            const toast = await toastController.create({
                message: msg,
                duration: duration,
                position: 'top',
                color: col
            })
            await toast.present();
        }

    },
    mounted(){
        this.getUpdates();
        if(isPlatform('android') || isPlatform('ios') ){
            App.getInfo().then(res =>{
            this.info = res;

        }).catch(e =>{
            console.error(e)
        });
    }

    }
})

</script>

<style scoped>
    ion-text {
        white-space: pre-line;
    }
    .cont{
        display: flex;
        flex-direction: column;
    }
    ion-col {
        text-align: center;
    }
    ion-icon {
        font-size: 512px;
    }
    .items{
        background-color: white;
    }
    .content-1{
        display:flex;
        flex-direction: column;
    }
    .infoText{
        white-space: break-spaces;
    }
</style>
