<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-header>
					<ion-toolbar>
						<ion-title>{{currentPage == 'table' ? $t('operator.routes') : $t('operator.addRoute')}}</ion-title>
						<ion-buttons slot="secondary">

							<ion-button size="large" @click="currentPage == 'table' ? changeView('addRoute') : changeView('table')">
								<ion-icon :icon="changeIcon()" slot="start" size="large"></ion-icon>
								{{currentPage == 'addRoute' ? $t('operator.routes') : $t('operator.addRoute')}}
							</ion-button>
						</ion-buttons>
					</ion-toolbar>
				</ion-header>
				<ion-content class="ion-padding ion-margin-top routeTable" v-if="currentPage == 'table'">
          <ion-grid>
						<ion-row>
							<ion-col size="12">
								<ion-searchbar id="routeSearch" :placeholder="$t('operator.searchRoutes')" @input="searchRoutes($event.target.value)"></ion-searchbar>
							</ion-col>
						</ion-row>
						<ion-row class="tableHeader bottomborder">
							<ion-col size="3">
								<ion-item lines="none" @click="sortBy('name')" style="cursor:pointer;" class="tabletfont">
									<b>{{$t('operator.routeName')}}</b>
									<ion-icon v-if="this.sortLastValue == 'name' && this.sortClicks == 0" :icon="chevronUpOutline" slot="end"></ion-icon>
									<ion-icon v-else-if="this.sortLastValue == 'name' && this.sortClicks == 1" :icon="chevronDownOutline" slot="end"></ion-icon>
									<b slot="end" v-else>-</b>
								</ion-item>
							</ion-col>
							<ion-col size="2">
								<ion-item lines="none" @click="sortBy('type')" style="cursor:pointer;" class="tabletfont ion-text-wrap">
									<b>{{$t('operator.routeType')}}</b>
									<ion-icon v-if="this.sortLastValue == 'type' && this.sortClicks == 0" :icon="chevronUpOutline" slot="end"></ion-icon>
									<ion-icon v-else-if="this.sortLastValue == 'type' && this.sortClicks == 1" :icon="chevronDownOutline" slot="end"></ion-icon>
									<b slot="end" v-else>-</b>
								</ion-item>
							</ion-col>
							<ion-col size="2">
								<ion-item lines="none" @click="sortBy('days')" style="cursor:pointer;" class="tabletfont ion-text-wrap">
									<b>{{$t('operator.routeDays')}}</b>
									<ion-icon v-if="this.sortLastValue == 'days' && this.sortClicks == 0" :icon="chevronUpOutline" slot="end"></ion-icon>
									<ion-icon v-else-if="this.sortLastValue == 'days' && this.sortClicks == 1" :icon="chevronDownOutline" slot="end"></ion-icon>
									<b slot="end" v-else>-</b>
								</ion-item>
							</ion-col>
							<ion-col size="3">
								<ion-item lines="none" class="tabletfont ion-text-wrap">
									<b>{{$t('operator.vehicle')}}</b>
								</ion-item>
							</ion-col>
						</ion-row>
              <ion-row v-for="(routes,index) in filteredRoutes" :key="index">
                <ion-col size="12" class="topborder">
                  <ion-row>
                    <ion-col size="3">
                      <ion-item lines="none">
                        {{routes.name}}
                      </ion-item>
                    </ion-col>
										<ion-col size="2">
											<ion-item lines="none">
												{{routes.parsedType}}
											</ion-item>
										</ion-col>
										<ion-col size="2">
											<ion-item lines="none" class="daystext">
												{{routes.parsedDays}}
											</ion-item>
										</ion-col>
										<ion-col size="3">
											<ion-item lines="none">
												{{findCar(routes.assignedvehicle)}}
											</ion-item>
										</ion-col>
                    <ion-col size="2" v-if="isdesktop">
                      <ion-item lines="none">
												<ion-row>
													<ion-col size="12">
														<ion-button size="full" @click="getEditData(index),setOpen(true)">{{$t('operator.modify')}}</ion-button>
													</ion-col>
													<ion-col size="12">
														<ion-button size="full" @click="getCopyData(index),setCopy(true)">{{$t('operator.copyRoute')}}</ion-button>
													</ion-col>
													<ion-col size="12">
														<ion-button size="full" @click="showDeleteAlert(index)" color="danger">{{$t('operator.delete')}}</ion-button>
													</ion-col>
													<ion-col size="12">
														<ion-button size="full" style="font-size:0.6em;" @click="addToDaily(index)" color="success">{{$t('operator.addtoDaily')}}</ion-button>
													</ion-col>
													<ion-col size="12">
														<ion-button size="full" @click="downloadRoute(index)" color="success">{{ $t('operator.downloadFile') }}</ion-button>
													</ion-col>
												</ion-row>
                      </ion-item>
                    </ion-col>
										<ion-col size="12" v-else>
											<ion-item lines="none" class="ion-float-right">
												<ion-row>
													<ion-col size="12">
														<ion-button size="large" @click="getEditData(index),setOpen(true)">
															<ion-icon :icon="pencilOutline"></ion-icon>
														</ion-button>
														<ion-button size="large" @click="getCopyData(index),setCopy(true)">
															<ion-icon :icon="copyOutline"></ion-icon>
														</ion-button>
														<ion-button size="large" @click="showDeleteAlert(index)" color="danger">
															<ion-icon :icon="trashOutline"></ion-icon>
														</ion-button>
														<ion-button size="large" @click="addToDaily(index)" color="success">
															<ion-icon :icon="downloadOutline"></ion-icon>
														</ion-button>
														<ion-button size="large" @click="downloadRoute(index)" color="success">
															<ion-icon :icon="cloudDownloadOutline"></ion-icon>
														</ion-button>
													</ion-col>
												</ion-row>
											</ion-item>
										</ion-col>
                  </ion-row>
                </ion-col>
								<ion-col size="12" class="bottomborder">
									<ion-list inset="true">
										<ion-item v-if="routes.assignedvehicle != null && routes.assignedvehicle != undefined" lines="none" color="warning" class="bld">
											<ion-text><ion-icon :icon="warning" size="large"></ion-icon></ion-text>
											<ion-text>{{String($t('operator.assignedToVehicle') + ' ' + findCar(routes.assignedvehicle))}}</ion-text>
										</ion-item>
										<ion-accordion-group>
											<ion-accordion>
												<ion-item slot="header">
													<ion-label class="bld">{{routes.name + " " +$t('general.additionalInfo').toLowerCase()}}</ion-label>
												</ion-item>
												<ion-list slot="content">
													<ion-list>
														<ion-item>
															<p class="bld">{{$t('operator.startAddress') + ": "}}</p>
															<p>{{routes.start.location_id}}</p>
														</ion-item>
														<ion-item>
															<p class="bld">{{$t('operator.routeLength') + ": "}}</p>
															<p>{{routes.routelength}}</p>
														</ion-item>
														<ion-item>
															<p class="bld">{{$t('operator.routeTime') + ": "}}</p>
															<p>{{routes.routetime}}</p>
														</ion-item>
													</ion-list>
												</ion-list>
											</ion-accordion>
											<ion-accordion>
												<ion-item slot="header">
													<ion-label class="bld">{{routes.name + " " +$t('operator.routePoints').toLowerCase()}}</ion-label>
												</ion-item>
												<ion-list slot="content">
													<ion-list>
														<ion-item>
															<ion-label class="bld tabletfont ion-text-wrap">{{$t('operator.pickupAddress')}}</ion-label>
															<ion-label class="bld tabletfont ion-text-wrap">{{$t('operator.deliveryAddress')}}</ion-label>
															<ion-label class="bld tabletfont ion-text-wrap">{{$t('operator.children')}}</ion-label>
															<ion-label v-if="routes.type == 0" class="bld ion-text-wrap tabletfont">{{$t('operator.startStopTime')}}</ion-label>
															<ion-label class="bld tabletfont ion-text-wrap">{{routes.type ? $t('operator.deliveryTime') : $t('operator.pickupTime')}}</ion-label>
														</ion-item>
													</ion-list>
													<div class="routettable">
														<ion-list>
															<ion-item v-for="(point, index) in routes.points" :key="index">
																<ion-label>{{point.pickup}}</ion-label>
																<ion-label>{{point.delivery}}</ion-label>
																<ion-label>{{point.childName}}</ion-label>
																<ion-label v-if="point.showExtra">{{secondsToMinutes(point.duration)}}</ion-label>
																<ion-label>{{point.timewindow}}</ion-label>
															</ion-item>
														</ion-list>
													</div>
												</ion-list>
											</ion-accordion>
										</ion-accordion-group>
									</ion-list>
								</ion-col>
              </ion-row>
          </ion-grid>
				</ion-content>
				<ion-content class="ion-padding ion-margin-top routeTable" v-if="currentPage == 'addRoute'">
					<AddRoute  @goback="parseAddEmited"/>
				</ion-content>
				<ion-modal :is-open="isOpenRef"  @didDismiss="setOpen(false), getRoutes()" class="bigmodal">
					<ion-content class="ion-padding ion-margin-top carsTable">
						<EditRoute :routedata="editData" :cardata="cars" @responsedata="parseModalEmitted"/>
					</ion-content>
				</ion-modal>
				<ion-modal :is-open="isCopyRef" @didDismiss="setCopy(false), getRoutes()" class="bigmodal">
					<ion-content class="ion-padding ion-margin-top carsTable">
						<CopyRoute :routedata="copyData" :cardata="cars" @responsedata="parseCopyEmitted" />
					</ion-content>
				</ion-modal>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonContent,
  IonGrid,
	IonAccordion,
	IonAccordionGroup,
  IonRow,
	IonList,
	IonButton,
	IonLabel,
	IonIcon,
	IonButtons,
	IonModal,
  IonItem,
  IonHeader,
  IonCol,
  IonTitle,
  IonToolbar,
	modalController,
	IonSearchbar,
	alertController,
	IonText,
  onIonViewDidEnter
} from "@ionic/vue";
import {
	add,
	arrowBackOutline,
	chevronUpOutline,
	chevronDownOutline,
	pencilOutline,
	copyOutline,
	trashOutline,
	downloadOutline,
	cloudDownloadOutline,
	warning
} from 'ionicons/icons';
import { defineComponent, ref, toRaw } from 'vue';
import AddRoute from './AddRoute.vue';
import EditRoute from './EditRoute.vue';
import CopyRoute from './CopyRoute.vue';
import StatusToast from '@/components/StatusToast.vue';
import axios from 'axios';
export default defineComponent({
		name: "OperatorRoutes",
		components:{
			IonContent,
			IonGrid,
			IonAccordion,
			IonAccordionGroup,
			IonRow,
			IonList,
			IonButton,
			IonLabel,
			IonModal,
			IonIcon,
			IonButtons,
			IonItem,
			IonHeader,
			IonCol,
			IonTitle,
			IonToolbar,
			AddRoute,
			EditRoute,
			CopyRoute,
			IonSearchbar,
			IonText

		},
		methods:{
			changeView(view){
				this.currentPage = view;
			},
			getKeys(obj){
				return Object.keys(obj[0]);
			},
			secondsToMinutes(sec){
				return (sec/60).toFixed(0);
			},
			parseAddEmited(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					this.changeView('table');
					this.getRoutes();
				}
        this.update = !this.update;
			},
			parseModalEmitted(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					modalController.dismiss();
					this.getRoutes();
				}
        this.update = !this.update;
			},
            parseCopyEmitted(values) {
                this.toastObject['message'] = values.statusMessage;
                this.toastObject['status'] = values.copyStatus;

                this.showToast();
				if (values.copyStatus) {
					modalController.dismiss();
                    this.changeView('table');
                    this.getRoutes();
                }
                        this.update = !this.update;
            },
			addToDaily(index){
				const rawObject = toRaw(this.filteredRoutes[index]);
				rawObject['routename'] = rawObject['name'];
				rawObject['routeLength'] = parseInt(rawObject['rl']);
        rawObject['routeTime'] = parseInt(rawObject['rt']);
				rawObject['waypoints'] = JSON.stringify(rawObject['points']);
				rawObject['optimizedPoints'] = rawObject['optimizedpoints']
				rawObject['start'] = JSON.stringify(rawObject['start']);

				axios.post(this.$api_add + localStorage.getItem("org") +'route/createdaily', rawObject).then((res)=>{
					if(res.data.result == 'ok' || res.data == 'ok'){
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.addToDailyResponses.addSuccess'));
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.addToDailyResponses.addFailure') + res.statusText);
						this.toastObject['status']  = false
					}
					this.showToast();
				}).catch((e)=>{
					this.toastObject['message'] = String(this.$t('operator.addToDailyResponses.addFailure') + e);
					this.toastObject['status']  = false
					this.showToast();
				});

			},
			async getRoutes(){
				axios.post(this.$api_add + localStorage.getItem("org") +'route/getdefault').then((res)=>{
					if (res.data.result == 'ok') {
						const prsd = this.parseRoutes(res.data.data);
						this.routes = prsd;
						//if page is opened for the first time, assign routes to filteredRoutes
						if(this.filteredRoutes == null){
							this.filteredRoutes = this.routes;
						}
						else{
							//if search has been made, search it again. Updates data
							this.searchRoutes(this.searchQuery);
						}
						this.sortBy(this.sortLastValue);
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.routeResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
					this.update = !this.update;
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.routeResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
                this.update = !this.update;
			},
			getEditData(index){
				this.editData = toRaw(this.filteredRoutes[index]);
			},
			getCopyData(index) {
				this.copyData = toRaw(this.filteredRoutes[index]);
            },
			async getChildren(){
				axios.post(this.$api_add + localStorage.getItem("org") +'child/getall').then((res)=>{
					if(res.data.result == 'ok'){
						this.children = toRaw(res.data.data);
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).then(() => {
                    this.getRoutes();
                }).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.userResponses.getFailed') + e);
					this.toastObject['status'] = false;
				})
			},
			deleteRoute(index){
				const data = toRaw(this.filteredRoutes[index]);
				const jwt = localStorage.getItem('token');
				const dt = {
					"id": data.dbid,
					"routename": data.name
				};

				axios.post(this.$api_add + localStorage.getItem("org") +'route/deletedefault', dt, {headers:{"Authorization": String("Bearer " + jwt)}}).then((res)=>{
					if(res.data.result == 'ok'){
						this.filteredRoutes.splice(index,1);
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.routeResponses.deleteSuccess'));
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.routeResponses.deleteFailure') + res.statusText);
						this.toastObject['status']  = false
						//this.toastToast(true);
					}
					this.showToast();
				}).catch((e)=>{
					this.toastObject['message'] = String(this.$t('operator.routeResponses.deleteFailure') + e);
					this.toastObject['status'] = false;
					this.showToast();
				}).finally(()=>{
					this.getRoutes();
				});
			},
			findCar(id){
				if(id != undefined && id != null){
					const rs = this.cars.find((i)=>{
						return i.id == id;
					});

					return rs.name;
				}
				else{
					return "-";
				}
			},
			changeIcon(){
				if(this.currentPage == 'table'){
					return add;
				}
				else if(this.currentPage == 'addRoute'){
					return arrowBackOutline;
				}
			},
			searchRoutes(value){
				const query = toRaw(value).toLowerCase();
				if(!query){
					this.filteredRoutes = this.routes;
				}
				else{
					this.filteredRoutes = this.routes.filter((x)=>{
						//stringify points because otherwise it will be read as [Object object]
						const pts = JSON.stringify(x.points);

						//get other object values, attach them to points and make the whole thing case insensitive with toLowerCase
						const xvalues = String(Object.values(x).join(",")+pts).toLowerCase();
						return xvalues.includes(query);
					});
				}
				this.searchQuery = query;
			},
			parseDays(days){
				let result = null;
				try{
					const days0 = days.split(",");
					let parsed = [];
					days0.forEach((day)=>{
						let t_string = String('general.days.'+day);
						parsed.push(this.$t(t_string)+"\n");
					});

					result =  parsed.join("");
				}
				catch(e){
					result = String('general.days.'+days);
				}
				return result;
			},
			showToast(){
				StatusToast.methods.openToast(this.toastObject);
			},
			parseTime(time){
				return (parseInt(time)/60).toFixed(0);
			},
			parseLength(length){
				return (parseInt(length)/1000).toFixed(1);
			},
			parseType(type){
				let str = String('operator.routeTypes.'+type);
				return this.$t(str);
			},
			parsePoints(points, type){
				let pts = JSON.parse(points);
				for (let i = 0; i < pts.length; i++) {
					pts[i]['childName'] = null;
					let childId = pts[i].child;
					pts[i]['showExtra'] = type == 0 ? true : false; //show extra info if type is morning
					try {
						let test = toRaw(this.children.find((el) => {
							return el.id == childId;
						}));
						if (typeof test == 'object') {
							pts[i]['childName'] = String(test.firstname + " " + test.lastname);
						}
						else {
							pts[i]['childName'] = pts[i]['child'];
						}
					}
					catch (error) {
						console.log(error);
          }
				}

				return pts;
			},
			parseRoutes(routes){
				const parsed = [];
				routes.forEach((route) => {
					try {
						parsed.push({
							"dbid": route.id,
							"name": route.name,
							"routelength": this.parseLength(route.routelength),
							"routetime": this.parseTime(route.routetime),
							"rl": route.routelength,
							"rt": route.routetime,
							"type": route.type,
							"parsedType": this.parseType(route.type),
							"parsedDays":this.parseDays(route.days),
							"days": route.days,
							"start": JSON.parse(route.startpoint),
							"end": JSON.parse(route.endpoint),
							"children": route.children,
							"optimizedpoints": route.optimizedpoints,
							"nonoptimizedroute": route.nonoptimizedroute,
							"points": this.parsePoints(route.waypoints, route.type),
							"assignedvehicle": route.assignedvehicle
						});
					}
					catch (error) {
						console.log(error);
                    }
				});
				return parsed;
			},
			async showDeleteAlert(index){
				const data = toRaw(this.filteredRoutes[index]);
				let message = `
					`+this.$t('operator.routeName')+`: `+data.name+`\n
					`+this.$t('operator.routeType')+`: `+this.parseType(data.type)+`\n
					`+this.$t('operator.routeDays')+`: `+this.parseDays(data.days)+`
				`;

				const alert = await alertController.create({
					header: this.$t('operator.confirmRouteDelete'),
					message: message,
					buttons: [{
							text: this.$t('general.cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							id: 'cancel-button'
						},
						{
							id: 'confirm-button',
							text: this.$t('operator.delete'),
							cssClass:'deletealert',
							handler: () => {
								this.deleteRoute(index);
							},
						}],
				});
				await alert.present();

				await alert.onDidDismiss();
			},
			sortBy(value){
				if(this.sortClicks == 0){
					this.filteredRoutes = this.filteredRoutes.sort((a,b)=>{
						return a[value] - b[value];
					});
					this.sortClicks++;
					this.sortLastValue = value;
				}
				else if(this.sortClicks == 1 && this.sortLastValue == value){
					this.filteredRoutes = this.filteredRoutes.sort((a,b)=>{
						return b[value] - a[value];
					});
					this.sortClicks = 0;
				}
				else if(this.sortLastValue != value){
					this.sortLastValue = value;
					this.sortClicks = 0;
				}
				else{
					this.sortClicks = 0;
					this.sortLastValue = value;
				}
			},
			getCars(){
				axios.post(this.$api_add + localStorage.getItem("org") +'vehicles/getvehicles').then((res)=>{
					if(res.data.result == 'ok'){
						this.cars = toRaw(res.data.data);
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.vehicleResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.vehicleResponses.getFailed') + e);
					this.toastObject['status'] = false;
				})
			},
			async downloadRoute(index){
				const filename = this.filteredRoutes[index].name +".csv";
				let data = ""

				let header = `0;RouteType;RouteName;Day;StartPointAddress;EndPointAddress;StartTime\r\n1;PickUpAddress;DeliveryAddress;Child;ChildName;WaitTime;PickDeliTime\r\n`;
				data += header;
				if(this.filteredRoutes[index].end){
					data += `0;${this.filteredRoutes[index].type};${this.filteredRoutes[index].name};${this.filteredRoutes[index].days};${this.filteredRoutes[index].start.location_id};${this.filteredRoutes[index].end.location_id};${this.filteredRoutes[index].start.start_time}`;

				}else{
					data += `0;${this.filteredRoutes[index].type};${this.filteredRoutes[index].name};${this.filteredRoutes[index].days};${this.filteredRoutes[index].start.location_id};;${this.filteredRoutes[index].start.start_time}`;

				}
				this.filteredRoutes[index].points.forEach(way =>{
					data += "\n";
					data += `1;${way.pickup};${way.delivery};${way.child};${this.children[this.children.findIndex(a => a.id == way.child)].firstname + " " + this.children[this.children.findIndex(a => a.id == way.child)].lastname};${way.duration};${way.timewindow}`
				})

				let csvContent = 'data:text/csv;charset=utf-8,' + data;
				let encodedUri = encodeURI(csvContent);

				let link = document.createElement('a');
				link.setAttribute('href', encodedUri);
				link.setAttribute('download', filename);
				document.body.appendChild(link);

				link.click();
			}
		},
		setup(){

			const isOpenRef = ref(false);
			const isCopyRef = ref(false);
			const setOpen = (state) => isOpenRef.value = state;
			const setCopy = (state) => isCopyRef.value = state;

			return {
				isOpenRef,
				isCopyRef,
				setOpen,
				setCopy,
				alertController,
				chevronUpOutline,
				chevronDownOutline,
				pencilOutline,
				copyOutline,
				trashOutline,
				downloadOutline,
				cloudDownloadOutline,
				warning
			}
		},
	data() {
			this.getChildren();
			this.getCars();

			return{
				toastObject:{
					"message": null,
					"status": false
				},
				editStatus: false,
				showMessage: false,
				statusMessage: null,
				searchQuery: null,
				editData: null,
				copyData: null,
				currentPage: 'table',
				routes: null,
				filteredRoutes: null,
				cars: null,
				schools: null,
				children: null,
				sortClicks: 0,
				sortLastValue: null,
				isdesktop: this.$isdesktop,
				update: false,
        shown: false
      }
    }
});
</script>
<style>
  .routeTable{
		height: 90vh;
	}
	.tableHeader {
    display: flex; /* or inline-flex */
    flex-direction: row;
	}
	.topborder{
		border-top: solid 2px black;
	}
	.bottomborder{
		border-bottom: solid 2px black;
	}
	.iconbtn{
		font-size: 1.2em;
	}
	.bld{
		font-weight: bold;
	}
	.routetable{
		max-height:30vh;
		overflow: auto;
	}
	.bigmodal {
  align-items: center;
  justify-content: center;
  &::part(content){
    width: 95vw;
    height: 95vh;
  }
}
	.daystext{
		display:table-caption;
	}
	.deletealert{
		color: red !important;
	}

	@media only screen and (max-height: 799px) {
		.tabletfont{
			font-size:1em;
		}
	}
</style>
