<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<form novalidate>
					<ion-row>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.username')"
										id="driverUsername"
										v-model="driverForm.username"
										name="driverUsername"
										:label="$t('general.username')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.password')"
										id="driverPass"
										v-model="driverForm.password"
										name="driverPass"
										type="text"
										:label="$t('general.password')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.firstName')"
										id="driverFirstName"
										v-model="driverForm.firstname"
										name="driverFirstName"
										type="text"
										:label="$t('general.firstName')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.lastName')"
										id="driverLastname"
										v-model="driverForm.lastname"
										name="driverLastname"
										type="text"
										:label="$t('general.lastName')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
            <ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										id="driverEmail"
										:placeholder="$t('operator.driverEmail')"
										v-model="driverForm.email"
										name="driverEmail"
										type="text"
										:label="$t('operator.driverEmail')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										id="driverPhone"
										:placeholder="$t('operator.driverPhone')"
										v-model="driverForm.phone"
										name="driverPhone"
										type="text"
										:label="$t('operator.driverPhone')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="registerDriver(driverForm)">{{$t('operator.addDriver')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script lang="ts">
import {
  IonGrid,
  IonRow,
  IonItem,
	IonLabel,
	IonInput,
  IonCol,
  IonButton
} from "@ionic/vue";
import { defineComponent, toRaw } from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "AddDriver",
		emits: ['goback'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonInput,
			IonCol,
			IonButton
		},
    methods:{
			registerDriver(form: object){
				const driverdata = toRaw(form);

				//test call
				axios.post(this.$api_add + localStorage.getItem("org") +'users/create', driverdata).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('goback', {statusMessage: String(res.status + " " + this.$t('operator.driverResponses.addSuccess')), editStatus: true});
					}
					else{
						this.$emit('goback', {statusMessage: String(res.status + " " + this.$t('operator.driverResponses.addFailure') + res.statusText), editStatus: false});
					}
				}).catch((e)=>{
					this.$emit('goback', {statusMessage: String(this.$t('operator.driverResponses.addFailure') + e.message), editStatus: false});
				});
			},
    },
    data(){
      return{
				driverForm: {
					firstname: null,
					lastname: null,
					password: null,
          username: null,
          email: null,
          phone: null,
					role: 'driver'
				},
				isdesktop: this.$isdesktop
      }
    },
		setup(){
			return{

			}
		}
});
</script>
<style>
	.inpt{
		--background: #dedad9;
	}
</style>
