<template>
	<ion-row v-if="dashboardItems.length > 0">
		<ion-col size="12" class="brdr ion-margin-top" v-for="(item, index) in dashboardItems" :key="index">
			<query-renderer :cubeApi="cubejsApi" :query="getQueryById(item.id)">
				<template #default="{ resultSet }">
					<template v-if="resultSet">
						<ion-label>
							<h5 class="bold">{{item.name}}</h5>
						</ion-label>
						<ChartRenderer :resultSet="resultSet" :chartType="item.type"></ChartRenderer>
						<ion-button @click="showDeleteAlert(item)"> {{$t('operator.delete')}} </ion-button>
					</template>
				</template>
			</query-renderer>
		</ion-col>
	</ion-row>
	<ion-row v-else-if="dashboardItems.length == 0 || dashboardItems == undefined">
		<ion-col size="12" class="ion-text-center">
			<h2 class="muted">{{$t('operator.analytics.noCharts')}}</h2>
		</ion-col>
	</ion-row>
</template>

<script>
import cubejs from '@cubejs-client/core';
import {  DELETE_DASHBOARD_ITEM } from '@/graphql/mutations';
import { QueryRenderer } from '@cubejs-client/vue3';
import StatusToast from '@/components/StatusToast.vue';
import WebSocketTransport from '@cubejs-client/ws-transport';
import axios from 'axios';
import {

	IonCol,
	IonRow,
	IonButton,

	IonLabel,
	alertController
} from "@ionic/vue";

import ChartRenderer from '@/components/analytics/ChartRenderer.vue';

export default {
  name: 'DashboardGrid',
  props: ['dashboardItems'],
  components: {
    ChartRenderer,
    QueryRenderer,
		IonCol,
		IonButton,
		IonRow,
		IonLabel
  },
	setup() {
		return{
			alertController,
		}
	},
  data() {
		const API_URL = this.$cube_add;
		const CUBEJS_TOKEN = localStorage.getItem("token");

		//const cubejsApi = cubejs(CUBEJS_TOKEN, { apiUrl: API_URL });
		const cubejsApi = cubejs({
			transport: new WebSocketTransport({
				authorization: CUBEJS_TOKEN,
				apiUrl: API_URL.replace('http', 'ws')
			})
		});
    return {
			toastObject:{
				"message": "",
				"status": false
			},
			cubejsApi
    };
  },
  methods: {
    getQueryById(id) {
			if(this.dashboardItems){
				let item = this.dashboardItems.filter((item) => item.id === id);
				return JSON.parse(item[0].vizState).query;
			}
    },
    async deleteDashboardItem(id) {
			try{

				await this.$apollo.mutate({
					mutation: DELETE_DASHBOARD_ITEM,
					variables: {
						id: id.toString(),
					},
				});

				const currState = localStorage.getItem('dashboardItems');
				const currCount = localStorage.getItem('dashboardIdCounter');
				const u_id = localStorage.getItem('id');
				axios.post(this.$api_add + localStorage.getItem("org") +'users/saveDashboard', {"id": u_id, "dashboardState": JSON.stringify(currState), "dashboardCount": parseInt(currCount)}).then((res)=>{
					if(res.data.result == 'ok'){
						this.toastObject['message'] = String(this.$t('operator.analytics.chartDeleteSuccess'));
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = String(this.$t('operator.analytics.chartDeleteFailure'));
						this.toastObject['status'] = false;
					}

					this.showToast();
				})
			}
			catch(e){
				this.toastObject['message'] = String(this.$t('operator.analytics.chartDeleteFailure') + " " + e);
				this.toastObject['status'] = false;
			}
    },
		async showDeleteAlert(item){


			let message = `
				`+this.$t('operator.analytics.chartName')+`: `+item.name+`
			`;

			const alert = await alertController.create({
				header: this.$t('operator.analytics.confirmChartDelete'),
				message: message,
				buttons: [{
						text: this.$t('general.cancel'),
						role: 'cancel',
						cssClass: 'secondary',
						id: 'cancel-button'
					},
					{
						id: 'confirm-button',
						text: this.$t('operator.delete'),
						cssClass:'deletealert',
						handler: () => {
							this.deleteDashboardItem(item.id);
						},
					}],
			});
			await alert.present();

			await alert.onDidDismiss();
		},
		showToast(){
			StatusToast.methods.openToast(this.toastObject);
		},
  },
};
</script>

<style>
.height-100 {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  overflow-y: hidden;
}

.brdr{
	border: solid 1px #cccc !important;
}

.muted{
	color: #a2a3a2
}
</style>
