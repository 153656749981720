
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";
import { logOutOutline } from "ionicons/icons";
import { ScreenOrientation } from '@capacitor/screen-orientation';
import {isPlatform} from '@ionic/vue';
import axios from 'axios';
const __default__ = defineComponent({
    name:"LogoutComponent",
    components:{
        IonIcon
    },
    props:{
        size: String
    },
    setup(){
        return{ logOutOutline}
    },
    methods:{
        onLogout(){
            const userid = localStorage.getItem('id');
            const pushtoken = localStorage.getItem('pushtoken');
						axios.post(this.$api_add + localStorage.getItem("org") + 'users/removepushtoken', {"id": userid, "pushtoken": pushtoken}).then(async()=>{
							//console.log(u);
						}).catch(()=>{
							//console.log("e", e);
						}).finally(()=>{
                            if(!isPlatform('desktop')){
      const unlock = async () => {
    await ScreenOrientation.unlock();
  }
  unlock();
    }
              localStorage.removeItem("role");
              localStorage.removeItem("id");
              localStorage.removeItem("token");
							localStorage.removeItem("pushtoken");
              this.$router.replace({path:"/"})
            });
        }
    }
})



import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "10edc850": (_ctx.size)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__