<template>
    <ion-page >
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title>{{$t('general.changePassword')}}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-title size="large">{{$t('general.changePassword')}}</ion-title>

                </ion-toolbar>
            </ion-header>
            <locale-switcher @updateLocale="updateLocale" size="36px" />
            <div id="container">
                <form novalidate>
                    <ion-list>
                        <ion-item>
                            <ion-input id="password1"
                                        label-placement="stacked"
                                        :label="$t('general.newPassword')"
                                       v-model="regForm.password1"
                                       name="password1"
                                       type="password"
                                       :spellcheck="false"
                                       autocapitalize="off"
                                       required></ion-input>
                        </ion-item>
                        <ion-item>
                            <ion-input id="password2"
                            label-placement="stacked"
                                        :label="$t('general.newPasswordAgain')"
                                       v-model="regForm.password2"
                                       name="password2"
                                       type="password"
                                       :spellcheck="false"
                                       autocapitalize="off"
                                       required></ion-input>
                        </ion-item>

                    </ion-list>
                    <ion-row responsive-sm>
                        <ion-col>
                            <ion-button @click="onSend()" expand="block">{{$t('general.send')}}</ion-button>
                        </ion-col>
                    </ion-row>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>
<script lang="ts">
    import { defineComponent } from 'vue'
    import { IonPage, IonContent, IonToolbar, IonHeader, IonItem, IonList, IonInput, IonButton, IonCol, IonRow, IonTitle, toastController } from '@ionic/vue';
    import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
    import { useRoute} from 'vue-router';
    import axios from 'axios';
    export default defineComponent({
        name: 'ForgottenPage',
        components: {
            IonPage,
            IonContent,
            IonToolbar,
            IonHeader,
            IonItem,
            IonList,
            IonInput,
            IonCol,
            IonRow,
            IonButton,
            IonTitle,
            LocaleSwitcher,
        },
        setup() {
            const route = useRoute();
            return { route };
        },
        data() {
            return {
                regForm: {
                    password1: null,
                    password2: null,
                    userid: null,
                    validationid: null
                },

            };
        },
        mounted(){
            const tenancy = String(this.$route.params['tenantid']+"/");

            localStorage.setItem("org", tenancy);
        },
        methods: {
            async onSend() {
                if (this.regForm.password1 == this.regForm.password2) {
                    await this.validateRegister().then(r => {
                        if (r) {
                            axios.post(this.$api_add + localStorage.getItem("org") +"users/renewpassword", { "password": this.regForm.password1, "user": this.regForm.userid, "validationid": this.regForm.validationid }, {}).then(result => {
                                if (result.data.result === "ok") {
                                    this.openToast(this.$t("general.passwordChangeOk"), "success");
                                    this.$router.push("/");
                                }
                            })
                        } else {
                            this.openToast(this.$t("general.passwordChangeFail"), "danger");
                        }
                    });
                }
                else {
                    this.openToast(this.$t("general.passwordChangeFail"), "danger");
                }



            },
            updateLocale(locale: string) {
                this.$root.$i18n.locale = locale;
            },
            async validateRegister() {
                const newPromise = new Promise((resolve) => {
                    axios.post(this.$api_add + localStorage.getItem("org") +"registration/validatepasswordreg", { validationid: this.$route.params.validateid }, {})
                        .then(response => {
                            if (response.data.result == "ok") {
                                this.regForm.userid = response.data.data.role;
                                this.regForm.validationid = response.data.data.validationid;
                                resolve(true);
                            }
                            else {
                                this.openToast(this.$t("general.passwordChangeTimedOut"), "danger");
                            }


                        })
                })


                return newPromise;
            },
            async openToast(msg: string, color: string) {
                const toast = toastController
                    .create({
                        message: msg,
                        duration: 3000,
                        color: color
                    });
                (await toast).present();
                (await toast).onDidDismiss().then(() => {
                    //this.$router.push("/");
                });
            }
        },
    })
</script>
