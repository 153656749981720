<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-header>
					<ion-toolbar>
						<ion-title>{{currentPage == 'table' ? $t('operator.feedbacks') : $t('operator.addBulletin')}}</ion-title>
					</ion-toolbar>
				</ion-header>
				<ion-content class="ion-padding ion-margin-top routeTable" v-if="currentPage == 'table'">
          <ion-grid>
						<ion-row>
							<ion-col size="12">
								<ion-searchbar id="routeSearch" :placeholder="$t('operator.searchFeedbacks')" @input="searchFeedbacks($event.target.value)"></ion-searchbar>
							</ion-col>
						</ion-row>
						<ion-accordion-group multiple="true">
								<ion-accordion value="feedbacks">
									<ion-item slot="header" color="light">
										<ion-label>{{$t('operator.feedbacks')}}</ion-label>
									</ion-item>
									<div class="ion-padding" slot="content">
										<ion-row class="tableHeader">
											<ion-col size="2">
												<ion-item lines="none">
													<b>{{$t('operator.timestamp')}}</b>
												</ion-item>
											</ion-col>
											<ion-col size="2">
												<ion-item lines="none">
													<b>{{$t('operator.feedbackType')}}</b>
												</ion-item>
											</ion-col>
											<ion-col size="3">
												<ion-item lines="none">
													<b>{{$t('operator.feedbackTitle')}}</b>
												</ion-item>
											</ion-col>
											<ion-col size="3">
												<ion-item lines="none">
													<b>{{$t('operator.feedbackDescription')}}</b>
												</ion-item>
											</ion-col>
											<ion-col size="2">
											</ion-col>
									</ion-row>
									<ion-row v-for="(feedback,index) in filteredFeedbacks" :key="index">
										<ion-col size="12" class="topborder">
											<ion-row>
												<ion-col size="2">
													<ion-item lines="none" class="daystext">
														{{feedback.parsed_date}}
													</ion-item>
												</ion-col>
												<ion-col size="2">
													<ion-item lines="none">
														{{feedback.parsed_type}}
													</ion-item>
												</ion-col>
												<ion-col size="3">
													<ion-item lines="none">
														{{feedback.title}}
													</ion-item>
												</ion-col>
												<ion-col size="3">
													<ion-item lines="none">
														{{feedback.description}}
													</ion-item>
												</ion-col>
												<ion-col size="2" v-if="isdesktop">
													<ion-item lines="none">
														<ion-row>
															<ion-col size="12">
																<ion-button size="full" @click="showDeleteAlert(index)" color="danger">{{$t('operator.archiveFeedback')}}</ion-button>
															</ion-col>
														</ion-row>
													</ion-item>
												</ion-col>
												<ion-col size="2" v-else>
													<ion-item lines="none">
														<ion-row>
															<ion-col size="12">
																<ion-button size="large" @click="showDeleteAlert(index)" color="danger">
																	<ion-icon :icon="archiveOutline"></ion-icon>
																</ion-button>
															</ion-col>
														</ion-row>
													</ion-item>
												</ion-col>
											</ion-row>
										</ion-col>
									</ion-row>
								</div>
							</ion-accordion>
							<ion-accordion value="archive">
								<ion-item slot="header" color="light">
									<ion-label>{{$t('general.archive')}}</ion-label>
								</ion-item>
								<div class="ion-padding" slot="content">
									<ion-row class="tableHeader">
										<ion-col size="3">
											<ion-item lines="none">
												<b>{{$t('operator.timestamp')}}</b>
											</ion-item>
										</ion-col>
										<ion-col size="3">
											<ion-item lines="none">
												<b>{{$t('operator.feedbackType')}}</b>
											</ion-item>
										</ion-col>
										<ion-col size="3">
											<ion-item lines="none">
												<b>{{$t('operator.feedbackTitle')}}</b>
											</ion-item>
										</ion-col>
										<ion-col size="3">
											<ion-item lines="none">
												<b>{{$t('operator.feedbackDescription')}}</b>
											</ion-item>
										</ion-col>
										<ion-col size="2">
										</ion-col>
								</ion-row>
								<ion-row v-for="(feedback,index) in processedArchive" :key="index">
									<ion-col size="12" class="topborder">
										<ion-row>
											<ion-col size="3">
												<ion-item lines="none" class="daystext">
													{{feedback.parsed_date}}
												</ion-item>
											</ion-col>
											<ion-col size="3">
												<ion-item lines="none">
													{{feedback.parsed_type}}
												</ion-item>
											</ion-col>
											<ion-col size="3">
												<ion-item lines="none">
													{{feedback.title}}
												</ion-item>
											</ion-col>
											<ion-col size="3">
												<ion-item lines="none">
													{{feedback.description}}
												</ion-item>
											</ion-col>
										</ion-row>
									</ion-col>
								</ion-row>
							</div>
						</ion-accordion>
						</ion-accordion-group>
          </ion-grid>
				</ion-content>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonContent,
  IonGrid,
	//IonAccordion,
	//IonAccordionGroup,
  IonRow,
	//IonList,
	IonButton,
	IonLabel,
	//IonModal,
	IonIcon,
  IonItem,
  IonHeader,
  IonCol,
  IonTitle,
  IonToolbar,
	modalController,
	IonAccordion,
	IonAccordionGroup,
	IonSearchbar,
	alertController
} from "@ionic/vue";
import { defineComponent, ref, toRaw } from 'vue';
import {
	archiveOutline
} from 'ionicons/icons';
import StatusToast from '@/components/StatusToast.vue';
import axios from 'axios';
import moment from 'moment';
export default defineComponent({
		name: "OperatorFeedback",
		components:{
			IonContent,
			IonGrid,
			//IonAccordion,
			//IonAccordionGroup,
			IonRow,
			//IonList,
			IonLabel,
			//IonModal,
			IonIcon,
			IonButton,
			IonItem,
			IonHeader,
			IonAccordion,
			IonAccordionGroup,
			IonCol,
			IonTitle,
			IonToolbar,
			IonSearchbar,

		},
		methods:{
			changeView(view){
				this.currentPage = view;
			},
			getKeys(obj){
				return Object.keys(obj[0]);
			},
			secondsToMinutes(sec){
				return (sec/60).toFixed(0);
			},
			parseAddEmited(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					this.changeView('table');
				}
				this.getFeedback();
			},
			parseDate(bulletins){
				for (let i = 0; i < bulletins.length; i++) {
					bulletins[i]['parsed_date'] = moment.utc(bulletins[i].timestamp).format('DD.MM.YYYY HH:mm');
					//console.log(String(this.t$('operator.feedbackTypes.'+bulletins[i].type)));
					let str = String('operator.feedbackTypes.'+bulletins[i].type);
					console.log(str);
					bulletins[i]['parsed_type'] = String(this.$t(str));
				}
				return bulletins;
			},
			parseModalEmitted(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					modalController.dismiss();
				}
				this.getFeedback();
			},
			async getFeedback(){
				axios.post(this.$api_add + localStorage.getItem("org") +'feedback/getfeedback').then((res)=>{
					//console.log("res ", res);
					if (res.data.result == 'ok') {
						//const prsd = this.parseRoutes(res.data.data);
						let parsed = this.parseDate(res.data.data);
						this.feedbacks = parsed;
						//console.log(parsed);
						//if page is opened for the first time, assign routes to filteredFeedbacks
						if(this.filteredFeedbacks == null){
							this.filteredFeedbacks = this.feedbacks;
						}
						else{
							//if search has been made, search it again. Updates data
							this.searchFeedbacks(this.searchQuery);
						}
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.feedbackResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
					this.getArchive();
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.feedbackResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			getEditData(index){
				this.editData = toRaw(this.filteredFeedbacks[index]);
			},
			deleteBulletin(index){
				const data = toRaw(this.filteredFeedbacks[index]);
				const jwt = localStorage.getItem('token');
				const dt = {
					"id": data.id
				};

				axios.post(this.$api_add + localStorage.getItem("org") +'feedback/archive', dt, {headers:{"Authorization": String("Bearer " + jwt)}}).then((res)=>{
					if(res.data.result == 'ok'){
						this.filteredFeedbacks.splice(index,1);
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.feedbackResponses.deleteSuccess'));
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.feedbackResponses.deleteFailure') + res.statusText);
						this.toastObject['status']  = false
						//this.toastToast(true);
					}
					this.showToast();
				}).catch((e)=>{
					this.toastObject['message'] = String(this.$t('operator.feedbackResponses.deleteFailure') + e);
					this.toastObject['status'] = false;
					this.showToast();
				}).finally(()=>{
					this.getFeedback();
				});
			},
			searchFeedbacks(value){
				const query = toRaw(value).toLowerCase();
				if(!query){
					this.filteredFeedbacks = this.feedbacks;
				}
				else{
					this.filteredFeedbacks = this.feedbacks.filter((x)=>{
						//stringify points because otherwise it will be read as [Object object]
						const pts = JSON.stringify(x.points);

						//get other object values, attach them to points and make the whole thing case insensitive with toLowerCase
						const xvalues = String(Object.values(x).join(",")+pts).toLowerCase();
						return xvalues.includes(query);
					});
				}
				this.searchQuery = query;
			},
			showToast(){
				StatusToast.methods.openToast(this.toastObject);
			},

			async showDeleteAlert(index){
				const data = toRaw(this.filteredFeedbacks[index]);
				let message = `
					`+this.$t('operator.feedbackTitle')+`: `+data.title+`<br>
					`+this.$t('operator.feedbackDescription')+`: `+data.description+`<br>
				`;

				const alert = await alertController.create({
					header: this.$t('operator.confirmFeedbackDelete'),
					message: message,
					buttons: [{
							text: this.$t('general.cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							id: 'cancel-button'
						},
						{
							id: 'confirm-button',
							text: this.$t('operator.archiveFeedback'),
							cssClass:'deletealert',
							handler: () => {
								this.deleteBulletin(index);
							},
						}],
				});
				await alert.present();

				await alert.onDidDismiss();
			},
			sortBy(value){
				if(this.sortClicks == 0){
					this.filteredFeedbacks = this.filteredFeedbacks.sort((a,b)=>{
						return a[value] - b[value];
					});
					this.sortClicks++;
					this.sortLastValue = value;
				}
				else if(this.sortClicks == 1 && this.sortLastValue == value){
					this.filteredFeedbacks = this.filteredFeedbacks.sort((a,b)=>{
						return b[value] - a[value];
					});
					this.sortClicks = 0;
				}
				else if(this.sortLastValue != value){
					this.sortLastValue = value;
					this.sortClicks = 0;
				}
				else{
					this.sortClicks = 0;
					this.sortLastValue = value;
				}
			},
			async getArchive(){
				axios.post(this.$api_add + localStorage.getItem("org") +'feedback/getarchivedfeedback').then((res)=>{
					if(res.data.result == 'ok'){
						this.archive = toRaw(res.data.data);
						this.processedArchive = this.parseDate(this.archive);
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.exceptionResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.exceptionResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
		},
		setup(){

			const isOpenRef = ref(false);
			const isCopyRef = ref(false);
			const setOpen = (state) => isOpenRef.value = state;
			const setCopy = (state) => isCopyRef.value = state;

			return {
				isOpenRef,
				isCopyRef,
				setOpen,
				setCopy,
				alertController,
				archiveOutline
			}
		},
		mounted(){
			this.getFeedback();
		},
	data() {
			return{
				toastObject:{
					"message": null,
					"status": false
				},
				currentPage: 'table',
				editStatus: false,
				showMessage: false,
				statusMessage: null,
				feedbacks: null,
				filteredFeedbacks: null,
				archive: null,
				processedArchive: null,
				sortLastValue: null,
				isdesktop: this.$isdesktop
      }
    }
});
</script>
<style>
  .routeTable{
		height: 90vh;
	}
	.tableHeader {
    display: flex; /* or inline-flex */
    flex-direction: row;
	}
	.topborder{
		border-top: solid 1px #cfcdca;
	}
	.iconbtn{
		font-size: 1.2em;
	}
	.bld{
		font-weight: bold;
	}
	.routetable{
		max-height:30vh;
		overflow: auto;
	}
	.bigmodal {
  align-items: center;
  justify-content: center;
  &::part(content){
    width: 95vw;
    height: 95vh;
  }
}
	.daystext{
		display:table-caption;
	}
	.deletealert{
		color: red !important;
	}
</style>
