import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "container" }
const _hoisted_2 = { novalidate: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_locale_switcher = _resolveComponent("locale-switcher")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('register.register')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, { collapse: "condense" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, { size: "large" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.register')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_locale_switcher, {
            onUpdateLocale: _ctx.updateLocale,
            size: "36px"
          }, null, 8, ["onUpdateLocale"]),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("form", _hoisted_2, [
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    hidden: "true",
                    style: {"visibility":"hidden"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        hidden: true,
                        label: "Rooli",
                        "label-placement": "stacked",
                        id: "role",
                        "v-model": _ctx.regForm.role,
                        name: "role",
                        type: "text",
                        spellcheck: false,
                        autocapitalize: "off",
                        required: "",
                        disabled: ""
                      }, null, 8, ["v-model"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        id: "name",
                        label: _ctx.$t('general.firstName'),
                        "label-placement": "stacked",
                        modelValue: _ctx.regForm.firstname,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.regForm.firstname) = $event)),
                        name: "name",
                        type: "text",
                        spellcheck: false,
                        autocapitalize: "on",
                        autocomplete: "given-name",
                        required: ""
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        id: "name",
                        label: _ctx.$t('general.lastName'),
                        "label-placement": "stacked",
                        modelValue: _ctx.regForm.lastname,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.regForm.lastname) = $event)),
                        name: "name",
                        type: "text",
                        spellcheck: false,
                        autocapitalize: "on",
                        autocomplete: "family-name",
                        required: ""
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  (_ctx.assistantMode == 0)
                    ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            id: "address",
                            label: _ctx.$t('general.address'),
                            "label-placement": "stacked",
                            modelValue: _ctx.regForm.address,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.regForm.address) = $event)),
                            name: "address",
                            type: "text",
                            spellcheck: false,
                            autocapitalize: "on",
                            autocomplete: "street-address",
                            required: ""
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.assistantMode == 0)
                    ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            id: "address",
                            label: _ctx.$t('general.postalCode'),
                            "label-placement": "stacked",
                            modelValue: _ctx.regForm.zipcode,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.regForm.zipcode) = $event)),
                            name: "zipcode",
                            type: "text",
                            spellcheck: false,
                            autocapitalize: "on",
                            autocomplete: "postal-code",
                            required: ""
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.assistantMode == 0)
                    ? (_openBlock(), _createBlock(_component_ion_item, { key: 2 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            position: "stacked",
                            color: "primary"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('general.city')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_input, {
                            id: "address",
                            label: _ctx.$t('general.city'),
                            "label-placement": "stacked",
                            modelValue: _ctx.regForm.city,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.regForm.city) = $event)),
                            name: "city",
                            type: "text",
                            spellcheck: false,
                            autocapitalize: "on",
                            autocomplete: "address-level2",
                            required: ""
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.assistantMode == 1)
                    ? (_openBlock(), _createBlock(_component_ion_item, { key: 3 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select, {
                            label: _ctx.$t('general.address'),
                            "label-placement": "stacked",
                            modelValue: _ctx.regForm.addressdefault,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.regForm.addressdefault) = $event)),
                            required: ""
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schools, (school) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  key: school.address,
                                  value: school.address
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(school.address), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        id: "email",
                        label: _ctx.$t('general.email'),
                        "label-placement": "stacked",
                        modelValue: _ctx.regForm.email,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.regForm.email) = $event)),
                        name: "email",
                        type: "email",
                        spellcheck: false,
                        autocapitalize: "off",
                        autocomplete: "email",
                        required: ""
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        id: "phone",
                        label: _ctx.$t('general.phone'),
                        "label-placement": "stacked",
                        modelValue: _ctx.regForm.phone,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.regForm.phone) = $event)),
                        name: "phone",
                        type: "text",
                        spellcheck: false,
                        autocapitalize: "off",
                        autocomplete: "tel",
                        required: ""
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        id: "username",
                        label: _ctx.$t('general.username'),
                        "label-placement": "stacked",
                        modelValue: _ctx.regForm.username,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.regForm.username) = $event)),
                        name: "username",
                        type: "text",
                        spellcheck: false,
                        autocapitalize: "off",
                        autocomplete: "username",
                        required: ""
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        id: "password",
                        label: _ctx.$t('general.password'),
                        "label-placement": "stacked",
                        modelValue: _ctx.regForm.password,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.regForm.password) = $event)),
                        name: "password",
                        type: "password",
                        spellcheck: false,
                        autocapitalize: "off",
                        autocomplete: "new-password",
                        required: ""
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        onClick: _ctx.openDisclaimer,
                        style: {"cursor":"pointer"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.disclaimer')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        label: _ctx.$t('general.disclaimer_approval'),
                        modelValue: _ctx.regForm.consent,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.regForm.consent) = $event)),
                        color: "danger"
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { "responsive-sm": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onRegister(_ctx.regForm))),
                        expand: "block"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.newAccount')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}