import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "steps" }
const _hoisted_2 = ["onClick", "data-step-valid", "data-step-active"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "form-body" }
const _hoisted_5 = { class: "step-nav" }
const _hoisted_6 = { class: "ion-text-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_info_component = _resolveComponent("info-component")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_locale_switcher = _resolveComponent("locale-switcher")!
  const _component_logout_component = _resolveComponent("logout-component")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_MapComponent = _resolveComponent("MapComponent")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_modal, {
        "is-open": _ctx.modal,
        onDidDismiss: _cache[3] || (_cache[3] = ($event: any) => (_ctx.modal = false))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.addStudent')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    color: "danger",
                    slot: "end",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modal = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.close')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.chooseSchool')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.schoolChosen,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.schoolChosen) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schools, (school) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: school.id,
                          value: school.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(school.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.getInfo')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.chooseStudents')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.childrenChosen,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.childrenChosen) = $event)),
                    multiple: true
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.childrenBySchool, (child) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: child.id,
                          value: child.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(child.firstname + ' ' + child.lastname), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.addChild')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["is-open"]),
      _createVNode(_component_ion_modal, { trigger: "car-button" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_radio_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("driver.chooseCar")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cars, (car) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: car.id,
                      ref_for: true,
                      ref: `car-${car.id}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(car.number), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(car.type), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_radio, {
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.modalController.dismiss()))
                        })
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.modalController.dismiss())),
                    color: 'danger'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.close')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, { trigger: "absences" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.absences')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.modalController.dismiss()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.close')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.name')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.datefrom')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.dateto')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.absences, (absent) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: absent.timestamp
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(absent.firstname + ' ' + absent.lastname), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.moment(absent.datefrom).utc().format("DD.MM.yyy HH:mm")), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.moment(absent.dateto).utc().format("DD.MM.yyy HH:mm")), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, { trigger: "message-button" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, { style: {"height":"100%","overflow-y":"scroll"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    color: "secondary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.newMessage')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.modalController.dismiss())),
                    color: "danger"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.close')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_list_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.sender')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.message')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (mes) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: mes.id,
                  ref_for: true,
                  ref: `mes-${mes.id}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(mes.name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_label, { style: {"white-space":"normal"} }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(mes.message), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, { trigger: "map-button" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_radio_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("driver.chooseRoute")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (r) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: r.id,
                      ref_for: true,
                      ref: `route-${r.id}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(r.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('operator.routeTypes.' + r.type.toString())), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_radio, {
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.modalController.dismiss()))
                        })
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.modalController.dismiss())),
                    color: "danger"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.close')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, { trigger: "new-button" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Siirtolomake")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.modalController.dismiss()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.close')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_FormKit, {
                type: "form",
                plugins: [_ctx.stepPlugin],
                actions: false
              }, {
                default: _withCtx(({ state: { valid } }) => [
                  _createElementVNode("ul", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, stepName) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: _normalizeClass(['step']),
                        onClick: ($event: any) => (_ctx.activeStep = stepName),
                        "data-step-valid": step.valid && step.errorCount === 0,
                        "data-step-active": _ctx.activeStep === stepName,
                        key: stepName
                      }, [
                        _createElementVNode("span", {
                          class: "step--errors",
                          textContent: _toDisplayString(step.errorCount + step.blockingCount)
                        }, null, 8, _hoisted_3)
                      ], 8, _hoisted_2))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("section", {
                      class: _normalizeClass({ invisible: _ctx.activeStep !== 'formType'})
                    }, [
                      _createVNode(_component_FormKit, {
                        type: "group",
                        id: "formType",
                        name: "formType"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_FormKit, {
                            "v-model": _ctx.formType,
                            type: "select",
                            label: _ctx.$t('driver.formType'),
                            name: "form_type",
                            placeholder: _ctx.$t('driver.formTypePlace'),
                            validation: "required",
                            options: [
              {label: _ctx.$t('driver.formTypeNewRoute'), value: 'new-route'},
              {label: _ctx.$t('driver.formTypeTransfer'), value: 'transfer'}
            ]
                          }, null, 8, ["v-model", "label", "placeholder", "options"])
                        ]),
                        _: 1
                      })
                    ], 2),
                    _createElementVNode("section", {
                      class: _normalizeClass({ invisible: _ctx.activeStep !== 'children'})
                    }, [
                      _createVNode(_component_FormKit, {
                        id: "children",
                        type: "group",
                        name: "children"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_FormKit, {
                            modelValue: _ctx.transferForm.children,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.transferForm.children) = $event)),
                            type: "checkbox",
                            label: _ctx.$t('driver.formTypeTransferChildren'),
                            name: "children",
                            multiple: "",
                            placeholder: "Oppilaat",
                            help: _ctx.$t('driver.formTypeTransferChildrenHelp'),
                            validation: "required|min:1"
                          }, null, 8, ["modelValue", "label", "help"])
                        ]),
                        _: 1
                      })
                    ], 2),
                    _createElementVNode("section", {
                      class: _normalizeClass({ invisible: _ctx.activeStep !== 'route'})
                    }, [
                      _createVNode(_component_FormKit, {
                        id: "route",
                        type: "group",
                        name: "route"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_FormKit, {
                            modelValue: _ctx.transferForm.routeid,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.transferForm.routeid) = $event)),
                            type: "select",
                            label: _ctx.$t('driver.formTypeTransferRoute'),
                            help: _ctx.$t('driver.formTypeTransferRouteHelp'),
                            name: "route",
                            placeholder: 'Valitse Reitti',
                            validation: "required"
                          }, null, 8, ["modelValue", "label", "help"])
                        ]),
                        _: 1
                      })
                    ], 2),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_FormKit, {
                        type: "button",
                        disabled: _ctx.activeStep == 'formType',
                        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.setStep(-1))),
                        class: _normalizeClass('form-previous')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('driver.previous')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"]),
                      _createVNode(_component_FormKit, {
                        type: "button",
                        class: "next",
                        disabled: _ctx.activeStep == 'application' ,
                        onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setStep(1)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('driver.next')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ])
                  ]),
                  _createVNode(_component_FormKit, {
                    type: "button",
                    label: _ctx.$t('driver.formTypeSubmit'),
                    disabled: !valid,
                    class: "form-submit",
                    id: "subbutton"
                  }, null, 8, ["label", "disabled"])
                ]),
                _: 1
              }, 8, ["plugins"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_split_pane, {
        id: "sidepanel",
        "content-id": "menu-content",
        when: "xs"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu, {
            side: _ctx.side,
            "content-id": "menu-content",
            "menu-id": "menu-id"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toolbar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_info_component, {
                        side: "end",
                        style: {"padding-right":"16px"}
                      }),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.listCircleOutline,
                        id: "absences",
                        slot: "end",
                        size: "large",
                        style: {"padding-right":"16px"}
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.gitBranchOutline,
                        id: "new-button",
                        slot: "end",
                        size: "large",
                        style: {"padding-right":"16px"}
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.chatboxOutline,
                        id: "message-button",
                        slot: "end",
                        size: "large",
                        style: {"padding-right":"16px"}
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.swapHorizontalOutline,
                        slot: "end",
                        size: "large",
                        style: {"padding-right":"16px"}
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.carOutline,
                        id: "car-button",
                        slot: "end",
                        size: "large",
                        style: {"padding-right":"16px"}
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.mapOutline,
                        id: "map-button",
                        slot: "end",
                        size: "large",
                        style: {"padding-right":"16px"}
                      }, null, 8, ["icon"]),
                      _createVNode(_component_locale_switcher, {
                        size: "36px",
                        onClick: _ctx.$forceUpdate,
                        slot: "end"
                      }, null, 8, ["onClick"]),
                      _createVNode(_component_logout_component, {
                        size: "36px",
                        slot: "end",
                        style: {"padding-left":"16px"}
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toolbar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_title, {
                        class: "toolbar-title",
                        slot: "start"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.routeNumber.name), 1)
                        ]),
                        _: 1
                      }),
                      (_ctx.routeNumber.name.length > 0)
                        ? (_openBlock(), _createBlock(_component_ion_toggle, {
                            key: 0,
                            slot: "end",
                            color: "success",
                            disabled: _ctx.optimized.disabled,
                            modelValue: _ctx.optimized.value,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.optimized.value) = $event)),
                            checked: _ctx.optimized.value
                          }, null, 8, ["disabled", "modelValue", "checked"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_content, {
                id: "side-panel-content",
                ref: "sidepanel"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_accordion_group, {
                    value: "numbers",
                    ref: "accordionGroup"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.routeNumber.type == 1)
                        ? (_openBlock(), _createBlock(_component_ion_accordion, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, { slot: "header" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('driver.afternoonConfirmations')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_list, {
                                slot: "content",
                                inset: "true"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('driver.addStudent')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_button, { id: "open-modal" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('driver.addStudent')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routeStops, (stops) => {
                                    return (_openBlock(), _createBlock(_component_ion_item, {
                                      key: stops.id,
                                      ref_for: true,
                                      ref: `stopconfirm-${stops.id}`
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, {
                                          color: stops.color
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(stops.firstName), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["color"]),
                                        _createVNode(_component_ion_button, { color: "danger" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('driver.delete')), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_button, { style: {"visibility":"hidden"} }),
                                        _createVNode(_component_ion_button, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('driver.confirm')), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128)),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_button, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('driver.startRoute')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routeStops, (stops, index) => {
                        return (_openBlock(), _createBlock(_component_ion_accordion, {
                          key: stops.id,
                          ref_for: true,
                          ref: `stops-${stops.id}`,
                          value: stops.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, { slot: "header" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, {
                                  color: stops.color,
                                  class: "ion-text-wrap"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(index+1 + ". " + stops.targetAddress), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color"]),
                                _createVNode(_component_ion_label, {
                                  color: stops.color,
                                  class: "ion-text-wrap"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(stops.firstName), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color"]),
                                _createVNode(_component_ion_label, {
                                  color: stops.color
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(stops.targetTime), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_list, {
                              slot: "content",
                              inset: "true"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("driver.address")) + ": " + _toDisplayString(stops.targetAddress), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("driver.targetTime")) + ": " + _toDisplayString(stops.targetTime), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("driver.helpLevel")) + ": " + _toDisplayString(stops.targetAssistance), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_grid, null, {
                                  default: _withCtx(() => [
                                    (_ctx.routeNumber.type == 0)
                                      ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_button, {
                                                  color: "primary",
                                                  class: "confirmButton"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.personAddOutline,
                                                      class: "confirmIcon"
                                                    }, null, 8, ["icon"])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_button, {
                                                  color: "warning",
                                                  class: "confirmButton"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.homeOutline,
                                                      class: "confirmIcon"
                                                    }, null, 8, ["icon"])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_button, {
                                                  color: "danger",
                                                  class: "confirmButton"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.personRemoveOutline,
                                                      class: "confirmIcon"
                                                    }, null, 8, ["icon"])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true),
                                    (_ctx.routeNumber.type == 1)
                                      ? (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_button, {
                                                  color: "primary",
                                                  class: "confirmButton"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.personRemoveOutline,
                                                      class: "confirmIcon"
                                                    }, null, 8, ["icon"])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_col, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_button, {
                                                  color: "danger",
                                                  class: "confirmButton"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.personAddOutline,
                                                      class: "confirmIcon"
                                                    }, null, 8, ["icon"])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 512),
                  (_ctx.routeNumber.type == 0)
                    ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('driver.confirmAll')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.routeNumber.type == 1)
                    ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('driver.endDrive')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 512)
            ]),
            _: 1
          }, 8, ["side"]),
          _createVNode(_component_ion_content, {
            fullscreen: true,
            id: "menu-content"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MapComponent, {
                carid: _ctx.car.id,
                routeid: _ctx.routeNumber.id,
                routelinestring: _ctx.linestrings,
                stops: _ctx.stops,
                doneStops: _ctx.doneStops,
                ref: "mapRef"
              }, null, 8, ["carid", "routeid", "routelinestring", "stops", "doneStops"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}