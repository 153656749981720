<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-label>{{$t("title.title")}}</ion-label>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">

            <ion-list>

                <ion-item-divider>

                    <ion-button id="bKuittaus" expand="full" style="min-width: 100%; padding-left: 25%;padding-right: 25%; font-size: large;" >{{$t("parent.confirm")}}
                    <ion-icon :icon="checkmarkOutline" slot="end"></ion-icon>
                    </ion-button>

                </ion-item-divider>

               <ion-item-divider>

                    <ion-button id="bOmatTiedot" expand="full" style="min-width: 100%; padding-left: 25%;padding-right: 25%; font-size: large;" >{{$t("parent.accountInfo")}}
                    <ion-icon :icon="personOutline" slot="end"></ion-icon>
                    </ion-button>

               </ion-item-divider>

                <ion-item-divider>
                    <ion-item>
                        <locale-switcher size="48px" @click="$forceUpdate" />
                    </ion-item>
                </ion-item-divider>

            </ion-list>

        </ion-content>

        <ion-modal trigger="bKuittaus">
             <ion-header>
                <ion-toolbar>
                    <ion-title>{{$t("parent.transportToday")}}</ion-title>
                    <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-toolbar>
            </ion-header>
            <ion-accordion-group ref="accordionGroup">
                <ion-accordion>
                    <ion-item slot="header">
                        <ion-label>Date</ion-label>
                        <ion-label>Time</ion-label>
                        <ion-label>Name</ion-label>
                    </ion-item>
                    <ion-list slot="content" inset="true">
                        <div>
                            <ion-item>{{$t("parent.pickUpAddress")}}: </ion-item>
                            <ion-item>{{$t("parent.dropOffAddress")}}: </ion-item>
                        </div>
                        <div>
                            <ion-button>{{$t("parent.changeAddress")}}</ion-button>
                            <ion-button>{{$t("parent.cancelTransport")}}</ion-button>
                        </div>
                        <ion-item-sliding>
                            <ion-item-options side="start">
                            <ion-item-option >{{$t("parent.confirm")}}</ion-item-option>
                            </ion-item-options>
                            <ion-item color="warning">
                                {{$t("parent.dragToConfirm")}}
                                <ion-icon :icon="arrowForwardOutline"></ion-icon>
                            </ion-item>
                        </ion-item-sliding>
                    </ion-list>
                </ion-accordion>
            </ion-accordion-group>
        </ion-modal>
        <ion-modal trigger="bOmatTiedot">
            <ion-header>
                <ion-label>{{$t("parent.accountInfo")}}</ion-label>
            </ion-header>
            <ion-item>
                <ion-button @click="switchContent('MyAccount');">
                    {{$t('parent.accountInfo')}}
                </ion-button>
                <ion-button @click="switchContent('ChangePassword');">
                    {{$t('general.changePassword')}}
                </ion-button>
            </ion-item>
            <ion-content v-if="accountInfoContent.content == 'MyAccount'">
                <ion-label>{{$t('parent.accountInfo')}}</ion-label>
                <ion-item>
                <ion-label position="stacked">{{$t('general.username')}}</ion-label>
                <ion-input type="text" :value="accountInfo.username" readonly></ion-input>
                </ion-item>
                <ion-item>
                <ion-label position="stacked">{{$t('general.firstName')}}</ion-label>
                <ion-input type="text" :value="accountInfo.firstName" :readonly="edit.disabled"></ion-input>
                </ion-item>
                <ion-item>
                <ion-label position="stacked">{{$t('general.lastName')}}</ion-label>
                <ion-input type="text" :value="accountInfo.lastName" :readonly="edit.disabled"></ion-input>
                </ion-item>
                <ion-item>
                <ion-label position="stacked">{{$t('general.defaultAddress')}}</ion-label>
                <ion-input type="text" :value="accountInfo.addressDefault" :readonly="edit.disabled"></ion-input>
                </ion-item>
                <ion-item>
                <ion-label position="stacked">{{$t('general.email')}}</ion-label>
                <ion-input type="email" :value="accountInfo.email" :readonly="edit.disabled"></ion-input>
                </ion-item>
                <ion-item>
                <ion-label position="stacked">{{$t('general.phone')}}</ion-label>
                <ion-input type="number" :value="accountInfo.phone" :readonly="edit.disabled"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-button @click="enableEdit">{{$t('general.edit')}}</ion-button>
                </ion-item>
                <ion-item>
                    <ion-button @click="enableEdit">{{$t('general.updateChanges')}}</ion-button>
                    <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-item>

            </ion-content>

            <ion-content v-if="accountInfoContent.content == 'ChangePassword'">
                <ion-label>{{$t('general.changePassword')}}</ion-label>
                <ion-item>
                    <ion-label position="floating">{{$t('general.currentPassword')}}</ion-label>
                    <ion-input type="password"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{$t('general.newPassword')}}</ion-label>
                    <ion-input type="password"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{$t('general.newPasswordAgain')}}</ion-label>
                    <ion-input type="password"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-button>{{$t('general.changePassword')}}</ion-button>
                    <ion-button slot="end" color="danger" @click="modalController.dismiss()">{{$t("parent.close")}} <ion-icon :icon="close" /></ion-button>
                </ion-item>

            </ion-content>
        </ion-modal>
    </ion-page>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import {
    IonButton,
    IonModal,
    IonContent,
    IonLabel,
    IonHeader,
    IonItem,
    IonList,
    IonToolbar,
    IonPage,
    IonItemDivider,
    IonAccordion,
    IonAccordionGroup,
    IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonIcon,
  modalController,
  IonInput,
  IonTitle,
    } from '@ionic/vue'
import { arrowForwardOutline, personOutline, checkmarkOutline, calendarOutline, pencilOutline, newspaperOutline, close} from 'ionicons/icons';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import { useRouter } from "vue-router";
export default defineComponent({
    name:"ChildPage",
    components:{
    IonButton,
    IonModal,
    IonContent,
    IonLabel,
    IonHeader,
    IonItem,
    IonList,
    IonToolbar,
    IonPage,
    IonItemDivider,
    IonAccordion,
    IonAccordionGroup,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonIcon,
    IonInput,
    LocaleSwitcher,
    IonTitle,
    },
    setup() {
        const router = useRouter();
    if(localStorage.getItem("token") == "" && localStorage.getItem("role") !== "child"){
      router.push("/home");
    }


        const accordionGroup = ref();
        const logAccordionValue = () => {
        if (accordionGroup.value) {
          console.log(accordionGroup.value.$el.value);
        }
      }
      const closeAccordion = () => {
        if (accordionGroup.value) {
          accordionGroup.value.$el.value = undefined;
        }
      }
        return{
            accordionGroup,
            logAccordionValue,
            closeAccordion,
            arrowForwardOutline,
            modalController,
            personOutline,
            checkmarkOutline,
            calendarOutline,
            pencilOutline,
            newspaperOutline,
            close,
        }
    },
    data(){
        return{
            events:[
                {
                    firstName: "Mikko",
                    Timestamp: "3.6.2022 13:30",
                    confirmStatus: "Kotona",
                    nameConfirm: "Liisa"
                },
                {
                    firstName: "Mikko",
                    Timestamp: "3.6.2022 12:30",
                    confirmStatus: "Kyydissä",
                    nameConfirm: "Kuski"
                },
                {
                    firstName: "Mikko",
                    Timestamp: "3.6.2022 8:30",
                    confirmStatus: "Koulussa",
                    nameConfirm: "Avustaja"
                },
                {
                    firstName: "Mikko",
                    Timestamp: "3.6.2022 7:30",
                    confirmStatus: "Kyydissä",
                    nameConfirm: "Liisa"
                },



            ],
            children:[
                {
                    id: 1,
                    name: "Mikko"
                },
                {
                    id: 2,
                    name: "Matti"
                }
            ],
            addresses:[
                {
                    id: 1,
                    address: "Testikatu 123, 12345, Kemi"
                },
                {
                    id: 2,
                    address: "Testikatu 15, 12346, Kemi"
                },
            ],
            accountInfoContent:
            {
                content: "MyAccount"
            },
            accountInfo: {
                id: 1,
                username: "testi.testaaja",
                firstName: "Testi",
                lastName: "Testaaja",
                addressDefault: "Testikatu 123, 91500, Kemi",
                email: "testi.testaaja@testi.fi",
                phone: "0501234567"
            },
            childInfo: [
                {
                    parentId: 1,
                    childid: 1,
                    firstName: "Mikko",
                    lastName: "Testaaja",
                    school: "Hepolan Koulu"
                }
            ],
            edit: {
                disabled: true
            },
            subAccounts: [
                {
                    id: 1,
                    parentId: 1,
                    username: "mikko.testaaja",
                    firstName: "Mikko",
                    lastName: "Testaaja",
                    email: "mikko.testaaja@testi.fi",
                    phone: "0501234567",
                    role: "child"
                }
            ]



        };
    },
    methods:{
        compareWith(child1:object, child2:object){
            return child1 == child2;
        },
        storeSelect(event:any){
            const values = event.detail.value;
            console.log(values);
        },
        switchContent(content: string){
            this.accountInfoContent.content = content;
        },
        enableEdit(){
            if(this.edit.disabled === false){
                this.edit.disabled = true;
            } else{
                this.edit.disabled = false;
            }
        },
    },
    updated() {
        this.$root.$i18n.locale = localStorage.getItem("locale");
    },
    mounted(){
        this.$root.$i18n.locale = localStorage.getItem("locale");
    },
})
</script>
<style scoped>
#mainmenu{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
</style>
