<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-item>
					<h3>{{$t('operator.editBulletin')}}</h3>
				</ion-item>
				<form>
					<ion-row>
						<ion-col :size="isdesktop ? 6 : 12">
							<ion-row>
								<ion-col size="12">
								<ion-item>
									<ion-input
										:placeholder="$t('operator.bulletinTitle')"
										id="routeName"
										v-model="bulletinForm.title"
										:label="$t('operator.bulletinTitle')"
										labelPlacement="stacked"
										name="routeName"
										type="text"
										required
									></ion-input>
								</ion-item>
							</ion-col>
							<ion-col size="12">
								<ion-item>
									<ion-textarea
										:placeholder="$t('operator.bulletinDescription')"
										id="routeName"
										v-model="bulletinForm.description"
										name="routeName"
										:label="$t('operator.bulletinDescription')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-textarea>
								</ion-item>
							</ion-col>
							</ion-row>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="updateBulletin(bulletinForm)">{{$t('general.save')}}</ion-button>
							<ion-button color="danger" @click="modalController.dismiss()">{{$t('general.cancel')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonInput,
  IonCol,
  IonButton,
	IonTextarea
} from "@ionic/vue";
import { defineComponent, toRaw } from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "EditBulletin",
		props: ['bulletindata'],
		emits: ['responsedata'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonInput,
			IonCol,
			IonButton,
			IonTextarea
		},
    methods:{
			async updateBulletin(form){
				//Combine forms
				const rawObject = {...form};
				axios.post(this.$api_add + localStorage.getItem("org") + 'feedback/update', rawObject).then((res) => {
					if (res.data.result == 'ok' || res.data == 'ok') {
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.bulletinResponses.editSuccess')),
							editStatus: true
						});					}
					else{
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.bulletinResponses.editFailure') + res.statusText),
							editStatus: false
						});					}
				}).catch((e)=>{
					this.$emit('responsedata', {
						statusMessage: String(this.$t('operator.bulletinResponses.editFailure') + e.message),
						editStatus: false
					});
				});
			},

    },
    data(){
			const rtdata = toRaw(this.bulletindata);
      return{
				toastObject:{
					"message": null,
					"status": false
				},
				bulletinForm: {
					id: rtdata.id,
					title: rtdata.title,
					description: rtdata.description,
					jwt: localStorage.getItem('token'),
				},
				isdesktop: this.$isdesktop
      }
    },
		setup(){
			return{

			}
		}
});
</script>
<style>
	.inpt{
		--background: #dedad9;
	}
	.bld{
		font-weight: bold;
	}
	.tableHeader {
		display: flex; /* or inline-flex */
		flex-direction: row;
	}
	.btmborder{
		border-bottom: solid 1px #cfcdca;
	}
	.topborder{
		border-top: solid 1px #cfcdca;
	}
	.pointtable{
		max-height:30vh;
		overflow: auto;
	}
</style>
