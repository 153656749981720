<template>
  <query-builder :cubeApi="cubejsApi" :query="query" :disableHeuristics="true" :initialVizState="vizState">
    <template #builder="{
			availableMeasures,
			availableDimensions,
			availableTimeDimensions,
			setTimeDimensions,
			setMeasures,
			setDimensions,
			updateChartType,
			updateMeasures,
			validatedQuery,
			timeDimensions
		}">
			<ion-row>
				<ion-col size="4">
					<ion-label>{{$t("operator.analytics.chartName")}}</ion-label>
					<ion-item>
						<ion-input v-model="cName" :placeholder="$t('operator.analytics.chartName')"></ion-input>
					</ion-item>
				</ion-col>
				<ion-col size="4">
					<ion-label>{{$t("operator.analytics.chartType")}}</ion-label>
					<ion-item>
						<ion-select
							interface="popover"
							:placeholder="$t('operator.analytics.chartType')"
							:label="$t('operator.analytics.chartType')"
							labelPlacement="stacked"
							@ionChange="setChartType(updateChartType, $event.detail.value)"
						>
							<ion-select-option v-for="(type,index) in chartTypes" :key="index" :value="type">
								{{$t(String('operator.analytics.chartTypes.'+type))}}
							</ion-select-option>
						</ion-select>
					</ion-item>
				</ion-col>
				<ion-col size="4">
					<ion-label>{{$t('operator.analytics.measure')}}</ion-label>
					<ion-item>
						<ion-select
							interface="popover"
							:placeholder="$t('operator.analytics.measure')"
							:label="$t('operator.analytics.measure')"
							multiple
							labelPlacement="stacked"
							@ionChange="set(setMeasures, $event.detail.value, updateMeasures)"
						>
							<ion-select-option v-for="(msr,index) in availableMeasures" :key="index" :value="msr">
								{{msr.title}}
							</ion-select-option>
						</ion-select>
					</ion-item>
				</ion-col>
				<ion-col size="4">
					<ion-label>{{$t('operator.analytics.dimensions')}}</ion-label>
					<ion-item>
						<ion-select
							interface="popover"
							:placeholder="$t('operator.analytics.measure')"
							:label="$t('operator.analytics.measure')"
							multiple
							labelPlacement="stacked"
							@ionChange="setDim(setDimensions, $event.detail.value)"
						>
							<ion-select-option v-for="(msr,index) in availableDimensions" :key="index" :value="msr">
								{{msr.title}}
							</ion-select-option>
						</ion-select>
					</ion-item>
				</ion-col>
				<ion-col size="4">
					<ion-label>{{$t('operator.analytics.timeDimensions')}}</ion-label>
					<ion-item>
						<ion-select
								interface="popover"
								:placeholder="$t('operator.analytics.timeDimensions')"
								:label="$t('operator.analytics.timeDimensions')"
								multiple
								labelPlacement="stacked"
								@ionChange="setTime(setTimeDimensions, $event.detail.value, timeDimensions)"
						>
							<ion-select-option v-for="(msr,index) in availableTimeDimensions" :key="index" :value="msr">
								{{msr.title}}
							</ion-select-option>
						</ion-select>
					</ion-item>
				</ion-col>
				<ion-col size="4">
					<ion-label>{{$t('operator.analytics.dateRange')}}</ion-label>
					<ion-item>
						<ion-select
								interface="popover"
								:placeholder="$t('operator.analytics.dateRange')"
								labelPlacement="stacked"
								:label="$t('operator.analytics.dateRange')"
								@ionChange="setDaterange(setTimeDimensions, $event.detail.value, timeDimensions)"
						>
							<ion-select-option v-for="(msr,index) in dateRangeItems" :key="index" :value="msr.value">
								{{msr.name}}
							</ion-select-option>
						</ion-select>
					</ion-item>
				</ion-col>
				<ion-col size="4">
					<ion-label>{{$t('operator.analytics.granularity')}}</ion-label>
					<ion-item>
						<ion-select
								interface="popover"
								:placeholder="$t('operator.analytics.granularity')"
								labelPlacement="stacked"
								:label="$t('operator.analytics.granularity')"
								@ionChange="setGranularity(setTimeDimensions, $event.detail.value, timeDimensions)"
						>
							<ion-select-option v-for="(msr,index) in GRANULARITIES" :key="index" :value="msr.name">
								{{$t(String('operator.analytics.granularityOptions.'+msr.title))}}
							</ion-select-option>
						</ion-select>
					</ion-item>
				</ion-col>
				<ion-col size="4">
					<ion-button @click="createDashboardItem(validatedQuery)">{{$t('general.save')}}</ion-button>
				</ion-col>
			</ion-row>
    </template>

		<template #default="{ resultSet }">
      <ChartRenderer :result-set="resultSet" :chartType="cType"/>
    </template>

    <template #empty>
			<p hidden="true">Loading...</p>
		</template>
  </query-builder>
</template>

<script>
import cubejs from '@cubejs-client/core';
import gql from 'graphql-tag';
import { QueryBuilder, GRANULARITIES } from '@cubejs-client/vue3';
import axios from 'axios';
import {  ref, toRaw } from 'vue';
import ChartRenderer from '@/components/analytics/ChartRenderer.vue';
import moment from 'moment';
import WebSocketTransport from '@cubejs-client/ws-transport';
import StatusToast from '@/components/StatusToast.vue';

import {
	IonSelect,
	IonSelectOption,
	IonRow,
	IonCol,
	IonLabel,
	IonButton,
	IonItem,
	IonInput,
} from "@ionic/vue";
export default {
  name: 'AnalyticsBuilder',
  components: {
    QueryBuilder,
		IonSelect,
		IonSelectOption,
		ChartRenderer,
		IonRow,
		IonCol,
		IonButton,
		IonInput,
		IonLabel,
		IonItem
  },
  data() {
    let query = {
      measures: [],
      timeDimensions: [],
    };

		const API_URL = this.$cube_add;
		const CUBEJS_TOKEN = localStorage.getItem("token");

		//const cubejsApi = cubejs(CUBEJS_TOKEN, { apiUrl: API_URL });
		const cubejsApi = cubejs({
			transport: new WebSocketTransport({
				authorization: CUBEJS_TOKEN,
				apiUrl: API_URL.replace('http', 'ws')
			}) //ws://majakka.westeurope.cloudapp.azure.com/cubejs/
		});

    return {
			GRANULARITIES,
      cubejsApi,
      selected: undefined,
      query,
			chartTypes: ["line", "bar", "column", "stacked_column", "area",  "pie"],
			cType: 'line',
			cName: 'New Chart',
			vizState: {
				query,
				chartType: 'line',
			},
			dateRangeItems: [
				{"name": String(this.$t('operator.analytics.dateRangeItems.today')), "value": 'day'},
				{"name": String(this.$t('operator.analytics.dateRangeItems.thisWeek')), "value": 'week'},
				{"name": String(this.$t('operator.analytics.dateRangeItems.thisMonth')), "value": 'month'},
				{"name": String(this.$t('operator.analytics.dateRangeItems.thisQuarter')), "value": 'quarter'},
				{"name": String(this.$t('operator.analytics.dateRangeItems.thisYear')), "value": 'year'}
			],
			toastObject:{
				"message": null,
				"status": false
			},
    };
  },
  methods: {
    customLabel(a) {
      return a.title;
    },
		async createDashboardItem(proxyquery) {
			try{

				const name = this.cName;
				const chartType = this.cType;
				const query = toRaw(proxyquery);
				await this.$apollo.mutate({
					mutation: gql`
						mutation($input: DashboardItemInput) {
							createDashboardItem(input: $input) {
								id
								name
							}
						}
					`,
					variables: {
						input: {
							layout: '',
							vizState: JSON.stringify({
								query,
							}),
							name,
							type: chartType,
						},
					},
				});

				const currState = localStorage.getItem('dashboardItems');
				const currCount = localStorage.getItem('dashboardIdCounter');
				const id = localStorage.getItem('id');
				axios.post(this.$api_add + localStorage.getItem("org") +'users/saveDashboard', {"id": id, "dashboardState": JSON.stringify(currState), "dashboardCount": parseInt(currCount)}).then((res)=>{
					if(res.data.result == 'ok'){
						this.toastObject['message'] = String(this.$t('operator.analytics.chartAddSuccess'));
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = String(this.$t('operator.analytics.chartAddFailure'));
						this.toastObject['status'] = false;
					}
					this.showToast();
				});
			}
			catch(e){
				this.toastObject['message'] = String(this.$t('operator.analytics.chartAddFailure') + " " + e);
				this.toastObject['status'] = false;
				this.showToast();
			}
		},
    set(setMeasures, value) {
			const measure = value.map((e) => e.name)
      setMeasures(measure);
    },
		setDim(setDimensions, value){
			setDimensions(value.map((e)=>e.name));
		},
		setGranularity(setTimeDimensions, value, timeDimensions){
			setTimeDimensions([
				{
					dimension: timeDimensions[0]['dimension']['name'],
					granularity: value,
					dateRange: timeDimensions[0]['dateRange'],
				},
			])
		},
		setTime(setTimeDimensions, value){
			const ddms = value.map((e)=>e.name);
			const dims = [];
			ddms.forEach((d)=>{
				dims.push({
					dimension: d,
					granularity: 'day'
				});
			});
			setTimeDimensions(dims);
		},
		setDaterange(setTimeDimensions, value, timeDimensions){
			const end = moment().endOf(value).format('YYYY-MM-DD');
			const start = moment().startOf(value).format('YYYY-MM-DD');
			const rng = [start, end];

			setTimeDimensions([
				{
					dimension: timeDimensions[0]['dimension']['name'],
					granularity: timeDimensions[0]['granularity'],
					dateRange: rng,
				},
			])
		},
		logThis(text){
			console.log("logThis ", text);
			return text;
		},
		parseMeasures(measures){
			console.log("measures", toRaw(measures));
			return toRaw(measures);
		},
		setChartType(updateChartType, type){
			this.cType = type;
		},
		processTypes(types){
			types.map(()=>{
				return this.$t('')
			})
		},
		showToast(){
			StatusToast.methods.openToast(this.toastObject);
		},
  },
	setup(){
		const isOpenRef = ref(false);
		const setOpen = (state) => isOpenRef.value = state;

		return{
			isOpenRef,
			setOpen
		}
	}
};
</script>
<style>
	ion-popover::part(content) {
		--width: fit-content;
	}
</style>
