<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<form novalidate>
					<ion-row>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('operator.carId')"
										id="vehicleId"
										v-model="carForm.vehicleId"
										name="vId"
										type="text"
										:label="$t('operator.carId')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('operator.vehicleNumber')"
										id="vehicleNumber"
										v-model="carForm.vehicleNumber"
										name="vId"
										type="text"
										:label="$t('operator.vehicleNumber')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('operator.vehicleEmissions')"
										id="vehicleNumber"
										v-model="carForm.vehicleEmissions"
										name="vId"
										type="text"
										:label="$t('operator.vehicleEmissions')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('operator.registerNumber')"
										id="vehicleRegister"
										v-model="carForm.vehicleRegister"
										name="vId"
										type="text"
										:label="$t('operator.registerNumber')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('operator.carConsumption')"
										id="vehicleId"
										v-model="carForm.consumption"
										name="vCons"
										type="text"
										:label="$t('operator.carConsumption')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
            <ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										id="vehicleEqpmnt"
										:placeholder="$t('operator.carEquipment')"
										v-model="carForm.Eqpmnt"
										name="vEq"
										type="text"
										:label="$t('operator.carEquipment')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
								<ion-input
									id="typeSeats"
									v-model.number="carForm.capacity"
									name="vSeats"
									type="number"
									:label="$t('operator.carSeats')"
									labelPlacement="stacked"
									required
								></ion-input>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="registerCar(carForm)">{{$t('operator.addCar')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonLabel,
	IonInput,
  IonCol,
  IonButton
} from "@ionic/vue";
import { defineComponent, toRaw } from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "AddCar",
		emits: ['goback'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonInput,
			IonCol,
			IonButton
		},
    methods:{
			registerCar(form){
				const vehicledata = toRaw(form);
				const obj = {
					"name": vehicledata.vehicleId,
					"type": "taxi", // TODO: kovakoodaus pois
					"number": vehicledata.vehicleNumber,
					"fuelconsumption":vehicledata.consumption,
					"emissions":vehicledata.vehicleEmissions,
					"servicelevel": vehicledata.Eqpmnt,
					"registration":vehicledata.vehicleRegister,
					"capacity":vehicledata.capacity
				}
				//test call
				axios.post(this.$api_add + localStorage.getItem("org") +'vehicles/create', obj).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('goback', {statusMessage: String(res.status + " " + this.$t('operator.vehicleResponses.addSuccess')), editStatus: true});
					}
					else{
						this.$emit('goback', {statusMessage: String(res.status + " " + this.$t('operator.vehicleResponses.addFailure') + res.statusText), editStatus: false});
					}
				}).catch((e)=>{
					this.$emit('goback', {statusMessage: String(this.$t('operator.vehicleResponses.addFailure') + e.message), editStatus: false});
				});
			}
    },
    data(){
      return{
				showTypeForm: false,
				carForm: {
					newType: false,
          vehicleId: null,
          type: null,
					vehicleRegister: null,
					vehicleNumber: null,
					vehicleEmissions: null,
          Eqpmnt: null,
					typeId: null,
					capacity: 0,
					profile: null,
					consumption: null,
				},
				isdesktop: this.$isdesktop
      }
    },
		setup(){
			return{

			}
		}
});
</script>
<style>
	.inpt{
		--background: #dedad9;
	}
</style>
