import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_assistant_info_page = _resolveComponent("assistant-info-page")!
  const _component_driver_info_page = _resolveComponent("driver-info-page")!
  const _component_operator_info_page = _resolveComponent("operator-info-page")!
  const _component_info_page = _resolveComponent("info-page")!
  const _component_parent_info_page = _resolveComponent("parent-info-page")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_icon, {
      id: 'infoModal' + _ctx.modalid,
      size: "large",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal())),
      slot: _ctx.side,
      icon: _ctx.informationCircleOutline,
      style: {"padding-right":"16px"}
    }, null, 8, ["id", "slot", "icon"]),
    _createVNode(_component_ion_modal, {
      trigger: 'infoModal' + _ctx.modalid
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_segment, {
                  modelValue: _ctx.segment,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.segment) = $event)),
                  scrollable: true,
                  value: "info"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_segment_button, { value: "info" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.informationCircleOutline }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('info.infoTitle')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_ctx.checkParent() || _ctx.checkOperator())
                      ? (_openBlock(), _createBlock(_component_ion_segment_button, {
                          key: 0,
                          value: "parent"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.peopleOutline }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('info.parentTitle')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.checkDriver() || _ctx.checkOperator())
                      ? (_openBlock(), _createBlock(_component_ion_segment_button, {
                          key: 1,
                          value: "driver"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.busOutline }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('info.driverTitle')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.checkAssistant() || _ctx.checkOperator())
                      ? (_openBlock(), _createBlock(_component_ion_segment_button, {
                          key: 2,
                          value: "assistant"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.schoolOutline }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('info.assistantTitle')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.checkOperator())
                      ? (_openBlock(), _createBlock(_component_ion_segment_button, {
                          key: 3,
                          value: "operator"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.desktopOutline }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('info.operatorTitle')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_segment_button, {
                      value: "close",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalController.dismiss()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.closeOutline }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('info.close')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, null, {
          default: _withCtx(() => [
            (_ctx.segment == 'assistant' && (_ctx.checkAssistant() || _ctx.checkOperator()))
              ? (_openBlock(), _createBlock(_component_assistant_info_page, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.segment == 'driver' && (_ctx.checkDriver() || _ctx.checkOperator()))
              ? (_openBlock(), _createBlock(_component_driver_info_page, { key: 1 }))
              : _createCommentVNode("", true),
            (_ctx.segment == 'operator' && _ctx.checkOperator())
              ? (_openBlock(), _createBlock(_component_operator_info_page, { key: 2 }))
              : _createCommentVNode("", true),
            (_ctx.segment == 'info')
              ? (_openBlock(), _createBlock(_component_info_page, { key: 3 }))
              : _createCommentVNode("", true),
            (_ctx.segment == 'parent' && (_ctx.checkOperator() || _ctx.checkParent()))
              ? (_openBlock(), _createBlock(_component_parent_info_page, { key: 4 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["trigger"])
  ], 64))
}