<template>
	<ion-modal trigger="addCoordinates">
		<ion-header>
			<ion-toolbar>
				<ion-title>{{$t('operator.addCoordinate')}}</ion-title>
					<ion-button slot="end" @click="modalController.dismiss()" color="danger">
						<ion-icon :icon="closeOutline"></ion-icon>{{ $t('general.close') }}
					</ion-button>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<ion-item>
				<ion-label>{{$t('operator.coordinatesInfo')}}</ion-label>
			</ion-item>
			<ion-item>
				<ion-input name="coordAddress" labelPlacement="floating" :label="$t('operator.coordAddress')" v-model="coordsForm.address" type="text"></ion-input>
			</ion-item>
			<ion-item>
				<ion-input name="coords" labelPlacement="floating" :label="$t('operator.coords')" v-model="coordsForm.coords" type="text"></ion-input>
			</ion-item>
			<ion-item>
				<ion-button :disabled="coordinatesValidation()" @click="addCoordinates(coordsForm)">{{$t('operator.sendCoordinates')}}</ion-button>
			</ion-item>
		</ion-content>
	</ion-modal>
	<ion-grid>
		<ion-row>
			<ion-col>
				<form>
					<ion-row>
                        <ion-col size="6">
                            <ion-input type="file" @change="onUpload">{{$t('general.addFile')}}</ion-input>
                        </ion-col>
						<ion-col size="6">
                            <ion-button id="addCoordinates">{{ $t('operator.addCoordinate') }}</ion-button>
                        </ion-col>
                    </ion-row>
					<ion-row>
						<ion-col size="12">
							<h3>{{$t('operator.routeInfo')}}</h3>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-select :label="$t('operator.routeType')" labelPlacement="stacked" :placeholder="$t('operator.routeType')" v-model="routeForm.type" required :change="setType(routeForm.type)" :value="0">
										<ion-select-option value="0">{{$t('operator.routeTypes.0')}}</ion-select-option>
										<ion-select-option value="1">{{$t('operator.routeTypes.1')}}</ion-select-option>
									</ion-select>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('operator.routeName')"
										id="routeName"
										v-model="routeForm.routename"
										name="routeName"
										type="text"
										:label="$t('operator.routeName')"
										labelPlacement="stacked"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-select :label="$t('operator.routeDays')" labelPlacement="stacked" :placeholder="$t('operator.routeDays')" v-model="routeForm.days" required>
										<ion-select-option value="1">{{$t('general.days.1')}}</ion-select-option>
										<ion-select-option value="2">{{$t('general.days.2')}}</ion-select-option>
										<ion-select-option value="3">{{$t('general.days.3')}}</ion-select-option>
										<ion-select-option value="4">{{$t('general.days.4')}}</ion-select-option>
										<ion-select-option value="5">{{$t('general.days.5')}}</ion-select-option>
										<ion-select-option value="6">{{$t('general.days.6')}}</ion-select-option>
									</ion-select>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
								<ion-select :label="$t('operator.routeEndPoint')" labelPlacement="stacked" :placeholder="$t('general.school')" v-model="routeForm.endPointIdx">
									<ion-select-option :value="null" key="9999">{{$t('operator.noEndPoint')}}</ion-select-option>
									<ion-select-option v-for="(school, index) in schools" :value="index" :key="index">{{school.name}}</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
								<ion-select :label="$t('operator.assignedVehicle')" labelPlacement="stacked" :placeholder="$t('operator.assignedVehicle')" v-model="routeForm.assignedvehicle">
									<ion-select-option :value="null" key="9999">{{$t('general.noneBase')}}</ion-select-option>
									<ion-select-option v-for="(car, index) in cars" :value="car.id" :key="index">{{car.name}}</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>
						<ion-col size="12" class="ion-margin-top">
							<h5>{{$t('operator.routeStartInfo')}}</h5>
						</ion-col>
						<ion-col :size="isdesktop ? 8 : 12" :hidden="this.routetype">
							<ion-row>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item>
										<ion-input
											id="startAdd"
											:placeholder="$t('general.address')"
											v-model="routeForm.startadd"
											name="startAdd0"
											type="text"
											:label="$t('general.address')"
											labelPlacement="stacked"
											required
										></ion-input>
								</ion-item>
							</ion-col>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item>
										<ion-input
											id="startAdd"
											:placeholder="$t('general.postalCode')"
											v-model="routeForm.startpostal"
											name="startPostal"
											type="text"
											:label="$t('general.postalCode')"
											labelPlacement="stacked"
											required
										></ion-input>
								</ion-item>
							</ion-col>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item>
										<ion-input
											id="startAdd"
											:placeholder="$t('general.city')"
											v-model="routeForm.startcity"
											name="startCity"
											type="text"
											:label="$t('general.city')"
											labelPlacement="stacked"
											required
										></ion-input>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-col>
					<ion-col :size="isdesktop ? 4 : 12" :hidden="!this.routetype">
						<ion-item>
							<ion-select :label="$t('operator.startAddress')" labelPlacement="stacked" :placeholder="$t('general.school')" :change="setRouteSchool()" v-model="routeForm.selectedSchoolIdx">
								<ion-select-option v-for="(school, index) in schools" :value="index" :key="index">{{school.name}}</ion-select-option>
							</ion-select>
						</ion-item>
					</ion-col>
					<ion-col :size="isdesktop ? 4 : 12">
						<ion-item>
								<ion-input
									id="startTime"
									:placeholder="$t('operator.startTime')"
									v-model="routeForm.starttime"
									name="startTime"
									type="text"
									:label="$t('operator.startTime')"
									labelPlacement="stacked"
									required
								></ion-input>
						</ion-item>
					</ion-col>
						<ion-col size="12">
							<h3>{{$t('operator.routePoints')}}</h3>
						</ion-col>
						<ion-col size="12" class="ion-margin-top">
							<ion-label class="bld">{{$t('operator.pickUpAddressInfo')}}</ion-label>
						</ion-col>
						<ion-col size="12" :hidden="this.routetype">
							<ion-row>
								<ion-col :size="isdesktop ? 4 : 12">
									<ion-item>
										<ion-select :label="$t('operator.pickupAddress')" labelPlacement="stacked" interface="popover" :placeholder="$t('operator.pickupAddress')" :change="setPointAddress('pickup')" v-model="pointForm.selectedAddressIdx">
											<ion-select-option v-for="(address, index) in filteredAddresses" :value="index" :key="index">{{address.address}}</ion-select-option>
										</ion-select>
									</ion-item>
									<ion-note class="ion-padding" color="secondary">{{$t('operator.selectAddressOrType')}}</ion-note>
								</ion-col>
								<ion-col :size="isdesktop ? 4 : 12">
									<ion-item>
											<ion-input
												id="pointPickAdd"
												:placeholder="$t('general.address')"
												v-model="pointForm.pickupadd"
												name="pointpickadd"
												type="text"
												:label="$t('general.address')"
												labelPlacement="stacked"
												required
												></ion-input>
										</ion-item>
								</ion-col>
								<ion-col :size="isdesktop ? 4 : 12">
									<ion-item>
											<ion-input
												id="pointPickAdd"
												:placeholder="$t('general.postalCode')"
												v-model="pointForm.pickuppostal"
												name="pointpickadd"
												type="text"
												:label="$t('general.postalCode')"
												labelPlacement="stacked"
												required
												></ion-input>
										</ion-item>
								</ion-col>
								<ion-col :size="isdesktop ? 4 : 12">
									<ion-item>
											<ion-input
												id="pointPickAdd"
												:placeholder="$t('general.city')"
												v-model="pointForm.pickupcity"
												name="pointpickadd"
												type="text"
												:label="$t('general.city')"
												labelPlacement="stacked"
												required
												></ion-input>
										</ion-item>
								</ion-col>
							</ion-row>
						</ion-col>
						<ion-col size="12" :hidden="!this.routetype">
								<ion-row>
									<ion-col :size="isdesktop ? 4 : 12" :hidden="!this.routetype">
										<ion-item>
											<ion-select :label="$t('operator.pickupAddress')" labelPlacement="stacked" :placeholder="$t('operator.pickupAddress')" :change="setSchool()" v-model="pointForm.selectedSchoolIdx">
												<ion-select-option v-for="(school, index) in schools" :value="index" :key="index">{{school.name}}</ion-select-option>
											</ion-select>
										</ion-item>
									</ion-col>
									<ion-col :size="isdesktop ? 4 : 12" :hidden="this.routetype">
										<ion-item class="ionic-no-padding">
											<ion-input
												id="pointDelAdd"
												:placeholder="$t('general.address')"
												v-model="pointForm.deliveryadd"
												name="pointdeladd"
												type="text"
												:label="$t('general.address')"
												labelPlacement="stacked"
												required
											></ion-input>
										</ion-item>
									</ion-col>
									<ion-col :size="isdesktop ? 4 : 12" :hidden="this.routetype">
										<ion-item>
											<ion-input
												id="pointDelAdd"
												:placeholder="$t('general.postalCode')"
												v-model="pointForm.deliverypostal"
												name="pointdeladd"
												type="text"
												:label="$t('general.postalCode')"
												labelPlacement="stacked"
												required
											></ion-input>
										</ion-item>
									</ion-col>
									<ion-col :size="isdesktop ? 4 : 12" :hidden="this.routetype">
										<ion-item>
											<ion-input
												id="pointDelAdd"
												:placeholder="$t('general.city')"
												v-model="pointForm.deliverycity"
												name="pointdeladd"
												type="text"
												:label="$t('general.city')"
												labelPlacement="stacked"
												required
											></ion-input>
									</ion-item>
								</ion-col>
							</ion-row>
						</ion-col>
						<ion-col size="12" class="ion-margin-top">
							<ion-label class="bld">{{$t('operator.deliveryAddressInfo')}}</ion-label>
						</ion-col>
						<ion-col :hidden="this.routetype" :size="isdesktop ? 4 : 12">
							<ion-item>
								<ion-select :label="$t('operator.deliveryAddress')" labelPlacement="stacked" :placeholder="$t('general.school')" :change="setSchool()" v-model="pointForm.selectedSchoolIdx">
									<ion-select-option v-for="(school, index) in schools" :value="index" :key="index">{{school.name}}</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>
						<ion-col size="12" :hidden="!this.routetype">
							<ion-row>
						<ion-col :size="isdesktop ? 4 : 12" :hidden="!this.routetype">
							<ion-item>
								<ion-select :label="$t('operator.deliveryAddress')" labelPlacement="stacked" interface="popover" :placeholder="$t('operator.deliveryAddress')" :change="setPointAddress('delivery')" v-model="pointForm.selectedAddressIdx">
										<ion-select-option v-for="(address, index) in filteredAddresses" :value="index" :key="index">{{address.address}}</ion-select-option>
									</ion-select>
								</ion-item>
								<ion-note class="ion-padding" color="secondary">{{$t('operator.selectAddressOrType')}}</ion-note>
							</ion-col>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item class="ionic-no-padding">
									<ion-input
										id="pointDelAdd"
										:placeholder="$t('general.address')"
										v-model="pointForm.deliveryadd"
										name="pointdeladd"
										type="text"
										:label="$t('general.address')"
										labelPlacement="stacked"
										required
									></ion-input>
								</ion-item>
							</ion-col>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item>
									<ion-input
										id="pointDelAdd"
										:placeholder="$t('general.postalCode')"
										v-model="pointForm.deliverypostal"
										name="pointdeladd"
										type="text"
										:label="$t('general.postalCode')"
										labelPlacement="stacked"
										required
									></ion-input>
								</ion-item>
							</ion-col>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item>
									<ion-input
										id="pointDelAdd"
										:placeholder="$t('general.city')"
										v-model="pointForm.deliverycity"
										name="pointdeladd"
										type="text"
										:label="$t('general.city')"
										labelPlacement="stacked"
										required
									></ion-input>
								</ion-item>
							</ion-col>
						</ion-row>
						</ion-col>
						<ion-col size="12" class="ion-margin-top">
							<ion-label class="bld">{{$t('operator.childInfo')}}</ion-label>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-row>
								<ion-col>
									<ion-item>
										<ion-select :label="String($t('general.childName'))" labelPlacement="stacked" :placeholder="$t('general.childName')" v-model="pointForm.childIdx">
											<ion-select-option v-for="(child, index) in children" :value="index" :key="index">{{String(child.firstname +" "+ child.lastname)}}</ion-select-option>
										</ion-select>
									</ion-item>
							</ion-col>
							<ion-col :hidden="!this.pointForm.showNameField">
								<ion-item>
									<ion-input
											id="delChild"
												class="ion-margin-top"
												:placeholder="$t('general.childNameAlt')"
												v-model="pointForm.child"
												name="delChild"
												type="text"
												:label="String($t('general.childNameAlt'))"
												labelPlacement="stacked"
												required
											></ion-input>
										</ion-item>
									</ion-col>
								</ion-row>
							</ion-col>
							<ion-col :hidden="this.routetype" :size="isdesktop ? 4 : 12">
								<ion-item>
									<ion-input
										id="startStoptime"
										:placeholder="$t('operator.startStopTime')"
										v-model="pointForm.timespent"
										name="startStoptime"
										value="2"
										type="number"
										:label="$t('operator.startStopTime')"
										labelPlacement="stacked"
										required
									></ion-input>
								</ion-item>
							</ion-col>
							<ion-col :size="isdesktop ? 4 : 12">
								<ion-item>
									<ion-input
										id="delTimewindow"
										:placeholder="this.routetype ? $t('operator.deliveryTime') : $t('operator.pickupTime')"
										v-model="pointForm.timewindow"
										name="delTimewindow"
										type="text"
										:label="this.routetype ? $t('operator.deliveryTime') : $t('operator.pickupTime')"
										labelPlacement="stacked"
										required
									></ion-input>
								</ion-item>
							</ion-col>
						<ion-col>
							<ion-item lines="none">
								<ion-button :size="isdesktop ? '' : 'full'" @click="addPoint(pointForm)">{{$t('operator.addPoint')}}</ion-button>
							</ion-item>
						</ion-col>
						<ion-col size="12" :hidden="!this.pointForm.showNameField">
								<ion-note>{{String("*"+$t('operator.childNotFound'))}}</ion-note>
						</ion-col>
						<ion-col size="12" class="btmborder topborder ion-margin-top">
							<ion-row>
								<ion-col size="12">
									<ion-item lines="none">
										<ion-toggle :enable-on-off-labels="true" labelPlacement="end" color="success" slot="start" v-model="this.reorder" :checked="!this.reorder">{{this.reorder ? $t('operator.reorderEnabled') : $t('operator.reorderDisabled')}}</ion-toggle>
									</ion-item>
								</ion-col>
								<ion-col size="12">
									<ion-row>
										<ion-col class="bld tabletfont" :size="isdesktop ? '' : 4">
											{{$t('operator.orderNumber')}}
										</ion-col>
										<ion-col class="bld tabletfont" :size="isdesktop ? '' : 4">
											{{$t('operator.pickupAddress')}}
										</ion-col>
										<ion-col class="bld tabletfont" :size="isdesktop ? '' : 4">
											{{$t('operator.deliveryAddress')}}
										</ion-col>
										<ion-col class="bld tabletfont" :size="isdesktop ? '' : 4">
											{{$t('general.childName')}}
										</ion-col>
										<ion-col class="bld tabletfont" :hidden="this.routetype" :size="isdesktop ? '' : 4">
											{{$t('operator.startStopTime')}}
										</ion-col>
										<ion-col class="bld tabletfont" :size="isdesktop ? '' : 4">
											{{this.routetype ? $t('operator.deliveryTime') : $t('operator.pickupTime')}}
										</ion-col>
										<ion-col class="bld tabletfont" :size="isdesktop ? '' : 4">
										</ion-col>
									</ion-row>
								</ion-col>
								<ion-col size="12">
									<ion-row class="pointtable" :key="forceUpdate">
										<ion-reorder-group :disabled="!this.reorder" @ionItemReorder="handleReorder($event)" class="fullwidth">
											<ion-reorder class="fullwidth tabletnopadding" v-for="(points, index) in routePoints" :key="index">
												<ion-col size="12">
												<ion-row>
													<ion-col :size="isdesktop ? '' : 4">
														<ion-item class="smalltext">
															<ion-icon v-if="isdesktop" slot="start" :icon="menuOutline" />
															<ion-select :label="isdesktop ? '' : $t('operator.orderNumber')" :labelPlacement="isdesktop ? '' : 'stacked'" :placeholder="index+1" @ionChange="changePointOrder(index, $event.detail.value)">
																<ion-select-option v-for="pidx in Array.from({length:routePoints.length}, (v,i)=>i)" :value="pidx" :key="pidx" :selected="pidx == index ? true : false">{{pidx+1}}</ion-select-option>
															</ion-select>
														</ion-item>
													</ion-col>
													<ion-col :size="isdesktop ? '' : 4">
														<ion-item class="smalltext">
															<ion-input :label="isdesktop ? '' : $t('operator.pickupAddress')" :labelPlacement="isdesktop ? '' : 'stacked'" :value="points.pickup" @ionChange="editPointAddress(index, 'pickup')" v-model="points.pickup"></ion-input>
														</ion-item>
													</ion-col>
													<ion-col :size="isdesktop ? '' : 4">
														<ion-item class="smalltext">
															<ion-input :label="isdesktop ? '' : $t('operator.deliveryAddress')" :labelPlacement="isdesktop ? '' : 'stacked'" :value="points.delivery" @ionChange="editPointAddress(index, 'delivery')" v-model="points.delivery"></ion-input>
														</ion-item>
													</ion-col>
													<ion-col :size="isdesktop ? '' : 4">
														<ion-item class="smalltext">
															<ion-select v-if="allChildren.length > 0" :label="isdesktop ? '' : $t('general.childName')" :labelPlacement="isdesktop ? '' : 'stacked'" :placeholder="$t('general.childName')" :value="points.child" :change="changeChild(points.child, index)" v-model="points.child">
																<ion-select-option v-for="child in allChildren" :value="child.id" :key="child.id" :selected="points.child == child.id">{{String(child.firstname +" "+ child.lastname)}}</ion-select-option>
															</ion-select>
														</ion-item>
													</ion-col>
													<ion-col :hidden="this.routetype" :size="isdesktop ? '' : 4">
														<ion-item class="smalltext">
															<ion-input :label="isdesktop ? '' : $t('operator.startStopTime')" :labelPlacement="isdesktop ? '' : 'stacked'" :value="points.timespent" v-model="points.timespent"></ion-input>
														</ion-item>
													</ion-col>
													<ion-col :size="isdesktop ? '' : 4">
														<ion-item class="smalltext">
															<ion-input :label="isdesktop ? '' : (this.routetype ? $t('operator.deliveryTime') : $t('operator.pickupTime'))" :labelPlacement="isdesktop ? '' : 'stacked'" :value="points.timewindow" v-model="points.timewindow"></ion-input>
														</ion-item>
													</ion-col>
													<ion-col :size="isdesktop ? '' : 4">
														<ion-button color="danger" :size="isdesktop ? 'small' : 'full'" @click="deletePoint(index)">
															{{$t('operator.delete')}}
														</ion-button>
													</ion-col>
												</ion-row>
										</ion-col>
									</ion-reorder>
									</ion-reorder-group>
									</ion-row>
								</ion-col>

							</ion-row>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="registerRoute(routeForm)">{{$t('operator.addRoute')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonNote,
	IonLabel,
	IonInput,
	IonSelect,
	IonSelectOption,
  IonCol,
  IonButton,
  IonReorder,
  IonReorderGroup,
	IonToggle,
	IonIcon,
	modalController,
	IonModal,
	IonContent,
	IonHeader,
	IonToolbar,
	IonTitle
} from "@ionic/vue";
import {
	menuOutline, closeOutline
} from 'ionicons/icons';
import { defineComponent, toRaw } from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "AddRoute",
		props: ['somedata'],
		emits: ['goback'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonLabel,
			IonNote,
			IonInput,
			IonSelect,
			IonSelectOption,
			IonCol,
			IonButton,
			IonReorder,
			IonReorderGroup,
			IonToggle,
			IonIcon,
	IonModal,
	IonContent,
	IonHeader,
	IonToolbar,
	IonTitle
		},

    methods:{
			async registerRoute(form){
				//Combine forms
				//console.log(this.routeForm.endPointIdx);
				const rawObject = {...form};
				console.log(rawObject);
				//console.log(rawObject);
				let points = toRaw(this.routePoints); //parse proxy object
				const childs = [];
				const schoolids = [];
				const rawdays = toRaw(this.routeForm.days);
				if(rawObject['startaddress'] == null && !this.routetype){
					rawObject['startaddress'] = String(rawObject['startadd'].trim() + ", " + rawObject['startpostal'].trim() + " " + rawObject['startcity'].trim())
				}
				else if(rawObject['startaddress'] == null && this.routetype && this.routeForm.selectedSchoolIdx != null){
					const schooldata = this.schools[this.routeForm.selectedSchoolIdx];
					rawObject['startaddress'] = schooldata.address;
				}

				for (let i = 0; i < points.length; i++) {
					if(points[i]['timespent'] != null  && rawObject['type'] == 0){
						points[i]['duration'] = parseInt(points[i]['timespent'])*60;
						//points[i]['timewindow'] = "12:00";
					}
					else if(rawObject['type'] == 1){
						points[i]['duration'] = 0;
						//points[i]['timewindow'] = "17:00";
						//points[i]['pickup'] = rawObject['startaddress'];
						if(points[i]['pickup'] == null || points[i]['pickup'] == undefined){
							points[i]['pickup'] = rawObject['startaddress'];
						}
					}
					childs.push(points[i].child);
					schoolids.push({"child": points[i].child, "schoolid": points[i].schoolid})
				}

				if(this.routeForm.endPointIdx != null && this.routeForm.endPointIdx != undefined && this.routeForm.endPointIdx != -1){
					rawObject['endaddress'] = this.schools[this.routeForm.endPointIdx].address;
				}
				rawObject['points'] = points;
				rawObject['days'] = rawdays;
				rawObject['children'] = childs;
				rawObject['schools'] = schoolids;
				rawObject['jwt'] = localStorage.getItem('token');
				rawObject['schoolid'] = this.currentSchool;

			axios.post(this.$opt_add + localStorage.getItem("org") + 'createRoute', rawObject).then((res) => {
					if (res.data.result == 'ok' || res.data == 'ok') {

						if (res.data.errors == 0) {
                            this.$emit('goback', { statusMessage: String(res.status + " " + this.$t('operator.routeResponses.addSuccess')), editStatus: true });
						}
						else {
							this.$emit('goback', { statusMessage: String(res.status + " " + this.$t('operator.routeResponses.addSuccessWithErrors')).replace("$1",res.data.errors), editStatus: true });
                        }

					}
					else{
						this.$emit('goback', {statusMessage: String(res.status + " " + this.$t('operator.routeResponses.addFailure') + res.statusText), editStatus: false});
					}
				}).catch((e)=>{
					this.$emit('goback', {statusMessage: String(this.$t('operator.routeResponses.addFailure') + e.message), editStatus: false});
				});
			},
			getChildName(id, index){
				let found = this.allChildren.find((yu)=>{
					return id == yu.id;
				});
				let name = String(found.firstname + " " + found.lastname);
				this.routePoints[index].childname = name;
				return name;
			},
			changeChild(id, index){
				let data = toRaw(this.routePoints[index]);
				let found = this.allChildren.find((yu)=>{
					return id == yu.id;
				});

				let name = String(found.firstname + " " + found.lastname);
				data['childname'] = name;
			},
			addPoint(form){
				const rawObject = {...form};
				const schooldata = toRaw(this.schools[this.pointForm.selectedSchoolIdx]);
				if(!this.pointForm.showNameField){
					const child = toRaw(this.children[this.pointForm.childIdx]);
					rawObject['child'] = child.id;
					rawObject['childname'] = String(child.firstname + " " + child.lastname);
				}
				else{
					rawObject['childname'] = rawObject['child'];
				}
				if(!this.routetype){
					rawObject['pickup'] = String(rawObject['pickupadd'].trim() + ", " + rawObject['pickuppostal'].trim() + " " + rawObject['pickupcity'].trim())
					rawObject['delivery'] = String(schooldata.address);
					rawObject['schoolid'] = this.schools[this.pointForm.selectedSchoolIdx].id;
				}
				else if(this.routetype){
					rawObject['pickup'] = String(schooldata.address);
					rawObject['delivery'] = String(rawObject['deliveryadd'].trim() + ", " + rawObject['deliverypostal'].trim() + " " + rawObject['deliverycity'].trim())
					rawObject['schoolid'] = this.schools[this.routeForm.selectedSchoolIdx].id;
				}

				this.routePoints.push(rawObject);
				this.resetPointForm();
			},
			deletePoint(index){
				this.routePoints.splice(index, 1);
			},
			changePointOrder(old_index, new_index, event) {
				if(new_index != null && old_index != new_index){

					const arr = this.routePoints;
					if (new_index >= arr.length) {
						var k = new_index - arr.length + 1;
						while (k--) {
							arr.push(undefined);
						}
					}
					arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
					this.routePoints = arr;
					let nextstate = !this.forceUpdate;
					this.forceUpdate = nextstate;
				}
				//return arr; // for testing
			},

			setSchool(){
				const idx = this.pointForm.selectedSchoolIdx;

				if(idx != null){
					const schoolid = toRaw(this.schools[idx]).id;
					this.currentSchool = schoolid;
					this.getChildren(schoolid);
				}
			},
			setPointAddress(type){
				try{
					const address0 = this.addresses[this.pointForm.selectedAddressIdx].address;
					if(address0 != null && address0 != undefined && address0.length > 1){
						const first = address0.split(",");
						const second = first[1].split(" ").filter((ui)=>{
							return ui != ''
						});
						if(type == 'pickup'){
							this.pointForm.pickupadd = first[0];
							this.pointForm.pickupcity = second[1];
							this.pointForm.pickuppostal = second[0];
						}
						else if(type == 'delivery'){
							this.pointForm.deliveryadd = first[0];
							this.pointForm.deliverycity = second[1];
							this.pointForm.deliverypostal = second[0];
						}
					}
				}
				catch(e){
					//do nothing
				}
			},
			editPointAddress(index,type){
				try{
					let address = type == 'pickup' ? this.routePoints[index].pickup : this.routePoints[index].delivery;
					if(address != null && address != undefined){
						const first = address.split(",");
						const second = first[1].split(" ").filter((ui)=>{
							return ui != ''
						});
						if(type == 'pickup'){
							this.routePoints[index].pickupadd = first[0];
							this.routePoints[index].pickupcity = second[1];
							this.routePoints[index].pickuppostal = second[0];
						}
						else if(type == 'delivery'){
							this.routePoints[index].deliveryadd = first[0];
							this.routePoints[index].deliverycity = second[1];
							this.routePoints[index].deliverypostal = second[0];
						}
					}
				}
				catch(e){
					//console.log(e);
				}
			},
			resetPointForm(){
				this.pointForm.pickup = null;
				this.pointForm.pickupadd = null;
				this.pointForm.pickupcity = null;
				this.pointForm.pickuppostal = null;
				this.pointForm.delivery = null;
				this.pointForm.deliveryadd = null;
				this.pointForm.deliverypostal = null;
				this.pointForm.deliverycity = null;
				this.pointForm.child = null;
				this.pointForm.childname = null;
				this.pointForm.timewindow = null;
				this.pointForm.timespent = 1;
				this.pointForm.showNameField = false;
			},
			setRouteSchool(){
				const idx = this.routeForm.selectedSchoolIdx;

				if(idx != null){
					const schoolid = toRaw(this.schools[idx]).id;
					this.getChildren(schoolid);
				}
			},
			setPointSchool(){
				const idx = this.pointForm.selectedPointSchoolIdx;

				if(idx != null){
					const schoolid = toRaw(this.schools[idx]).id;
					this.getChildren(schoolid);
				}
			},
			setType(type){
				if(type == 0){
					this.routetype =  false;
				}
				else{
					this.routetype = true;
				}
			},
			checkChildSelect(){
				if(this.pointForm.childIdx == -1){
					this.pointForm.showNameField = true;
				}
				else{
					this.pointForm.showNameField = false;
				}
			},
			async getSchools(){
				axios.post(this.$api_add + localStorage.getItem("org") +'school/getall').then((res)=>{
					if(res.data.result == 'ok'){
						this.schools = toRaw(res.data.data);
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.schoolResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.schoolResponses.getFailed') + e);
					this.toastObject['status'] = false;
				})
			},
			getAddresses(){
				axios.post(this.$api_add + localStorage.getItem("org") +'coordinates/getdistinct').then((res)=>{
					if(res.data.result == 'ok'){
						this.addresses = res.data.data;
						this.filteredAddresses = res.data.data;
					}
				});
			},
			async getChildren(schoolid){
				const id = parseInt(schoolid);
				axios.post(this.$api_add + localStorage.getItem("org") +'child/getbyschool', {"schoolid":id}).then((res)=>{
					if(res.data.result == 'ok'){
						this.children = toRaw(res.data.data).sort((a,b)=>{
							return a.lastname - b.lastname
						});
						//this.getTempChildren();
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.userResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			getCars(){
				axios.post(this.$api_add + localStorage.getItem("org") +'vehicles/getvehicles').then((res)=>{
					if(res.data.result == 'ok'){
						this.cars = toRaw(res.data.data);
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.vehicleResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.vehicleResponses.getFailed') + e);
					this.toastObject['status'] = false;
				})
			},
			coordinatesValidation(){
				if(this.coordsForm.address && this.coordsForm.coords){
					return false;
				}
				else{
					return true;
				}
			},
			async addCoordinates(Form){
				const temp = Form.coords.split(', ');
				const coords = {
					address: Form.address,
					lat: Number(temp[0]),
					lon: Number(temp[1])
				}
				axios.post(this.$api_add + localStorage.getItem("org") +'coordinates/create', coords).then((res)=>{
					if(res.data.result == 'ok'){
						this.toastObject['message'] = String(this.$t('operator.coordinateResponses.addSuccess'));
						this.toastObject['status'] = true;
						this.coordsForm.address = null;
						this.coordsForm.coords = null;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.coordinateResponses.addFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.coordinateResponses.addFailed') + e);
					this.toastObject['status'] = false;
				})
			},
			async getAllChildren(){
				axios.post(this.$api_add+ localStorage.getItem("org") +'child/getall').then((res)=>{
					if(res.data.result == 'ok'){
						this.allChildren = toRaw(res.data.data).sort((a,b)=>{
							return a.lastname - b.lastname
						});
						//this.getTempChildren();
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.userResponses.getFailed') + e);
					this.toastObject['status'] = false;
				}).finally(()=>{
					this.getParents();
				})
			},
			async getParents(){
				// TODO: Needs a query that gets all users except operators and drivers
				axios.post(this.$api_add + localStorage.getItem("org") + 'users/getnoncontractors').then((res)=>{
					if(res.data.result == 'ok'){
						this.parents = toRaw(res.data.data);
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.userResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			async getTempChildren() { //TODO add tempchildren to selectable children

				axios.post(this.$api_add + localStorage.getItem("org") + 'child/getbyschooltemp', { "schoolid": this.currentSchool }).then((res) => {
					if (res.data.result == 'ok') {
						this.children = this.children.concat(toRaw(res.data.data));
					}
					else {
						//this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						//this.toastObject['status'] = false;
					}
				}).catch(() => {
					//this.toastObject['message'] = String(this.$t('operator.userResponses.getFailed') + e);
					//this.toastObject['status'] = false;
				})
			},
		handleReorder(event){

        this.routePoints = event.detail.complete(this.routePoints);


      },
	parseAddress(address){
		let split1 = address.split(',');
		let street = split1[0];
		let split2 = address.split(' ');
		let result = [street, split2[2], split2[3]];
		return result;
		},
      readFile(file){
        let addpick, adddeli, add;
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () =>{

            let text = reader.result;
            let lines = text.split('\n')
            for(let i = 2; i < lines.length;i++){
                let data = lines[i].split(';');
				if(data.length == 0){
					continue;
				}
                if(data[0] == 0){
                    this.routeForm.routename = data[2];
                    add = this.parseAddress(data[4]);
                    this.routeForm.startaddress = data[4];
					this.routeForm.startadd = add[0];
					this.routeForm.startpostal = add[1];
					this.routeForm.startcity = add[2];
                    this.routeForm.starttime = data[6];
					if(data[5]){
						add = this.parseAddress(data[5]);
					this.routeForm.endaddress = data[5]
					this.routeForm.endadd = add[0];
					this.routeForm.endpostal = add[1];
					this.routeForm.endcity = add[2];
					}

					this.routeForm.type = data[1];

                    this.routeType = data[1];
					this.routeForm.days = data[3];

                }else if(data[0] == 1){


					let idx = this.schools.findIndex(a => a.address == data[2])

					if(idx < 0){
						this.pointForm.selectedSchoolIdx = this.schools.findIndex(a => a.address == data[1])
					}else{
						this.pointForm.selectedSchoolIdx = idx;

					}

					addpick = this.parseAddress(data[1]);
					adddeli = this.parseAddress(data[2]);
                    let object = {
					child:data[3],
					childname:data[4],
					pickup: data[1],
					delivery: data[2],
					schoolid:this.schools[this.pointForm.selectedSchoolIdx].id,
					duration: data[5],
					timewindow:data[6],

					pickupadd: addpick[0],
					pickupcity: addpick[2],
					pickuppostal: addpick[1],
					timespent: data[6]/60,
					deliveryadd: adddeli[0],
					deliverypostal: adddeli[1],
					deliverycity: adddeli[2],
					selectedPointSchoolIdx: null,
					childIdx: null,
					showNameField: false,
					selectedAddressIdx:null,
					forceUpdate: false


					}
					this.routePoints.push(object);
                }
                else{
                    break;
                }
            }
        }
      },
      onUpload(e){
        let file = e.target.files || e.dataTransfer.files;
        if(!file.length) return;
        this.readFile(file[0])

      }

    },
		watch:{
			routetype:{
				handler(newVal, oldVal)
					{
						//Replace prop with a property that would change
						if(newVal !== oldVal){
							this.routePoints = [];
						}
					},
					deep: true //important to watch object for changes
			}
		},
    data(){
			this.getSchools();
			this.getAddresses();
			this.getAllChildren();
			this.getCars();
      return{
				routetype: 0,
				toastObject:{
					"message": null,
					"status": false
				},
				routeForm: {
					routename: null,
          startaddress: null,
					startadd: null,
					startpostal: null,
					startcity: null,
          starttime: null,
					endaddress:null,
					endadd:null,
					endpostal:null,
					endcity:null,
					points: [],
					type: 0,
					days: null,
					check: "add",
					children: null,
					jwt: localStorage.getItem('token'),
					vehicle: null,
					selectedSchoolIdx: null,
					endPointIdx:null,
					assignedvehicle: null
				},
				pointForm : {
					pickup: null,
					pickupadd: null,
					pickupcity: null,
					pickuppostal: null,
					delivery: null,
					deliveryadd: null,
					deliverypostal: null,
					deliverycity: null,
					child: null,
					childname: null,
					timewindow: null,
					timespent: 1,
					selectedSchoolIdx: null,
					selectedPointSchoolIdx: null,
					childIdx: null,
					showNameField: false,
					selectedAddressIdx:null
				},
				coordsForm:{
					address: null,
					coords: null
				},
				routePoints: [],
				schools: [],
				cars: [],
				children: [],
				allChildren:[],
				pointchildren:[],
				tempchildren: [],
				currentSchool: null,
				addresses: null,
				filteredAddresses: null,
				isdesktop: this.$isdesktop,
				parents: null,
				reorder: false,
				forceUpdate: false
      }
    },
		setup(){

      return {
		menuOutline,
			closeOutline,
			modalController
			}
		}
});
</script>
<style>
	.inpt{
		--background: #dedad9;
	}
	.bld{
		font-weight: bold;
	}
	.tableHeader {
		display: flex; /* or inline-flex */
		flex-direction: row;
	}
	.btmborder{
		border-bottom: solid 1px #cfcdca;
	}
	.topborder{
		border-top: solid 1px #cfcdca;
	}
	.pointtable{
		max-height:30vh;
		overflow: auto;
	}
	.fullwidth{
		width: 100%;
	}
	.smalltext{
		font-size:1em;
	}
	.dltpnt{
		z-index: 99999;
	}
</style>
