<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-header>
					<ion-toolbar>
						<ion-title>{{currentPage == 'table' ? $t('operator.drivers') : $t('operator.addDriver')}}</ion-title>
						<ion-buttons slot="secondary">
							<ion-button size="large" @click="currentPage == 'table' ? changeView('addDriver') : changeView('table')">
								<ion-icon :icon="changeIcon()" slot="start" size="large"></ion-icon>
								{{currentPage == 'addDriver' ? $t('operator.drivers') : $t('operator.addDriver')}}
							</ion-button>
						</ion-buttons>
					</ion-toolbar>
				</ion-header>
				<ion-content class="ion-padding ion-margin-top driverTable" v-if="currentPage == 'table'">
          <ion-grid>
						<ion-row>
							<ion-col size="12">
								<ion-searchbar id="userSearch" :placeholder="$t('operator.searchDrivers')" @input="searchDrivers($event.target.value)"></ion-searchbar>
							</ion-col>
						</ion-row>
						<ion-row class="tableHeader">
							<ion-col size="2">
								<b>{{$t('general.name')}}</b>
							</ion-col>
							<ion-col size="2">
								<b>{{$t('login.username')}}</b>
							</ion-col>
							<ion-col size="4">
								<b>{{$t('general.contactInfo')}}</b>
							</ion-col>
						</ion-row>
              <ion-row v-for="(driver,index) in filteredDrivers" :key="index">
                <ion-col size="12" class="btmborder">
                  <ion-row>
                    <ion-col size="2">
                      <ion-item lines="none">
                        {{driver.firstname + " " + driver.lastname}}
                      </ion-item>
                    </ion-col>
                    <ion-col size="2">
                      <ion-item lines="none">
                        {{driver.username}}
                      </ion-item>
                    </ion-col>
                    <ion-col size="4">
                      <ion-item lines="none">
                        {{driver.phone}}
												<br>
												{{driver.email}}
                      </ion-item>
                    </ion-col>
                    <ion-col size="2" v-if="isdesktop">
                      <ion-item lines="none">
												<ion-row>
													<ion-col size="12">
														<ion-button size="full" @click="getEditData(index), setOpen(true)">{{$t('operator.modify')}}</ion-button>
													</ion-col>
													<ion-col size="12">
														<ion-button size="full" @click="showDeleteAlert(index)" color="danger">{{$t('operator.delete')}}</ion-button>
													</ion-col>
												</ion-row>
                      </ion-item>
                    </ion-col>
										<ion-col size="2" v-else>
											<ion-item lines="none">
												<ion-row>
													<ion-col size="12">
														<ion-button size="large" @click="getEditData(index), setOpen(true)">
															<ion-icon :icon="pencilOutline"></ion-icon>
														</ion-button>
													</ion-col>
													<ion-col size="12">
														<ion-button size="large" @click="showDeleteAlert(index)" color="danger">
															<ion-icon :icon="trashOutline"></ion-icon>
														</ion-button>
													</ion-col>
												</ion-row>
											</ion-item>
										</ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
          </ion-grid>
				</ion-content>
        <ion-content class="ion-padding ion-margin-top driverTable" v-if="currentPage == 'addDriver'">
					<AddDriver @goback="parseAddEmited"/>
				</ion-content>
				<ion-modal :is-open="isOpenRef"  @didDismiss="setOpen(false), getDrivers()">
					<ion-content class="ion-padding ion-margin-top carsTable">
						<EditDriver :driverdata="editData" @responsedata="parseModalEmitted"/>
					</ion-content>
				</ion-modal>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonContent,
  IonGrid,
  IonRow,
	IonButton,
	IonIcon,
	IonModal,
	IonButtons,
  IonItem,
  IonHeader,
  IonCol,
  IonTitle,
  IonToolbar,
	modalController,
	alertController,
	IonSearchbar
} from "@ionic/vue";
import {
	add,
	arrowBackOutline,
	pencilOutline,
	trashOutline } from 'ionicons/icons';
import { defineComponent, ref, toRaw } from 'vue';
import AddDriver from './AddDriver.vue';
import EditDriver from './EditDriver.vue';
import axios from 'axios';
import StatusToast from '@/components/StatusToast.vue';
export default defineComponent({
    name: "OperatorCars",
    components:{
      IonContent,
			IonButton,
      IonGrid,
      IonRow,
			IonIcon,
			IonButtons,
      IonItem,
      IonHeader,
      IonCol,
			IonModal,
      IonTitle,
      IonToolbar,
			AddDriver,
			EditDriver,
			IonSearchbar
		},
    methods:{
			changeView(view){
				this.currentPage = view;
			},
			getEditData(index){
				this.editData = toRaw(this.filteredDrivers[index]);
			},
			getDrivers(){
				// TODO: Get car data from database
				axios.post(this.$api_add + localStorage.getItem("org") +'users/getbyrole', {"role": "driver"}).then((res)=>{
					if(res.data.result == 'ok'){
						this.drivers = res.data.data;
						if(this.filteredDrivers == null){
							this.filteredDrivers = this.drivers;
						}
						else{
							this.searchDrivers(this.searchQuery)
						}
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.driverResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.driverResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			parseAddEmited(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					this.changeView('table');
					this.getDrivers();
				}
			},
			parseModalEmitted(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					modalController.dismiss();
					this.getDrivers();
				}
			},
			deleteDriver(index){
				const deldata = this.filteredDrivers[index];

				axios.post(this.$api_add + localStorage.getItem("org") +'users/deleteuser', deldata).then((res)=>{
					if(res.data.result == 'ok'){
						this.filteredDrivers.splice(index,1);
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.driverResponses.deleteSuccess'));
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.driverResponses.deleteFailure') + res.statusText);
						this.toastObject['status']  = false
					}
					this.showToast();
				}).catch((e)=>{
					this.toastObject['message'] = String(this.$t('operator.driverResponses.deleteFailure') + e);
					this.toastObject['status'] = false;
					this.showToast();
				});
			},
			async showDeleteAlert(index){
				const data = this.filteredDrivers[index];
				const message = this.$t('general.username')+`: `+data.username+`<br>`+this.$t('general.name')+
					`: `+String(data.firstname + " " + data.lastname)

				const alert = await alertController.create({
					header: this.$t('operator.confirmDeleteDriver'),
					message: message,
					buttons: [{
							text: this.$t('general.cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							id: 'cancel-button'
						},
						{
							id: 'confirm-button',
							text: this.$t('operator.delete'),
							cssClass:'deletealert',
							handler: () => {
								this.deleteDriver(index);
							},
						}],
				});
				await alert.present();

				await alert.onDidDismiss();
			},
			changeIcon(){
				if(this.currentPage == 'table'){
					return add;
				}
				else if(this.currentPage == 'addDriver'){
					return arrowBackOutline;
				}
			},
			showToast(){
				StatusToast.methods.openToast(this.toastObject);
			},
			searchDrivers(value){
				const query = toRaw(value).toLowerCase();
				if(!query){
					this.filteredDrivers = this.drivers;
				}
				else{
					this.filteredDrivers = this.drivers.filter((x)=>{
						const xvalues = String(Object.values(x).join(",")).toLowerCase();
						return xvalues.includes(query);
					});
				}
				this.searchQuery = query;
			},
    },
		setup(){
			const isOpenRef = ref(false);
			const setOpen = (state) => isOpenRef.value = state;

			return{
        add,
				isOpenRef,
				setOpen,
				alertController,
				pencilOutline,
				trashOutline
			}

		},
		mounted(){
			this.getDrivers();
		},
    data(){
      return{
				toastObject:{
					"message": null,
					"status": false
				},
				currentPage: 'table',
				editData: null,
				drivers:[],
				filteredDrivers: null,
				searchQuery: null,
				isdesktop: this.$isdesktop
      }
    }
});
</script>
<style>
  .driverTable{
		height: 90vh;
	}
	.tableHeader {
    display: flex;
    flex-direction: row
	}
	.btmborder{
		border-bottom: solid 1px #cfcdca;
	}
	.iconbtn{
		font-size: 1.2em;
	}
	.cntcs{
		width: fit-content;
	}
</style>
