<template>
	<ion-grid :key="forceUpdate">
		<ion-row>
			<ion-col>
				<ion-header>
					<ion-toolbar>
						<ion-title>{{currentPage == 'table' ? $t('operator.users') : $t('operator.addUsersAlt')}}</ion-title>
						<ion-buttons slot="secondary">
							<ion-button size="large" @click="currentPage == 'table' ? changeView('addUser') : changeView('table')">
								<ion-icon :icon="changeIcon()" slot="start" size="large"></ion-icon>
								{{currentPage == 'addUser' ? $t('operator.users') : $t('operator.addUsersAlt')}}
							</ion-button>
						</ion-buttons>
					</ion-toolbar>
				</ion-header>
				<ion-content class="ion-padding ion-margin-top userTable" v-if="currentPage == 'table'">
          <ion-grid>
						<ion-row>
							<ion-col size="12">
								<ion-searchbar id="userSearch" :placeholder="$t('operator.searchUsers')" @input="searchUsers($event.target.value)"></ion-searchbar>
							</ion-col>
						</ion-row>
						<ion-accordion-group multiple="true">
								<ion-accordion value="users">
									<ion-item slot="header" color="light">
										<ion-label>{{$t('operator.users')}}</ion-label>
									</ion-item>
									<div class="ion-padding" slot="content">
										<ion-row class="tableHeader">
											<ion-col size="1">
												<b>{{$t('general.name')}}</b>
											</ion-col>
											<ion-col :size="isdesktop ? 1 : 2">
												<b>{{$t('general.username')}}</b>
											</ion-col>
											<ion-col size="2">
												<b>{{$t('general.address')}}</b>
											</ion-col>
											<ion-col size="2">
												<b>{{$t('general.contactInfo')}}</b>
											</ion-col>
											<ion-col size="1">
												<b>{{$t('operator.role')}}</b>
											</ion-col>
											<ion-col size="2">
												<b>{{$t('operator.children')}}</b>
											</ion-col>
											<ion-col size="2">
											</ion-col>
										</ion-row>
										<ion-row v-for="(user,index) in filteredUsers" :key="index">
											<ion-col size="12" class="btmborder smallertext">
												<ion-row>
													<ion-col size="1">
														<ion-item lines="none">
															{{user.firstname +" "+ user.lastname}}
														</ion-item>
													</ion-col>
													<ion-col :size="isdesktop ? 1 : 2">
														<ion-item lines="none">
															{{user.username}}
														</ion-item>
													</ion-col>
													<ion-col size="2">
														<ion-item lines="none">
															{{user.addressdefault}}
														</ion-item>
													</ion-col>
													<ion-col size="2">
														<ion-item lines="none">
															{{user.phone}}
															<br>
															{{user.email}}
														</ion-item>
													</ion-col>
													<ion-col size="1">
														<ion-item lines="none" :class="isdesktop ? '' : 'smallertext_wrap'">
															{{$t(String('general.roles.'+user.role))}}
														</ion-item>
													</ion-col>
													<ion-col size="2">
														<ion-item lines="none" class="pre">
															{{findChilds(user.id)}}
														</ion-item>
													</ion-col>
												<ion-col size="2" v-if="isdesktop">
													<ion-item lines="none">
														<ion-row>
															<ion-col size="12">
																<ion-button @click="getEditData('parent', index),setUserOpen(true)" size="full">{{$t('operator.modify')}}</ion-button>
															</ion-col>
															<ion-col size="12">
																<ion-button size="full" @click="showDeleteAlert('parent', index)" color="danger">{{$t('operator.delete')}}</ion-button>
															</ion-col>
														</ion-row>
													</ion-item>
												</ion-col>
												<ion-col size="2" v-else>
													<ion-item lines="none">
														<ion-row>
															<ion-col size="12">
																<ion-button @click="getEditData('parent', index),setUserOpen(true)" size="large">
																	<ion-icon :icon="pencilOutline"></ion-icon>
																</ion-button>
															</ion-col>
															<ion-col size="12">
																<ion-button size="large" @click="showDeleteAlert('parent', index)" color="danger">
																	<ion-icon :icon="trashOutline"></ion-icon>
																</ion-button>
															</ion-col>
														</ion-row>
													</ion-item>
												</ion-col>
											</ion-row>
										</ion-col>
									</ion-row>
								</div>
							</ion-accordion>
							<ion-accordion value="children">
								<ion-item slot="header" color="light">
									<ion-label>{{$t('operator.children')}}</ion-label>
								</ion-item>
								<div class="ion-padding" slot="content">
									<ion-row class="tableHeader">
										<ion-col size="2">
											<b>{{$t('general.name')}}</b>
										</ion-col>
										<ion-col size="2">
											<b>{{$t('general.school')}}</b>
										</ion-col>
										<ion-col size="2">
											<b>{{$t('driver.helpLevel')}}</b>
										</ion-col>
										<ion-col size="2">
											<b>{{$t('general.parent')}}</b>
										</ion-col>
										<ion-col size="2">
											<b>{{$t('general.active')}}</b>
										</ion-col>
										<ion-col size="2">
										</ion-col>
									</ion-row>
									<ion-row v-for="(child,index) in filteredChildren" :key="index">
										<ion-col size="12" class="btmborder">
											<ion-row>
												<ion-col size="2">
													<ion-item lines="none">
														{{child.firstname +" "+ child.lastname}}
													</ion-item>
												</ion-col>
												<ion-col size="2">
													<ion-item lines="none">
														{{findSchool(child.schoolid)}}
													</ion-item>
												</ion-col>
												<ion-col size="2">
													<ion-item lines="none">
														{{child.assistancelevel}}
													</ion-item>
												</ion-col>
												<ion-col size="2">
													<ion-item lines="none">
														{{findParent(child.parentid)}}
													</ion-item>
												</ion-col>
												<ion-col size="2">
													<ion-item lines="none">
														{{child.active ? $t('general.generalYes') : $t('general.generalNo')}}
													</ion-item>
												</ion-col>
											<ion-col size="2" v-if="isdesktop">
												<ion-item lines="none">
													<ion-row>
														<ion-col size="12">
															<ion-button @click="getEditData('child', index),setChildOpen(true)" size="full">{{$t('operator.modify')}}</ion-button>
														</ion-col>
														<ion-col size="12">
															<ion-button size="full" @click="showDeleteAlert('child', index)" color="danger">{{$t('operator.delete')}}</ion-button>
														</ion-col>
													</ion-row>
												</ion-item>
											</ion-col>
											<ion-col size="2" v-else>
												<ion-item lines="none">
													<ion-row>
														<ion-col size="12">
															<ion-button  @click="getEditData('child', index),setChildOpen(true)" size="large">
																	<ion-icon :icon="pencilOutline"></ion-icon>
															</ion-button>
														</ion-col>
														<ion-col size="12">
															<ion-button size="large" @click="showDeleteAlert('child', index)" color="danger">
																	<ion-icon :icon="trashOutline"></ion-icon>
															</ion-button>
														</ion-col>
													</ion-row>
												</ion-item>
											</ion-col>
										</ion-row>
									</ion-col>
								</ion-row>
							</div>
							</ion-accordion>
						</ion-accordion-group>
          </ion-grid>
				</ion-content>
        <ion-content class="ion-padding ion-margin-top userTable" v-if="currentPage == 'addUser'">
					<AddUser @goback="parseAddEmited"/>
				</ion-content>
				<ion-modal :is-open="isUserOpenRef"  @didDismiss="setUserOpen(false), getUsers()">
					<ion-content class="ion-padding ion-margin-top carsTable">
						<EditUser :userdata="editData" :parentdata="parents" @responsedata="parseModalEmitted"/>
					</ion-content>
				</ion-modal>
				<ion-modal :is-open="isChildOpenRef"  @didDismiss="setChildOpen(false), getChildren()">
					<ion-content class="ion-padding ion-margin-top carsTable">
						<EditChild :userdata="editData" :schooldata="schools" :parentdata="parents" @responsedata="parseModalEmitted"/>
					</ion-content>
				</ion-modal>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonContent,
  IonGrid,
  IonRow,
	IonButton,
	IonIcon,
	IonButtons,
	IonModal,
  IonItem,
  IonHeader,
  IonCol,
  IonTitle,
  IonToolbar,
	modalController,
	IonAccordion,
	IonAccordionGroup,
	IonLabel,
	alertController,
	IonSearchbar,
} from "@ionic/vue";
import {
	add,
	arrowBackOutline,
	chevronUpOutline,
	chevronDownOutline,
	pencilOutline,
	trashOutline
} from 'ionicons/icons';
import { defineComponent, ref, toRaw } from 'vue';
import AddUser from './AddUser.vue';
import StatusToast from '@/components/StatusToast.vue';
import axios from 'axios';
import EditUser from './EditUser.vue';
import EditChild from './EditChild.vue';
export default defineComponent({
    name: "OperatorCars",
    components:{
      IonContent,
			IonButton,
      IonGrid,
      IonRow,
			IonIcon,
			IonModal,
			IonButtons,
      IonItem,
      IonHeader,
      IonCol,
      IonTitle,
      IonToolbar,
			AddUser,
			EditUser,
			EditChild,
			IonAccordion,
			IonAccordionGroup,
			IonLabel,
			IonSearchbar
		},
    methods:{
			changeView(view){
				this.currentPage = view;
			},
			getKeys(obj){
				return Object.keys(obj[0]);
			},
			parseAddEmited(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					this.changeView('table');
					this.getUsers();
					this.getChildren();
					setTimeout(()=>{
						this.forceUpdate = !this.forceUpdate;
					},0);
				}
			},
			parseModalEmitted(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					modalController.dismiss();
					this.getUsers();
					this.getChildren();
					setTimeout(()=>{
						this.forceUpdate = !this.forceUpdate;
					},0)
				}
			},
			showToast(){
				StatusToast.methods.openToast(this.toastObject);
			},
			getEditData(type, index){
				if(type == 'parent'){
					let dt = toRaw(this.filteredUsers[index]);
					dt['check'] = 'parent';
					this.editData = dt
				}
				else if (type == 'child') {
					this.editData = toRaw(this.filteredChildren[index]);
					let dt = toRaw(this.filteredChildren[index]);
					dt['check'] = 'child';
					this.editData = dt
				}
			},
			getUsers(){
				// TODO: Needs a query that gets all users except operators and drivers
				axios.post(this.$api_add + localStorage.getItem("org") +'users/getnoncontractors').then((res)=>{
					if(res.data.result == 'ok'){
						this.users = toRaw(res.data.data);

						if(this.filteredUsers == null){
							this.filteredUsers = toRaw(res.data.data);
						}
						else{
							this.searchUsers(this.searchQuery);
						}
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.userResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			getChildren(){
				axios.post(this.$api_add + localStorage.getItem("org") +'child/getall').then((res)=>{
					if(res.data.result == 'ok'){
						const dt = toRaw(res.data.data);
						this.children = dt;
						if(this.filteredChildren == null){
							this.filteredChildren = dt;
						}
						else{
							this.searchUsers(this.searchQuery);
						}
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				});
			},
			async getSchools(){
				axios.post(this.$api_add + localStorage.getItem("org") +'school/getall').then((res)=>{
					if(res.data.result == 'ok'){
						this.schools = toRaw(res.data.data);
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.schoolResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.schoolResponses.getFailed') + e);
					this.toastObject['status'] = false;
				})
			},
			getParents(){
				axios.post(this.$api_add + localStorage.getItem("org") +'users/getparents').then((res)=>{
					if(res.data.result == 'ok'){
						let data = toRaw(res.data.data);
						for (let i = 0; i < data.length; i++) {
							data[i]['name'] = data[i]['firstname'] + " " + data[i]['lastname'];
						}
						this.parents = data;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.userResponses.getFailed') + e);
					this.toastObject['status'] = false;
				});
			},
			searchUsers(value){
				if(value != "" && value != undefined && value != null){
					const query = toRaw(value).toLowerCase();
					if(!query){
						this.filteredUsers = this.users;
						this.filteredChildren = this.children;
					}
					else{
						this.filteredUsers = this.users.filter((x)=>{
							//stringify points because otherwise it will be read as [Object object]
							//get other object values, attach them to points and make the whole thing case insensitive with toLowerCase
							const xvalues = String(Object.values(x).join(",")).toLowerCase();
							return xvalues.includes(query);
						});
						this.filteredChildren = this.children.filter((x)=>{
							//stringify points because otherwise it will be read as [Object object]
							//get other object values, attach them to points and make the whole thing case insensitive with toLowerCase
							const xvalues = String(Object.values(x).join(",")).toLowerCase();
							return xvalues.includes(query);
						});
					}
					this.searchQuery = query;
				}
			},
			deleteUser(check, index){
				let query = null;
				let deldata = null;
				if(check == 'parent'){
					deldata = this.filteredUsers[index];
					query = axios.post(this.$api_add + localStorage.getItem("org") +'users/deleteuser', deldata);
				}
				else if(check == 'child'){
					deldata = this.filteredChildren[index];
					query = axios.post(this.$api_add + localStorage.getItem("org") +'child/delete', deldata);
				}
				query.then((res)=>{

					if(res.data.result == 'ok'){
						if(check == 'parent'){
							this.filteredUsers.splice(index,1);
						}
						else if(check == 'child'){
							this.filteredChildren.splice(index,1);
						}
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.deleteSuccess'))
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.userResponses.deleteFailure') + " " + res.statusText);
						this.toastObject['status']  = false
					}
					this.showToast();
					this.getUsers();
					this.getChildren();
					this.forceUpdate = !this.forceUpdate;
				}).catch((e)=>{
					this.toastObject['message'] = String(this.$t('operator.userResponses.deleteFailure') + " " + e);
					this.toastObject['status'] = false;
					this.showToast();
				});
			},
			changeIcon(){
				if(this.currentPage == 'table'){
					return add;
				}
				else if(this.currentPage == 'addUser'){
					return arrowBackOutline;
				}
			},
			findParent(id){
				try{
					const prnts = this.parents;
					const parentname = prnts.find((x)=>{
						return x.id == id;
					})['name'];

					if (parentname) {
						return parentname;
					}
					else{
						return "";
					}
				}
				catch(e){
					return "";
				}
			},
			findChilds(id){
				try{
					const chlds = this.children;
					const childnames = toRaw(chlds).filter((x)=>{
						return x.parentid == id;
					}).map((y)=>{
						return String(y.firstname + " "+ y.lastname);
					}).join(`\n`);

					if(childnames) {
						return childnames;
					}
					else{
						return "";
					}
				}
				catch(e){
					return "";
				}
			},
			findSchool(id){
				try{
					const sch = this.schools;
					const schoolname = sch.find((x)=>{
						return x.id == id;
					})['name'];

					if (schoolname) {
						return schoolname;
					}
					else{
						return "";
					}
				}
				catch(e){
					return "";
				}
			},
			async showDeleteAlert(check, index){
				let message = null;
				let data = null;
				let header = null;

				if(check == 'parent'){
					header = this.$t('operator.confirmUserDelete');
					data = this.filteredUsers[index];
					message = this.$t('general.username')+`: `+data.username+`<br>`+this.$t('general.name')+
						`: `+String(data.firstname + " " + data.lastname)+`<br>`+this.$t('operator.role')+
						`: `+this.$t(String('general.roles.'+data.role));
				}
				else if(check == 'child'){
					header = this.$t('operator.confirmChildDelete');
					data = this.filteredChildren[index];
					message = this.$t('general.name')+`: `+String(data.firstname + " " + data.lastname)+`<br>`
						+this.$t('general.school')+`: `+this.findSchool(data.schoolid)+`<br>`
						+this.$t('general.parent')+`: `+this.findParent(data.parentid)
				}
				const alert = await alertController.create({
					header: header,
					message: message,
					buttons: [{
							text: this.$t('general.cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							id: 'cancel-button'
						},
						{
							id: 'confirm-button',
							text: this.$t('operator.delete'),
							cssClass:'deletealert',
							handler: () => {
								this.deleteUser(check, index);
							},
						}],
				});
				await alert.present();

				await alert.onDidDismiss();
			}
    },
		mounted(){
			this.getUsers();
			this.getChildren();
			this.getSchools();
			this.getParents();
		},
		setup(){
			const isUserOpenRef = ref(false);
			const isChildOpenRef = ref(false);
			const setUserOpen = (state) => isUserOpenRef.value = state;
			const setChildOpen = (state) => isChildOpenRef.value = state;
			return{
        add,
				isUserOpenRef,
				setUserOpen,
				setChildOpen,
				isChildOpenRef,
				alertController,
				chevronUpOutline,
				chevronDownOutline,
				pencilOutline,
				trashOutline
			}

		},
    data(){
      return{
				toastObject:{
					"message": "",
					"status": false
				},
				currentPage: 'table',
				editData: null,
				users: [],
				filteredUsers: null,
				children: [],
				filteredChildren: null,
				schools: [],
				parents: [],
				sortClicks: 0,
				sortLastValue: null,
				searchQuery: null,
				isdesktop: this.$isdesktop,
				forceUpdate: false
      }
    }
});
</script>
<style>
  .userTable{
		height: 90vh;
	}
	.tableHeader {
    display: flex; /* or inline-flex */
    flex-direction: row
	}
	.btmborder{
		border-bottom: solid 1px #cfcdca;
	}
	.iconbtn{
		font-size: 1.2em;
	}
	.pre{
		white-space: pre;
	}
	.smallertext{
		font-size: 0.8em;
	}
	.smallertext_wrap{
		font-size: 1em;
	}
</style>
