import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { slot: "label" }
const _hoisted_2 = { id: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_locale_switcher = _resolveComponent("locale-switcher")!
  const _component_logout_vue = _resolveComponent("logout-vue")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('title.title')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_menu_button, {
                slot: "end",
                "auto-hide": "false",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openMenu()))
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_menu, {
        side: "end",
        "content-id": "menu-content",
        "menu-id": "menu"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('assistant.menu')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_locale_switcher, {
                    onUpdateLocale: _ctx.updateLocale,
                    size: "36px",
                    onClick: _ctx.$forceUpdate,
                    slot: "end"
                  }, null, 8, ["onUpdateLocale", "onClick"]),
                  _createVNode(_component_logout_vue, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancelTimers())),
                    size: "36px",
                    slot: "end"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { id: "bAccountInfo" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('assistant.account')) + " ", 1),
                          _createVNode(_component_ion_icon, { icon: _ctx.person }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.currentSchool != 0)
                    ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select, {
                            id: "mySelect",
                            interface: "action-sheet",
                            multiple: "true",
                            "ok-text": _ctx.$t('assistant.choose'),
                            "cancel-text": _ctx.$t('assistant.cancel'),
                            onIonChange: _ctx.chooseStudents
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, [
                                _createVNode(_component_ion_icon, { icon: _ctx.peopleOutline }, null, 8, ["icon"]),
                                _createTextVNode(_toDisplayString(_ctx.$t('assistant.chooseStudents')), 1)
                              ]),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.students, (item) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  value: item,
                                  key: item.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.firstname) + " " + _toDisplayString(item.lastname) + ".", 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["ok-text", "cancel-text", "onIonChange"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { id: "message-button" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('driver.messages')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_router_outlet, { id: "menu-content" }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_modal, {
            onWillPresent: _ctx.getSchools,
            "is-open": _ctx.schoolOpenRef
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_radio_group, {
                modelValue: _ctx.currentSchool,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentSchool) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("assistant.chooseSchool")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schools, (school) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: school.id,
                      ref_for: true,
                      ref: `school-${school.id}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(school.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_radio, {
                          onClick: ($event: any) => {_ctx.schoolSelected(school.id); _ctx.setSchoolOpen(false)},
                          value: school.id
                        }, null, 8, ["onClick", "value"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["onWillPresent", "is-open"]),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(), _createBlock(_component_ion_content, { key: _ctx.update }, {
              default: _withCtx(() => [
                (_ctx.viewModifier == 'morning')
                  ? (_openBlock(), _createBlock(_component_ion_list, {
                      key: 0,
                      id: "list-container",
                      inset: true
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              expand: "block",
                              size: "large",
                              slot: "start",
                              color: _ctx.morning,
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleView('morning')))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('assistant.morning')), 1)
                              ]),
                              _: 1
                            }, 8, ["color"]),
                            _createVNode(_component_ion_button, {
                              expand: "block",
                              size: "large",
                              slot: "end",
                              color: _ctx.afternoon,
                              onClick: _cache[4] || (_cache[4] = ($event: any) => {_ctx.toggleView('afternoon');_ctx.getCarNumbers();})
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('assistant.afternoon')), 1)
                              ]),
                              _: 1
                            }, 8, ["color"])
                          ]),
                          _: 1
                        }),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chosenStudents, (item) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: item.id
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, {
                                position: "fixed",
                                class: "ion-text-wrap"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.firstname) + " " + _toDisplayString(item.lastname) + ".", 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_label, {
                                class: "list-label",
                                position: "fixed"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.arr_time != undefined ? item.arr_time : _ctx.$t('assistant.noTimeFound')), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_label, {
                                class: "list-label",
                                position: "fixed"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('parent.confirmStatus.' + item.status)), 1)
                                ]),
                                _: 2
                              }, 1024),
                              (parseInt(item.status) < 3)
                                ? (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 0,
                                    color: "primary",
                                    onClick: ($event: any) => (_ctx.onConfirm(item.id))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('parent.confirm')) + " ", 1),
                                      _createVNode(_component_ion_icon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"])
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.viewModifier == 'afternoon')
                  ? (_openBlock(), _createBlock(_component_ion_list, {
                      key: 1,
                      id: "list-container"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              expand: "block",
                              size: "large",
                              slot: "start",
                              color: _ctx.morning,
                              onClick: _cache[5] || (_cache[5] = ($event: any) => {_ctx.toggleView('morning');})
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('assistant.morning')), 1)
                              ]),
                              _: 1
                            }, 8, ["color"]),
                            _createVNode(_component_ion_button, {
                              expand: "block",
                              size: "large",
                              slot: "end",
                              color: _ctx.afternoon,
                              onClick: _cache[6] || (_cache[6] = ($event: any) => {_ctx.toggleView('afternoon');_ctx.getCarNumbers();})
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('assistant.afternoon')), 1)
                              ]),
                              _: 1
                            }, 8, ["color"])
                          ]),
                          _: 1
                        }),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chosenStudents, (item) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: item.id
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { position: "fixed" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.firstname) + " " + _toDisplayString(item.lastname) + ".", 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_label, {
                                class: "list-label",
                                position: "fixed"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.dep_time != undefined ? item.dep_time : _ctx.$t('assistant.noTimeFound')), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_label, {
                                class: "list-label",
                                position: "fixed",
                                style: {"white-space":"normal"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.ride != "" ? item.ride : _ctx.$t('assistant.noVehicleFound')), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, { trigger: "bAccountInfo" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('assistant.account')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "stacked" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.username')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_input, { readonly: "" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.account.username), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "stacked" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.email')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_input, {
                type: "text",
                modelValue: _ctx.accountForm.email,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.accountForm.email) = $event)),
                value: _ctx.accountForm.email
              }, null, 8, ["modelValue", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "stacked" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.phone')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_input, {
                modelValue: _ctx.accountForm.phone,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.accountForm.phone) = $event)),
                value: _ctx.accountForm.phone
              }, null, 8, ["modelValue", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, { onClick: _ctx.updateAccount }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('assistant.send')) + " ", 1),
                  _createVNode(_component_ion_icon, { icon: _ctx.sendOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_button, {
                slot: "end",
                color: "danger",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.modalController.dismiss()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('assistant.close')) + " ", 1),
                  _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, {
        ref: "myModal",
        trigger: "message-button"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, { style: {"height":"100%","overflow-y":"scroll"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    onClick: _ctx.newMessage,
                    color: "secondary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.newMessage')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.modalController.dismiss())),
                    color: "danger"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.close')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_list_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.sender')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('driver.message')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (mes) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: mes.id,
                  ref_for: true,
                  ref: `mes-${mes.id}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(mes.name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_label, { style: {"white-space":"normal"} }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(mes.message), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512),
      _createVNode(_component_ion_modal, { trigger: "bClass" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('assistant.classHeader')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    color: "danger",
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.modalController.dismiss()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('assistant.close')) + " ", 1),
                      _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list_header, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('assistant.studentList')), 1)
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.students, (student) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: 'student-'+student.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(student.firstname), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_select, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_select_option, null, {
                              default: _withCtx(() => [
                                _createTextVNode("6A")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('assistant.confirm')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}