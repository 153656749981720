import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_locale_switcher = _resolveComponent("locale-switcher")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_item_option = _resolveComponent("ion-item-option")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("title.title")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item_divider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    id: "bKuittaus",
                    expand: "full",
                    style: {"min-width":"100%","padding-left":"25%","padding-right":"25%","font-size":"large"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.confirm")) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.checkmarkOutline,
                        slot: "end"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item_divider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    id: "bOmatTiedot",
                    expand: "full",
                    style: {"min-width":"100%","padding-left":"25%","padding-right":"25%","font-size":"large"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.accountInfo")) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.personOutline,
                        slot: "end"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item_divider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_locale_switcher, {
                        size: "48px",
                        onClick: _ctx.$forceUpdate
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, { trigger: "bKuittaus" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.transportToday")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    color: "danger",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalController.dismiss()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                      _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_accordion_group, { ref: "accordionGroup" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_accordion, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Date")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Time")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Name")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_list, {
                    slot: "content",
                    inset: "true"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("parent.pickUpAddress")) + ": ", 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("parent.dropOffAddress")) + ": ", 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_button, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("parent.changeAddress")), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("parent.cancelTransport")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _createVNode(_component_ion_item_sliding, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item_options, { side: "start" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item_option, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("parent.confirm")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, { color: "warning" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("parent.dragToConfirm")) + " ", 1),
                              _createVNode(_component_ion_icon, { icon: _ctx.arrowForwardOutline }, null, 8, ["icon"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, { trigger: "bOmatTiedot" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("parent.accountInfo")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.switchContent('MyAccount');})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('parent.accountInfo')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.switchContent('ChangePassword');})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.changePassword')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.accountInfoContent.content == 'MyAccount')
            ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('parent.accountInfo')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.username')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        type: "text",
                        value: _ctx.accountInfo.username,
                        readonly: ""
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.firstName')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        type: "text",
                        value: _ctx.accountInfo.firstName,
                        readonly: _ctx.edit.disabled
                      }, null, 8, ["value", "readonly"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.lastName')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        type: "text",
                        value: _ctx.accountInfo.lastName,
                        readonly: _ctx.edit.disabled
                      }, null, 8, ["value", "readonly"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.defaultAddress')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        type: "text",
                        value: _ctx.accountInfo.addressDefault,
                        readonly: _ctx.edit.disabled
                      }, null, 8, ["value", "readonly"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.email')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        type: "email",
                        value: _ctx.accountInfo.email,
                        readonly: _ctx.edit.disabled
                      }, null, 8, ["value", "readonly"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.phone')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        type: "number",
                        value: _ctx.accountInfo.phone,
                        readonly: _ctx.edit.disabled
                      }, null, 8, ["value", "readonly"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { onClick: _ctx.enableEdit }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.edit')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { onClick: _ctx.enableEdit }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.updateChanges')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_ion_button, {
                        slot: "end",
                        color: "danger",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.modalController.dismiss()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                          _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.accountInfoContent.content == 'ChangePassword')
            ? (_openBlock(), _createBlock(_component_ion_content, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.changePassword')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "floating" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.currentPassword')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, { type: "password" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "floating" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.newPassword')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, { type: "password" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "floating" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.newPasswordAgain')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, { type: "password" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.changePassword')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_button, {
                        slot: "end",
                        color: "danger",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.modalController.dismiss()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("parent.close")) + " ", 1),
                          _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}