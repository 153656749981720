import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createBlock(_component_ion_icon, {
    icon: _ctx.logOutOutline,
    onClick: _ctx.onLogout,
    class: "icon"
  }, null, 8, ["icon", "onClick"]))
}