<template>
  <div id="map" class="map"></div>
</template>

<script>
import "ol/ol.css";
import {toRaw, defineComponent} from 'vue';
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";

import { useGeographic } from "ol/proj";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { watchMapPosition} from "@/helpers/Geolocation.js";
import {Circle,  Stroke, Style, Icon} from 'ol/style';
import StatusToast from '@/components/StatusToast.vue';
import axios from 'axios';
import MapPopup from '@/components/Popup.vue'
import {defaults as defaultInteractions} from 'ol/interaction.js';

import {carOutline} from 'ionicons/icons';

export default defineComponent({
  name: "MapComponent",
	components:{

	},
  setup(){
    return{
			carOutline
    };
  },
  data() {
    return {
			currentLayer: null,
      mainMap: null,
			toastObject:{
				"message": null,
				"status": false
			},
      initialCoordinates: [ 24.5676,65.7343],
      routeCoords: [],
      route:{
        routeId: null
      },
			routes: null,
			cars: []

    };
  },
  mounted() {
		this.getRoutes();
		this.getCarLocations();
		setTimeout(()=>{
			this.myMap(this.initialCoordinates);
		},1000)
  },
  methods: {
    myMap(coords) {
      useGeographic();
			this.mainMap = new Map({
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        target: "map",
        view: new View({
          center: coords,
          zoom: 16,
        }),
				interactions: defaultInteractions({
					doubleClickZoom: false,
				}),
      });

			let carCoords = [];
      const source = new VectorSource();
			const carFeatures = [];
			this.cars.forEach(c => {
				//source.addFeatures([new Feature(new Point([c.longitude, c.latitude]))]);
				let newFeat = new Feature({
					geometry: new Point([c.longitude, c.latitude]),
					name: c.vehiclename,
					data: c,
				})

				const iconStyle = new Style({
					image: new Icon({
						anchor: [0.5, 46],
						anchorXUnits: 'fraction',
						anchorYUnits: 'pixels',
						src: "assets/icon/car.png"
					}),
				});

				newFeat.setStyle(iconStyle);

				carFeatures.push(newFeat)
				carCoords.push([c.longitude, c.latitude]);
			});

			source.addFeatures(carFeatures);

			const layer = new VectorLayer({
				source: source,
			});

    //this.mainMap.addLayer(pointLayer);
    this.mainMap.addLayer(layer);
    setTimeout(() => {
      this.mainMap.updateSize();
      this.getMapUpdates;
			this.addClickEvents(this.mainMap);
    }, 500);
  },
  getMapUpdates(){
      this.initialCoordinates = watchMapPosition;
  },
	async addClickEvents(map){

		map.on('dblclick', async (event) => {
			const feature = map.forEachFeatureAtPixel(event.pixel, (feature)=>{
				return feature;
			});

			if(feature != undefined){
				const ft = toRaw(feature);

				const carData = toRaw(ft.values_.data);

				let event2 = {
					target : {
						getBoundingClientRect: () => {
							return {
								top: event.pixel_[0],
								right: event.pixel_[1]
							};
						}
					}
				};

				const vehicleroute = await axios.post(this.$api_add + localStorage.getItem("org") +'route/getbyvehicle', {"vehicleid": carData.vehicleid});
				if(vehicleroute.data.result === 'ok'){

					if (this.currentLayer != null) {
						const layersToRemove = [];
						map.getLayers().forEach(layer => {
							if (layer.get('name') && layer.get('name') == 'carroute'){
								layersToRemove.push(layer);
							}
						});
						layersToRemove.forEach((layer)=>{
							map.removeLayer(layer)
						})
						this.currentLayer = null;
					}

					const vehiclert = vehicleroute.data.data;
					let routeData = {
						"routename": this.$t('operator.noRoute'),
						"donePoints": ""
					}
					if(vehiclert.length > 0){

						const optPoints = JSON.parse(vehiclert[0].optimizedpoints);
						const routeLines = optPoints.drawnRoute;
						let routepts = optPoints.points;
						const doneSource = new VectorSource();
						const notDonesource = new VectorSource();




						let doneIdx = null;

						//find the ongoing shipment aka shipment that isn't done
						for (let j = 0; j < routepts.length; j++) {
							if(routepts[j].done){
								continue;
							}
							else{
								doneIdx = j;
								break;
							}
						}

						console.log(doneIdx, routepts.length);
						//filter points
						const pntcoord = routepts.map((h)=>{
							return [h.type,[h.address.lon, h.address.lat]]
						});


						//get unique points
						const set0 = new Set(pntcoord.map(JSON.stringify));
						const set1 = Array.from(set0).map(JSON.parse);



						const services = [];
						//create array from filtered points
						set1.forEach((ele)=>{
							let str = {
								coordinates: ele[1],
								type: "Point"
							}
							services.push(str);
						});

						//get linestrings
						let filteredLines = routeLines.filter((g)=>{
							return g.type === 'LineString'
						});

						const fl_lines = [];
						/*compare linestring's start & end coordinates with point's coordinates
							in order to rearrange them into correct order
						*/
						for (let c = 0; c < filteredLines.length; c++) {
							let currcoords = filteredLines[c].coordinates;
							let start = currcoords[0];
							let end = currcoords[currcoords.length-1];
							for (let b = 0; b < services.length; b++) {
								let currpoint = services[b].coordinates;
								let startDiffX = Math.abs(start[1] - currpoint[1]);
								let startDiffY = Math.abs(start[0] - currpoint[0]);
								let endDiffX = Math.abs(end[1] - currpoint[1]);
								let endDiffY = Math.abs(end[0] - currpoint[0]);
								if(startDiffX < 0.001 && startDiffY < 0.001){
									fl_lines.push(filteredLines[c]);
									fl_lines.push(services[b]);
									break;
								}
								else if(endDiffX < 0.001 && endDiffY < 0.001){
									fl_lines.push(filteredLines[c]);
									fl_lines.push(services[b]);
									break;
								}
							}
						}

						//filter uniques again
						const set2 = new Set(fl_lines.map(JSON.stringify));
						const set3 = Array.from(set2).map(JSON.parse);

						//get the coordinates of last done point
						const notdonepnt = [routepts[doneIdx].address.lon, routepts[doneIdx].address.lat];

						//find corresponding line/point
						let fnd = set3.findIndex((el0)=>{
							if(el0.type == 'LineString'){
								return (this.arraysEqual(el0.coordinates[0], notdonepnt) || this.arraysEqual(el0.coordinates[el0.coordinates.length-1], notdonepnt));
							}
							else if(el0.type == 'Point'){
								return this.arraysEqual(el0.coordinates, notdonepnt);
							}
						});

						if(fnd == -1){
							fnd = 0;
						}
						//slice arrays to done & not done
						const notDoneLines = set3.slice(fnd, set3.length-1);
						const notDonePoints = routepts.slice(doneIdx, routepts.length-1);
						const doneLines = set3.slice(0,fnd);
						const donePoints = routepts.slice(0,doneIdx);

						const notDoneFeatures = [];
						const doneFeatures = [];


						notDoneLines.forEach((el)=>{
							let str = null;
							if(el.type === 'Point'){
								str = {
									geometry: new Point(el.coordinates),
									name: 'notdonepoint'
								}
							}
							else if(el.type == 'LineString'){
								str = {
									geometry: new LineString(el.coordinates),
									name: 'line'
								}
							}

							notDoneFeatures.push(new Feature(str));
						});

						doneLines.forEach((el)=>{
							let str = null;
							if(el.type === 'Point'){
								str = {
									geometry: new Point(el.coordinates),
									name: 'donepoint'
								}
							}
							else if(el.type == 'LineString'){
								str = {
									geometry: new LineString(el.coordinates),
									name: 'line'
								}
							}

							doneFeatures.push(new Feature(str));
						});

						notDonesource.addFeatures(notDoneFeatures);
						doneSource.addFeatures(doneFeatures);

						const donelayer = new VectorLayer({
							source: doneSource,
							name: 'carroute',
							style: new Style({
								stroke: new Stroke({
									color: 'green',
									width: 4
								}),
								circle: new Circle({
									color: "blue"
								})
							})
						});

						const notdonelayer = new VectorLayer({
							source: notDonesource,
							name: 'carroute',
							style: new Style({
								stroke: new Stroke({
									color: 'red',
									width: 2
								}),
							})
						});



						map.addLayer(notdonelayer)
						map.addLayer(donelayer)
						const currentLayers = [];
						currentLayers.push([donelayer, notdonelayer]);
						this.currentLayer = currentLayers;
						routeData = {
							"routename": vehiclert[0].name,
							"donePoints": String(donePoints.length + "/" + notDonePoints.length)
						}
					}


					MapPopup.methods.openPopover(carData, event2, routeData);
				}
			}
			else{
				if (this.currentLayer != null) {
					const layersToRemove = [];
					map.getLayers().forEach(layer => {
						if (layer.get('name') && layer.get('name') == 'carroute'){
							layersToRemove.push(layer);
						}
					});
					layersToRemove.forEach((layer)=>{
						map.removeLayer(layer)
					})
					this.currentLayer = null;
				}
			}


		});
	},
	getUnique(value, index, self){
		return self.indexOf(value) === index;
	},

	getCarLocations(){
		axios.post(this.$api_add + localStorage.getItem("org") +'location/getlocations').then((x)=>{
			const data = x.data.data;
			this.cars = data;
		});
	},
	arraysEqual(a, b) {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a.length !== b.length) return false;

		// If you don't care about the order of the elements inside
		// the array, you should sort both arrays here.
		// Please note that calling sort on an array will modify that array.
		// you might want to clone your array first.

		for (let i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) return false;
		}
		return true;
	},
  updateCarLocation(){
    //TODO: Get car location coordinates and send it to API.
  },
		getRouteId(){
			return this.route.routeId;
		},
		updateRouteId(id){
			console.log(this.initialCoordinates);
			this.routeId = id;
		},
		getRoutes(){
			axios.post(this.$api_add + localStorage.getItem("org") +'route/getdefault').then((res)=>{
				if(res.data.result == 'ok'){
					const prsd = this.parseRoutes(res.data.data);
					this.routes = prsd;
					setTimeout(()=>{
						this.getRouteCoordinates(0);
					},2000);
				}
				else {
					this.toastObject['message'] = String(res.status + " " + this.$t('operator.routeResponses.getFailed') + res.statusText);
					this.toastObject['status'] = false;
					console.log(res.status);
				}
			}).catch(e => {

				this.toastObject['message'] = String(this.$t('operator.routeResponses.getFailed') + e);
				this.toastObject['status'] = false;
				if (e != undefined && e.response != undefined && e.response.status == 401) {
					location.reload();
                }

			});
		},
		showToast(){
			StatusToast.methods.openToast(this.toastObject);
		},
		showPopup(){
			MapPopup.methods.openPopover();
		}
	}
});
</script>

<style>
.map {
  min-height: 100px;
  height: 100%;
  width: 100%;
	z-index:1
}
.popup{
	position: absolute;
	top: 50vh;
	left: 50vw;
	z-index:10;
}
</style>
