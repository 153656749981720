<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-item>
					<h3>{{$t('operator.editDriver')}}</h3>
				</ion-item>
				<form novalidate>
					<ion-row>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										disabled
										:placeholder="$t('general.username')"
										id="driverUsername"
										v-model="editDriverForm.username"
										name="driverUsername"
										:label="$t('general.username')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.firstName')"
										id="driverFirstName"
										v-model="editDriverForm.firstname"
										name="driverFirstName"
										:label="$t('general.firstName')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										:placeholder="$t('general.lastName')"
										id="driverLastname"
										v-model="editDriverForm.lastname"
										name="driverLastname"
										:label="$t('general.lastName')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
							</ion-item>
						</ion-col>
            <ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										id="driverEmail"
										:placeholder="$t('operator.driverEmail')"
										v-model="editDriverForm.email"
										name="driverEmail"
										:label="$t('operator.driverEmail')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col :size="isdesktop ? 4 : 12">
							<ion-item>
									<ion-input
										id="driverPhone"
										:placeholder="$t('operator.driverPhone')"
										v-model="editDriverForm.phone"
										name="driverPhone"
										:label="$t('operator.driverPhone')"
										labelPlacement="stacked"
										type="text"
										required
									></ion-input>
							</ion-item>
						</ion-col>
						<ion-col size="12">
							<ion-button @click="editDriver(editDriverForm)">{{$t('general.save')}}</ion-button>
							<ion-button color="danger" @click="modalController.dismiss()">{{$t('general.cancel')}}</ion-button>
						</ion-col>
					</ion-row>
				</form>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonItem,
	IonInput,
	modalController,
  IonCol,
  IonButton
} from "@ionic/vue";
import { defineComponent, toRaw } from 'vue';
import axios from 'axios';
export default defineComponent({
    name: "EditDriver",
		props: ['driverdata'],
		emits: ['responsedata'],
		components:{
			IonGrid,
			IonRow,
			IonItem,
			IonInput,
			IonCol,
			IonButton
		},
    methods:{
			editDriver(form){

				const rawObject = toRaw(form);
				//test call
				axios.post(this.$api_add + localStorage.getItem("org") +'users/update', rawObject).then((res)=>{
					if(res.data.result == 'ok'){
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.driverResponses.editSuccess')),
							editStatus: true
						});
					}
					else{
						this.$emit('responsedata', {
							statusMessage: String(res.status + " " + this.$t('operator.driverResponses.editFailure') + res.statusText),
							editStatus: false
						});
					}
				}).catch((e)=>{
					this.$emit('responsedata', {
						statusMessage: String(this.$t('operator.driverResponses.editFailure') + e.message),
						editStatus: false
					});
				});
			},
    },
    data(){
			const driverDt = toRaw(this.driverdata);
      return{
				editDriverForm: {
					id: driverDt.id,
					firstname: driverDt.firstname,
					lastname: driverDt.lastname,
					username: driverDt.username,
          email: driverDt.email,
          phone: driverDt.phone,
				},
				isdesktop: this.$isdesktop
      }
    },
		setup(){
			return{
				modalController
			}
		}
});
</script>
<style>
	.inpt{
		--background: #dedad9;
	}
</style>
