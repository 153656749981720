<template>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-header>
					<ion-toolbar>
						<ion-title>{{currentPage == 'table' ? $t('operator.vehicles') : $t('operator.addCar')}}</ion-title>
						<ion-buttons slot="secondary">
							<ion-button size="large" @click="currentPage == 'table' ? changeView('addCar') : changeView('table')">
								<ion-icon :icon="changeIcon()" slot="start" size="large"></ion-icon>
								{{currentPage == 'addCar' ? $t('operator.back') : $t('operator.addCar')}}
							</ion-button>
						</ion-buttons>
					</ion-toolbar>
				</ion-header>
				<ion-content class="ion-padding ion-margin-top carsTable" v-if="currentPage == 'table'">
          <ion-grid>
						<ion-row>
							<ion-col size="12">
								<ion-searchbar id="userSearch" :placeholder="$t('operator.searchCars')" @input="searchCars($event.target.value)"></ion-searchbar>
							</ion-col>
						</ion-row>
						<ion-row class="tableHeader">
							<ion-col size="2">
								<ion-item lines="none" class="smallertext">
									<b>{{$t('operator.carId')}}</b>
								</ion-item>
							</ion-col>
							<ion-col size="2">
								<ion-item lines="none" class="smallertext">
									<b>{{$t('operator.vehicleNumber')}}</b>
								</ion-item>
							</ion-col>
							<ion-col size="2">
								<ion-item lines="none" class="smallertext">
									<b>{{$t('operator.registerNumber')}}</b>
								</ion-item>
							</ion-col>
							<ion-col size="2">
								<ion-item lines="none" class="smallertext">
									<b>{{$t('operator.carEquipment')}}</b>
								</ion-item>
							</ion-col>
							<ion-col size="2">
								<ion-item lines="none" class="smallertext">
									<b>{{$t('operator.consumptionAndEmissions')}}</b>
								</ion-item>
							</ion-col>
							<ion-col size="2">
							</ion-col>
						</ion-row>
              <ion-row>
                <ion-col size="12" class="btmborder"  v-for="(cars,index) in filteredCars" :key="index">
                  <ion-row>
                    <ion-col size="2">
                      <ion-item lines="none">
                        {{cars.name}}
                      </ion-item>
                    </ion-col>
										<ion-col size="2">
											<ion-item lines="none">
												{{cars.number}}
											</ion-item>
										</ion-col>
										<ion-col size="2">
											<ion-item lines="none">
												{{cars.registration}}
											</ion-item>
										</ion-col>
                    <ion-col size="2">
                      <ion-item lines="none">
                        {{parseServices(cars.servicelevel)}}
												<br>
												{{$t('operator.carSeats') + ": " + (cars.capacity || "-")}}
                      </ion-item>
                    </ion-col>
										<ion-col size="2">
											<ion-item lines="none">
												{{cars.fuelconsumption + $t('operator.fuelUnit')}}
												<br>
												{{cars.emissions + $t('operator.emissionsUnit')}}
											</ion-item>
										</ion-col>
                    <ion-col size="2" v-if="isdesktop">
                      <ion-item lines="none">
												<ion-row>
													<ion-col size="12">
														<ion-button class="smallertext" @click="getEditData(index), setOpen(true)" size="full">{{$t('operator.modify')}}</ion-button>
													</ion-col>
													<ion-col size="12">
														<ion-button class="smallertext" @click="showDeleteAlert(index)" size="full" color="danger">{{$t('operator.delete')}}</ion-button>
													</ion-col>
												</ion-row>
                      </ion-item>
                    </ion-col>
										<ion-col size="2" v-else>
											<ion-item lines="none">
												<ion-row>
													<ion-col size="12">
														<ion-button @click="getEditData(index), setOpen(true)" size="large">
															<ion-icon :icon="pencilOutline"></ion-icon>
														</ion-button>
													</ion-col>
													<ion-col size="12">
														<ion-button @click="showDeleteAlert(index)" size="large" color="danger">
															<ion-icon :icon="trashOutline"></ion-icon>
														</ion-button>
													</ion-col>
												</ion-row>
											</ion-item>
										</ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
          </ion-grid>
				</ion-content>
        <ion-content class="ion-padding ion-margin-top carsTable" v-if="currentPage == 'addCar'">
					<AddCar @goback="parseAddEmited"/>
				</ion-content>
				<ion-modal :is-open="isOpenRef"  @didDismiss="setOpen(false), getCars()" v-on:openmodal="setOpen(openmodal)">
					<ion-content class="ion-padding ion-margin-top carsTable">
						<EditCar :cardata="editData" @responsedata="parseModalEmitted"/>
					</ion-content>
				</ion-modal>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import {
  IonContent,
  IonGrid,
  IonRow,
	IonButton,
	IonIcon,
	IonButtons,
	IonModal,
  IonItem,
  IonHeader,
  IonCol,
  IonTitle,
  IonToolbar,
	modalController,
	alertController,
	IonSearchbar
} from "@ionic/vue";
import {
	add,
	arrowBackOutline,
	pencilOutline,
	trashOutline
} from 'ionicons/icons';
import { defineComponent, toRaw, ref } from 'vue';
import AddCar from './AddCar.vue';
import EditCar from './EditCar.vue';
import StatusToast from '@/components/StatusToast.vue';
import axios from 'axios';
export default defineComponent({
    name: "OperatorCars",
    components:{
      IonContent,
			IonButton,
      IonGrid,
      IonRow,
			IonIcon,
			IonButtons,
			IonModal,
      IonItem,
      IonHeader,
      IonCol,
      IonTitle,
      IonToolbar,
			AddCar,
			EditCar,
			IonSearchbar
		},
    methods:{
			changeView(view){
				this.currentPage = view;
			},
			getEditData(index){
				this.editData = toRaw(this.filteredCars[index]);
			},
			parseAddEmited(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					this.changeView('table');
					this.getCars();
				}
			},
			parseModalEmitted(values){
				this.toastObject['message'] = values.statusMessage;
				this.toastObject['status']  = values.editStatus;

				this.showToast();
				if(values.editStatus){
					modalController.dismiss();
					this.getCars();
				}
			},
			getCars(){
				axios.post(this.$api_add + localStorage.getItem("org") +'vehicles/getvehicles').then((res)=>{
					if(res.data.result == 'ok'){
						this.cars = toRaw(res.data.data);
						if(this.filteredCars == null){
							this.filteredCars = this.cars;
						}
						else{
							this.searchCars(this.searchQuery);
						}
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.vehicleResponses.getFailed') + res.statusText);
						this.toastObject['status'] = false;
					}
				}).catch(e=>{
					this.toastObject['message'] = String(this.$t('operator.vehicleResponses.getFailed') + e);
					this.toastObject['status'] = false;
				})
			},
			parseServices(services){
			try{
				const srvs = services.split(",");
				const parsed = [];

				srvs.forEach((service)=>{
					parsed.push(service + "\n");
				})

				return parsed.join("");
			}
			catch(e){
				//console.log(e);
			}
				
			},
			searchCars(value){
				const query = toRaw(value).toLowerCase();
				if(!query){
					this.filteredCars = this.cars;
				}
				else{
					this.filteredCars = this.cars.filter((x)=>{
						const xvalues = String(Object.values(x).join(",")).toLowerCase();
						return xvalues.includes(query);
					});
				}
				this.searchQuery = query;
			},
			deleteCar(index){
				const deldata = this.filteredCars[index];
				//test call
				axios.post(this.$api_add + localStorage.getItem("org") +'vehicles/deletevehicle', deldata).then((res)=>{
					if(res.data.result == 'ok'){
						this.filteredCars.splice(index,1);
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.vehicleResponses.deleteSuccess'));
						this.toastObject['status'] = true;
					}
					else{
						this.toastObject['message'] = String(res.status + " " + this.$t('operator.vehicleResponses.deleteFailure') + res.statusText);
						this.toastObject['status']  = false
					}
					this.showToast();
				}).finally(()=>{
					this.getCars();
				}).catch((e)=>{
					this.toastObject['message'] = String(this.$t('operator.vehicleResponses.deleteFailure') + e);
					this.toastObject['status'] = false;
					this.showToast();
				});
			},
			changeIcon(){
				if(this.currentPage == 'table'){
					return add;
				}
				else if(this.currentPage == 'addCar'){
					return arrowBackOutline;
				}
			},
			showToast(){
				StatusToast.methods.openToast(this.toastObject);
			},
			async showDeleteAlert(index){
				const data = this.filteredCars[index];
				const message = this.$t("operator.carId") + ": " + data.name + "<br>"+this.$t('operator.registerNumber') +": "+data.registration
				const alert = await alertController.create({
					header: this.$t('operator.confirmDeleteCar'),
					message: message,
					buttons: [{
							text: this.$t('general.cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							id: 'cancel-button'
						},
						{
							id: 'confirm-button',
							text: this.$t('operator.delete'),
							cssClass:'warning',
							handler: () => {
								this.deleteCar(index);
							},
						}],
				});

				await alert.present();

				await alert.onDidDismiss();
			}
    },
		setup(){
			const isOpenRef = ref(false);
			const setOpen = (state) => isOpenRef.value = state;
			return{
				isOpenRef,
				setOpen,
        add,
				alertController,
				pencilOutline,
				trashOutline
			}

		},
		mounted(){
			this.getCars();
		},
    data(){
      return{
				toastObject:{
					"message": null,
					"status": false
				},
				editData: null,
				currentPage: 'table',
				cars:[],
				filteredCars: null,
				searchQuery: null,
				isdesktop: this.$isdesktop
      }
    }
});
</script>
<style>
  .carsTable{
		height: 90vh;
	}
	.tableHeader {
    display: flex; /* or inline-flex */
    flex-direction: row
	}
	.btmborder{
		border-bottom: solid 1px #cfcdca;
	}
	.iconbtn{
		font-size: 1.2em;
	}

	@media only screen and (max-width: 1300px) {
		.smallertext{
			font-size:0.8em;
		}
	}
</style>
